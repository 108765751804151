import React, { useState } from "react";
import { BeatLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";
import CheckListModal from "./CheckListModal";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";

const AvailabilityConfirmedRenter = ({
  message,
  onOpenModal,
  rentalRequestChatId,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);

  const navigate = useNavigate();

  const {
    listingTitle,
    startDate,
    content,
    endDate,
    totalCost,
    price,
    numberOfDays,
    listingImage,
  } = message;

  let deposit;

  if (message.deposit) {
    deposit = message.deposit;
  }

  const showChecklistModal = () => {
    setIsChecklistModalOpen(true);
  };

  const handleNavigateToRentalPaymentPage = () => {
    navigate("/confirm-rental", {
      state: {
        message,
        rentalRequestChatId,
      },
    });
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  // Convert Firestore Timestamp to JavaScript Date object
  const startDateDate = startDate.toDate();
  const endDateDate = endDate.toDate();

  const formatDate = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || "th";

    return `${day}${suffix} ${month}`;
  };

  // Using the converted Date objects from above
  const readableStartDate = formatDate(startDateDate);
  const readableEndDate = formatDate(endDateDate);

  return (
    <div className="">
      <CheckListModal
        isOpen={isChecklistModalOpen}
        onClose={() => setIsChecklistModalOpen(false)}
        handleConfirm={handleNavigateToRentalPaymentPage}
      />
      <h2 className="text-center font-bold">Availability Request</h2>
      <p className="text-center my-5 new-line">{content}</p>
      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md"></img>
        <p className="font-semibold mt-2">{listingTitle}</p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p>
          <span className="font-bold">Total Cost:</span> €{totalCost.toFixed(2)}
        </p>
        <p>
          <span className="font-bold">Duration:</span> {numberOfDays} days
        </p>
        <p>{`${readableStartDate} - ${readableEndDate}`}</p>
        {deposit && (
          <p>
            <span className="font-bold">Required deposit:</span> €
            {deposit.toFixed(2)}
          </p>
        )}
      </div>
      <p className="text-center my-4 font-bold text-emerald-400 text-lg">
        Item is available
      </p>
      <p className="text-sm text-center">
        Rental is not confirmed, you now need to pay and verify by clicking
        "Confirm Rental" below.
      </p>
      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3">
        <button
          className="p-2 btn-confirm"
          onClick={() => showChecklistModal()}
        >
          {isLoading ? (
            <BeatLoader />
          ) : (
            <p>
              {" "}
              <FontAwesomeIcon icon={faCheck} /> Confirm Rental
            </p>
          )}
        </button>
        <button onClick={() => onOpenModal()} className="p-2 btn-delete">
          <FontAwesomeIcon icon={faCancel} /> Cancel Request
        </button>
      </div>
      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>{" "}
      </div>
    </div>
  );
};

export default AvailabilityConfirmedRenter;
