import React, { useEffect, useState } from "react";
import Select from "react-select";
import categoriesAndSubcategories from "../config/categories"; // Adjust the import path as necessary

function CategorySelector({
  onCategorySelected,
  onSubcategorySelected,
  onNestedSubcategorySelected,
  initialCategory,
  initialSubcategory,
  initialNestedSubcategory,
}) {
  const [mainCategory, setMainCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  //const [nestedSubCategory, setNestedSubCategory] = useState(null);

  useEffect(() => {
    if (initialCategory) {
      const mainCat = {
        value: initialCategory,
        label: initialCategory,
      };
      setMainCategory(mainCat);
    }
    if (initialSubcategory) {
      const subCat = {
        value: initialSubcategory,
        label: initialSubcategory,
      };
      setSubCategory(subCat);
    }
  }, [initialCategory, initialSubcategory]);

  const handleMainCategoryChange = (selectedOption) => {
    setMainCategory(selectedOption);
    onCategorySelected(selectedOption); // Notify parent component
    setSubCategory(null); // Reset subCategory state
    //setNestedSubCategory(null); //
  };

  const handleSubCategoryChange = (selectedOption) => {
    setSubCategory(selectedOption);
    onSubcategorySelected(selectedOption); // Notify parent component
    //setNestedSubCategory(null);
  };

  const mainCategoriesOptions = Object.keys(categoriesAndSubcategories).map(
    (key) => ({
      value: key,
      label: key,
    })
  );

  const subCategoriesOptions = mainCategory
    ? Object.keys(categoriesAndSubcategories[mainCategory.value] || {}).map(
        (key) => ({
          value: key,
          label: key,
        })
      )
    : [];

  return (
    <div className="">
      <h2 className="label">Category</h2>
      <div className="flex flex-col gap-2 text-sm">
        <Select
          value={mainCategory}
          onChange={handleMainCategoryChange}
          options={mainCategoriesOptions}
          isSearchable
          placeholder="Select a Category"
        />
        {subCategoriesOptions.length > 0 && (
          <Select
            value={subCategory}
            onChange={handleSubCategoryChange}
            options={subCategoriesOptions}
            isSearchable
            placeholder="Select a Subcategory"
          />
        )}
      </div>
    </div>
  );
}

export default CategorySelector;
