import React from "react";

const CancellationPolicy = () => {
  return (
    <div>
      <h1>Cancellation Policy</h1>
      {/* Item Owners Cancellation Policy */}
      <div>
        <h2>Item Owners Cancellation Policy</h2>
      </div>

      {/* Renters Cancellation Policy */}
      <div>
        <h2>Renters Cancellation Policy</h2>
      </div>
    </div>
  );
};

export default CancellationPolicy;
