import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useAuth } from "../hooks/useAuth";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faUpload, faSave } from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase.config"; // Adjust the import path as necessary
import {
  doc,
  getDoc,
  updateDoc,
  collection,
  query,
  where,
  getDocs,
} from "firebase/firestore";
import {
  getAuth,
  updateEmail,
  signOut,
  verifyBeforeUpdateEmail,
} from "firebase/auth";
import { BeatLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import LocationSearch from "../components/LocationSearch";
import VeriffButton from "../components/VeriffButton";
import { toast } from "react-toastify";
import profilePicPlaceholder from "../assets/profilePicture.png";

const MyProfile = () => {
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    about: "",
    phoneNumber: "",
    emailVerified: false,
    profilePictureURL: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentUsername, setCurrentUsername] = useState("");
  const [usernameIsAvailable, setUsernameIsAvailable] = useState(false);
  const [usernameIsTaken, setUsernameIsTaken] = useState(false);
  const [location, setLocation] = useState({});
  const [geolocation, setGeolocation] = useState({});
  const [profilePictureURL, setProfilePictureURL] = useState("");
  const { currentUser } = useAuth();

  const { username, email, phoneNumber, about } = formData;

  const functions = getFunctions();

  const navigate = useNavigate();

  const fetchUserData = async () => {
    const userRef = doc(db, "users", currentUser.uid);
    const docSnap = await getDoc(userRef);
    if (docSnap.exists()) {
      setFormData(docSnap.data());
      setCurrentUsername(docSnap.data().username);
      setLocation(docSnap.data().location);
      setGeolocation(docSnap.data()._geoloc);

      if (docSnap.data().profilePictureURL) {
        setProfilePictureURL(docSnap.data().profilePictureURL);
      }
    }
  };

  const handleLocationChange = (selectedLocation) => {
    setLocation(selectedLocation);
  };
  // Debounce function
  function debounce(func, delay) {
    var inDebounce;

    return function () {
      var context = this;
      var args = arguments;
      clearTimeout(inDebounce);
      inDebounce = setTimeout(function () {
        func.apply(context, args);
      }, delay);
    };
  }

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const checkUsernameAvailability = async (username) => {
    if (username.length >= 4) {
      console.log("running query");
      // Reference to the users collection
      const usersRef = collection(db, "users");

      // Create a query against the collection to check for the username
      const q = query(usersRef, where("username", "==", username));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // If the querySnapshot is empty, then the username is available
      return querySnapshot.empty;
    }
  };

  const checkUsernameAvailabilityDebounce = useCallback(
    debounce((username) => {
      console.log("checking username availability");
      checkUsernameAvailability(username).then((isAvailable) => {
        if (isAvailable) {
          console.log(`${username} is available`);
          setUsernameIsAvailable(true);
          setUsernameIsTaken(false); // Ensure to reset this state
        } else {
          setUsernameIsAvailable(false);
          setUsernameIsTaken(true);
          console.log(`${username} is not available`);
        }
      });
    }, 2000),
    [] // Dependencies array
  );

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      username: username,
    }));
    setUsernameIsAvailable(false);
    setUsernameIsTaken(false);
    checkUsernameAvailabilityDebounce(username);
  };

  const cloudName = "kitsharecloudinary";
  const uploadPreset = "kitShareUploadPreset";

  const openUploadWidget = () => {
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: cloudName,
          uploadPreset: uploadPreset,
          multiple: false,
          maxFiles: 1,
          cropping: true,
          croppingShowBackButton: true,
          croppingDefaultSelectionRatio: 0.8,

          sources: ["local", "camera"],
          maxFileSize: 5500000,

          folder: "profilePictures",
          context: {
            alt: "profile_picture",
            caption: "profile picture",
          },
          resourceType: "image",
        },
        (error, result) => {
          if (!error && result && result.event === "success") {
            setProfilePictureURL(result.info.secure_url);
            console.log("Image uploaded successfully:", result.info.secure_url);
          }
        }
      )
      .open();
  };

  const handleEmailUpdate = async (newEmail) => {
    const auth = getAuth();
    const user = auth.currentUser;

    try {
      await updateEmail(user, newEmail);
      // Also update email in Firestore to keep it in sync
      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, { email: newEmail });
      toast.success("Email updated successfully!");
    } catch (error) {
      toast.error("Failed to update email:", error);
      console.error("Failed to update email:", error);
    }
  };

  const handleSaveChanges = async () => {
    try {
      setIsLoading(true);
      const updateData = {
        about,
        phoneNumber,
        location: { town: location.town, county: location.county },
        _geoloc: { lat: geolocation.lat, lng: geolocation.lng },
      };

      if (username !== currentUsername && usernameIsAvailable) {
        updateData.username = username;
      }

      if (formData.profileImage) {
        // const newImageUrl = await storeImage(formData.profileImage);
        // updateData.profilePictureURL = newImageUrl;
      }

      const userDocRef = doc(db, "users", currentUser.uid);
      await updateDoc(userDocRef, updateData);
      setIsLoading(false);
      toast.success("Profile changes saved successfully.");

      // First - check if user has changed their email address - if so, run handleEmailUpdate
      if (formData.email !== currentUser.email) {
        await handleEmailUpdate(formData.email);
        toast.info(
          "You need to reauthenticate after changing your email address",
          { autoClose: 5000 }
        );
        // If email has been changed - log out the user and request the user to re-authenticate
        signOut();
        navigate("/sign-in");
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to save changes: " + error.message);
    }
  };

  const loadCloudinaryScript = (callback) => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        if (callback) callback();
      };
    } else if (callback) {
      callback();
    }
  };

  useEffect(() => {
    loadCloudinaryScript(() => {
      console.log("cloudinary script loaded");
    });
  }, []);

  useEffect(() => {
    if (currentUser) {
      fetchUserData();
    }
  }, [currentUser]);

  return (
    <div className="flex flex-col items-center justify-center mb-5 px-5 md:px-0">
      <div className="flex w-full justify-center">
        <h1 className="w-2/3 text-center mt-5 text-xl font-bold header__center">
          Edit Profile
        </h1>
      </div>
      <div className="mt-5">
        <div className="relative rounded-full w-40 h-40 bg-gray-200 overflow-hidden">
          <img
            src={profilePictureURL ? profilePictureURL : profilePicPlaceholder}
            alt="ProfilePicture"
            className="object-cover w-full h-full text-center"
            // onError={(e) => (e.target.src = "path/to/your/default-avatar.jpg")} // Placeholder if the image fails to load
          />

          <button
            onClick={() => openUploadWidget()}
            className="absolute bottom-5 right-5 p-2 bg-white rounded-full text-teal-500 hover:text-teal-600"
          >
            <FontAwesomeIcon icon={faEdit} />
          </button>
        </div>
      </div>
      <p className="pt-5">
        Email verified:{" "}
        {currentUser?.emailVerified ? (
          <span className="font-bold text-emerald-600">Yes</span>
        ) : (
          <span className="font-bold text-red-500">No</span>
        )}
      </p>

      {/* Veriff Button to verify ID */}
      <VeriffButton
        currentUser={currentUser}
        idVerified={currentUser.idVerified}
      />

      <div className="w-full max-w-md">
        <hr className="my-5"></hr>
        {/* Profile Information */}
        <label className="block">
          <span className="text-gray-700 font-bold text-sm">About You</span>
          <textarea
            id="about"
            placeholder="Add a few words about yourself.."
            value={about}
            onChange={onChange}
            className="text-sm mt-1 block w-full rounded-md border-gray-400 shadow-sm"
          />
        </label>

        <label className="block mt-4">
          <span className="text-gray-700 font-bold text-sm">Email</span>
          <input
            type="text"
            id="email"
            value={email}
            onChange={onChange}
            className="text-sm mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </label>
        <div className="flex flex-col mt-5 shadow-sm text-sm">
          <span className="text-gray-700 font-bold text-sm">Username</span>
          {usernameIsTaken ? (
            <label className="text-sm font-bold text-red-500">
              {username} is not available
            </label>
          ) : usernameIsAvailable && username.length >= 4 ? (
            <label className="text-xs font-bold text-green-500">
              {username} is available
            </label>
          ) : null}
          {/* {usernameIsAvailable && username.length >= 4 ? (
                    <label className="text-sm font-bold text-green-500">
                      Username is available
                    </label>
                  ) : null} */}
          <input
            className={
              username.length >= 4 && usernameIsTaken
                ? "rejected p-2 text-sm mt-1"
                : username.length >= 4 && usernameIsAvailable
                ? "accepted p-2 text-sm mt-1"
                : "p-2 text-sm mt-1 "
            }
            type="text"
            id="username"
            value={username}
            onChange={onChangeUsername}
            placeholder="Username"
          ></input>
        </div>
        <label className="block mt-4">
          <span className="text-gray-700 font-bold text-sm">Phone Number</span>
          <input
            onChange={onChange}
            id="phoneNumber"
            type="text"
            value={phoneNumber}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
          />
        </label>
        <label className="block mt-4">
          <span className="text-gray-700 font-bold text-sm">
            General Location
          </span>
          <LocationSearch
            onLocationSelect={handleLocationChange}
            defaultLocation={currentUser.location}
          />
        </label>

        <div className="flex w-full justify-center align-center items-center gap-5">
          <div className="flex justify-center">
            <button
              className="btn-secondary px-2 sm:px-3 text-sm sm:text-base"
              onClick={openUploadWidget}
              type="button"
            >
              <FontAwesomeIcon icon={faUpload} /> Update Profile Picture
            </button>
          </div>

          <div className="flex justify-center">
            {isLoading ? (
              <button
                onClick={() => handleSaveChanges()}
                className="btn-continue px-3 my-3"
              >
                <BeatLoader />
              </button>
            ) : (
              <button
                onClick={() => handleSaveChanges()}
                className="btn-continue px-3 my-3 text-sm sm:text-base"
              >
                <FontAwesomeIcon icon={faSave} /> Save Changes
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyProfile;
