import React, { useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import {
  getAuth,
  createUserWithEmailAndPassword,
  updateProfile,
  sendEmailVerification,
} from "firebase/auth";
import { collection, query, where, getDocs } from "firebase/firestore";
import { app, db } from "../firebase.config";
import { setDoc, doc, serverTimestamp } from "firebase/firestore";
import "../css/SignUp.css";
import profanityList from "../data/profanityList.js";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import Footer from "../components/Footer";
import { FaRegCircleUser } from "react-icons/fa6";
import { formatToTimeZone } from "date-fns-timezone";
import LocationSearch from "../components/LocationSearch";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import SignUpPic from "../assets/signUp.png";

const windowWidth = window.screen.width;

// Get time of order - sending to order confirmation
const date = new Date();
const format = "dd-MM-yyyy";
const signUpDate = formatToTimeZone(date, format, {
  timeZone: "Australia/Sydney",
});

// Debounce function
function debounce(func, delay) {
  var inDebounce;

  return function () {
    var context = this;
    var args = arguments;
    clearTimeout(inDebounce);
    inDebounce = setTimeout(function () {
      func.apply(context, args);
    }, delay);
  };
}

const SignUp = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    username: "",
    confirmPassword: "",
    //phoneNumber: "",
    //isBusiness: false,
    //profilePictureURL: ""
  });
  const [usernameIsAvailable, setUsernameIsAvailable] = useState(false);
  const [usernameIsTaken, setUsernameIsTaken] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [location, setLocation] = useState(null);
  const [phoneNumber, setPhoneNumber] = useState();

  const {
    email,
    password,
    username,
    firstName,
    lastName,
    confirmPassword,
    //phoneNumber,
    //isBusiness,
  } = formData;

  const navigate = useNavigate();

  const auth = getAuth(app);

  const checkUsernameAvailability = async (username) => {
    if (username.length >= 4) {
      console.log("running query");
      // Reference to the users collection
      const usersRef = collection(db, "users");

      // Create a query against the collection to check for the username
      const q = query(usersRef, where("username", "==", username));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // If the querySnapshot is empty, then the username is available
      return querySnapshot.empty;
    }
  };

  const checkUsernameAvailabilityDebounce = useCallback(
    debounce((username) => {
      console.log("checking username availability");
      checkUsernameAvailability(username).then((isAvailable) => {
        if (isAvailable) {
          console.log(`${username} is available`);
          setUsernameIsAvailable(true);
          setUsernameIsTaken(false); // Ensure to reset this state
        } else {
          setUsernameIsAvailable(false);
          setUsernameIsTaken(true);
          console.log(`${username} is not available`);
        }
      });
    }, 2000),
    [] // Dependencies array
  );

  const checkIfEmailIsAlreadySignedUp = async (email) => {
    try {
      const emailsRef = collection(db, "users");

      // Create a query against the collection to check for the username
      const q = query(emailsRef, where("email", "==", email));

      // Execute the query
      const querySnapshot = await getDocs(q);

      // If email is found - return and show toast notification
      if (!querySnapshot.empty) {
        toast.error("This email is already signed up.", { autoClose: 4000 });
      }
      return;
    } catch (error) {
      console.log(error.message);
    }

    // If the querySnapshot is empty, then the username is available
    //return !querySnapshot.empty;
  };

  // Sign Up User
  const createUserAccount = async (e) => {
    e.preventDefault();
    setShowSpinner(true);

    if (
      // profanityList.some((v) => firstName.toLowerCase().includes(v)) ||
      // profanityList.some((x) => lastName.toLowerCase().includes(x))
      profanityList.some((x) => username.toLowerCase().includes(x))
    ) {
      toast.error("Profanity detected in username, please choose another.", {
        autoClose: 2000,
      });
      setShowSpinner(false);
      return;
    }

    if (!usernameIsAvailable) {
      toast.error("Username is no longer available.");
      setShowSpinner(false);
      return;
    }

    await checkIfEmailIsAlreadySignedUp(email);

    // Check if user has selected location
    if (!location?.town && !location?.county) {
      toast.error(
        "Please select your closest location from the location dropdown. This general location is only used to find listings near you. ",
        {
          autoClose: 8000,
        }
      );
      setShowSpinner(false);
      return;
    }

    if (
      (isEmail(email) &&
        firstName.length > 1 &&
        lastName.length > 1 &&
        username.length > 4,
      password === confirmPassword &&
        phoneNumber.length < 14 &&
        phoneNumber.length > 6)
    ) {
      try {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential.user;

        updateProfile(auth.currentUser, {
          //displayName: `${firstName} ${lastName}`,
          displayName: username,
        });

        const formDataCopy = { ...formData };
        delete formDataCopy.password;
        delete formDataCopy.confirmPassword;

        const geoloc = { lat: location.lat, lng: location.lng };
        const loc = { town: location.town, county: location.county };

        // Submitting user information to DB
        await setDoc(doc(db, "users", user.uid), {
          username: formDataCopy.username,
          email: formDataCopy.email,
          phoneNumber,
          firstName: formDataCopy.firstName,
          lastName: formDataCopy.lastName,
          idVerified: false,
          // strikeCount: 0,
          cancellationCount: 0,
          bookingCount: 0,
          rentalCount: 0,
          reviewCount: 0,
          _geoloc: geoloc,
          location: loc,
          signUpTimestamp: serverTimestamp(),
        });

        setShowSpinner(false);
        toast.success("Thanks for signing up!", { autoClose: 2000 });

        // Send verification email to user
        await sendEmailVerification(user).then(() => {
          toast.warning(
            "Please check your email to verify your email. You will not be able to rent or list items for rent until you verify your email.",
            { autoClose: 6000 }
          );
        });

        navigate("/");
      } catch (error) {
        toast.error(`${error.message}`);
        setShowSpinner(false);
      }
    } else {
      toast.error("Something went wrong - check form items.", {
        autoClose: 2000,
      });
      setShowSpinner(false);
    }
  };

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setFormData((prevState) => ({
      ...prevState,
      username: username,
    }));
    setUsernameIsAvailable(false);
    setUsernameIsTaken(false);
    checkUsernameAvailabilityDebounce(username);
  };

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  // const onChangeIsBusiness = (e) => {
  //   setFormData((prevState) => ({
  //     ...prevState,
  //     isBusiness: !isBusiness,
  //   }));
  // };

  // function to check if email is correct email format
  const isEmail = (email) => {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  const handleLocationChange = (selectedLocation) => {
    setLocation(selectedLocation);
  };

  return (
    <div className="force-footer">
      <div className="main-content">
        <div className="flex w-full justify-center">
          <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
            Register Here
          </h1>
        </div>

        <div className="flex justify-center">
          <div className="flex justify-center my-3 w-full max-w-3xl px-10 md:px-0">
            <div className="border-2 rounded-2xl border-gray-300/80 py-3 px-4 shadow-inner shadow-md bg-gray-50 mb-5 w-full">
              <div className="flex flex-col justify-center items-center text-center">
                <img src={SignUpPic} alt="signUp" className="w-64 rounded-xl" />
                <p className="mt-2 text-base sm:text-lg font-semibold">
                  Sign Up
                </p>
              </div>

              <form onSubmit={createUserAccount} className="mt-4">
                <div className="flex flex-wrap justify-between gap-4">
                  {/* Username Field */}
                  <div className="flex flex-col w-full md:w-[48%] ">
                    <label className="text-sm mb-1 font-bold">Username</label>
                    <input
                      className="p-2 text-sm sm:text-base"
                      type="text"
                      id="username"
                      value={username}
                      maxLength={16}
                      onChange={onChangeUsername}
                      placeholder="Max 16 characters.."
                    />
                  </div>

                  {/* Email Field */}
                  <div className="flex flex-col w-full md:w-[48%] ">
                    <label className="text-sm font-bold mb-1">Email</label>
                    <input
                      className="p-2 text-sm sm:text-base"
                      type="email"
                      id="email"
                      value={email}
                      onChange={onChange}
                      placeholder="Enter email"
                    />
                  </div>

                  {/* First Name Field */}
                  <div className="flex flex-col w-full md:w-[48%] ">
                    <label className="text-sm font-bold mb-1">First Name</label>
                    <input
                      className="p-2 text-sm sm:text-base"
                      type="text"
                      id="firstName"
                      value={firstName}
                      onChange={onChange}
                      placeholder="First Name"
                    />
                  </div>

                  {/* Last Name Field */}
                  <div className="flex flex-col w-full md:w-[48%] ">
                    <label className="text-sm font-bold mb-1">Last Name</label>
                    <input
                      className="p-2 text-sm sm:text-base"
                      type="text"
                      id="lastName"
                      value={lastName}
                      onChange={onChange}
                      placeholder="Surname/Last name"
                    />
                  </div>

                  {/* Password Field */}
                  <div className="flex flex-col w-full md:w-[48%] ">
                    <label className="text-sm mb-1 font-bold">Password</label>
                    <input
                      className="p-2 text-sm sm:text-base"
                      type="password"
                      id="password"
                      placeholder="Minimum 6 characters..."
                      value={password}
                      onChange={onChange}
                    />
                  </div>

                  {/* Confirm Password Field */}
                  <div className="flex flex-col w-full md:w-[48%] shadow-md">
                    <label className="text-sm mb-1 font-bold">
                      Confirm Password
                    </label>
                    <input
                      className="p-2 text-sm sm:text-base"
                      type="password"
                      id="confirmPassword"
                      placeholder="Confirm password"
                      value={confirmPassword}
                      onChange={onChange}
                    />
                  </div>

                  {/* Phone Number Field */}
                  <div className="flex flex-col w-full md:w-[48%]">
                    <label className="text-sm mb-1 font-bold">
                      Phone Number
                    </label>
                    <PhoneInput
                      defaultCountry="IE"
                      country="IE"
                      onChange={setPhoneNumber}
                      value={phoneNumber}
                    />
                  </div>

                  {/* Location Field */}
                  <div className="flex flex-col w-full md:w-[48%]">
                    <label className="text-sm mb-1 font-bold">Location</label>
                    <LocationSearch onLocationSelect={handleLocationChange} />
                    <p className="mt-1 text-xs">
                      *Sharing your location enhances your experience by
                      enabling location-based filtering of listings, making it
                      easier to find and offer relevant listings nearby.
                    </p>
                  </div>
                </div>

                {showSpinner ? (
                  <div className="flex mt-5 mb-4 justify-center">
                    <BeatLoader color="#14B8A6" />
                  </div>
                ) : (
                  <div className="text-center rounded-md py-2 mt-4 mb-4">
                    <button className="w-full md:w-1/2 btn-primary">
                      Sign Up
                    </button>
                  </div>
                )}

                <div
                  onClick={() => navigate("/sign-in")}
                  className="text-center mt-5 border-gray-300 duration-300 hover:font-semibold hover:cursor-pointer"
                >
                  <button className="text-base text-accent font-semibold hover:font-bold">
                    Sign In Instead
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SignUp;
