import React, { useState, useEffect } from "react";
import ReviewForm from "../components/ReviewForm";
import { useParams } from "react-router-dom";
import { db } from "../firebase.config";
import { doc, getDoc } from "firebase/firestore";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";

const ReviewPage = () => {
  const [revieweeDetails, setRevieweeDetails] = useState({});
  const [listingDetails, setListingDetails] = useState({});
  const [rentalRequestDetails, setRentalRequestDetails] = useState({});
  const { rentalRequestId, revieweeUid } = useParams(); // Ensure these params are passed in the URL

  const getRentalRequestDetails = async () => {
    const rentalRequestRef = doc(db, "rental-requests", rentalRequestId);
    const rentalRequestDoc = await getDoc(rentalRequestRef);
    if (rentalRequestDoc.exists()) {
      setRentalRequestDetails(rentalRequestDoc.data());
      return rentalRequestDoc.data();
    }
  };

  const getListingDetails = async () => {
    const rentalRequestDetails = await getRentalRequestDetails();
    const { listingId } = rentalRequestDetails;
    const listingRef = doc(db, "listings", listingId);
    const listingDoc = await getDoc(listingRef);
    if (listingDoc.exists()) {
      setListingDetails({
        ...listingDoc.data(),
        id: listingDoc.id,
      });
    }
  };

  const getRevieweeDetails = async () => {
    const userRef = doc(db, "users", revieweeUid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      setRevieweeDetails(userDoc.data());
    }
  };

  useEffect(() => {
    getRevieweeDetails();
    getListingDetails();
  }, []);

  return (
    <div className="container mx-auto">
      <h1 className="text-center my-5 text-xl font-bold header__center">
        Review Your Rental Experience
      </h1>
      <div className="flex justify-center mb-5">
        {/* <p className="max-w-2xl text-center text-gray-700">
          Reviews are a crucial element of the KitShare experience. They build
          trust between renters and owners, providing both parties with the
          confidence they need to engage in transactions. For renters, reviews
          offer insights into the reliability and quality of the items available
          for rent, ensuring that they can make informed decisions. For owners,
          reviews validate their credibility and encourage more users to rent
          their items, knowing they will receive them in good condition and on
          time. This mutual feedback system not only fosters a sense of
          community but also enhances the overall safety and reliability of the
          KitShare platform, giving everyone peace of mind whether they are
          renting or leasing their items.
        </p> */}
      </div>
      {/* Listing Details */}
      <div className="w-full flex justify-center mb-10">
        <div className="flex flex-col w-1/4 items-center rounded-md shadow-lg p-5">
          <h2 className="font-semibold my-3 text-lg text-gray-800">
            {listingDetails.title}
          </h2>
          <img
            src={listingDetails.imageURLs ? listingDetails.imageURLs[0] : ""}
            alt="Listing"
            className="w-48 h-48 object-cover rounded-md mb-2"
          />
          <div className="flex flex-col text-gray-600 gap-2">
            <p>
              <span className="font-bold">Duration:</span>{" "}
              {rentalRequestDetails.numberOfDays} days
            </p>
            <p>
              <span className="font-bold">From:</span>{" "}
              {firestoreTimestampToDate(
                rentalRequestDetails.startDate
              )?.toLocaleDateString()}
            </p>
            <p>
              <span className="font-bold">To:</span>{" "}
              {firestoreTimestampToDate(
                rentalRequestDetails.endDate
              )?.toLocaleDateString()}
            </p>
            <p className="text-center text-teal-500 font-semibold">
              {revieweeDetails.username}
            </p>
          </div>
        </div>
      </div>
      <ReviewForm
        rentalRequestId={rentalRequestId}
        rentalRequestDetails={rentalRequestDetails}
        revieweeUid={revieweeUid}
        revieweeDetails={revieweeDetails}
        listingDetails={listingDetails}
      />
    </div>
  );
};

export default ReviewPage;
