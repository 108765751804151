import { useState, useEffect } from "react";
import { db } from "../firebase.config";
import {
  collection,
  addDoc,
  serverTimestamp,
  doc,
  getDoc,
  updateDoc,
  increment,
} from "firebase/firestore";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { BeatLoader } from "react-spinners";
import Footer from "../components/Footer";

const CheckAvailability = () => {
  const [requestMessage, setRequestMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [listingOwnerDetails, setListingOwnerDetails] = useState({});
  const [isThreePlusDayRate, setIsThreePlusDayRate] = useState(false);
  const [isSevenPlusDayRate, setIsSevenPlusDayRate] = useState(false);
  const [deposit, setDeposit] = useState(0);

  const navigate = useNavigate();
  const location = useLocation();
  const { startDate, endDate, listing } = location.state;

  const [calculatedRate, setCalculatedRate] = useState();

  const { dailyRate, threePlusDayRate, sevenPlusDayRate } = listing;

  console.log(startDate, endDate);

  console.log(startDate.getTime());

  const { currentUser } = useAuth();

  const getListingOwnerDetails = async (listingOwnerUid) => {
    // ownerUid
    const ownerDocRef = doc(db, "users", listingOwnerUid);
    const ownerSnap = await getDoc(ownerDocRef);
    if (ownerSnap.exists()) {
      setListingOwnerDetails(ownerSnap.data());
    } else {
      setListingOwnerDetails({ username: null, location: null });
    }
  };

  useEffect(() => {
    getListingOwnerDetails(listing.ownerUid);
    calculateAppropriateRate();
    if (listing.deposit) setDeposit(listing.deposit);
  }, [listing]);

  const calculateDaysBetweenDates = () => {
    if (!startDate || !endDate) return 0;
    const diffTime = Math.abs(endDate - startDate);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays + 1; // Add 1 to include the end date in the count
  };

  // Rental details and calculations
  const rentalCost =
    calculateDaysBetweenDates(startDate, endDate) * calculatedRate;
  const numberOfDays = calculateDaysBetweenDates(startDate, endDate);
  const serviceFee = rentalCost * 0.1;
  const totalCost = rentalCost + serviceFee;

  const calculateAppropriateRate = () => {
    // if number of days = 7+ and 7+day rate exists
    if (sevenPlusDayRate && numberOfDays >= 7) {
      setCalculatedRate(sevenPlusDayRate);
      setIsSevenPlusDayRate(true);
    } else if (threePlusDayRate && numberOfDays < 7 && numberOfDays >= 3) {
      setCalculatedRate(threePlusDayRate);
      setIsThreePlusDayRate(true);
    } else {
      setCalculatedRate(dailyRate);
    }
  };

  const createUserRentalRequest = async () => {
    try {
      setIsLoading(true);

      // Prepare the rental request data
      const rentalRequestData = {
        listingId: listing.id,
        ownerUid: listing.ownerUid,
        renterUid: currentUser.uid,
        startDate: startDate,
        endDate: endDate,
        numberOfDays: numberOfDays,
        rate: calculatedRate,
        rentalCost: rentalCost,
        serviceFee: serviceFee,
        totalCost: totalCost,
        status: "open",
        createdAt: serverTimestamp(),
        seen: false,
        renterHasReviewed: false,
        ownerHasReviewed: false,
      };

      // Include deposit if it exists
      if (listing.deposit) {
        rentalRequestData.deposit = listing.deposit;
      }

      // Add a new document in the rental-requests collection
      const rentalRequestDocument = await addDoc(
        collection(db, "rental-requests"),
        rentalRequestData
      );

      setIsLoading(false);
      return rentalRequestDocument;
    } catch (error) {
      console.error("Error submitting booking request: ", error);
      setIsLoading(false);
      toast.error("Failed to submit booking request.");
    }
  };

  const incrementOwnerNewMessageCount = async () => {
    const ownerRef = doc(db, "users", listing.ownerUid);
    await updateDoc(ownerRef, {
      newMessageCount: increment(1),
    });
  };

  const createInitialChatMessage = async (rentalRequestDocumentId) => {
    try {
      setIsLoading(true);

      // Add a new document in the messages collection
      const messageRef = await addDoc(
        collection(db, "rental-requests", rentalRequestDocumentId, "messages"),
        {
          listingId: listing.id,
          listingTitle: listing.title,
          listingImage: listing.imageURLs[0],
          startDate: startDate,
          endDate: endDate,
          numberOfDays: numberOfDays,
          ownerUid: listing.ownerUid,
          renterUid: currentUser.uid,
          rate: calculatedRate,
          rentalCost: rentalCost,
          serviceFee: serviceFee,
          rentalRequestId: rentalRequestDocumentId,
          totalCost: totalCost,
          senderUid: currentUser.uid,
          receiverUid: listing.ownerUid,
          type: "availability-request",
          content: requestMessage,
          createdAt: serverTimestamp(),
        }
      );

      // If there is a deposit required for this rental - add this to the message document
      if (listing.deposit) {
        await updateDoc(messageRef, {
          deposit: listing.deposit,
        });
      }

      toast.success(
        "The owner has been notified of your request. Keep an eye on your inbox for a response.",
        { autoClose: 5000 }
      );
      setIsLoading(false);
      navigate("/");
    } catch (error) {
      console.error("Error submitting booking request: ", error);
      setIsLoading(false);
      // Handle the error, possibly showing a notification to the user
    }
  };

  const createAvailabilityRequestEmail = async () => {
    const body = JSON.stringify({
      ownerUsername: listingOwnerDetails.username,
      ownerEmail: listingOwnerDetails.email,
      requestMessage: requestMessage,
      renterUsername: currentUser.username,
      listingTitle: listing.title,
      earnings: calculatedRate * numberOfDays,
      duration: numberOfDays,
      startDate: startDate.toDateString(),
      endDate: endDate.toDateString(),
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://availabilityrequestemail-availabilityrequestemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      toast.error(
        "Something went wrong sending the availability rejection email.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  const handleMessageChange = (e) => {
    setRequestMessage(e.target.value);
  };

  const handleSubmitRequest = async () => {
    // Ensure user has submitted message
    if (requestMessage.length <= 0) {
      toast.error("You need to provide a message to the item owner.", {
        autoClose: 3000,
      });
      // Message contains content, process create rental request and initial check availability chat message
    } else {
      const rentalRequestDocument = await createUserRentalRequest();
      await createInitialChatMessage(rentalRequestDocument.id);
      await incrementOwnerNewMessageCount();
      await createAvailabilityRequestEmail();
    }
  };

  // Use startDate, endDate, and listingId as needed in this component
  return (
    <div>
      <div className="flex px-5 flex-col-reverse sm:flex-row justify-center sm:gap-10 md:gap-20 mb-5">
        {/* Left hand side container - message request and buttons */}
        <div className="w-full p-3 sm:p-0 sm:w-1/3 my-4">
          <h2 className="font-semibold text-lg">Check Availability</h2>
          <p className="mb-4">
            Request confirmation of availability from the owner before you pay
            and verify.
          </p>
          <p className="font-semibold">Confirm with the owner:</p>
          <ul className="">
            <li>- Pickup and return times</li>
            <li>- Item Condition</li>
            <li>- Deposit collection preference (cash, bank transfer etc.)</li>
          </ul>

          <h2 className="font-semibold text-lg mt-5 mb-1">
            Message the Owner <span className="text-xs">(required)</span>
          </h2>

          <textarea
            value={requestMessage}
            onChange={handleMessageChange}
            placeholder="Send any details about your request including pickup times etc."
            className="text-sm"
            rows={5}
          ></textarea>
          <div className="flex gap-5 justify-center mt-5">
            <button className="px-10 btn-back" onClick={() => navigate(-1)}>
              Back
            </button>
            <button
              disabled={isLoading}
              onClick={handleSubmitRequest}
              className={`${
                requestMessage.length <= 0
                  ? "px-5 sm:px-10 btn-disabled"
                  : "px-5 sm:px-10 btn-continue"
              }`}
            >
              {isLoading ? <BeatLoader color="white" /> : "Submit Request"}
            </button>
          </div>
        </div>
        {/* Right hand side container - listing, user and pricing details */}
        <div className="w-full sm:w-1/4 p-3 sm:p-0">
          <h2 className="font-semibold text-lg my-4">{listing.title}</h2>
          {/* User details */}
          <div className="flex justify-between">
            <p className="text-base mb-2">
              <span className="font-semibold">
                {listingOwnerDetails?.username ?? ""}
              </span>{" "}
              in {listingOwnerDetails?.location?.town ?? ""}
            </p>
            <div className="flex items-center mb-2 gap-2">
              <p className="text-sm">
                {listingOwnerDetails.averageReviewRating
                  ? listingOwnerDetails.averageReviewRating
                  : "N/A"}{" "}
                <FontAwesomeIcon icon={faStar} color="gold" />
              </p>
              <p className="text-sm">
                {" "}
                (
                {listingOwnerDetails.reviewCount > 0
                  ? listingOwnerDetails.reviewCount
                  : 0}
                )
              </p>
            </div>
          </div>
          {/* Listing pricing details */}
          <div className="bg-gray-100 shadow-md flex flex-col gap-3 rounded-md p-2">
            <p>
              <span className="font-semibold">Number of days: </span>{" "}
              {numberOfDays}
            </p>
            <p>
              <span className="font-semibold">Dates:</span>{" "}
              {startDate.toDateString()} - {endDate.toDateString()}
            </p>
            {deposit > 0 ? (
              <p className="">
                <span className="font-semibold">Required Deposit:</span> €
                {deposit.toFixed(2)}
              </p>
            ) : null}
            {isSevenPlusDayRate ? (
              <div className="bg-teal-500 text-center text-white font-semibold p-1 rounded-md shadow-sm">
                7+ day rate
              </div>
            ) : isThreePlusDayRate ? (
              <div className="bg-teal-500 text-center text-white font-semibold p-1 rounded-md shadow-sm">
                3+ day rate
              </div>
            ) : null}
            <p>
              <span className="font-semibold">Rate:</span> €
              {calculatedRate && calculatedRate.toFixed(2)}
            </p>
            {/* <div>
            {threeDayPlusRate ? <p>3+ Day Rate: €{threeDayPlusRate}</p> : null}
          </div>
          <div>
            {sevenDayPlusRate ? <p>7+ Day Rate: €{sevenDayPlusRate}</p> : null}
          </div> */}
            <p className="border-t-2 border-gray-400 pt-4">
              €{calculatedRate && calculatedRate.toFixed(2)} x {numberOfDays}{" "}
              days = €{rentalCost.toFixed(2)}
            </p>
            <p>
              <span className="font-semibold">Service Fee (10%):</span> €
              {serviceFee.toFixed(2)}
            </p>
            <p className="border-t-2 border-gray-400 pt-4">
              <span className="font-semibold">Total:</span> €
              {totalCost.toFixed(2)}
            </p>
          </div>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
};

export default CheckAvailability;
