import {
  faHeart,
  faMapLocationDot,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase.config";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
} from "firebase/firestore";
import { useAuth } from "../../hooks/useAuth";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";

const UserProfileListingCard = ({ listingDetails, onRemove }) => {
  const [itemOwnerDetails, setItemOwnerDetails] = useState({});
  const [isFavourited, setIsFavourited] = useState(false);
  const { title, dailyRate, location, imageURLs, id, ownerUid } =
    listingDetails;
  const [isLoading, setIsLoading] = useState(false);

  const { currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const getItemOwnerDetails = async () => {
      const itemOwnerRef = doc(db, "users", ownerUid);
      const itemOwnerSnap = await getDoc(itemOwnerRef);
      if (itemOwnerSnap.exists()) {
        setItemOwnerDetails({
          ...itemOwnerSnap.data(),
          id: itemOwnerSnap.id,
        });
      }
    };

    const checkIfListingIsFavourited = async () => {
      if (currentUser) {
        const userRef = doc(db, "users", currentUser.uid);
        const userSnap = await getDoc(userRef);
        if (userSnap.exists() && userSnap.data().favourites?.includes(id)) {
          setIsFavourited(true);
        }
      }
    };

    getItemOwnerDetails();
    checkIfListingIsFavourited();
  }, [currentUser, ownerUid, id]);

  const handleAddToFavourites = async (e) => {
    e.stopPropagation();

    // Check if user is logged in
    if (!currentUser) {
      toast.error("You need to be logged in to add to favourites.", {
        autoClose: 2000,
      });
      return;
    }

    const userRef = doc(db, "users", currentUser.uid);
    try {
      setIsLoading(true);
      await updateDoc(userRef, {
        favourites: arrayUnion(id),
      });
      setIsFavourited(true);
      toast.success("Added to favourites!");
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to add to favourites");
      setIsLoading(false);
    }
  };

  const handleRemoveFromFavourites = async (e) => {
    e.stopPropagation();

    // Check if user is logged in first
    if (!currentUser) {
      toast.error("You need to be logged in to remove from favourites.", {
        autoClose: 2000,
      });
      return;
    }

    const userRef = doc(db, "users", currentUser.uid);
    try {
      setIsLoading(true);
      await updateDoc(userRef, {
        favourites: arrayRemove(id),
      });
      setIsFavourited(false);
      onRemove && onRemove(id); // If there's an onRemove function, call it
      toast.success("Removed from favourites!");
      setIsLoading(false);
    } catch (error) {
      toast.error("Failed to remove from favourites");
      setIsLoading(false);
    }
  };

  const handleNavigate = (e) => {
    e.stopPropagation();
    const titleSlug = title.replace(/\s+/g, "-").toLowerCase();
    //const path = `/listings/${location.town}/${encodeURIComponent(id)}`;
    const path = `/listings/${encodeURIComponent(id)}`;
    navigate(path);
  };

  return (
    <div
      className="w-64 bg-white pb-4 rounded-lg shadow transition duration-300 ease-in-out transform hover:-translate-y-1 hover:shadow-lg cursor-pointer"
      onClick={handleNavigate}
    >
      <div className="relative">
        <img
          src={imageURLs ? imageURLs[0] : "path/to/your/placeholder-image.png"}
          className="w-64 h-64 p-2 object-contain rounded-t-lg"
          alt="listingImage"
          onError={(e) => (e.target.src = "path/to/your/placeholder-image.png")}
        />

        {isLoading ? (
          <div className="absolute top-2 right-2">
            <ClipLoader size={20} color="red" />
          </div>
        ) : currentUser && ownerUid !== currentUser.uid ? ( // Check if the current user is not the item owner
          <FontAwesomeIcon
            icon={faHeart}
            color={isFavourited ? "red" : "grey"}
            size="lg"
            className="absolute top-2 right-2"
            onClick={
              isFavourited ? handleRemoveFromFavourites : handleAddToFavourites
            }
          />
        ) : null}
      </div>

      <div className="px-4 pt-2 flex flex-col gap-2">
        <p className="font-semibold text-gray-800">{title}</p>
        <div className="text-sm text-gray-800 flex items-center align-center justify-between border-t-2 border-gray-200"></div>
        <div className="flex items-center gap-2 my-2 text-gray-600">
          <FontAwesomeIcon icon={faMapLocationDot} size="sm" />
          <p className="text-xs">
            {location.town}, {location.county}
          </p>
        </div>
        <p className="text-lg font-bold text-emerald-600">${dailyRate} / day</p>
      </div>
    </div>
  );
};

export default UserProfileListingCard;
