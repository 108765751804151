import React, { useState, useEffect, useRef } from "react";
import { db } from "../../firebase.config";
import {
  collection,
  addDoc,
  serverTimestamp,
  query,
  doc,
  orderBy,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { useAuth } from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import AvailabilityRequestRenter from "./AvailabilityRequestRenter";
import AvailabilityRequestOwner from "./AvailabilityRequestOwner";
import AvailabilityConfirmedRenter from "./AvailabilityConfirmedRenter";
import AvailabilityConfirmedOwner from "./AvailabilityConfirmedOwner";
import RentalConfirmedRenter from "./RentalConfirmedRenter";
import RentalConfirmedOwner from "./RentalConfirmedOwner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
  faMessage,
} from "@fortawesome/free-solid-svg-icons";
import { format } from "date-fns";
import { convertToTimeZone } from "date-fns-timezone";
import { toast } from "react-toastify";
import profilePicturePlaceholder from "../../assets/profilePicture.png";
import DynamicChatModal from "./DynamicChatModal";
import { BeatLoader } from "react-spinners";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";
import RentalCompleteMessageRequest from "../RentalCompleteMessageRequest";
import { FaArrowLeftLong } from "react-icons/fa6";

const ChatWindow = ({
  selectedChatId,
  onDeleteChat,
  recipientDetails,
  listingTitle,
  onBack,
}) => {
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const { currentUser } = useAuth();
  const endOfMessagesRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [modalMessage, setModalMessage] = useState("");
  const [modalConfirmAction, setModalConfirmAction] = useState(() => () => {}); // Default empty function
  const [modalConfirmText, setModalConfirmText] = useState("");
  const [modalCancelText, setModalCancelText] = useState("");
  const [modalShowInputField, setModalShowInputField] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [sendingMessage, setSendingMessage] = useState(false);

  const { username, profilePictureURL } = recipientDetails;

  const navigate = useNavigate();

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  const handleOpenModal = (
    title,
    message,
    confirmAction,
    confirmText,
    cancelText,
    showInputField
  ) => {
    setModalTitle(title);
    setModalMessage(message);
    setModalConfirmAction(() => confirmAction);
    setModalConfirmText(confirmText);
    setModalCancelText(cancelText);
    setIsModalOpen(true);
    setModalShowInputField(showInputField);
  };

  // Helper function to call the firebase cloud function to delete the rental request
  const handleDeleteRentalRequest = async () => {
    const body = JSON.stringify({
      rentalRequestId: selectedChatId,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://deleterentalrequest-deleterentalrequest-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsModalOpen(false);
      setIsLoading(false);
      toast.success("The rental availability request has been deleted.", {
        autoClose: 3000,
      });
      // Remove the chat from the state
      onDeleteChat(selectedChatId);
    } catch (error) {
      console.log(error.message);
      setIsModalOpen(false);
      setIsLoading(false);
      toast.error("Something went wrong deleting the rental request.", {
        autoClose: 3000,
      });
    }
  };

  // Helper function to call the firebase cloud function to send the availability request rejected email to the requestor
  const sendAutomatedAvailabilityRejectedEmail = async (reason) => {
    const body = JSON.stringify({
      reason: reason,
      recipientDetails: recipientDetails,
      ownerUsername: currentUser.username,
      listingTitle: listingTitle,
    });
    try {
      setIsLoading(true);
      // Need to requestor details (will receive the email), reason, listing details and listing owner username
      const response = await fetch(
        `https://rejectavailrequestemail-rejectavailrequestemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      toast.error(
        "Something went wrong sending the availability rejection email.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  // Helper function to call the firebase cloud function to send the availability retracted email to the requestor
  const sendAutomatedRetractConfirmationEmail = async (reason) => {
    const body = JSON.stringify({
      reason: reason,
      recipientDetails: recipientDetails,
      ownerUsername: currentUser.username,
      listingTitle: listingTitle,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://retractavailconfirmationemail-retractavailconfirm-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      toast.error(
        "Something went wrong sending the availability rejection email.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  // Helper function to call the firebase cloud function to send the availability cancelled email to the item owner
  const sendCancelConfirmedAvailabilityRequestEmail = async (reason) => {
    const body = JSON.stringify({
      reason: reason,
      recipientDetails: recipientDetails,
      listingTitle: listingTitle,
      renterUsername: currentUser.username,
    });

    console.log(body);

    try {
      setIsLoading(true);
      const response = await fetch(
        `https://cancelavailrequestemail-cancelavailrequestemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsLoading(false);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      toast.error(
        "Something went wrong sending the availability rejection email.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  // Listing owner: rejecting availability request
  const handleRejectAvailabilityRequest = async (reason) => {
    try {
      // Send automated email to the requestor that the item is not available + provide reason (if supplied)
      await sendAutomatedAvailabilityRejectedEmail(reason);

      // Delete the rental-request from Firestore
      await handleDeleteRentalRequest();

      // Remove the chat from the list of chats at the sidebar
      onDeleteChat(selectedChatId);

      // showcase toast notification on successful deletion
      toast.success("Availability request rejected.", { autoClose: 3000 });
      setIsModalOpen(false);
    } catch (error) {}

    console.log("handle reject availability request", reason);
    setIsModalOpen(false);
  };

  // Availability requestor: cancel availability request
  // No automated email required?
  const handleCancelAvailabilityRequest = async () => {
    const body = JSON.stringify({
      rentalRequestId: selectedChatId,
    });
    try {
      setIsLoading(true);
      const response = await fetch(
        `https://deleterentalrequest-deleterentalrequest-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsModalOpen(false);
      setIsLoading(false);
      toast.success("Your rental availability request has been deleted.", {
        autoClose: 3000,
      });
      // Remove the chat from the state
      onDeleteChat(selectedChatId);

      // Reload the page to showcase the updated chat list
      //window.location.reload();
    } catch (error) {
      console.log(error.message);
      setIsModalOpen(false);
      setIsLoading(false);
      toast.error("Something went wrong deleting the rental request.", {
        autoClose: 3000,
      });
    }
  };

  // Item owner: retract availability confirmation
  const handleRetractConfirmation = async (reason) => {
    try {
      // Send automated email to the requestor that the item is no longer available + provide reason (if supplied)
      await sendAutomatedRetractConfirmationEmail(reason);

      // Delete the rental-request from Firestore
      await handleDeleteRentalRequest();

      // Remove the chat from the list of chats at the sidebar
      onDeleteChat(selectedChatId);

      // showcase toast notification on successful deletion
      toast.success(`Availability retracted for ${listingTitle}`, {
        autoClose: 3000,
      });
      setIsModalOpen(false);
    } catch (error) {}

    console.log("handle reject availability request", reason);
    setIsModalOpen(false);
  };

  // Requestor: cancel request before "confirming" and paying for rental (last stage)
  const handleCancelConfirmedAvailabilityRequest = async (reason) => {
    try {
      await sendCancelConfirmedAvailabilityRequestEmail(reason);

      await handleDeleteRentalRequest();

      onDeleteChat(selectedChatId);

      toast.success(`Cancelled availability request for ${listingTitle}`, {
        autoClose: 3000,
      });
      setIsModalOpen(false);
    } catch (error) {
      toast.error("Failed to cancel availability request.", {
        autoClose: 3000,
      });
      setIsModalOpen(false);
    }
  };

  useEffect(() => {
    if (selectedChatId) {
      const messagesRef = collection(
        db,
        "rental-requests",
        selectedChatId,
        "messages"
      );
      const q = query(messagesRef, orderBy("createdAt", "asc"));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const messagesData = querySnapshot.docs.map((doc) => {
          const data = { id: doc.id, ...doc.data() };
          return data;
        });
        setMessages(messagesData);
      });

      return () => unsubscribe();
    }
  }, [selectedChatId]);

  useEffect(() => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  // Determine the user's role with respect to the selected chat
  const isCurrentUserTheOwner = (message) => {
    return message.ownerUid === currentUser.uid;
  };

  const renderMessageContent = (message) => {
    // Pass isOwner boolean to message components to adjust their rendering
    const isOwner = isCurrentUserTheOwner(message);

    // Check if endDate exists and is not null
    const endDate = message.endDate
      ? firestoreTimestampToDate(message.endDate)
      : null;
    const today = new Date().toLocaleDateString();

    if (endDate && endDate.toLocaleDateString() === today) {
      return (
        <RentalCompleteMessageRequest
          message={message}
          currentUser={currentUser}
        />
      );
    }

    switch (message.type) {
      case "availability-request":
        // Decide which message component to render based on whether the current user is the owner
        return isOwner ? (
          <AvailabilityRequestOwner
            message={message}
            onOpenModal={() =>
              handleOpenModal(
                "Reject Request?",
                "Are you sure you want to reject this request?",
                handleRejectAvailabilityRequest,
                "Yes, reject request",
                "No, keep request",
                true
              )
            }
            currentUser={currentUser}
            recipientDetails={recipientDetails}
          />
        ) : (
          <AvailabilityRequestRenter
            message={message}
            onOpenModal={() =>
              handleOpenModal(
                "Cancel Availability Request?",
                "Are you sure you want to cancel this availability request?",
                handleCancelAvailabilityRequest,
                "Yes, cancel request",
                "No, keep request",
                false
              )
            }
          />
        );
      case "availability-confirmed":
        return isOwner ? (
          <AvailabilityConfirmedOwner
            message={message}
            onOpenModal={() =>
              handleOpenModal(
                "Retract Confirmation?",
                "Are you sure you want to retract the availability confirmation?",
                handleRetractConfirmation,
                "Yes, retract confirmation",
                "No, keep confirmation",
                true
              )
            }
          />
        ) : (
          <AvailabilityConfirmedRenter
            message={message}
            rentalRequestChatId={selectedChatId}
            onOpenModal={() =>
              handleOpenModal(
                "Cancel Request?",
                "Are you sure you want to cancel your rental request?",
                handleCancelConfirmedAvailabilityRequest,
                "Yes, cancel request",
                "No, keep request",
                true
              )
            }
          />
        );
      case "rental-confirmed":
        return isOwner ? (
          <RentalConfirmedOwner message={message} />
        ) : (
          <RentalConfirmedRenter message={message} />
        );

      default:
        return (
          <div>
            <p>{message.content}</p>
            <p className="text-xs text-gray-100 text-right">
              {formatTimestamp(message.createdAt)}
            </p>{" "}
          </div>
        );
    }
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === "") return;

    const messageToSend = newMessage;
    setNewMessage(""); // Clear the input field immediately for a seamless user experience
    setSendingMessage(true);

    try {
      await addDoc(
        collection(db, "rental-requests", selectedChatId, "messages"),
        {
          content: messageToSend,
          senderUid: currentUser.uid,
          createdAt: serverTimestamp(),
        }
      );
    } catch (error) {
      console.error("Error sending message:", error);
      toast.error("Failed to send message.");
      setNewMessage(messageToSend); // Restore the message if sending fails
    } finally {
      setSendingMessage(false);
    }
  };

  //const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  return (
    <div className="flex flex-col h-full">
      <DynamicChatModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        onConfirm={modalConfirmAction}
        title={modalTitle}
        message={modalMessage}
        isLoading={isLoading}
        confirmText={modalConfirmText}
        cancelText={modalCancelText}
        showInputField={modalShowInputField}
      />
      {/* Chat window heading */}
      <div className="relative flex text-gray-700 justify-center align-center items-center bg-gradient-to-r from-gray-200 to-gray-300 shadow-sm p-2 text-center font-semibold hover:font-bold hover:cursor-pointer">
        {onBack && (
          <FaArrowLeftLong
            className="absolute top-3 left-3 text-gray-700 mr-4 cursor-pointer"
            onClick={onBack}
            size={"30px"}
          />
        )}
        <div
          className="flex justify-center items-center align-center"
          onClick={() => navigate(`/users/${recipientDetails.id}`)}
        >
          <img
            src={
              profilePictureURL ? profilePictureURL : profilePicturePlaceholder
            }
            alt="proflePicture"
            className="w-10 h-10 rounded-full mr-2"
          />
          <p>Chat with {username}</p>
        </div>
      </div>
      <div className="flex-1 overflow-y-auto p-4">
        {messages.map((message) => (
          <div
            key={message.id}
            className={`p-2 rounded-lg m-2 ${
              message.senderUid === currentUser.uid
                ? "w-2/3 md:w-2/5 lg:w-1/3 ml-auto bg-blue-500 text-white shadow-md"
                : "w-2/3 md:w-2/5 lg:w-1/3 mr-auto bg-gray-500 text-white shadow-md"
            }`}
          >
            {renderMessageContent(message)}
          </div>
        ))}
        <div ref={endOfMessagesRef} />
      </div>
      <div className="mt-auto pt-3 border-t border-gray-200">
        <form className="flex items-center gap-2" onSubmit={sendMessage}>
          <div className="flex w-full gap-4">
            <input
              className="text-sm"
              type="text"
              value={newMessage}
              placeholder="Add your message here..."
              onChange={(e) => setNewMessage(e.target.value)}
            ></input>
            <button
              className="p-2 w-1/4 md:w-1/6 text-sm md:text-base bg-blue-500 text-white rounded-lg shadow-md font-semibold hover:bg-blue-600"
              type="submit"
            >
              Send <FontAwesomeIcon icon={faArrowRight} size="lg" />
            </button>
          </div>
          {/* )} */}
        </form>
      </div>
    </div>
  );
};

export default ChatWindow;
