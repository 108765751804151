import React, { useState } from "react";
import { useAuth } from "../hooks/useAuth"; // Custom hook to get current user
import { toast } from "react-toastify";
import { FaCircleInfo } from "react-icons/fa6";

const VeriffButton = ({ currentUser, idVerified }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleVerification = async () => {
    if (!currentUser) {
      toast.error("You need to be logged in to verify your identity.");
      return;
    }

    setIsLoading(true);

    const body = JSON.stringify({
      userUid: currentUser.uid,
      firstName: currentUser.firstName,
      lastName: currentUser.lastName,
    });

    try {
      const response = await fetch(
        `https://startveriffsession-startveriffsession-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      if (!response.ok) {
        // If response is not OK (status not in the range 200-299)
        const errorText = await response.text(); // Get the error message text
        throw new Error(errorText || "Failed to create Veriff session.");
      }

      const data = await response.json();
      setIsLoading(false);
      window.location.href = data.verificationUrl; // Redirect to Veriff session
    } catch (error) {
      console.log("Error creating Veriff session:", error.message);
      setIsLoading(false);
      toast.error("Something went wrong creating the verification session.", {
        autoClose: 3000,
      });
    }
  };

  return (
    <>
      {idVerified && idVerified ? (
        <p className="my-2">
          ID Verified: <span className="font-bold text-emerald-600">Yes</span>
        </p>
      ) : (
        <div className="relative inline-block">
          <button
            className="btn-primary px-5 my-3"
            onClick={handleVerification}
            disabled={isLoading}
          >
            {isLoading ? "Processing..." : "Verify your ID"}
          </button>

          {/* Info Icon with Tooltip */}
          <FaCircleInfo
            className="ml-2 inline cursor-pointer text-gray-500 hover:text-gray-700"
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          />
          {showTooltip && (
            <div className="absolute top-0 left-full ml-3 w-72 bg-white text-gray-800 p-3 rounded-md shadow-lg z-10">
              <p className="text-sm">
                🌟 At KitShare, we prioritize trust and safety. Verifying your
                ID helps us create a secure community where everyone can
                confidently rent and share items.
              </p>
              <p className="text-sm font-semibold mt-2">
                You must verify your ID before you can rent or list items for
                rent.
              </p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default VeriffButton;
