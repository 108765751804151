import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronUp,
  faBook,
} from "@fortawesome/free-solid-svg-icons";

const ListingDetails = ({ listingDetails }) => {
  const [showAdDescription, setShowAdDescription] = useState(true);

  const {
    title,
    description,
    dailyRate,
    threePlusDayRate,
    sevenPlusDayRate,
    condition,
    category,
    subcategory,
    deposit,
  } = listingDetails;

  return (
    <div className="bg-white shadow-md rounded-lg p-6 mb-5">
      <h1 className="text-xl font-bold text-gray-800 mb-2">{title}</h1>
      <div className="flex flex-col gap-4">
        <p className="text-base text-gray-700">
          Daily Rate:{" "}
          <span className="font-semibold">€{dailyRate.toFixed(2)} per day</span>
        </p>
        {threePlusDayRate ? (
          <p className="text-base text-gray-700">
            3+ Day Rate:{" "}
            <span className="font-semibold">
              €{threePlusDayRate.toFixed(2)} per day
            </span>
          </p>
        ) : null}
        {sevenPlusDayRate ? (
          <p className="text-base text-gray-700">
            7+ Day Rate:{" "}
            <span className="font-semibold">
              €{sevenPlusDayRate.toFixed(2)} per day
            </span>
          </p>
        ) : null}
        <p className="text-gray-600">
          Condition: <span className="font-semibold">{condition}</span>
        </p>
        <p className="text-gray-600">
          Category: <span className="font-semibold">{category}</span>
        </p>
        {subcategory && (
          <p className="text-gray-600">
            Subcategory: <span className="font-semibold">{subcategory}</span>
          </p>
        )}
        {/* Description Toggle */}
        <div className="border-t border-gray-200 pt-4">
          <button
            className="flex items-center space-x-2 text-gray-800 hover:text-gray-500"
            onClick={() => setShowAdDescription(!showAdDescription)}
          >
            {/* <FontAwesomeIcon icon={faBook} className="text-lg" /> */}
            <span className="font-bold">Item Details</span>
            <FontAwesomeIcon
              icon={showAdDescription ? faChevronUp : faChevronDown}
            />
          </button>
          {showAdDescription && (
            <p className="mt-2 text-gray-600 whitespace-pre-line">
              {description}
            </p>
          )}
        </div>

        {/* Deposit */}
        {deposit && deposit > 0 ? (
          <div>
            <h2 className="font-bold">
              Deposit Required:{" "}
              <span className="font-semibold">€{deposit}</span>
            </h2>
            <p className="text-sm">
              Some owners may require a security deposit for their rentals. This
              deposit enhances the trust and security between the renter and the
              owner, ensuring a smooth and secure transaction. By requiring a
              deposit, owners can safeguard their valuable items, and renters
              can be assured of the owner’s commitment to the rental agreement.
              The deposit will be collected by the owner at the start of the
              rental period and will be fully refunded at the end of the rental,
              provided the item is returned in its original condition.
            </p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ListingDetails;
