import React, { useEffect, useState } from "react";
import { useAuth } from "../hooks/useAuth";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { db } from "../firebase.config";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBackspace,
  faPlusCircle,
  faRemove,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import Footer from "../components/Footer";
import { SyncLoader } from "react-spinners";
import CategorySelector from "../components/CategorySelector";

const EditListing = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [itemCondition, setItemCondition] = useState(null);
  const [imageURLs, setImageURLs] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    dailyRate: "",
    threePlusDayRate: "",
    sevenPlusDayRate: "",
    quantity: "",
    minRentDays: "",
    location: "",
    status: "",
  });

  const {
    title,
    description,
    dailyRate,
    threePlusDayRate,
    sevenPlusDayRate,
    status,
  } = formData;

  const { currentUser } = useAuth();
  const { listingId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser) {
      fetchListingData();
    } else {
      toast.error("You need to be logged in to edit a listing.", {
        autoClose: 5000,
      });
      navigate("/");
    }
  }, [currentUser, listingId, navigate]);

  const fetchListingData = async () => {
    setShowSpinner(true);
    try {
      const docRef = doc(db, "listings", listingId);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();

        // Check if the current user is the owner of the listing
        if (currentUser.uid !== data.ownerUid) {
          toast.error("You need to be the owner of this listing to edit.", {
            autoClose: 5000,
          });
          navigate("/");
          return;
        }

        setFormData(data);
        if (data.condition) {
          const conditionOption = itemConditionOptions.find(
            (option) => option.value === data.condition
          );
          setItemCondition(conditionOption);
        }
        setImageURLs(data.imageURLs || []);
      } else {
        toast.error("Listing not found");
        navigate("/");
      }
    } catch (error) {
      toast.error("Failed to fetch listing data: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const loadCloudinaryScript = () => {
    const existingScript = document.getElementById("cloudinaryWidgetScript");
    if (!existingScript) {
      const script = document.createElement("script");
      script.src = "https://widget.cloudinary.com/v2.0/global/all.js";
      script.id = "cloudinaryWidgetScript";
      document.body.appendChild(script);
      script.onload = () => {
        console.log("Cloudinary script loaded");
      };
    }
  };

  useEffect(() => {
    loadCloudinaryScript();
  }, []);

  // Test function for rental request cleanup
  const getExpiredRentalRequests = async () => {
    try {
      console.log("capturing rental request");
      const response = await fetch(
        `https://testrentalcleanup-deleterentalrequest-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("after function");
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const itemConditionOptions = [
    { label: "New", value: "New" },
    { label: "Used (Excellent)", value: "Used (Excellent)" },
    { label: "Used (Good)", value: "Used (Good)" },
    { label: "Used (Fair)", value: "Used (Fair)" },
    { label: "Used (Bad)", value: "Used (Bad)" },
  ];

  const openUploadWidget = () => {
    setShowSpinner(true);
    window.cloudinary
      .createUploadWidget(
        {
          cloudName: "kitsharecloudinary",
          uploadPreset: "kitHireUploadPreset",
          multiple: true,
          maxFiles: 8,
          sources: ["local", "camera"],
          folder: "listingImages",
          context: {
            alt: "user_uploaded_image",
            caption: "Uploaded on KitShare",
          },
          resourceType: "image",
        },
        (error, result) => {
          setShowSpinner(false);
          if (!error && result && result.event === "success") {
            setImageURLs((prevUrls) => [...prevUrls, result.info.secure_url]);
            console.log("Image uploaded successfully:", result.info.secure_url);
          } else if (error) {
            console.error("Cloudinary upload error:", error);
            toast.error("Failed to upload image. Please try again.");
          }
        }
      )
      .open();
  };

  const handleItemConditionChange = (selectedOption) => {
    setItemCondition(selectedOption);
    setFormData((prev) => ({ ...prev, condition: selectedOption.value }));
  };

  const handleCategorySelected = (category) => {
    setSelectedCategory(category);
    setSelectedSubcategory(null);
    setFormData((prev) => ({ ...prev, category: category.value }));
  };

  const handleSubcategorySelected = (subcategory) => {
    setSelectedSubcategory(subcategory);
    setFormData((prev) => ({ ...prev, subcategory: subcategory.value }));
  };

  const handleRemoveImage = (url) => {
    setImageURLs((prev) => prev.filter((image) => image !== url));
  };

  const handleSaveChanges = async (e) => {
    e.preventDefault();
    setShowSpinner(true);
    try {
      const listingRef = doc(db, "listings", listingId);
      await updateDoc(listingRef, {
        ...formData,
        imageURLs: imageURLs,
      });
      toast.success("Listing updated successfully!");
      navigate(`/listing/${listingId}`);
    } catch (error) {
      console.error("Failed to update listing: ", error);
      toast.error("Error updating listing: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const changeListingStatus = async () => {
    setShowSpinner(true);
    try {
      const listingRef = doc(db, "listings", listingId);
      await updateDoc(listingRef, {
        status: status === "active" ? "inactive" : "active",
      });
      toast.success(
        `You have updated the status of your listing to ${
          status === "active" ? "inactive" : "active"
        }`
      );
      setFormData((prev) => ({
        ...prev,
        status: status === "active" ? "inactive" : "active",
      }));
    } catch (error) {
      toast.error("Failed to change status: " + error.message);
    } finally {
      setShowSpinner(false);
    }
  };

  const onChange = (e) => {
    let value = e.target.value;
    if (e.target.type === "number" && value !== "") {
      value = parseInt(value, 10);
    }
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: value,
    }));
  };

  return (
    <div className="force-footer">
      <div className="main-content">
        <div className="flex w-full justify-center">
          <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
            Edit Listing
          </h1>
        </div>

        <div className="flex justify-center">
          <form
            className="w-11/12 md:w-2/3 xl:w-4/12"
            onSubmit={handleSaveChanges}
          >
            <div className="flex flex-col py-2 w-full mb-2">
              <label className="label">Listing Title</label>
              <input
                className="shadow-md"
                type="text"
                id="title"
                value={title}
                onChange={onChange}
                placeholder=""
                required
              />
            </div>

            <div className="flex flex-col py-2 w-full mb-2 text-sm">
              <label className="label">Item Condition</label>
              <Select
                options={itemConditionOptions}
                isSearchable
                value={itemCondition}
                onChange={handleItemConditionChange}
              />
            </div>

            <CategorySelector
              onCategorySelected={handleCategorySelected}
              onSubcategorySelected={handleSubcategorySelected}
              initialCategory={formData.category}
              initialSubcategory={formData.subcategory}
            />

            <div className="flex flex-col w-full py-2 mb-2 mt-3">
              <label className="label">Listing Description</label>
              <textarea
                placeholder="Enter listing details"
                value={description}
                id="description"
                onChange={onChange}
                className="border-2 p-2 shadow-md"
                required
                rows={10}
              ></textarea>
            </div>

            <div className="flex flex-col py-2 w-full mb-2">
              <label className="label">Rental price (€) per:</label>
              <div className="flex justify-between gap-2">
                <div className="w-1/3">
                  <label className="label">Daily Rate</label>
                  <input
                    className="shadow-md"
                    type="number"
                    min={1}
                    required
                    id="dailyRate"
                    value={dailyRate}
                    onChange={onChange}
                  />
                </div>
                <div className="w-1/3">
                  <label className="label">3+ Day Rate</label>
                  <input
                    className="shadow-md"
                    type="number"
                    id="threePlusDayRate"
                    placeholder="Optional"
                    value={threePlusDayRate}
                    onChange={onChange}
                  />
                </div>
                <div className="w-1/3">
                  <label className="label">7+ Day Rate</label>
                  <input
                    className="shadow-md"
                    placeholder="Optional"
                    type="number"
                    id="sevenPlusDayRate"
                    value={sevenPlusDayRate}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>

            <button
              type="button"
              //onClick={changeListingStatus}
              onClick={() => getExpiredRentalRequests()}
              className={`px-2 ${
                status === "active" ? "btn-delete" : "btn-primary"
              }`}
            >
              {showSpinner ? (
                <SyncLoader size={10} margin={5} />
              ) : (
                <span className="text-base">
                  Make {status === "active" ? "Inactive" : "Active"}{" "}
                </span>
              )}
            </button>

            <div className="flex my-4">
              {imageURLs.map((url, index) => (
                <div key={index} className="mx-2 flex flex-col">
                  <img
                    src={url}
                    alt="Uploaded"
                    className="h-24 w-24 object-cover border-2 my-2 p-1 rounded-md"
                  />
                  <button
                    className="btn-delete px-1"
                    onClick={() => handleRemoveImage(url)}
                    type="button"
                  >
                    Remove {<FontAwesomeIcon icon={faRemove} />}
                  </button>
                </div>
              ))}
            </div>

            <div className="flex justify-center mt-4 mb-2 gap-3">
              <button
                className="btn-secondary px-3"
                onClick={openUploadWidget}
                type="button"
              >
                Upload Images
              </button>

              <button type="submit" className="btn-continue px-3">
                {showSpinner ? (
                  <SyncLoader size={10} margin={5} />
                ) : (
                  <span className="text-base">Save Changes</span>
                )}
              </button>
            </div>
          </form>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default EditListing;
