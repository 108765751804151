import React, { useState } from "react";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel } from "@fortawesome/free-solid-svg-icons";

const AvailabilityRequestRenter = ({ message, onOpenModal }) => {
  const {
    listingTitle,
    startDate,
    content,
    endDate,
    totalCost,
    price,
    numberOfDays,
    listingImage,
  } = message;

  let deposit;

  if (message.deposit) {
    deposit = message.deposit;
  }

  // Convert Firestore Timestamp to JavaScript Date object
  const startDateDate = startDate.toDate();
  const endDateDate = endDate.toDate();

  const formatDate = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || "th";

    return `${day}${suffix} ${month}`;
  };

  // Using the converted Date objects from above
  const readableStartDate = formatDate(startDateDate);
  const readableEndDate = formatDate(endDateDate);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  return (
    <div className="">
      <h2 className="text-center font-bold">Availability Request</h2>
      <p className="text-center my-5 new-line">{content}</p>
      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md"></img>
        <p className="font-bold mt-2">{listingTitle}</p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p>
          <span className="font-bold">Total cost:</span> €{totalCost.toFixed(2)}
        </p>
        <p>
          <span className="font-bold">Duration:</span> {numberOfDays} days
        </p>
        <p>{`${readableStartDate} - ${readableEndDate}`}</p>
        {deposit && (
          <p>
            <span className="font-bold">Required deposit:</span> €
            {deposit.toFixed(2)}
          </p>
        )}
      </div>
      <p className="text-sm text-center my-4">
        Waiting for the owner to confirm availability of the item
      </p>
      <div className="flex justify-center mt-3 mb-2">
        <button onClick={() => onOpenModal()} className="p-2 btn-delete">
          <FontAwesomeIcon icon={faCancel} /> Cancel Request
        </button>
      </div>
      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>{" "}
      </div>
    </div>
  );
};

export default AvailabilityRequestRenter;
