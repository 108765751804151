import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faX } from "@fortawesome/free-solid-svg-icons";
import { doc, deleteDoc, updateDoc, arrayRemove } from "firebase/firestore";
import { db } from "../../firebase.config";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";

const ConfirmDeleteListingModal = ({
  isOpen,
  onClose,
  listing,
  currentUser,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // Remove listingId from user ownedListings array
  const removeListingFromUserOwnedListings = async (listingId) => {
    const userRef = doc(db, "users", currentUser.uid);
    await updateDoc(userRef, {
      ownedListings: arrayRemove(listingId),
    });
  };

  const handleDeleteListing = async () => {
    setIsLoading(true);
    await deleteDoc(doc(db, "listings", listing.id));
    await removeListingFromUserOwnedListings(listing.id);
    toast.success("Listing has been deleted", { autoClose: 3000 });
    setIsLoading(false);
    navigate("/my-listings");
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(255,255,255,0.8)",
    },
  };

  return (
    <div className="w-1/2">
      <div className="">
        <Modal
          isOpen={isOpen}
          onRequestClose={onClose}
          contentLabel="Confirm delete listing"
          style={customStyles}
          ariaHideApp={false}
        >
          <div>
            <p className="font-semibold">
              Are you sure you want to delete this listing?
            </p>
            <div className="flex justify-center gap-5 mt-5">
              <button
                onClick={() => {
                  onClose();
                }}
                className="px-3 btn-back"
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Go Back
              </button>
              <button
                onClick={() => handleDeleteListing()}
                className="px-3 btn-delete"
              >
                {isLoading ? (
                  <BeatLoader />
                ) : (
                  <p>
                    <FontAwesomeIcon icon={faX} /> Delete Listing
                  </p>
                )}
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default ConfirmDeleteListingModal;
