import React, { useState, useEffect } from "react";
import algoliasearch from "algoliasearch";
import ListingCard from "../components/ListingCard";
import { useLocation } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import AlgoliaSearch from "../components/AlgoliaSearch";
import LocationSearch from "../components/LocationSearch";
import towns from "../data/towns"; // Assume this is an array of town objects
import notFound from "../assets/notFound.png";
import { useAuth } from "../hooks/useAuth";

// Initialize Algolia search client
const searchClient = algoliasearch(
  "5TYNRTXW6E",
  "887153ed77313de15ae83a2959ff2053"
);

const defaultMapCenter = {
  lat: 53.349805,
  lng: -6.26031, // Default center (Dublin, Ireland)
};

const SearchResults = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [selectedGeolocation, setSelectedGeolocation] =
    useState(defaultMapCenter);
  const [selectedLocation, setSelectedLocation] = useState("");
  const location = useLocation();

  const query = new URLSearchParams(location.search).get("q");

  const { currentUser } = useAuth();

  // Set initial geolocation based on user status
  useEffect(() => {
    if (currentUser && currentUser._geoloc) {
      // If user is logged in and has geolocation
      setSelectedGeolocation({
        lat: currentUser._geoloc.lat,
        lng: currentUser._geoloc.lng,
      });
      setSelectedLocation({
        town: currentUser.location.town, // Assuming town and county are stored in user object
        county: currentUser.location.county,
      });
    } else {
      // Use default geolocation (Dublin)
      setSelectedGeolocation(defaultMapCenter);
      setSelectedLocation({ town: "Dublin", county: "" });
    }
  }, [currentUser]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      if (query) {
        setIsLoading(true);
        const index = searchClient.initIndex("kitshare_listings");
        const { hits, nbPages } = await index.search(query, {
          getRankingInfo: true,
          page,
          hitsPerPage: 8, // Adjust the number of results per page
          aroundLatLng: `${selectedGeolocation.lat},${selectedGeolocation.lng}`, // Use selected location
          aroundRadius: 50000, // Radius can be adjusted based on requirements
        });

        const hitsWithAddedId = hits.map((hit) => ({
          ...hit,
          id: hit.objectID,
        }));

        setSearchResults((prevResults) =>
          page === 0 ? hitsWithAddedId : [...prevResults, ...hitsWithAddedId]
        );
        setTotalPages(nbPages);
        setIsLoading(false);
      }
    };

    fetchSearchResults();
  }, [query, page, selectedGeolocation]);

  const handleShowMore = () => {
    if (page < totalPages - 1) {
      setPage(page + 1);
    }
  };

  const handleLocationChange = (location) => {
    if (location) {
      // Find the selected town's lat and lng
      const town = towns.find(
        (t) => t.town === location.town && t.county === location.county
      );
      if (town) {
        setSelectedGeolocation({
          lat: town.lat,
          lng: town.lng,
        });
        setSelectedLocation({
          town: town.town,
          county: town.county,
        });
        setPage(0); // Reset to first page on location change
      } else {
        setSelectedGeolocation(defaultMapCenter);
      }
    } else {
      setSelectedGeolocation(defaultMapCenter); // Reset to default location if input is cleared
    }
  };

  return (
    <div className="mx-auto">
      {/* Search section */}
      <div className="bg-gray-100 pt-4 pb-2 mb-5">
        <div className="flex justify-center">
          <div className="w-full md:w-5/6 xl:w-1/2">
            <AlgoliaSearch />
          </div>
        </div>

        {/* Change location */}
        <div className="flex flex-col md:flex-row justify-center items-center align-center">
          <div className="w-full md:w-1/3 p-3">
            <p className="font-semibold ml-1">Change location:</p>
            <LocationSearch onLocationSelect={handleLocationChange} />
          </div>
          <div className="w-9/10 md:w-1/4 mt-5 bg-white p-3 rounded-lg border-2">
            <span className="font-bold">Search Focus Location: </span>
            {selectedLocation
              ? `${selectedLocation.town}, ${selectedLocation.county}`
              : "Dublin"}
          </div>
        </div>
      </div>

      <div className="px-4">
        <h2 className="text-lg md:text-2xl font-semibold mb-4">
          Search Results for "{query}"
        </h2>
        {isLoading && page === 0 ? (
          <div className="flex justify-center items-center align-center h-64">
            <SyncLoader size={15} color="#14b8a6" />
          </div>
        ) : (
          <div className="flex w-full justify-center">
            <div className="grid grid-cols-2 lg:grid-cols-4 gap-5 md:gap-10 mb-5">
              {searchResults.length > 0 ? (
                searchResults.map((listingDetails, index) => (
                  <ListingCard listingDetails={listingDetails} key={index} />
                ))
              ) : (
                <div className="flex flex-col justify-center items-center align-center">
                  <img src={notFound} className="w-64" alt="notFound"></img>
                  <p>No results found for "{query}".</p>
                </div>
              )}
            </div>
          </div>
        )}
        {page < totalPages - 1 && (
          <div className="flex justify-center mt-6">
            <button
              onClick={handleShowMore}
              className="bg-accent text-white px-4 py-2 shadow-md rounded-md hover:bg-accentDarker"
            >
              Show More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchResults;
