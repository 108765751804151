import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="bg-gradient-to-r from-gray-500 via-gray-500 to-gray-600">
        <div className="max-w-screen-lg mx-auto px-4 pt-6 pb-3 md:grid md:grid-cols-5 gap-8 md:gap-16 sm:grid sm:grid-cols-2 sm:gap-8">
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              My Account
            </h2>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/my-account"
            >
              My Account
            </Link>
            {/* <Link
              className="text-sm sm:text-base block no-underline mb-3 sm:mb-2 text-white hover:font-semibold"
              to="/cart"
            >
              Cart
            </Link> */}
          </div>
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              Rent
            </h2>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/new-ad"
            >
              List an Item
            </Link>
            {/* <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/entry-lists"
            >
              Entry Lists
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-3 sm:mb-2 text-white hover:font-semibold"
              to="/winners"
            >
              Winners
            </Link> */}
          </div>
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              About Us
            </h2>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/about-us"
            >
              About Us
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-3 sm:mb-2 text-white hover:font-semibold"
              to="/contact-us"
            >
              Contact Us
            </Link>
          </div>
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              Information
            </h2>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/terms-and-conditions"
            >
              Terms & Conditions
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="/privacy-policy"
            >
              Privacy Policy
            </Link>
          </div>
          <div className="text-white">
            <h2 className="text-base sm:text-lg font-semibold mb-3 border-b-2 border-accent pb-1">
              Social
            </h2>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="https://www.instagram.com/liftcompetitions/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="https://www.facebook.com/liftcompetitions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Facebook
            </Link>
            <Link
              className="text-sm sm:text-base block no-underline mb-2 text-white hover:font-semibold"
              to="https://www.tiktok.com/@liftcompetitions"
              target="_blank"
              rel="noopener noreferrer"
            >
              Tiktok
            </Link>
          </div>
        </div>
        {/* Copyright */}
        <div className="pt-3 bg-white">
          <p className="text-xs sm:text-base text-center">
            Copyright &copy; KitShare Pty Ltd 2024 - All Rights Reserved
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
