import React, { useState, useEffect, useCallback } from "react";
import towns from "../data/towns";
import { debounce } from "../utils/debounce";

const LocationSearch = ({ onLocationSelect, defaultLocation }) => {
  const [query, setQuery] = useState("");
  const [filteredLocations, setFilteredLocations] = useState([]);
  const [locationState, setLocationState] = useState(defaultLocation);

  // Initialize the query with the default location if present
  useEffect(() => {
    if (defaultLocation) {
      setQuery(`${defaultLocation.town}, ${defaultLocation.county}`);
    }
  }, [defaultLocation]);

  // Debounce function setup
  const debouncedSearch = useCallback(
    debounce((searchQuery) => {
      const results = towns.filter(
        (loc) =>
          loc.town.toLowerCase().includes(searchQuery.toLowerCase()) ||
          loc.county.toLowerCase().includes(searchQuery.toLowerCase())
      );
      setFilteredLocations(results);
    }, 300),
    []
  );

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setQuery(newQuery);

    // Clear locationState if the input is cleared
    if (!newQuery) {
      setLocationState(null);
      onLocationSelect(null); // Notify parent component of the change
    } else {
      // Proceed with debouncing and filtering locations
      debouncedSearch(newQuery);
    }
  };

  const handleLocationSelect = (location) => {
    setLocationState(location);
    setQuery(`${location.town}, ${location.county}`);
    onLocationSelect(location); // Notify parent component of the change
    setFilteredLocations([]);
  };

  return (
    <div className="relative">
      <input
        type="text"
        className="border-2 border-gray-300 rounded p-2 w-full"
        placeholder="Search nearest town..."
        value={query}
        onChange={handleInputChange}
        onFocus={() => {
          if (locationState) {
            debouncedSearch(query);
          }
        }}
      />
      {filteredLocations.length > 0 && (
        <ul className="absolute w-full list-none bg-white shadow-lg mt-1 z-10">
          {filteredLocations.map((location, index) => (
            <li
              key={index}
              className="py-1 px-2 cursor-pointer hover:bg-gray-100"
              onClick={() => handleLocationSelect(location)}
            >
              {location.town}, {location.county}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default LocationSearch;
