import React from "react";
import { BeatLoader } from "react-spinners";
import placeholderPictureURL from "../../assets/profilePicture.png";

const ListOfChats = ({
  onSelectChat,
  currentUser,
  selectedChatId,
  listOfChats,
  isLoading,
}) => {
  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <BeatLoader color="#14b8a6" />
      </div>
    );
  }

  return (
    <div className="chat-list">
      {listOfChats.length > 0 ? (
        listOfChats.map((chat) => (
          <div
            key={chat.id}
            className={`p-4 cursor-pointer border-b border-gray-300 hover:bg-gray-100 shadow-sm ${
              selectedChatId === chat.id
                ? "bg-gray-200 border-r-2 border-emerald-400"
                : ""
            }`}
            onClick={() => onSelectChat(chat.id)}
          >
            {/* Display chat status badges */}
            {chat.seen === false && chat.ownerUid === currentUser.uid ? (
              <div className="w-full flex my-1 p-1">
                <p className="text-xs font-semibold text-center bg-gradient-to-r from-teal-500 to-blue-500 text-white rounded-md w-full p-1">
                  NEW REQUEST
                </p>
              </div>
            ) : (chat.renterUid === currentUser.uid &&
                chat.newMessageForRenter) ||
              (chat.ownerUid === currentUser.uid && chat.newMessageForOwner) ? (
              <div className="w-full flex my-1 p-1">
                <p className="text-xs font-semibold text-center bg-red-500 text-white rounded-md w-full p-1">
                  NEW MESSAGES
                </p>
              </div>
            ) : chat.status === "rental-confirmed" ? (
              <div className="w-full flex my-1 p-1">
                <p className="text-xs font-semibold text-center bg-teal-500 text-white rounded-md w-full p-1">
                  CONFIRMED
                </p>
              </div>
            ) : chat.ownerUid === currentUser.uid ? (
              <div className="w-full flex my-1 p-1">
                <p className="text-xs font-semibold text-center bg-red-500 text-white rounded-md w-full p-1">
                  REQUEST
                </p>
              </div>
            ) : null}

            {/* Chat information */}
            <p className="font-semibold">{chat.listingTitle}</p>
            <div className="flex items-center mt-2">
              <img
                src={
                  chat.recipientDetails.profilePictureURL
                    ? chat.recipientDetails.profilePictureURL
                    : placeholderPictureURL
                }
                className="w-10 h-10 rounded-full mr-2"
                alt="Profile"
              />
              <p className="text-sm text-gray-700">
                Chat with {chat.recipientDetails.username}
              </p>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center text-gray-500 mt-5 font-bold">
          No chats found.
        </p>
      )}
    </div>
  );
};

export default ListOfChats;
