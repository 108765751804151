import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "../firebase.config";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  collection,
  query,
  getDocs,
  limit,
  orderBy,
} from "firebase/firestore";
// import ImageSlider from "../components/Listing/ImageSlider";
import ListingDetails from "../components/Listing/ListingDetails";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCalendarCheck,
  faEdit,
  faHeart,
  faStar,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  FaRegCalendarCheck,
  FaRegHeart,
  FaHeart,
  FaArrowRightFromBracket,
} from "react-icons/fa6";

import GoogleMapComponent from "../components/Listing/GoogleMapComponent";
import useListing from "../hooks/useListing";
import { useAuth } from "../hooks/useAuth";
import "react-datepicker/dist/react-datepicker.css";
import EnquireModal from "../components/Listing/EnquireModal";
import ConfirmDeleteListingModal from "../components/Listing/ConfirmDeleteListingModal";
import { toast } from "react-toastify";
import Spinner from "../components/Spinner";
import profilePicturePlaceholder from "../assets/profilePicture.png";
import { BeatLoader } from "react-spinners";
import ListingItemOwnerReviews from "../components/Listing/ListingItemOwnerReviews";
import ImageGallery from "react-image-gallery";
import "../css/ImageGallery.css";
import OtherItemsOwnedBy from "../components/Listing/OtherItemsOwnedBy";
import "../css/DatePicker.css";

const Listing = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [isEnquireModalOpen, setIsEnquireModalOpen] = useState(false);
  const [isConfirmDeleteListingModalOpen, setIsConfirmDeleteListingModalOpen] =
    useState(false);
  const [isFavourited, setIsFavourited] = useState(false);
  const [addingToFavourites, setAddingToFavourites] = useState(false);
  const [itemOwnerRecentReviews, setItemOwnerRecentReviews] = useState([]);
  const [itemOwnerDetails, setItemOwnerDetails] = useState({});
  const [error, setError] = useState(null);
  const [images, setImages] = useState([]);
  const { listingId } = useParams();
  const { listing, loading } = useListing(listingId);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  const checkVerificationAndShowCalendarModal = () => {
    if (!currentUser) {
      toast.info("You need to be logged in to enquire about a listing.", {
        autoClose: 3000,
      });
      return;
    } else if (!currentUser.emailVerified) {
      toast.info("You need to verify your email to enquire about listings.", {
        autoClose: 5000,
      });
      return;
    } else {
      setIsEnquireModalOpen(true);
    }
  };

  useEffect(() => {
    const getUserAndFavourites = async () => {
      if (currentUser) {
        try {
          const userRef = doc(db, "users", currentUser.uid);
          const userSnap = await getDoc(userRef);

          if (userSnap.exists()) {
            const isFavourite = userSnap.data().favourites?.includes(listingId);
            setIsFavourited(isFavourite);
          }
        } catch (error) {
          console.log(error.message);
          toast.error("Failed to fetch user data.");
        }
      }
    };

    getUserAndFavourites();
  }, [currentUser, listingId]);

  const fetchReviews = async (ownerUid) => {
    try {
      // setIsLoading(true);
      const reviewsRef = collection(db, "users", ownerUid, "reviews");
      const q = query(reviewsRef, orderBy("createdAt", "desc"), limit(4));
      const querySnapshot = await getDocs(q);

      const reviewsArray = [];
      querySnapshot.forEach((doc) => {
        reviewsArray.push({ id: doc.id, ...doc.data() });
      });
      setItemOwnerRecentReviews(reviewsArray);
      // setIsLoading(false);
    } catch (error) {
      console.log(error);
      // setIsLoading(false);
    }
  };

  useEffect(() => {
    const getItemOwnerDetails = async () => {
      try {
        const itemOwnerRef = doc(db, "users", listing?.ownerUid);
        const itemOwnerSnap = await getDoc(itemOwnerRef);
        if (itemOwnerSnap.exists()) {
          const itemOwnerData = {
            ...itemOwnerSnap.data(),
            uid: itemOwnerSnap.id,
          };
          setItemOwnerDetails(itemOwnerData);
          fetchReviews(itemOwnerData.uid);
        }
      } catch (error) {
        console.log(error.message);
      }
    };

    if (listing?.ownerUid) {
      getItemOwnerDetails();
    }
  }, [listing]);

  const showConfirmDeleteListingModal = () => {
    setIsConfirmDeleteListingModalOpen(true);
  };

  const handleAddToFavourites = async () => {
    if (!currentUser) {
      toast.error("You need to be logged in to add to favourites.", {
        autoClose: 2000,
      });
      return;
    }
    const userDocRef = doc(db, "users", currentUser.uid);
    setAddingToFavourites(true);
    try {
      await updateDoc(userDocRef, {
        favourites: arrayUnion(listingId),
      });
      toast.success("Added to favorites!", { autoClose: 3000 });
      setAddingToFavourites(false);
      setIsFavourited(true);
    } catch (error) {
      console.error("Error adding to favorites: ", error);
      toast.error("Failed to add to favorites.");
      setAddingToFavourites(false);
    }
  };

  useEffect(() => {
    if (loading) {
      setIsLoading(true);
    } else {
      if (!listing) {
        setError("Listing does not exist");
        setIsLoading(false);
      } else {
        let imageArray = [];
        // Get listing image URLs and format correctly for react-image-gallery
        listing.imageURLs.forEach((image) => {
          imageArray.push({
            original: image,
            thumbnail: image,
          });
        });
        setImages(imageArray);
        setIsLoading(false);
      }
    }
  }, [loading, listing]);

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return (
      <div className="flex justify-center items-center mt-10">
        <div className="text-center">
          <h1 className="text-2xl font-semibold">Listing Not Found</h1>
          <p>{error}</p>
          <button
            className="mt-4 px-4 py-2 bg-teal-500 text-white rounded-md hover:font-semibold hover:bg-teal-600"
            onClick={() => navigate(-1)}
          >
            <FontAwesomeIcon icon={faArrowLeft} /> Go Back
          </button>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="">
        <EnquireModal
          isOpen={isEnquireModalOpen}
          onClose={() => setIsEnquireModalOpen(false)}
          listing={listing}
        />
      </div>
      <ConfirmDeleteListingModal
        isOpen={isConfirmDeleteListingModalOpen}
        onClose={() => setIsConfirmDeleteListingModalOpen(false)}
        listing={listing}
        currentUser={currentUser}
      />

      <div className="flex justify-center">
        <div className="w-full p-2 sm:w-4/5 xl:w-2/3 2xl:w-3/5">
          <div className="my-3">
            <p
              className="font-semibold hover:font-bold hover:cursor-pointer"
              onClick={() => navigate(-1)}
            >
              <FontAwesomeIcon icon={faArrowLeft} /> Go back
            </p>
          </div>
          {/* Container for Listing Details and Image Slider */}
          <div className="flex flex-col-reverse w-full lg:flex-row justify-center">
            <div className="w-full lg:w-2/3">
              <ListingDetails
                listingDetails={listing}
                listingId={listingId}
                isLoading={isLoading}
              />

              <GoogleMapComponent mapCenter={listing._geoloc} />

              {/* Item Owner Details */}
              <div className="flex flex-col mt-5 bg-gray-100 p-2 rounded-md shadow-md">
                <p className="font-semibold mb-2">Owner</p>
                <div className="flex justify-between items-center align-center">
                  <div
                    onClick={() => navigate(`/users/${itemOwnerDetails.uid}`)}
                    className="flex jusity-center items-center align-center hover:cursor-pointer hover:font-semibold"
                  >
                    <img
                      src={
                        itemOwnerDetails?.profilePictureURL
                          ? itemOwnerDetails.profilePictureURL
                          : profilePicturePlaceholder
                      }
                      className="w-8 h-8 rounded-full"
                      alt="profile"
                    ></img>
                    <p className="ml-2">{itemOwnerDetails.username}</p>
                  </div>
                  <div className="flex justify-center align-center items-center gap-2">
                    <p className="">
                      {itemOwnerDetails.averageReviewRating}{" "}
                      <FontAwesomeIcon icon={faStar} color="gold" />
                    </p>
                    <p className="text-xs mt-1 font-semibold">
                      ({itemOwnerDetails.reviewCount})
                    </p>
                  </div>
                </div>
              </div>

              {/* Item owner reviews  */}
              {itemOwnerRecentReviews.length > 0 ? (
                <ListingItemOwnerReviews reviews={itemOwnerRecentReviews} />
              ) : null}
            </div>
            <div className="md:ml-5 w-full lg:w-3/5">
              {/* <ImageSlider isLoading={loading} images={listing.imageURLs} /> */}
              <div className="flex justify-center">
                <div className="w-4/5">
                  {isLoading ? (
                    <BeatLoader />
                  ) : (
                    <ImageGallery
                      items={images}
                      autoPlay={true}
                      showPlayButton={false}
                      slideDuration={600}
                      showNav={false}
                      showFullscreenButton={false}
                      showBullets={true}
                      showThumbnails={false}
                    />
                  )}
                </div>
              </div>

              {/* Component to either enquire or edit listing */}
              {currentUser && currentUser.uid === listing.ownerUid ? (
                <div className="flex flex-col items-center align-center gap-5 justify-center my-5">
                  <button
                    className="w-1/2 btn-edit"
                    onClick={() => navigate(`/edit-listing/${listingId}`)}
                  >
                    <div className="flex justify-center align-center items-center gap-2">
                      <FaArrowRightFromBracket />
                      <span>Edit Listing</span>
                    </div>
                  </button>
                  <button
                    className="w-1/2 btn-delete"
                    onClick={() => showConfirmDeleteListingModal()}
                  >
                    <FontAwesomeIcon icon={faXmarkSquare} /> Delete Listing
                  </button>
                </div>
              ) : (
                // Enquire or add to favourites
                <div>
                  <div className="flex justify-center my-5">
                    <button
                      className="w-1/2 btn-continue"
                      onClick={checkVerificationAndShowCalendarModal}
                    >
                      <div className="flex justify-center align-center items-center gap-2">
                        <FaRegCalendarCheck />
                        <span>Check Availability</span>
                      </div>
                    </button>
                  </div>
                  <div className="flex justify-center my-5">
                    {isFavourited ? (
                      <button className="w-1/2 rounded-md text-gray-500 shadow-sm py-2 bg-gray-300 cursor-not-allowed">
                        <div className="flex justify-center align-center items-center gap-2">
                          <FaHeart />
                          <span>Favourited</span>
                        </div>
                      </button>
                    ) : (
                      <button
                        className="w-1/2 btn-delete"
                        onClick={() => handleAddToFavourites()}
                      >
                        {addingToFavourites ? (
                          <BeatLoader color="white" />
                        ) : (
                          <div className="flex justify-center align-center items-center gap-2">
                            <FaRegHeart />
                            <span>Add to Favourites</span>
                          </div>
                        )}{" "}
                      </button>
                    )}
                  </div>
                </div>
              )}
              <div></div>
            </div>
          </div>
        </div>
      </div>

      <div className="flex w-full justify-center bg-gray-50 mt-5">
        <div className="w-full px-5 md:w-4/5">
          {/* Other items owned by user - will show null if no other items are owned by this user  */}
          <OtherItemsOwnedBy
            itemOwnerDetails={itemOwnerDetails}
            currentListingId={listingId}
          />
        </div>
      </div>
    </div>
  );
};

export default Listing;
