const categoriesAndSubcategories = {
  "Film and Photography": {
    Cameras: {
      Camcorders: {},
      "Compact cameras": {},
      "Digital cinema cameras": {},
      "DSLR cameras": {},
      "Film cameras": {},
      "Mirrorless cameras": {},
      "Other cameras": {},
      "Polaroid cameras": {},
    },
    "Camera Lenses": {
      "Cinema prime lenses": {},
      Converter: {},
      "DSLR lenses": {},
      Filter: {},
      "Lens accessories": {},
      "Medium format lenses": {},
      "Mirrorless lenses": {},
      "Other lenses": {},
    },
    "Stand and rigs": {
      "C-stands": {},
      "Cable cams": {},
      "Camera rigs and shoulder frames": {},
      "Clamps and grips": {},
      "Car mounts": {},
      "Crane and jibs": {},
      "Gimbals and stabilisers": {},
      "Gorilla pods": {},
      Monopods: {},
      "Other in Stand and rigs": {},
      "Slide rail and dolly": {},
      "Tripod heads": {},
    },
    "Camera bag": {},
    Monitor: {},
    "Memory Card": {},
    "Flash and lights": {},
    "Other in Film and Photography": {},
    "Photo background": {},
  },
  Other: "",
};

export default categoriesAndSubcategories;
