import React, { useState } from "react";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase.config";
import {
  addDoc,
  collection,
  serverTimestamp,
  arrayUnion,
  doc,
  updateDoc,
  increment,
  getDoc,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import { BeatLoader } from "react-spinners";
import { v4 as uuidv4 } from "uuid";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import stripeLogo from "../assets/stripeLogo.png";

const CheckoutForm = ({
  message,
  rentalRequestChatId,
  listingOwnerDetails,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [isLoading, setIsLoading] = useState(false);
  const [paymentStatusMessage, setPaymentStatusMessage] = useState("");
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  const {
    listingId,
    listingTitle,
    numberOfDays,
    ownerUid,
    rate,
    rentalCost,
    serviceFee,
    startDate,
    endDate,
    totalCost,
  } = message;

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const fetchExistingBookings = async () => {
    const listingRef = doc(db, "listings", listingId);
    const listingDoc = await getDoc(listingRef);
    if (listingDoc.exists) {
      return listingDoc.data().bookings || [];
    }
    return [];
  };

  const checkDateOverlap = (startDate1, endDate1, startDate2, endDate2) => {
    const start1 = startDate1.seconds;
    const end1 = endDate1.seconds;
    const start2 = startDate2.seconds;
    const end2 = endDate2.seconds;
    return start1 <= end2 && start2 <= end1;
  };

  const isBookingAvailable = (existingBookings, newStartDate, newEndDate) => {
    for (const booking of existingBookings) {
      const { startDate, endDate } = booking;
      if (checkDateOverlap(newStartDate, newEndDate, startDate, endDate)) {
        toast.error(
          "These dates are already booked. Please choose different dates.",
          { autoClose: 6000 }
        );
        return false;
      }
    }
    return true;
  };

  // Function used for incrementing booking count or rental count for owner/renter
  const incrementCounterForUser = async (userId, field) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, {
      [field]: increment(1),
    });
  };

  // Function used to create booking/rental document for owner/renter
  const createDocumentForUser = async (userId, collectionName, data) => {
    const docRef = await addDoc(
      collection(db, "users", userId, collectionName),
      data
    );
    return docRef.id;
  };

  const createRentalBookingInListingDocument = async (bookingId) => {
    const bookingDetails = {
      bookingId,
      userUid: currentUser.uid,
      startDate,
      endDate,
      createdAt: new Date(),
    };
    const listingRef = doc(db, "listings", listingId);
    await updateDoc(listingRef, {
      bookings: arrayUnion(bookingDetails),
    });
  };

  const updateRentalRequestStatus = async () => {
    // Update rental-request document to status === confirmed
    const rentalRef = doc(db, "rental-requests", rentalRequestChatId);
    await updateDoc(rentalRef, { status: "confirmed" });

    // Update rental-request message document status to "rental-confirmed"
    const messageRef = doc(
      db,
      "rental-requests",
      rentalRequestChatId,
      "messages",
      message.id
    );
    await updateDoc(messageRef, {
      type: "rental-confirmed",
      createdAt: serverTimestamp(),
    });
  };

  const sendEmailToOwner = async () => {
    const body = JSON.stringify({
      ownerUsername: listingOwnerDetails.username,
      ownerEmail: listingOwnerDetails.email,
      renterUsername: currentUser.username,
      listingTitle,
      earnings: rate * numberOfDays,
      duration: numberOfDays,
      startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
      endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
    });

    try {
      const response = await fetch(
        `https://rentalconfirmedowneremail-rentalconfirmedownerema-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );
      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error.message);
      toast.error("Failed to send confirmation email to owner.", {
        autoClose: 3000,
      });
    }
  };

  const writePaymentIntentToFirebase = async (
    paymentIntent,
    bookingId,
    rentalDocId
  ) => {
    const rentalDocumentRef = doc(
      db,
      "users",
      currentUser.uid,
      "rentals",
      rentalDocId
    );
    const ownerDocumentRef = doc(db, "users", ownerUid, "bookings", bookingId);
    const rentalRequestRef = doc(db, "rental-requests", rentalRequestChatId);

    await Promise.all([
      updateDoc(rentalDocumentRef, { paymentIntent }),
      updateDoc(ownerDocumentRef, { paymentIntent }),
      updateDoc(rentalRequestRef, { paymentIntent }),
    ]);
  };

  const confirmPayment = async () => {
    if (!stripe || !elements) {
      return null;
    }
    setPaymentStatusMessage("");
    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/rental-success`,
      },
      redirect: "if_required",
    });

    if (error) {
      setPaymentStatusMessage(error.message);
      throw new Error(error.message);
    } else if (paymentIntent) {
      return paymentIntent;
    }
    return null;
  };

  const handleConfirmRental = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    try {
      const existingBookings = await fetchExistingBookings();
      if (!isBookingAvailable(existingBookings, startDate, endDate)) {
        setIsLoading(false);
        return;
      }

      const bookingId = uuidv4();
      const paymentIntent = await confirmPayment();
      if (!paymentIntent) throw new Error("Payment confirmation failed.");

      const rentalDocId = await createDocumentForUser(
        currentUser.uid,
        "rentals",
        {
          bookingId,
          listingId,
          ownerUid,
          numberOfDays,
          startDate,
          endDate,
          rentalCost,
          rate,
          totalCost,
          serviceFee,
          createdAt: serverTimestamp(),
        }
      );

      await incrementCounterForUser(currentUser.uid, "rentalCount");

      const firebaseBookingId = await createDocumentForUser(
        ownerUid,
        "bookings",
        {
          bookingId,
          listingId,
          renterUid: currentUser.uid,
          numberOfDays,
          rate,
          startDate,
          endDate,
          earnings: rate * numberOfDays,
          createdAt: serverTimestamp(),
        }
      );

      await incrementCounterForUser(ownerUid, "bookingCount");

      await createRentalBookingInListingDocument(bookingId);

      await updateRentalRequestStatus();

      await writePaymentIntentToFirebase(
        paymentIntent,
        firebaseBookingId,
        rentalDocId
      );
      await sendEmailToOwner();

      toast.success("Rental confirmed.", { autoClose: 3000 });
      setIsLoading(false);
      navigate("/my-rentals");
    } catch (error) {
      console.error(error);
      toast.error(`Failed to confirm rental: ${error.message}`, {
        autoClose: 5000,
      });
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleConfirmRental}>
      <div className="flex flex-col">
        <div className="bg-gray-100 py-2 px-1 rounded-md shadow-md my-3">
          <PaymentElement />
        </div>

        {paymentStatusMessage && (
          <div className="bg-gray-200 rounded-md p-2 font-semibold text-center my-2">
            {paymentStatusMessage}
          </div>
        )}

        <div className="flex justify-center my-2">
          <button
            className="min-w-48 btn-confirm px-3"
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <BeatLoader color="white" />
            ) : (
              <div>Confirm Rental €{totalCost.toFixed(2)}</div>
            )}
          </button>
        </div>
      </div>
      <div className="flex justify-center align-center items-center">
        <p className="text-sm text-center font-bold">Payment secured with</p>
        <img src={stripeLogo} alt="stripeLogo" className="inline w-1/6"></img>
      </div>
    </form>
  );
};

export default CheckoutForm;
