import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";

const FAQ = () => {
  const [selectedQuestion, setSelectedQuestion] = useState(null);

  const faqs = [
    {
      question: "How much does it cost to rent an item?",
      answer:
        "Placing an item for rent on KitShare is absolutely free. We only take a small cut if and when you get paid for a rental. This allows our members to list as many items for rent as they desire, without worrying about costs.",
    },
    {
      question: "How much does KitShare charge if I rent my item?",
      answer:
        "Entering a competition is as simple as selecting one of our active competitions and purchasing a selected number of tickets. Our competitions showcase the prize details and the total number of tickets for that competition.",
    },
    {
      question: "How do I get paid?",
      answer:
        "We use the industry leading Stripe and Stripe Connect to process our payments. Once onboarded with Stripe Connect, you will have access to a full dashboard of transactions and history of payments. When a rental is confirmed, the money will be taken from the renters account and will be transferred to the item owners account on the start date of the rental.",
    },
    {
      question: "Does KitShare insure provide insurance on rentals?",
      answer:
        "While KitShare does not currently provide insurance for your rented items, we allow you to request a deposit from the renter.",
    },
    {
      question: "When do I get paid from KitShare?",
      answer:
        "While KitShare does not currently provide insurance for your rented items, we allow you to request a deposit from the renter.",
    },
    {
      question: "Are transactions secure with KitShare?",
      answer:
        "While KitShare does not currently provide insurance for your rented items, we allow you to request a deposit from the renter.",
    },
  ];

  const toggleFAQ = (index) => {
    setSelectedQuestion(selectedQuestion === index ? null : index);
  };

  return (
    <div className="bg-gray-50 py-10">
      <div className="flex w-full justify-center">
        <h1 className="w-full md:w-2/3 mb-6 text-2xl font-bold text-gray-800 header__center">
          Frequently Asked Questions
        </h1>
      </div>
      <div className="flex justify-center">
        <div className="w-11/12 md:w-8/12 lg:w-6/12">
          <div className="faq">
            {faqs.map((faq, i) => (
              <div
                key={i}
                onClick={() => toggleFAQ(i)}
                className="faq-item mb-4 p-4 rounded-lg bg-white shadow-md hover:shadow-lg transition-shadow duration-200 cursor-pointer"
              >
                <div className="faq-question flex justify-between items-center text-lg font-semibold text-gray-700">
                  {faq.question}
                  <FontAwesomeIcon
                    icon={selectedQuestion === i ? faChevronUp : faChevronDown}
                    className="text-gray-500"
                  />
                </div>
                <div
                  className={`faq-answer overflow-hidden transition-all duration-300`}
                  style={{
                    maxHeight: selectedQuestion === i ? "1000px" : "0",
                  }}
                >
                  <p className="mt-2 text-gray-600 text-base leading-relaxed">
                    {faq.answer}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQ;
