import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  doc,
  serverTimestamp,
  addDoc,
  collection,
  getDoc,
  updateDoc,
  increment,
  getDocs,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import "../css/ReviewForm.css";
import reviews from "../assets/reviews.png";
import { BeatLoader } from "react-spinners";

const ReviewForm = ({
  revieweeUid,
  revieweeDetails,
  rentalRequestDetails,
  listingDetails,
  rentalRequestId,
}) => {
  const [rating, setRating] = useState(0);
  const [hoverRating, setHoverRating] = useState(0);
  const [comment, setComment] = useState("");
  const [submittingReview, setSubmittingReview] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  console.log(rentalRequestDetails);

  const changeHasReviewedPropertyToTrue = async () => {
    // get the rental-request document
    const rentalRef = doc(db, "rental-request", rentalRequestId);
    const rentalDoc = await getDoc(rentalRef);
    if (rentalDoc.exists) {
      if (revieweeUid === rentalDoc.data().ownerUid) {
        await updateDoc(rentalRef, {
          ownerHasReviewed: true,
        });
      } else if (revieweeUid === rentalDoc.data().renterUid) {
        await updateDoc(rentalRef, {
          renterHasReviewed: true,
        });
      } else {
        // reviewee does not match renter or owner - something wrong here
        console.log("revieweeUid does not match ownerUid or renterUid");
        return;
      }
    }
  };

  const incrementReviewCount = async () => {
    const userRef = doc(db, "users", revieweeUid);
    const userDoc = await getDoc(userRef);
    if (userDoc.exists()) {
      await updateDoc(userRef, {
        reviewCount: increment(1),
      });
    }
  };

  const createReview = async () => {
    const reviewsCollectionRef = collection(
      db,
      "users",
      revieweeUid,
      "reviews"
    );
    await addDoc(reviewsCollectionRef, {
      comment,
      rating,
      createdAt: serverTimestamp(),
      listingId: listingDetails.id,
      reviewerUid: currentUser.uid,
      rentalRequestId,
    });
  };

  const updateFirestoreAverageReviewRating = async (
    updatedAverageReviewRating
  ) => {
    const userRef = doc(db, "users", revieweeUid);
    await updateDoc(userRef, {
      averageReviewRating: updatedAverageReviewRating,
    });
  };

  const updateAverageReviewRating = async () => {
    let updatedAverageReviewRating = 0;
    let sumOfAllRatings = 0;
    let numberOfRatings = 0;
    const userRef = collection(db, "users", revieweeUid, "reviews");
    const userCol = await getDocs(userRef);
    if (userCol.empty) {
      console.log("empty");
    } else {
      userCol.forEach((review) => {
        sumOfAllRatings += review.data().rating;
        numberOfRatings++;
      });
    }
    updatedAverageReviewRating = Number(
      (sumOfAllRatings / numberOfRatings).toFixed(2)
    );

    // write new average rating to firestore
    updateFirestoreAverageReviewRating(updatedAverageReviewRating);
  };

  const handleSubmitReview = async (e) => {
    e.preventDefault();
    if (!rating || !comment) {
      toast.error("Please fill in all fields", { autoClose: 3000 });
      return;
    }

    try {
      setSubmittingReview(true);

      const rentalRef = doc(db, "rental-requests", rentalRequestId);
      const rentalDoc = await getDoc(rentalRef);

      if (rentalDoc.exists()) {
        const rentalData = rentalDoc.data();

        if (
          (revieweeUid === rentalData.ownerUid &&
            rentalData.ownerHasReviewed) ||
          (revieweeUid === rentalData.renterUid && rentalData.renterHasReviewed)
        ) {
          toast.error("You have already reviewed this rental", {
            autoClose: 3000,
          });
          setSubmittingReview(false);
          return;
        }

        // Create review
        await createReview();

        // Increment review count
        await incrementReviewCount();

        // Recalculate user's average review rating
        await updateAverageReviewRating();

        // Update hasReviewed property
        await changeHasReviewedPropertyToTrue();

        toast.success("Review submitted successfully", { autoClose: 3000 });
        navigate("/dashboard");
      } else {
        toast.error("Rental request not found", { autoClose: 3000 });
      }
    } catch (error) {
      toast.error("Failed to submit review");
      console.error("Error submitting review: ", error);
    } finally {
      setSubmittingReview(false);
    }
  };

  return (
    <div>
      {/* <div className="flex justify-center">
        <img src={reviews} alt="reviews" className="w-64" />
      </div> */}
      <form onSubmit={handleSubmitReview} className="review-form bg-gray-50">
        <h2 className="font-semibold">
          Leave a review for{" "}
          <span className="text-teal-500">
            {revieweeDetails && revieweeDetails.username}
          </span>
        </h2>
        <div className="rating">
          {[1, 2, 3, 4, 5].map((star) => (
            <FontAwesomeIcon
              key={star}
              icon={faStar}
              className={`star ${
                star <= (hoverRating || rating) ? "hover" : ""
              }`}
              onClick={() => setRating(star)}
              onMouseEnter={() => setHoverRating(star)}
              onMouseLeave={() => setHoverRating(0)}
            />
          ))}
        </div>
        <p className="text-sm font-semibold mt-5">Comment (required)</p>
        <textarea
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          className="mt-2 text-sm"
        />
        <div className="flex justify-center">
          <button
            type="submit"
            className="btn-continue px-3 mt-2"
            disabled={submittingReview}
          >
            {submittingReview ? <BeatLoader color="white" /> : "Submit Review"}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ReviewForm;
