import React, { useState } from "react";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCancel } from "@fortawesome/free-solid-svg-icons";
import { db } from "../firebase.config";
import { doc, updateDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "rgba(255,255,255,0.8)",
  },
};

const RentalCompleteMessageRequest = ({ message, currentUser }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [displayMoreTimeModal, setDisplayMoreTimeModal] = useState(false);
  const [requestMessage, setRequestMessage] = useState("");
  const rentalRequestId = message.rentalRequestId;

  const {
    listingTitle,
    startDate,
    endDate,
    content,
    numberOfDays,
    listingImage,
    rentalCost,
  } = message;

  let deposit;

  if (message.deposit) {
    deposit = message.deposit;
  }

  const onChange = (e) => {
    setRequestMessage(e.target.value);
  };

  const onClose = () => {
    setDisplayMoreTimeModal(false);
  };

  const sendEmailToKitShareTeam = async () => {
    const body = JSON.stringify({
      requestorUid: currentUser.uid,
      requestorUsername: currentUser.username,
      requestorEmail: currentUser.email,
      requestorPhoneNumber: currentUser.phoneNumber,
      renterUid: message.renterUid,
      ownerUid: message.ownerUid,
      listingId: message.listingId,
      listingTitle: message.listingTitle,
      requestMessage: requestMessage,
    });
    try {
      const response = await fetch(``, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body,
      });

      const data = await response.json();
      console.log(data);
    } catch (error) {
      console.log(error.message);
      setIsLoading(false);
      toast.error(
        "Something went wrong sending the availability rejection email.",
        {
          autoClose: 3000,
        }
      );
    }
  };

  const setMoreTimeRequestedFlag = async () => {
    const rentalRequestRef = doc(db, "rental-requests", rentalRequestId);
    await updateDoc(rentalRequestRef, {
      moreTimeRequested: true,
    });
  };

  const handleRequestMoreTime = async () => {
    // set status of moreTimeRequested = true on the rental-request
    await setMoreTimeRequestedFlag();
    // send email to KitShare Team
    sendEmailToKitShareTeam();
  };

  // Convert Firestore Timestamp to JavaScript Date object
  const startDateDate = startDate.toDate();
  const endDateDate = endDate.toDate();

  // Add 2 days to endDate
  const extendedEndDate = new Date(endDateDate);
  extendedEndDate.setDate(extendedEndDate.getDate() + 2);

  const formatDate = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || "th";

    return `${day}${suffix} ${month}`;
  };

  // Using the converted Date objects from above
  const readableStartDate = formatDate(startDateDate);
  const readableEndDate = formatDate(endDateDate);
  const readableExtendedEndDate = formatDate(extendedEndDate);

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  return (
    <div className="">
      <Modal
        isOpen={displayMoreTimeModal}
        onRequestClose={onClose}
        contentLabel="Checklist Modal"
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <h2 className="text-center font-bold text-lg mb-4">
            Need more time?
          </h2>
          <p className="font-semibold my-2">
            Let the KitShare team know why more time is required.
          </p>
          <p className="font-semibold my-2">
            This will keep this chat open for 48 more hours.
          </p>
          <p className="font-semibold my-2">
            After 48 hours, this chat and it's contents will be deleted.
          </p>

          <div>
            <div>
              <input
                type="text"
                id="request"
                value={requestMessage}
                onChange={onChange}
                placeholder="add request here"
              ></input>
            </div>
            <div className="flex items-center align-center justify-center mt-3 mb-2 gap-3 text-white">
              <button
                className="p-2 bg-green-500 rounded-md shadow-md hover:bg-green-600 hover:font-semibold"
                onClick={() => handleRequestMoreTime()}
              >
                <p>
                  {" "}
                  <FontAwesomeIcon icon={faCheck} /> Request more time
                </p>
              </button>
              <button
                onClick={() => setDisplayMoreTimeModal(false)}
                className="p-2 bg-red-500 rounded-md shadow-md hover:bg-red-600 hover:font-semibold"
              >
                <FontAwesomeIcon icon={faCancel} /> Go back
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <h2 className="text-center text-xl text-emerald-400 font-bold">
        Rental Completed?
      </h2>
      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md"></img>
        <p className="font-bold mt-2">{listingTitle}</p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p>
          <span className="font-bold">Duration:</span> {numberOfDays} days
        </p>
        <p>
          <span className="font-bold">Dates: </span>
          {`${readableStartDate} - ${readableEndDate}`}
        </p>
      </div>
      <p className="text-center text-base text-yellow-400 my-2 font-bold">
        This chat will be deleted on {readableExtendedEndDate}.
      </p>
      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3">
        <button
          className="p-2 bg-red-500 rounded-md shadow-md hover:bg-red-600 hover:font-semibold"
          onClick={() => setDisplayMoreTimeModal(true)}
        >
          Need more time?
        </button>
      </div>
      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>{" "}
      </div>
    </div>
  );
};

export default RentalCompleteMessageRequest;
