import React, { useState } from "react";
import { doc, serverTimestamp, updateDoc } from "firebase/firestore";
import { db } from "../../firebase.config";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { convertToTimeZone } from "date-fns-timezone";
import { format } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faDeleteLeft, faX } from "@fortawesome/free-solid-svg-icons";
import firestoreTimestampToDate from "../../utils/firestoreTimestampToDate";
import CheckListModal from "./CheckListModal";

const AvailabilityRequestOwner = ({
  message,
  onOpenModal,
  currentUser,
  recipientDetails,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isChecklistModalOpen, setIsChecklistModalOpen] = useState(false);
  const rentalRequestId = message.rentalRequestId;

  const {
    listingTitle,
    startDate,
    content,
    endDate,
    totalCost,
    rate,
    numberOfDays,
    listingImage,
    serviceFee,
    rentalCost,
  } = message;

  let deposit;

  if (message.deposit) {
    deposit = message.deposit;
  }

  // Get email and username of user who will receive the availability confirmed email
  const { email, username } = recipientDetails;

  // Convert Firestore Timestamp to JavaScript Date object
  const startDateDate = startDate.toDate();
  const endDateDate = endDate.toDate();

  const formatDate = (date) => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    const day = date.getDate();
    const month = months[date.getMonth()];
    const suffix = ["th", "st", "nd", "rd"][((day % 100) - 20) % 10] || "th";

    return `${day}${suffix} ${month}`;
  };

  // Using the converted Date objects from above
  const readableStartDate = formatDate(startDateDate);
  const readableEndDate = formatDate(endDateDate);

  const sendAvailabilityConfirmedEmail = async () => {
    // username -> who should receive the email
    // email -> user email who should receive the email
    // listingOwner -> currentUser.username
    // listingTitle
    // numberOfDays
    // startDate
    // endDate
    // price
    // serviceFee
    // totalCost
    try {
      const body = JSON.stringify({
        renterUsername: username,
        renterEmail: email,
        ownerUsername: currentUser.username,
        listingTitle,
        numberOfDays,
        startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
        endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
        rate,
        serviceFee,
        totalCost,
      });

      setIsLoading(true);
      const response = await fetch(
        `https://availabilityconfirmedemail-availabilityconfirmede-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log(data);
      setIsLoading(false);
      console.log("sending email");
    } catch (error) {
      console.log(error);
    }
  };

  const showChecklistModal = () => {
    setIsChecklistModalOpen(true);
  };

  const handleConfirmAvailability = async () => {
    try {
      setIsLoading(true);
      const messageRef = doc(
        db,
        "rental-requests",
        rentalRequestId,
        "messages",
        message.id
      );

      await updateDoc(messageRef, {
        type: "availability-confirmed",
        createdAt: serverTimestamp(),
      });

      await sendAvailabilityConfirmedEmail();

      setIsLoading(false);

      toast.success(
        "Requestor has been notified of the availability of the item.",
        {
          autoClose: 3000,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return "";

    // Convert the Firebase timestamp to a JavaScript date
    const date = timestamp.toDate();

    // Convert to the specified timezone (Europe/Dublin)
    const dublinDate = convertToTimeZone(date, { timeZone: "Europe/Dublin" });

    // Format the local date
    return format(dublinDate, "MMM dd, yyyy HH:mm");
  };

  return (
    <div className="">
      <CheckListModal
        isOpen={isChecklistModalOpen}
        onClose={() => setIsChecklistModalOpen(false)}
        handleConfirm={handleConfirmAvailability}
      />
      <h2 className="text-center font-semibold">Availability Request</h2>
      <p className="text-center my-5 new-line">{content}</p>
      <div className="flex flex-col items-center my-3">
        <img src={listingImage} alt="listing" className="w-36 rounded-md"></img>
        <p className="font-semibold mt-2">{listingTitle}</p>
      </div>
      <div className="flex flex-col items-center gap-2">
        <p>
          <span className="font-bold">Your earnings:</span> €
          {rentalCost.toFixed(2)}
        </p>
        <p>
          <span className="font-bold">Duration:</span> {numberOfDays} days
        </p>
        <p>{`${readableStartDate} - ${readableEndDate}`}</p>
        {deposit && (
          <p>
            <span className="font-bold">Required deposit:</span> €
            {deposit.toFixed(2)}
          </p>
        )}
      </div>
      <div className="flex flex-col items-center align-center justify-center mt-3 mb-2 gap-3">
        <button
          className="p-2 w-1/2 btn-confirm"
          onClick={() => showChecklistModal()}
        >
          {isLoading ? (
            <BeatLoader />
          ) : (
            <p>
              <FontAwesomeIcon icon={faCheck} /> Yes it's available
            </p>
          )}
        </button>
        <button className="p-2 w-1/2 btn-delete" onClick={onOpenModal}>
          <FontAwesomeIcon icon={faX} /> Reject Request
        </button>
      </div>
      <div>
        <p className="text-xs text-gray-100 text-right">
          {formatTimestamp(message.createdAt)}
        </p>{" "}
      </div>
    </div>
  );
};

export default AvailabilityRequestOwner;
