import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";
import { Elements } from "@stripe/react-stripe-js";
import { doc, getDoc } from "firebase/firestore";
import { loadStripe } from "@stripe/stripe-js";
import { useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faMoneyBill,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import CheckoutForm from "../components/CheckoutForm"; // make sure the path is correct
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { toast } from "react-toastify";
import { useAuth } from "../hooks/useAuth";
import { BeatLoader } from "react-spinners";

// Load Stripe with your public key
const stripePromise = loadStripe(
  "pk_test_51P5Qf6AyrpazurttmWPqdBQGwW7pr7EtqlSNEp63XJwJNgHtyQgWp5U82x3S0Nfpj9GzlwhzFbXh26pcVLTVBoXM00f7ruZuW5"
);

const ConfirmRental = () => {
  const [listingOwnerDetails, setListingOwnerDetails] = useState("");
  const [deposit, setDeposit] = useState(0);
  const [clientSecret, setClientSecret] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();

  const { currentUser } = useAuth();

  const getListingOwnerDetails = async (listingOwnerUid) => {
    const ownerDocRef = doc(db, "users", listingOwnerUid);
    const ownerSnap = await getDoc(ownerDocRef);
    if (ownerSnap.exists()) {
      setListingOwnerDetails(ownerSnap.data());
    } else {
      setListingOwnerDetails({ username: null, location: null });
    }
  };

  useEffect(() => {
    const { ownerUid } = location.state.message;
    getListingOwnerDetails(ownerUid);
    if (location.state.message) {
      setDeposit(location.state.message.deposit);
    }
  }, [location]);

  const { message, rentalRequestChatId } = location.state;
  const {
    listingTitle,
    listingImage,
    numberOfDays,
    rate,
    rentalCost,
    serviceFee,
    startDate,
    endDate,
    totalCost,
    ownerUid,
    listingId,
  } = message;

  const formattedStartDate =
    firestoreTimestampToDate(startDate)?.toDateString() || "Loading...";
  const formattedEndDate =
    firestoreTimestampToDate(endDate)?.toDateString() || "Loading...";

  const handlePayNow = async () => {
    try {
      setIsLoading(true);
      const response = await fetch(
        "https://createpaymentintent-createpaymentintent-iz3msmwhcq-nw.a.run.app",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            amount: totalCost,
            currency: "eur",
            email: currentUser.email,
            title: `Rental Payment for ${listingTitle}`,
            renterUid: currentUser.uid,
            ownerUid,
            listingId,
            startDate: firestoreTimestampToDate(startDate).toLocaleDateString(),
            endDate: firestoreTimestampToDate(endDate).toLocaleDateString(),
            numberOfDays,
            rate,
            rentalCost,
            totalCost,
            serviceFee,
          }),
        }
      );

      const data = await response.json();
      if (data.clientSecret) {
        setClientSecret(data.clientSecret);
      } else {
        toast.error("Failed to initialize payment.");
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error creating payment intent:", error);
      toast.error("Failed to initialize payment.");
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex w-full justify-center">
        <h1 className="w-2/3 text-center my-5 text-2xl font-bold header__center">
          Confirm Your Rental
        </h1>
      </div>

      {/* Main Flex Container */}
      <div className="flex flex-col-reverse sm:flex-row justify-center gap-10">
        {/* Left Hand Side - Rental Details */}

        <div className="w-full sm:w-1/3 p-3 sm:p-0">
          <p className="font-semibold text-xl mb-2">Rental Details</p>
          {/* Listing pricing details */}
          <div className="bg-gray-100 shadow-md flex flex-col gap-3 rounded-md p-2">
            <p>
              <span className="font-semibold">Number of days: </span>{" "}
              {numberOfDays}
            </p>
            <p>
              <span className="font-semibold">Dates:</span> {formattedStartDate}{" "}
              - {formattedEndDate}
            </p>
            {deposit && deposit > 0 ? (
              <p>
                <span className="font-semibold">Required Deposit:</span> €
                {deposit.toFixed(2)}
              </p>
            ) : null}
            <p>
              <span className="font-semibold">Rate:</span> €{rate.toFixed(2)}
            </p>
            {/* <div>
            {threeDayPlusRate ? <p>3+ Day Rate: €{threeDayPlusRate}</p> : null}
          </div>
          <div>
            {sevenDayPlusRate ? <p>7+ Day Rate: €{sevenDayPlusRate}</p> : null}
          </div> */}
            <p className="border-t-2 border-gray-400 pt-4">
              €{rate.toFixed(2)} x {numberOfDays} days = €
              {rentalCost.toFixed(2)}
            </p>
            <p>
              <span className="font-semibold">Service Fee (10%):</span> €
              {serviceFee.toFixed(2)}
            </p>
            <p className="border-t-2 border-gray-400 pt-4">
              <span className="font-semibold">Total:</span> €
              {totalCost.toFixed(2)}
            </p>
          </div>
          {clientSecret ? (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm
                message={message}
                rentalRequestChatId={rentalRequestChatId}
                listingOwnerDetails={listingOwnerDetails}
              />
            </Elements>
          ) : (
            <div className="flex justify-center mt-5 ">
              <button
                onClick={handlePayNow}
                disabled={isLoading}
                className="px-3 btn-confirm"
              >
                {isLoading ? (
                  <BeatLoader color="white" />
                ) : (
                  <p>
                    <FontAwesomeIcon icon={faCheck} /> Confirm and Pay
                  </p>
                )}
              </button>
            </div>
          )}

          {/* Cancellation policy */}
          <div className="mt-5 bg-gray-100 p-2 rounded-md shadow-md">
            <div className="flex flex-col ">
              <h2 className="font-semibold text-lg">Cancellation Policy</h2>
              <p>
                Our goal is to provide a flexible and fair cancellation policy
                for our customers while ensuring the sustainability of our
                rental services. Please review our cancellation terms below:
              </p>
              <h3 className="font-semibold mt-5">Cancellation Terms:</h3>
              <ul>
                <li>
                  <strong>Full Refund</strong>: Cancellations made 14 or more
                  days before the rental start date will receive a full refund.
                </li>
                <li>
                  <strong>50% Refund</strong>: Cancellations made between 7 and
                  14 days before the rental start date will receive a 50%
                  refund.
                </li>
                <li>
                  <strong>No Refund</strong>: Cancellations made less than 7
                  days before the rental start date will not be eligible for a
                  refund.
                </li>
                <li>
                  <strong>Cancellation Fee</strong>: A flat fee of €20 will be
                  charged for cancellations made within 7 days of the rental
                  start date.
                </li>
                <li>
                  <strong>Special Circumstances</strong>: Full refunds or
                  waivers of the cancellation fee may be considered for
                  documented emergencies or unforeseen events, evaluated on a
                  case-by-case basis.
                </li>
              </ul>
              <p>
                We believe this policy strikes a balance between flexibility for
                our customers and the necessary safeguards for our operations.
                If you have any questions or require assistance, please contact
                our support team.
              </p>
              <p className="mt-4">
                <strong>Contact Us</strong>: info@kitshare.ie
              </p>
            </div>
          </div>
        </div>

        {/* Right Hand Side - Listing and User Details */}
        <div className="flex flex-col p-2">
          {/* Listing Details */}
          <div className="flex flex-col justify-center align-center items-center">
            <h2 className="font-semibold text-xl mb-4">{listingTitle}</h2>
            <img src={listingImage} className="w-48 mb-5" alt="listingImage" />
          </div>
          {/* User details */}
          <div className="flex justify-between bg-gray-100 rounded-md p-2 shadow-md">
            <p className="text-base mb-2">
              <span className="font-semibold">
                {listingOwnerDetails?.username ?? ""}
              </span>{" "}
              in {listingOwnerDetails?.location?.town ?? ""}
            </p>
            <div className="ml-5 flex items-center mb-2 gap-2">
              <p className="text-sm">
                {listingOwnerDetails.averageReviewRating
                  ? listingOwnerDetails.averageReviewRating
                  : "N/A"}{" "}
                <FontAwesomeIcon icon={faStar} color="gold" />
              </p>
              <p className="text-sm">
                {" "}
                (
                {listingOwnerDetails.reviewCount > 0
                  ? listingOwnerDetails.reviewCount
                  : 0}
                )
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRental;
