import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  getDoc,
  addDoc,
  where,
  serverTimestamp,
  updateDoc,
  arrayRemove,
  increment,
  deleteDoc,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";

const MyBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [renterDetails, setRenterDetails] = useState({});
  const [selectedBooking, setSelectedBooking] = useState({});
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  const fetchListingDetails = async (listingId) => {
    const docRef = doc(db, "listings", listingId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : { title: "Listing not found" };
  };

  const fetchRenterDetails = async (renterUid) => {
    const docRef = doc(db, "users", renterUid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setRenterDetails({ id: docSnap.id, ...docSnap.data() });
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      return null;
    }
  };

  useEffect(() => {
    const fetchBookings = async () => {
      if (!currentUser) return;

      const bookingsRef = collection(db, "users", currentUser.uid, "bookings");
      const q = query(bookingsRef, orderBy("createdAt", "desc"));
      try {
        const querySnapshot = await getDocs(q);
        const bookingsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const bookingData = doc.data();
            const listingDetails = await fetchListingDetails(
              bookingData.listingId
            );
            const renterDetails = await fetchRenterDetails(
              bookingData.renterUid
            );

            console.log(bookingData);

            return {
              ...bookingData,
              id: doc.id,
              listingDetails,
              renterUsername: renterDetails?.username || "Unknown",
              formattedStartDate: firestoreTimestampToDate(
                bookingData.startDate
              ).toLocaleDateString(),
              formattedEndDate: firestoreTimestampToDate(
                bookingData.endDate
              ).toLocaleDateString(),
            };
          })
        );
        setBookings(bookingsData);
      } catch (error) {
        console.error("Failed to fetch bookings:", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchBookings();
  }, [currentUser]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleShowCancelBooking = (booking) => {
    setShowCancellationModal(true);
    setSelectedBooking(booking);
  };

  const processUserRefund = async () => {
    const body = JSON.stringify({
      payment_intent: selectedBooking.paymentIntent.id,
      amount: selectedBooking.rentalCost, // conversion to lowest value e.g. cent is handled server side
    });
    try {
      const response = await fetch(
        `https://createstriperefund-createstriperefund-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Refund processed:", data);
    } catch (error) {
      console.error("Error processing refund:", error.message);
    }
  };

  const deleteBookingFromListingDocument = async () => {
    try {
      const listingRef = doc(db, "listings", selectedBooking.listingId);

      const listingDoc = await getDoc(listingRef);

      if (!listingDoc.exists()) {
        console.error("Listing not found");
        return;
      }

      const { bookings } = listingDoc.data();

      // Find the booking to be removed
      const bookingToRemove = bookings.find(
        (booking) => booking.bookingId === selectedBooking.bookingId
      );

      if (!bookingToRemove) {
        console.error("Booking not found in listing");
        return;
      }

      // Remove the booking from the bookings array
      await updateDoc(listingRef, {
        bookings: arrayRemove(bookingToRemove),
      });

      console.log("Booking successfully removed from listing document.");
    } catch (error) {
      console.error("Error removing booking from listing document:", error);
    }
  };

  const sendCancellationEmailToRenter = async () => {
    const body = JSON.stringify({
      renterUsername: renterDetails.username,
      renterEmail: renterDetails.email,
      listingTitle: selectedBooking.listingDetails.title,
      ownerUsername: currentUser.username,
      reason: inputValue,
    });
    try {
      const response = await fetch(
        `https://cancelbookingemail-sendcancelbookingemail-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Cancellation email sent:", data);
    } catch (error) {
      console.error("Failed to send cancellation email:", error);
    }
  };

  const deleteRentalRequest = async () => {
    const body = JSON.stringify({
      rentalRequestId: selectedBooking.id,
    });
    try {
      const response = await fetch(
        `https://deleterentalrequest-deleterentalrequest-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Rental request deleted:", data);
    } catch (error) {
      console.error("Failed to delete rental request:", error);
      toast.error("Something went wrong deleting the rental request.", {
        autoClose: 3000,
      });
    }
  };

  const deleteBookingForOwnerAndReduceBookingCount = async () => {
    try {
      const bookingRef = doc(
        db,
        "users",
        currentUser.uid,
        "bookings",
        selectedBooking.id
      );
      await deleteDoc(bookingRef);

      const userRef = doc(db, "users", currentUser.uid);
      await updateDoc(userRef, {
        bookingCount: increment(-1),
      });

      console.log("Booking deleted and booking count reduced.");
    } catch (error) {
      console.error("Failed to delete booking:", error);
    }
  };

  const deleteRentalForRenterAndReduceRentalCount = async () => {
    try {
      let bookingId = selectedBooking.bookingId;

      const q = query(
        collection(db, "users", selectedBooking.renterUid, "rentals"),
        where("bookingId", "==", bookingId)
      );
      const querySnapshot = await getDocs(q);

      querySnapshot.forEach(async (rentalDoc) => {
        const rentalRef = doc(
          db,
          "users",
          selectedBooking.renterUid,
          "rentals",
          rentalDoc.id
        );

        await deleteDoc(rentalRef);

        const renterRef = doc(db, "users", selectedBooking.renterUid);
        await updateDoc(renterRef, {
          rentalCount: increment(-1),
        });

        console.log(`Rental deleted and rental count reduced for renter.`);
      });
    } catch (error) {
      console.error("Failed to delete rental for renter:", error);
    }
  };

  const createNewCancellationDocument = async () => {
    const cancellationData = {
      userUid: currentUser.uid,
      listingId: selectedBooking.listingId,
      cancellationReason: inputValue,
      cancellationDate: serverTimestamp(),
      startDate: selectedBooking.startDate,
      endDate: selectedBooking.endDate,
      cancellationType: "owner-cancellation",
    };

    try {
      await addDoc(collection(db, "cancellations"), cancellationData);
      console.log("New cancellation document created.");
    } catch (error) {
      console.error("Failed to create cancellation document:", error);
      toast.error("Failed to process cancellation.");
    }
  };

  const incremenetCancellationCountForOwner = async () => {
    const userRef = doc(db, "users", currentUser.uid);
    await updateDoc(userRef, {
      cancellationCount: increment(1),
    });
    console.log("User cancellation count incremented.");
  };

  const removeBookingFromState = () => {
    setBookings((prevBookings) =>
      prevBookings.filter((booking) => booking.id !== selectedBooking.id)
    );
  };

  const handleCancelBooking = async () => {
    setIsCancelling(true);

    try {
      await processUserRefund();
      await deleteRentalRequest();
      await deleteBookingFromListingDocument();
      await deleteRentalForRenterAndReduceRentalCount();
      await deleteBookingForOwnerAndReduceBookingCount();
      await createNewCancellationDocument();
      await incremenetCancellationCountForOwner();
      await sendCancellationEmailToRenter();

      toast.success("Booking has been cancelled successfully.", {
        autoClose: 3000,
      });

      removeBookingFromState();
    } catch (error) {
      console.error("Failed to cancel booking:", error);
      toast.error("Failed to cancel booking. Please try again.", {
        autoClose: 3000,
      });
    } finally {
      setShowCancellationModal(false);
      setInputValue("");
      setIsCancelling(false);
    }
  };

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      maxWidth: "450px",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(255,255,255,0.8)",
    },
  };

  return (
    <div className="container mx-auto">
      <Modal
        isOpen={showCancellationModal}
        onRequestClose={() => setShowCancellationModal(false)}
        contentLabel="Cancellation Modal"
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <p className="text-center font-semibold mb-5">
            Are you sure you want to cancel this booking?
          </p>
          <div>
            <p className="text-sm">
              Cancellations are monitored and captured in our system. Frequent
              cancellations can lead to account suspensions.
            </p>
            <p className="text-sm">
              To read our full cancellation policy, please visit{" "}
              <span
                onClick={() => navigate("/cancellation-policy")}
                className="hover:cursor-pointer font-semibold hover:underline"
              >
                Cancellation Policy
              </span>
            </p>
          </div>

          <div className="flex flex-col my-5">
            <label className="text-left text-sm font-semibold">
              Provide reason for cancellation (mandatory)
            </label>
            <span className="text-sm">
              This message will be sent to the renter and logged in our system.
            </span>
            <input
              className="text-sm my-2"
              placeholder="Reason for cancellation..."
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex gap-3 justify-center">
            <button
              className="px-3 btn-cancel"
              onClick={() => setShowCancellationModal(false)}
            >
              <FontAwesomeIcon icon={faCancel} /> No, keep booking
            </button>
            <button
              className={
                inputValue.length < 6
                  ? "bg-gray-300 text-white p-3 rounded-lg"
                  : "btn-continue px-3"
              }
              onClick={handleCancelBooking}
              disabled={isCancelling || inputValue.length < 5}
            >
              {isCancelling ? (
                <BeatLoader color="white" />
              ) : (
                <p>
                  <FontAwesomeIcon icon={faCheck} /> Yes, cancel booking
                </p>
              )}
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex w-full justify-center">
        <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
          My Bookings
        </h1>
      </div>
      <div className="overflow-x-auto px-5">
        {bookings.length > 0 ? (
          <table className="min-w-full table-auto text-center">
            <thead>
              <tr>
                <th className="px-4 py-2">Item</th>
                <th className="px-4 py-2">Renter</th>
                <th className="px-4 py-2">Days</th>
                <th className="px-4 py-2">Earnings</th>
                <th className="px-4 py-2">Start Date</th>
                <th className="px-4 py-2">End Date</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {bookings.map((booking) => (
                <tr key={booking.id}>
                  <td
                    className="border px-4 py-2 hover:cursor-pointer hover:font-semibold"
                    onClick={() =>
                      navigate(
                        `/listings/${booking.listingDetails.location.town}/${booking.listingId}`
                      )
                    }
                  >
                    {booking.listingDetails.title}
                  </td>
                  <td
                    className="border px-4 py-2 hover:cursor-pointer hover:font-semibold"
                    onClick={() => navigate(`/users/${booking.renterUid}`)}
                  >
                    {booking.renterUsername}
                  </td>
                  <td className="border px-4 py-2">{booking.numberOfDays}</td>
                  <td className="border px-4 py-2">
                    ${booking.earnings.toFixed(2)}
                  </td>
                  <td className="border px-4 py-2">
                    {booking.formattedStartDate}
                  </td>
                  <td className="border px-4 py-2">
                    {booking.formattedEndDate}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() => handleShowCancelBooking(booking)}
                      className="btn-cancel px-2"
                    >
                      <FontAwesomeIcon icon={faCancel} /> Cancel Booking
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No bookings found.</p>
        )}
      </div>
    </div>
  );
};

export default MyBookings;
