import React, { useEffect, useState } from "react";
import { db } from "../firebase.config";
import {
  collection,
  query,
  getDocs,
  orderBy,
  doc,
  getDoc,
  arrayRemove,
  addDoc,
  serverTimestamp,
  updateDoc,
  increment,
  where,
  deleteDoc,
} from "firebase/firestore";
import { useAuth } from "../hooks/useAuth";
import firestoreTimestampToDate from "../utils/firestoreTimestampToDate";
import { useNavigate } from "react-router-dom";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCancel, faCheck } from "@fortawesome/free-solid-svg-icons";
import { BeatLoader } from "react-spinners";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";

const MyRentals = () => {
  const [rentals, setRentals] = useState([]);
  const [isFetching, setIsFetching] = useState(true);
  const [isCancelling, setIsCancelling] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [showCancellationModal, setShowCancellationModal] = useState(false);
  const [itemOwnerDetails, setItemOwnerDetails] = useState({});
  const [selectedRental, setSelectedRental] = useState({});
  const { currentUser } = useAuth();

  const navigate = useNavigate();

  const fetchListingDetails = async (listingId) => {
    const docRef = doc(db, "listings", listingId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : { title: "Listing not found" };
  };

  const fetchItemOwnerDetails = async (itemOwnerUid) => {
    const docRef = doc(db, "users", itemOwnerUid);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      setItemOwnerDetails({ id: docSnap.id, ...docSnap.data() });
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      return null;
    }
  };

  useEffect(() => {
    const fetchRentals = async () => {
      if (!currentUser) return;

      const rentalsRef = collection(db, "users", currentUser.uid, "rentals");
      const q = query(rentalsRef, orderBy("createdAt", "desc"));
      try {
        const querySnapshot = await getDocs(q);
        const rentalsData = await Promise.all(
          querySnapshot.docs.map(async (doc) => {
            const rentalData = doc.data();
            const listingDetails = await fetchListingDetails(
              rentalData.listingId
            );
            const itemOwnerDetails = await fetchItemOwnerDetails(
              listingDetails.ownerUid
            );

            console.log(rentalData);

            return {
              ...rentalData,
              id: doc.id,
              listingDetails,
              itemOwnerUsername: itemOwnerDetails?.username || "Unknown",
              formattedStartDate: firestoreTimestampToDate(
                rentalData.startDate
              ).toLocaleDateString(),
              formattedEndDate: firestoreTimestampToDate(
                rentalData.endDate
              ).toLocaleDateString(),
            };
          })
        );

        setRentals(rentalsData);
      } catch (error) {
        console.error("Failed to fetch rentals:", error);
      } finally {
        setIsFetching(false);
      }
    };

    fetchRentals();
  }, [currentUser]);

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleShowCancelRental = (rental) => {
    setShowCancellationModal(true);
    setSelectedRental(rental);
  };

  const processUserRefund = async () => {
    const body = JSON.stringify({
      payment_intent: selectedRental.paymentIntent.id,
      amount: selectedRental.rentalCost, // conversion to lowest value e.g. cent is handled server side
    });
    try {
      const response = await fetch(
        `https://createstriperefund-createstriperefund-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Refund processed:", data);
    } catch (error) {
      console.error("Error processing refund:", error.message);
    }
  };

  const sendCancellationEmailToOwner = async () => {
    const body = JSON.stringify({
      ownerUsername: itemOwnerDetails.username,
      ownerEmail: itemOwnerDetails.email,
      listingTitle: selectedRental.listingDetails.title,
      renterUsername: currentUser.username,
      reason: inputValue,
    });
    try {
      const response = await fetch(
        `https://cancelbookedrentalemail-sendcancelbookedrentalema-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Cancellation email sent.");
      setIsCancelling(false);
    } catch (error) {
      console.log(error.message);
    }
  };

  const deleteRentalRequest = async () => {
    const body = JSON.stringify({
      rentalRequestId: selectedRental.id,
    });
    try {
      const response = await fetch(
        `https://deleterentalrequest-deleterentalrequest-iz3msmwhcq-nw.a.run.app`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body,
        }
      );

      const data = await response.json();
      console.log("Rental availability request deleted.");
      // toast.success("The rental availability request has been deleted.", {
      //   autoClose: 3000,
      // });
    } catch (error) {
      console.log(error.message);
      toast.error("Something went wrong deleting the rental request.", {
        autoClose: 3000,
      });
    }
  };

  const deleteRentalForRenterAndReduceRentalCount = async () => {
    const rentalRef = doc(
      db,
      "users",
      currentUser.uid,
      "rentals",
      selectedRental.id
    );
    await deleteDoc(rentalRef);

    const userRef = doc(db, "users", currentUser.uid);
    await updateDoc(userRef, {
      rentalCount: increment(-1),
    });
    console.log("Deleted rental for renter and reduced rental count.");
  };

  const deleteBookingForOwnerAndReduceBookingCount = async () => {
    let bookingId;

    const bookingRef = doc(
      db,
      "users",
      currentUser.uid,
      "rentals",
      selectedRental.id
    );
    const bookingDoc = await getDoc(bookingRef);
    if (bookingDoc.exists) {
      bookingId = bookingDoc.data().bookingId;
    }

    const q = query(
      collection(db, "users", selectedRental.ownerUid, "bookings"),
      where("bookingId", "==", bookingId)
    );
    const querySnapshot = await getDocs(q);

    querySnapshot.forEach(async (bookingDoc) => {
      const rentalRef = doc(
        db,
        "users",
        selectedRental.ownerUid,
        "bookings",
        bookingDoc.id
      );

      await deleteDoc(rentalRef);

      const ownerRef = doc(db, "users", selectedRental.ownerUid);
      await updateDoc(ownerRef, {
        bookingCount: increment(-1),
      });

      console.log(`Deleted booking document with ID: ${bookingDoc.id}`);
    });
  };

  const createNewCancellationDocument = async () => {
    const cancellationData = {
      userUid: currentUser.uid,
      listingId: selectedRental.listingId,
      cancellationReason: inputValue,
      cancellationDate: serverTimestamp(),
      bookingStartDate: selectedRental.startDate,
      bookingEndDate: selectedRental.endDate,
      cancellationType: "renter-cancellation",
    };

    try {
      await addDoc(collection(db, "cancellations"), cancellationData);
      console.log("Created new cancellation document.");
    } catch (error) {
      console.error("Error processing cancellation:", error);
      toast.error("Failed to process cancellation.");
    }
  };

  const deleteBookingFromListingDocument = async () => {
    try {
      const listingRef = doc(db, "listings", selectedRental.listingId);

      const listingDoc = await getDoc(listingRef);

      if (!listingDoc.exists()) {
        console.error("Listing not found");
        return;
      }

      const { bookings } = listingDoc.data();

      // Find the booking to be removed
      const bookingToRemove = bookings.find(
        (booking) => booking.bookingId === selectedRental.bookingId
      );

      if (!bookingToRemove) {
        console.error("Booking not found in listing");
        return;
      }

      // Remove the booking from the bookings array
      await updateDoc(listingRef, {
        bookings: arrayRemove(bookingToRemove),
      });

      console.log("Booking successfully removed from listing document.");
    } catch (error) {
      console.error("Error removing booking from listing document:", error);
    }
  };

  const incrementCancellationCountForRenter = async () => {
    const userRef = doc(db, "users", currentUser.uid);
    await updateDoc(userRef, {
      cancellationCount: increment(1),
    });
    console.log("incremented user cancellation count");
  };

  const removeRentalFromState = () => {
    setRentals((prevRentals) =>
      prevRentals.filter((rental) => rental.id !== selectedRental.id)
    );
  };

  const handleCancelRental = async () => {
    setIsCancelling(true);

    try {
      await processUserRefund();
      await deleteRentalRequest();
      await deleteBookingFromListingDocument();
      await deleteBookingForOwnerAndReduceBookingCount();
      await deleteRentalForRenterAndReduceRentalCount();
      await createNewCancellationDocument();
      await incrementCancellationCountForRenter();
      await sendCancellationEmailToOwner();

      toast.success("Rental has been cancelled successfully.", {
        autoClose: 3000,
      });

      removeRentalFromState();
    } catch (error) {
      console.error("Failed to cancel rental:", error);
      toast.error("Failed to cancel rental. Please try again.", {
        autoClose: 3000,
      });
    } finally {
      setShowCancellationModal(false);
      setInputValue("");
      setIsCancelling(false);
    }
  };

  if (isFetching) {
    return <Spinner />;
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      maxWidth: "450px",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      backgroundColor: "rgba(255,255,255,0.8)",
    },
  };

  return (
    <div className="container mx-auto">
      <Modal
        isOpen={showCancellationModal}
        onRequestClose={() => setShowCancellationModal(false)}
        contentLabel="Cancellation Modal"
        style={customStyles}
        ariaHideApp={false}
      >
        <div>
          <p className="text-center font-semibold mb-5">
            Are you sure you want to cancel this rental?
          </p>
          <div>
            <h4 className="font-semibold text-center mb-2">
              Cancellation Policy
            </h4>
            <p className="text-sm">
              Note: you will not be refunded the service fee of €
              {selectedRental.serviceFee}
            </p>
            <p className="text-sm">
              To read our full cancellation policy, please visit our{" "}
              <span
                onClick={() => navigate("/cancellation-policy")}
                className="hover:cursor-pointer font-semibold hover:underline"
              >
                Cancellation Policy
              </span>
            </p>
          </div>

          <div className="flex flex-col my-5">
            <label className="text-left text-sm font-semibold">
              Provide reason for cancellation (mandatory)
            </label>
            <span className="text-sm">
              This will be sent to the item owner and logged in our system.
            </span>
            <input
              className="text-sm my-2"
              placeholder="reason for cancellation.."
              value={inputValue}
              onChange={handleInputChange}
            />
          </div>
          <div className="flex gap-3 justify-center">
            <button
              className="btn-cancel px-3"
              onClick={() => setShowCancellationModal(false)}
            >
              <FontAwesomeIcon icon={faCancel} /> No, keep rental
            </button>
            <button
              className={
                inputValue.length < 6
                  ? "bg-gray-300 px-3 text-white rounded-lg"
                  : "btn-continue px-3"
              }
              onClick={handleCancelRental}
              disabled={isCancelling || inputValue.length < 5}
            >
              {isCancelling ? (
                <BeatLoader color="white" />
              ) : (
                <span>
                  <FontAwesomeIcon icon={faCheck} /> Yes, cancel rental{" "}
                </span>
              )}
            </button>
          </div>
        </div>
      </Modal>
      <div className="flex w-full justify-center">
        <h1 className="w-2/3 text-center my-5 text-xl font-bold header__center">
          My Rentals
        </h1>
      </div>
      <div className="overflow-x-auto px-5">
        {rentals.length > 0 ? (
          <table className="min-w-full table-auto text-center">
            <thead>
              <tr>
                <th className="px-4 py-2">Item</th>
                <th className="px-4 py-2">Item Owner</th>
                <th className="px-4 py-2">Days</th>
                <th className="px-4 py-2">Cost</th>
                <th className="px-4 py-2">Start Date</th>
                <th className="px-4 py-2">End Date</th>
                <th className="px-4 py-2">Actions</th>
              </tr>
            </thead>
            <tbody>
              {rentals.map((rental) => (
                <tr key={rental.id}>
                  <td
                    className="border px-4 py-2 hover:cursor-pointer hover:font-semibold"
                    onClick={() =>
                      navigate(
                        `/listings/${rental.listingDetails.location.town}/${rental.listingId}`
                      )
                    }
                  >
                    {rental.listingDetails.title}
                  </td>
                  <td
                    className="border px-4 py-2 hover:cursor-pointer hover:font-semibold"
                    onClick={() =>
                      navigate(`/users/${rental.listingDetails.ownerUid}`)
                    }
                  >
                    {rental.itemOwnerUsername}
                  </td>
                  <td className="border px-4 py-2">{rental.numberOfDays}</td>
                  <td className="border px-4 py-2">
                    ${rental.totalCost.toFixed(2)}
                  </td>
                  <td className="border px-4 py-2">
                    {rental.formattedStartDate}
                  </td>
                  <td className="border px-4 py-2">
                    {rental.formattedEndDate}
                  </td>
                  <td className="border px-4 py-2">
                    <button
                      onClick={() => handleShowCancelRental(rental)}
                      className="btn-cancel px-2"
                    >
                      <FontAwesomeIcon icon={faCancel} /> Cancel Rental
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No rentals found.</p>
        )}
      </div>
    </div>
  );
};

export default MyRentals;
