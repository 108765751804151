import React, { useEffect } from "react";
import L from "leaflet";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import iconMarker from "leaflet/dist/images/marker-icon.png";
import iconRetina from "leaflet/dist/images/marker-icon-2x.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

const icon = L.icon({
  iconRetinaUrl: iconRetina,
  iconUrl: iconMarker,
  shadowUrl: iconShadow,
});

const towns = [
  { town: "Abbeydorney", county: "Kerry", lat: 52.346618, lng: -9.6879095 },
  { town: "Abbeyfeale", county: "Limerick", lat: 52.3851792, lng: -9.3007966 },
  { town: "Abbeyknockmoy", county: "Galway", lat: 53.43649, lng: -8.74804 },
  {
    town: "Abbeyleix",
    county: "Laois",
    lat: 52.9152801,
    lng: -7.347892300000001,
  },
  { town: "Adamstown", county: "Wexford", lat: 52.3935687, lng: -6.7185567 },
  { town: "Adare", county: "Limerick", lat: 52.5636521, lng: -8.7925281 },
  {
    town: "Aghada - Rostellan",
    county: "Cork",
    lat: 51.8394856,
    lng: -8.2149056,
  },
  {
    town: "Aglish",
    county: "Waterford",
    lat: 52.0715072,
    lng: -7.822269599999999,
  },
  { town: "Ahascragh", county: "Galway", lat: 53.3976127, lng: -8.3346318 },
  { town: "Burtonport", county: "Donegal", lat: 54.9845286, lng: -8.4326581 },
  { town: "Allenwood", county: "Kildare", lat: 53.2839545, lng: -6.8618405 },
  { town: "Annagry", county: "Donegal", lat: 55.02044, lng: -8.31366 },
  { town: "Bunbeg", county: "Donegal", lat: 55.060874, lng: -8.3003485 },
  {
    town: "Carrick",
    county: "Donegal",
    lat: 54.6577244,
    lng: -8.632748399999999,
  },
  { town: "Carraroe", county: "Galway", lat: 53.2641084, lng: -9.5982671 },
  {
    town: "Dungloe",
    county: "Donegal",
    lat: 54.95028869999999,
    lng: -8.357362799999999,
  },
  {
    town: "Falcarragh, Cross Roads",
    county: "Donegal",
    lat: 55.13640840000001,
    lng: -8.1044862,
  },
  { town: "Annacotty", county: "Limerick", lat: 52.667128, lng: -8.5324694 },
  {
    town: "Annagassan",
    county: "Louth",
    lat: 53.882628,
    lng: -6.343494199999999,
  },
  { town: "Annaghdown", county: "Galway", lat: 53.3881317, lng: -9.0710338 },
  { town: "Annascaul", county: "Kerry", lat: 52.1510786, lng: -10.0567611 },
  {
    town: "Annayalla",
    county: "Monaghan",
    lat: 54.16064,
    lng: -6.788880000000001,
  },
  {
    town: "Ring",
    county: "Waterford",
    lat: 52.04689450000001,
    lng: -7.571887899999999,
  },
  { town: "Spiddle", county: "Galway", lat: 53.2441766, lng: -9.3058803 },
  { town: "Ardagh", county: "Limerick", lat: 52.4941478, lng: -9.0637542 },
  { town: "Ardara", county: "Donegal", lat: 54.7671456, lng: -8.4092864 },
  { town: "Ardee", county: "Louth", lat: 53.8576227, lng: -6.5400009 },
  { town: "Ardfert", county: "Kerry", lat: 52.3269477, lng: -9.7807341 },
  { town: "Ardfinnan", county: "Tipperary", lat: 52.3092483, lng: -7.8842292 },
  {
    town: "Ardmore",
    county: "Waterford",
    lat: 51.9510741,
    lng: -7.725328999999999,
  },
  {
    town: "Castlebank",
    county: "Clare",
    lat: 52.7026919,
    lng: -8.625214699999999,
  },
  {
    town: "Arklow",
    county: "Wicklow",
    lat: 52.79769349999999,
    lng: -6.1599293,
  },
  {
    town: "Arthurstown",
    county: "Wexford",
    lat: 52.24206059999999,
    lng: -6.9517472,
  },
  { town: "Arvagh", county: "Cavan", lat: 53.9214383, lng: -7.5799134 },
  { town: "Ashbourne", county: "Meath", lat: 53.5111621, lng: -6.3973818 },
  {
    town: "Ashford",
    county: "Wicklow",
    lat: 53.0107896,
    lng: -6.108289000000001,
  },
  { town: "Askeaton", county: "Limerick", lat: 52.6005095, lng: -8.975062 },
  { town: "Athboy", county: "Meath", lat: 53.6236329, lng: -6.915636999999999 },
  { town: "Athea", county: "Limerick", lat: 52.4606497, lng: -9.287626 },
  {
    town: "Athenry",
    county: "Galway",
    lat: 53.3008131,
    lng: -8.745376799999999,
  },
  {
    town: "Athgarvan",
    county: "Kildare",
    lat: 53.15289079999999,
    lng: -6.777700299999999,
  },
  { town: "Athleague", county: "Roscommon", lat: 53.5676272, lng: -8.2567472 },
  {
    town: "Athlone",
    county: "Westmeath",
    lat: 53.4239331,
    lng: -7.940689799999999,
  },
  { town: "Athy", county: "Kildare", lat: 52.9918336, lng: -6.985728 },
  { town: "Aughnacliffe", county: "Longford", lat: 53.84885, lng: -7.60445 },
  {
    town: "Aughrim",
    county: "Wicklow",
    lat: 52.8559337,
    lng: -6.325933099999999,
  },
  { town: "Avoca", county: "Wicklow", lat: 52.8586664, lng: -6.2140891 },
  {
    town: "Claregalway",
    county: "Galway",
    lat: 53.34090310000001,
    lng: -8.9456735,
  },
  {
    town: "Ballymakeery",
    county: "Cork",
    lat: 51.93639049999999,
    lng: -9.1454314,
  },
  {
    town: "Bailieborough",
    county: "Cavan",
    lat: 53.9156096,
    lng: -6.969294199999999,
  },
  {
    town: "Balbriggan",
    county: "Dublin",
    lat: 53.6083194,
    lng: -6.182079799999999,
  },
  { town: "Balla", county: "Mayo", lat: 53.8043767, lng: -9.1305014 },
  {
    town: "Ballaghaderreen",
    county: "Roscommon",
    lat: 53.9016968,
    lng: -8.5792106,
  },
  {
    town: "Ballina",
    county: "Tipperary",
    lat: 52.8070154,
    lng: -8.430917899999999,
  },
  { town: "Ballina", county: "Mayo", lat: 54.1149482, lng: -9.1550693 },
  { town: "Ballinabranna", county: "Carlow", lat: 52.7890512, lng: -6.9924779 },
  { town: "Ballinaclash", county: "Wicklow", lat: 52.9052365, lng: -6.2579487 },
  {
    town: "Ballinagar",
    county: "Offaly",
    lat: 53.2662048,
    lng: -7.337107199999998,
  },
  { town: "Ballinagh", county: "Cavan", lat: 53.9302, lng: -7.41044 },
  { town: "Ballinakill", county: "Laois", lat: 52.8745935, lng: -7.3085929 },
  {
    town: "Ballinalack",
    county: "Westmeath",
    lat: 53.63055809999999,
    lng: -7.473040999999999,
  },
  { town: "Ballinalee", county: "Longford", lat: 53.7750724, lng: -7.6550915 },
  { town: "Ballinameen", county: "Roscommon", lat: 53.8972258, lng: -8.301216 },
  { town: "Ballinamore", county: "Leitrim", lat: 54.0522181, lng: -7.8032043 },
  { town: "Ballinasloe", county: "Galway", lat: 53.3281251, lng: -8.2236807 },
  {
    town: "Ballincar",
    county: "Sligo",
    lat: 54.2979892,
    lng: -8.497289499999999,
  },
  {
    town: "Ballinderreen",
    county: "Galway",
    lat: 53.18567210000001,
    lng: -8.909645399999999,
  },
  {
    town: "Ballindine",
    county: "Mayo",
    lat: 53.66925690000001,
    lng: -8.9567295,
  },
  {
    town: "Ballindrait",
    county: "Donegal",
    lat: 54.8451594,
    lng: -7.526530599999999,
  },
  {
    town: "Ballineen - Enniskean",
    county: "Cork",
    lat: 51.735556,
    lng: -8.948611000000001,
  },
  { town: "Ballingarry", county: "Limerick", lat: 52.4732187, lng: -8.8627555 },
  {
    town: "Ballingarry",
    county: "Tipperary",
    lat: 52.5863379,
    lng: -7.5416271,
  },
  {
    town: "Ballinlough",
    county: "Roscommon",
    lat: 53.7418858,
    lng: -8.6335579,
  },
  { town: "Ballinroad", county: "Waterford", lat: 52.1037518, lng: -7.5734156 },
  { town: "Ballinrobe", county: "Mayo", lat: 53.6237338, lng: -9.2211143 },
  {
    town: "Ballinspittle",
    county: "Cork",
    lat: 51.6663408,
    lng: -8.596594099999999,
  },
  {
    town: "Ballintober",
    county: "Roscommon",
    lat: 53.7180878,
    lng: -8.4100998,
  },
  { town: "Ballintogher", county: "Sligo", lat: 54.2006449, lng: -8.3649676 },
  {
    town: "Ballintra",
    county: "Donegal",
    lat: 54.57884989999999,
    lng: -8.1243234,
  },
  { town: "Ballytore", county: "Kildare", lat: 53.009742, lng: -6.8156295 },
  {
    town: "Ballivor",
    county: "Meath",
    lat: 53.5329521,
    lng: -6.968071699999999,
  },
  {
    town: "Ballon",
    county: "Carlow",
    lat: 52.7391042,
    lng: -6.769783899999999,
  },
  {
    town: "Ballyagran",
    county: "Limerick",
    lat: 52.4018224,
    lng: -8.782366699999999,
  },
  { town: "Ballybay", county: "Monaghan", lat: 54.1294617, lng: -6.9027864 },
  {
    town: "Ballybofey - Stranorlar",
    county: "Donegal",
    lat: 54.80166699999999,
    lng: -7.774999999999999,
  },
  {
    town: "Ballyboughal",
    county: "Dublin",
    lat: 53.52020599999999,
    lng: -6.267595399999999,
  },
  { town: "Ballybrittas", county: "Laois", lat: 53.109028, lng: -7.1374187 },
  {
    town: "Ballybunion",
    county: "Kerry",
    lat: 52.51108,
    lng: -9.670969999999999,
  },
  { town: "Ballycanew", county: "Wexford", lat: 52.6106782, lng: -6.3049672 },
  {
    town: "Ballycannan",
    county: "Clare",
    lat: 52.70296399999999,
    lng: -8.649498,
  },
  {
    town: "Ballycastle",
    county: "Mayo",
    lat: 54.2796698,
    lng: -9.372464299999999,
  },
  {
    town: "Ballyclerahan",
    county: "Tipperary",
    lat: 52.4118971,
    lng: -7.753067199999999,
  },
  { town: "Ballyclogh", county: "Cork", lat: 52.1673919, lng: -8.742242 },
  {
    town: "Ballycolla",
    county: "Laois",
    lat: 52.8825474,
    lng: -7.446860600000001,
  },
  {
    town: "Ballyconnell",
    county: "Cavan",
    lat: 54.1162175,
    lng: -7.583954299999999,
  },
  { town: "Ballycotton", county: "Cork", lat: 51.8299148, lng: -8.0097621 },
  { town: "Ballycullane", county: "Wexford", lat: 52.2803179, lng: -6.8397818 },
  {
    town: "Ballycumber",
    county: "Offaly",
    lat: 53.3264159,
    lng: -7.686113700000001,
  },
  {
    town: "Ballydehob",
    county: "Cork",
    lat: 51.5644261,
    lng: -9.463412199999999,
  },
  { town: "Ballydesmond", county: "Cork", lat: 52.1796564, lng: -9.2341524 },
  {
    town: "Ballyduff",
    county: "Kerry",
    lat: 52.45455159999999,
    lng: -9.662905199999999,
  },
  {
    town: "Ballyforan",
    county: "Roscommon",
    lat: 53.4700864,
    lng: -8.270057999999999,
  },
  { town: "Ballygar", county: "Galway", lat: 53.5238778, lng: -8.3346912 },
  { town: "Ballygarvan", county: "Cork", lat: 51.824267, lng: -8.4612549 },
  { town: "Ballygawley", county: "Sligo", lat: 54.1920204, lng: -8.4452435 },
  { town: "Ballyhack", county: "Wexford", lat: 52.2487468, lng: -6.9640362 },
  { town: "Ballyhahill", county: "Limerick", lat: 52.5595432, lng: -9.1944765 },
  {
    town: "Ballyhaise",
    county: "Cavan",
    lat: 54.0473393,
    lng: -7.315509299999999,
  },
  { town: "Ballyhale", county: "Kilkenny", lat: 52.4669813, lng: -7.2002655 },
  { town: "Ballyhaunis", county: "Mayo", lat: 53.7628378, lng: -8.7645957 },
  { town: "Ballyheigue", county: "Kerry", lat: 52.3904645, lng: -9.8277419 },
  {
    town: "Ballyhogue",
    county: "Wexford",
    lat: 52.41432,
    lng: -6.575349999999999,
  },
  { town: "Ballyhooly", county: "Cork", lat: 52.1452748, lng: -8.3980968 },
  {
    town: "Ballyjamesduff",
    county: "Cavan",
    lat: 53.8638736,
    lng: -7.206456199999999,
  },
  { town: "Ballyknockan", county: "Wicklow", lat: 53.1029934, lng: -6.4959254 },
  {
    town: "Ballylanders",
    county: "Limerick",
    lat: 52.37200929999999,
    lng: -8.3487556,
  },
  { town: "Ballyliffin", county: "Donegal", lat: 55.2784391, lng: -7.3942123 },
  {
    town: "Ballylinan",
    county: "Laois",
    lat: 52.94358829999999,
    lng: -7.0420455,
  },
  { town: "Ballylongford", county: "Kerry", lat: 52.5479992, lng: -9.4788097 },
  {
    town: "Ballymacarbry",
    county: "Waterford",
    lat: 52.2670804,
    lng: -7.717650399999999,
  },
  {
    town: "Ballymacoda",
    county: "Cork",
    lat: 51.89210929999999,
    lng: -7.9395743,
  },
  {
    town: "Ballymahon",
    county: "Longford",
    lat: 53.5643632,
    lng: -7.764596399999999,
  },
  {
    town: "Ballymore",
    county: "Westmeath",
    lat: 53.4909131,
    lng: -7.679746399999999,
  },
  {
    town: "Ballymore Eustace",
    county: "Kildare",
    lat: 53.1330099,
    lng: -6.614880899999999,
  },
  {
    town: "Ballymote",
    county: "Sligo",
    lat: 54.08982880000001,
    lng: -8.5171827,
  },
  {
    town: "Ballymurn",
    county: "Wexford",
    lat: 52.4412829,
    lng: -6.477046899999999,
  },
  {
    town: "Ballynacargy",
    county: "Westmeath",
    lat: 53.5820789,
    lng: -7.5364989,
  },
  {
    town: "Ballinacarrow",
    county: "Sligo",
    lat: 54.13409679999999,
    lng: -8.560462,
  },
  { town: "Glen", county: "Limerick", lat: 52.5688921, lng: -9.2819067 },
  { town: "Ballyneety", county: "Limerick", lat: 52.5977547, lng: -8.5490207 },
  { town: "Ballynoe", county: "Cork", lat: 52.05968499999999, lng: -8.0975355 },
  {
    town: "Ballyoulster",
    county: "Kildare",
    lat: 53.34146459999999,
    lng: -6.512193799999999,
  },
  {
    town: "Ballyporeen",
    county: "Tipperary",
    lat: 52.2701094,
    lng: -8.0992106,
  },
  { town: "Ballyragget", county: "Kilkenny", lat: 52.7888894, lng: -7.3328641 },
  {
    town: "Ballyroan",
    county: "Laois",
    lat: 52.9483991,
    lng: -7.304667599999998,
  },
  { town: "Ballyroe", county: "Kildare", lat: 52.9578429, lng: -6.9389751 },
  { town: "Ballysadare", county: "Sligo", lat: 54.2121162, lng: -8.5090366 },
  { town: "Ballyshannon", county: "Donegal", lat: 54.5029882, lng: -8.1920416 },
  {
    town: "Ballysimon",
    county: "Wexford",
    lat: 52.5227528,
    lng: -6.483686199999999,
  },
  {
    town: "Ballyvaughan",
    county: "Clare",
    lat: 53.1155333,
    lng: -9.149423299999999,
  },
  { town: "Balrothery", county: "Dublin", lat: 53.5885512, lng: -6.188471 },
  { town: "Blanchardstown", county: "Dublin", lat: 53.3839704, lng: -6.377579 },
  { town: "Baltimore", county: "Cork", lat: 51.4843274, lng: -9.3661093 },
  {
    town: "Baltinglass",
    county: "Wicklow",
    lat: 52.9407807,
    lng: -6.710979999999999,
  },
  { town: "Baltray", county: "Louth", lat: 53.7371107, lng: -6.2704904 },
  {
    town: "Banagher",
    county: "Offaly",
    lat: 53.1901112,
    lng: -7.986104900000001,
  },
  {
    town: "Bandon",
    county: "Cork",
    lat: 51.74586679999999,
    lng: -8.735055299999999,
  },
  {
    town: "Bangor Erris",
    county: "Mayo",
    lat: 54.14464839999999,
    lng: -9.7428784,
  },
  { town: "Bansha", county: "Tipperary", lat: 52.4482369, lng: -8.0642959 },
  { town: "Banteer", county: "Cork", lat: 52.12946059999999, lng: -8.8935092 },
  { town: "Bantry", county: "Cork", lat: 51.6800803, lng: -9.452576299999999 },
  { town: "Barndarrig", county: "Wicklow", lat: 52.90998, lng: -6.11336 },
  {
    town: "Barntown",
    county: "Wexford",
    lat: 52.34238800000001,
    lng: -6.5284919,
  },
  {
    town: "Baylin",
    county: "Westmeath",
    lat: 53.43380999999999,
    lng: -7.840739999999998,
  },
  { town: "Belmullet", county: "Mayo", lat: 54.2238996, lng: -9.9876028 },
  {
    town: "Ballingeary",
    county: "Cork",
    lat: 51.8507469,
    lng: -9.232640199999999,
  },
  {
    town: "Bearna",
    county: "Galway",
    lat: 53.2506642,
    lng: -9.155134499999999,
  },
  { town: "Beaufort", county: "Kerry", lat: 52.0693796, lng: -9.6333332 },
  { town: "Beaulieu", county: "Louth", lat: 53.9507955, lng: -6.5406821 },
  { town: "Belcarra", county: "Mayo", lat: 53.8022746, lng: -9.2141371 },
  {
    town: "Belgooly",
    county: "Cork",
    lat: 51.7368994,
    lng: -8.481586900000002,
  },
  {
    town: "Bellanagare",
    county: "Roscommon",
    lat: 53.8369829,
    lng: -8.3815026,
  },
  { town: "Ballinode", county: "Monaghan", lat: 54.266922, lng: -7.0364418 },
  { town: "Bellavary", county: "Mayo", lat: 53.89366, lng: -9.15104 },
  {
    town: "Belmont",
    county: "Offaly",
    lat: 53.2560708,
    lng: -7.895165199999999,
  },
  {
    town: "Belturbet",
    county: "Cavan",
    lat: 54.10190559999999,
    lng: -7.449668,
  },
  {
    town: "Bennettsbridge",
    county: "Kilkenny",
    lat: 52.5923475,
    lng: -7.1843272,
  },
  {
    town: "Tallaght",
    county: "Dublin",
    lat: 53.28891410000001,
    lng: -6.357193,
  },
  { town: "Lucan", county: "Dublin", lat: 53.3572085, lng: -6.4498488 },
  {
    town: "Clondalkin",
    county: "Dublin",
    lat: 53.32044759999999,
    lng: -6.3946263,
  },
  { town: "Malahide", county: "Dublin", lat: 53.4509236, lng: -6.1501378 },
  {
    town: "Leixlip",
    county: "Kildare",
    lat: 53.36384280000001,
    lng: -6.4900033,
  },
  { town: "Greystones", county: "Wicklow", lat: 53.1491175, lng: -6.0793414 },
  {
    town: "Limerick City Suburbs",
    county: "Limerick",
    lat: 52.663839,
    lng: -8.626729899999999,
  },
  {
    town: "Galway City Suburbs",
    county: "Galway",
    lat: 53.274001,
    lng: -9.051266199999999,
  },
  {
    town: "Cork City Suburbs",
    county: "Cork",
    lat: 51.8985143,
    lng: -8.4756035,
  },
  { town: "Dundrum", county: "Dublin", lat: 53.2931758, lng: -6.246175 },
  {
    town: "Swords Village",
    county: "Dublin",
    lat: 53.4557467,
    lng: -6.219740600000001,
  },
  { town: "Palmerstown", county: "Dublin", lat: 53.3566137, lng: -6.3714808 },
  { town: "Rathfarnham", county: "Dublin", lat: 53.3004978, lng: -6.2838544 },
  { town: "Ballymun", county: "Dublin", lat: 53.4116673, lng: -6.2641626 },
  { town: "Skerries", county: "Dublin", lat: 53.5805519, lng: -6.1078779 },
  { town: "Donabate", county: "Dublin", lat: 53.4853365, lng: -6.1504482 },
  { town: "Blackrock", county: "Dublin", lat: 53.300791, lng: -6.1770668 },
  {
    town: "Sandyford",
    county: "Dublin",
    lat: 53.27896980000001,
    lng: -6.2163425,
  },
  {
    town: "Blessington",
    county: "Wicklow",
    lat: 53.1696579,
    lng: -6.534068700000001,
  },
  {
    town: "Carrick-on-Suir",
    county: "Tipperary",
    lat: 52.3467186,
    lng: -7.414770799999999,
  },
  { town: "Fermoy", county: "Cork", lat: 52.1378017, lng: -8.2752606 },
  {
    town: "Cashel",
    county: "Tipperary",
    lat: 52.51590969999999,
    lng: -7.8915829,
  },
  { town: "Kilrush", county: "Clare", lat: 52.6406999, lng: -9.4859374 },
  {
    town: "Dungarvan",
    county: "Waterford",
    lat: 52.0936763,
    lng: -7.620362699999999,
  },
  { town: "Listowel", county: "Kerry", lat: 52.4460488, lng: -9.4853655 },
  { town: "Westport", county: "Mayo", lat: 53.8011505, lng: -9.5223 },
  { town: "Youghal", county: "Cork", lat: 51.9542704, lng: -7.8471707 },
  { town: "Loughrea", county: "Galway", lat: 53.1989136, lng: -8.5660931 },
  {
    town: "Castleblayney",
    county: "Monaghan",
    lat: 54.120659,
    lng: -6.7397374,
  },
  {
    town: "Kenmare",
    county: "Kerry",
    lat: 51.8801017,
    lng: -9.583520199999999,
  },
  { town: "Moate", county: "Westmeath", lat: 53.3949987, lng: -7.7186188 },
  { town: "Buncrana", county: "Donegal", lat: 55.1365797, lng: -7.4560128 },
  { town: "Banbridge", county: "Down", lat: 54.348729, lng: -6.2704803 },
  { town: "Larne", county: "Antrim", lat: 54.8578003, lng: -5.8236224 },
  {
    town: "Downpatrick",
    county: "Down",
    lat: 54.32875139999999,
    lng: -5.715692199999999,
  },
  { town: "Omagh", county: "Tyrone", lat: 54.5977149, lng: -7.3099596 },
  { town: "Antrim", county: "Antrim", lat: 54.71891669999999, lng: -6.2116527 },
  {
    town: "Cookstown",
    county: "Tyrone",
    lat: 54.6418158,
    lng: -6.744389399999999,
  },
  { town: "Dromod", county: "Leitrim", lat: 53.8575697, lng: -7.9208348 },
  { town: "Longford", county: "Longford", lat: 53.7276296, lng: -7.7932573 },
  {
    town: "Carrick-on-Shannon",
    county: "Leitrim",
    lat: 53.945181,
    lng: -8.0863766,
  },
  { town: "Boyle", county: "Roscommon", lat: 53.9722082, lng: -8.2972756 },
  {
    town: "Ballybofey",
    county: "Donegal",
    lat: 54.7968244,
    lng: -7.787548099999999,
  },
  { town: "Donegal Town", county: "Donegal", lat: 54.653827, lng: -8.1096142 },
  { town: "Birr", county: "Offaly", lat: 53.0980052, lng: -7.909688200000001 },
  {
    town: "Blacklion",
    county: "Cavan",
    lat: 54.2920639,
    lng: -7.875127599999999,
  },
  {
    town: "Blackwater",
    county: "Wexford",
    lat: 52.4473539,
    lng: -6.346930299999999,
  },
  { town: "Blarney", county: "Cork City", lat: 51.9338275, lng: -8.5612438 },
  { town: "Boherbue", county: "Cork", lat: 52.1582386, lng: -9.0684828 },
  {
    town: "Boherlahan",
    county: "Tipperary",
    lat: 52.5730161,
    lng: -7.893562899999999,
  },
  { town: "Bohola", county: "Mayo", lat: 53.9025842, lng: -9.0549945 },
  {
    town: "Bonniconlon",
    county: "Mayo",
    lat: 54.10908999999999,
    lng: -9.03075,
  },
  { town: "Borris", county: "Carlow", lat: 52.6012982, lng: -6.9279771 },
  {
    town: "Borris in Ossory",
    county: "Laois",
    lat: 52.93917769999999,
    lng: -7.6310983,
  },
  {
    town: "Borrisokane",
    county: "Tipperary",
    lat: 52.9963736,
    lng: -8.127325599999999,
  },
  {
    town: "Borrisoleigh",
    county: "Tipperary",
    lat: 52.7525257,
    lng: -7.957887599999999,
  },
  { town: "Bracknagh", county: "Offaly", lat: 53.209205, lng: -7.0981836 },
  {
    town: "Bray",
    county: "Wicklow / Dublin",
    lat: 53.2006993,
    lng: -6.110931900000001,
  },
  { town: "Bree", county: "Wexford", lat: 52.43517689999999, lng: -6.6087149 },
  {
    town: "Bridebridge",
    county: "Cork",
    lat: 52.0799774,
    lng: -8.228111499999999,
  },
  { town: "Bridge End", county: "Donegal", lat: 55.0422391, lng: -7.3821884 },
  { town: "Bridgetown", county: "Wexford", lat: 52.2302422, lng: -6.549064 },
  { town: "Brittas", county: "Dublin", lat: 53.2288212, lng: -6.4432551 },
  { town: "Broadford", county: "Clare", lat: 52.8060302, lng: -8.6328674 },
  { town: "Ardskeagh", county: "Clare", lat: 52.8065902, lng: -8.6237897 },
  {
    town: "Broadford",
    county: "Limerick",
    lat: 52.34552859999999,
    lng: -8.9752937,
  },
  { town: "Broadford", county: "Kildare", lat: 53.4244724, lng: -6.960683 },
  { town: "Brosna", county: "Kerry", lat: 52.3125648, lng: -9.267679 },
  { town: "Brownstown", county: "Kildare", lat: 53.27041, lng: -6.94318 },
  { town: "Curragh Camp", county: "Kildare", lat: 53.1474495, lng: -6.8299723 },
  { town: "Bruff", county: "Limerick", lat: 52.4755485, lng: -8.5474034 },
  { town: "Bruree", county: "Limerick", lat: 52.4243025, lng: -8.6606015 },
  { town: "Bunclody", county: "Wexford", lat: 52.6550857, lng: -6.652193 },
  { town: "Bundoran", county: "Donegal", lat: 54.4791416, lng: -8.2779341 },
  { town: "Brinlack", county: "Donegal", lat: 55.1297357, lng: -8.2804066 },
  { town: "Bunratty", county: "Clare", lat: 52.6980379, lng: -8.8148982 },
  { town: "Burnfoot", county: "Donegal", lat: 55.0583056, lng: -7.4049447 },
  { town: "Butlersbridge", county: "Cavan", lat: 54.0452119, lng: -7.3769588 },
  {
    town: "Buttevant",
    county: "Cork",
    lat: 52.2321335,
    lng: -8.670124699999999,
  },
  { town: "Bweeng", county: "Cork", lat: 52.0448011, lng: -8.7231577 },
  {
    town: "Caherconlish",
    county: "Limerick",
    lat: 52.5951658,
    lng: -8.4723577,
  },
  {
    town: "Cahir",
    county: "Tipperary",
    lat: 52.3745296,
    lng: -7.925246899999999,
  },
  { town: "Cahersiveen", county: "Kerry", lat: 51.9477992, lng: -10.2209047 },
  {
    town: "Callan",
    county: "Kilkenny",
    lat: 52.544567,
    lng: -7.390170899999999,
  },
  {
    town: "Calverstown",
    county: "Kildare",
    lat: 53.0826121,
    lng: -6.797989400000001,
  },
  { town: "Camolin", county: "Wexford", lat: 52.6156787, lng: -6.4263356 },
  { town: "Campile", county: "Wexford", lat: 52.2881507, lng: -6.9413872 },
  { town: "Cappamore", county: "Limerick", lat: 52.6149925, lng: -8.3377813 },
  {
    town: "Cappawhite",
    county: "Tipperary",
    lat: 52.5822151,
    lng: -8.164659000000002,
  },
  {
    town: "Cappoquin",
    county: "Waterford",
    lat: 52.1462713,
    lng: -7.842743799999999,
  },
  {
    town: "Carlanstown",
    county: "Meath",
    lat: 53.7597065,
    lng: -6.841353799999999,
  },
  { town: "Carlingford", county: "Louth", lat: 54.0468677, lng: -6.190181 },
  { town: "Carlow", county: "Carlow", lat: 52.835854, lng: -6.932474399999999 },
  {
    town: "Carndonagh",
    county: "Donegal",
    lat: 55.2506229,
    lng: -7.266890500000001,
  },
  { town: "Carnew", county: "Wicklow", lat: 52.7096658, lng: -6.4992316 },
  { town: "Carney", county: "Sligo", lat: 54.3386704, lng: -8.5277091 },
  { town: "Clontarf", county: "Dublin", lat: 53.3498053, lng: -6.2603097 },
  { town: "Carragh", county: "Kildare", lat: 53.2371324, lng: -6.7289787 },
  {
    town: "Carraig Airt",
    county: "Donegal",
    lat: 55.1775506,
    lng: -7.793277699999999,
  },
  {
    town: "Carrickmacross",
    county: "Monaghan",
    lat: 53.9779778,
    lng: -6.7188238,
  },
  { town: "Carrigaline", county: "Cork", lat: 51.8164452, lng: -8.3912494 },
  {
    town: "Carrigallen",
    county: "Leitrim",
    lat: 53.9769348,
    lng: -7.644325899999999,
  },
  {
    town: "Carrigans",
    county: "Donegal",
    lat: 54.9512185,
    lng: -7.428285199999999,
  },
  { town: "Carrigkerry", county: "Limerick", lat: 52.49449, lng: -9.1486153 },
  { town: "Carrignavar", county: "Cork", lat: 51.9891078, lng: -8.477924 },
  { town: "Danescastle", county: "Wexford", lat: 52.2415149, lng: -6.7315761 },
  { town: "Carrigtwohill", county: "Cork", lat: 51.9094919, lng: -8.2589402 },
  {
    town: "Kerrykeel",
    county: "Donegal",
    lat: 55.13872,
    lng: -7.662849999999999,
  },
  {
    town: "Castlebar",
    county: "Mayo",
    lat: 53.8550014,
    lng: -9.287925699999999,
  },
  { town: "Kilsaran", county: "Louth", lat: 53.8910516, lng: -6.4040689 },
  {
    town: "Castlebellingham",
    county: "Louth",
    lat: 53.8978634,
    lng: -6.3894299,
  },
  {
    town: "Castlebridge - Blackwater",
    county: "Wexford",
    lat: 52.3824281,
    lng: -6.4502872,
  },
  {
    town: "Castlecomer",
    county: "Kilkenny",
    lat: 52.8052843,
    lng: -7.210312699999998,
  },
  {
    town: "Castleconnell",
    county: "Limerick",
    lat: 52.7129297,
    lng: -8.500052499999999,
  },
  { town: "Castledermot", county: "Kildare", lat: 52.9118755, lng: -6.837567 },
  { town: "Castlefinn", county: "Donegal", lat: 54.802999, lng: -7.5895814 },
  {
    town: "Castlegregory",
    county: "Kerry",
    lat: 52.25346510000001,
    lng: -10.0207218,
  },
  { town: "Castleisland", county: "Kerry", lat: 52.2328974, lng: -9.4601492 },
  { town: "Castlelyons", county: "Cork", lat: 52.0885669, lng: -8.233699 },
  {
    town: "Castlemagner",
    county: "Cork",
    lat: 52.16877909999999,
    lng: -8.8209117,
  },
  { town: "Castlemaine", county: "Kerry", lat: 52.1684322, lng: -9.7014756 },
  { town: "Castlemartyr", county: "Cork", lat: 51.9119477, lng: -8.0535486 },
  {
    town: "Castlepollard",
    county: "Westmeath",
    lat: 53.6800722,
    lng: -7.2964508,
  },
  { town: "Castlerea", county: "Roscommon", lat: 53.7686571, lng: -8.4924537 },
  { town: "Castletown", county: "Laois", lat: 52.9774693, lng: -7.4995604 },
  {
    town: "Castletownbere",
    county: "Cork",
    lat: 51.65149539999999,
    lng: -9.910330199999999,
  },
  {
    town: "Castletown Geoghegan",
    county: "Westmeath",
    lat: 53.4462801,
    lng: -7.487606899999999,
  },
  { town: "Castletownroche", county: "Cork", lat: 52.1731113, lng: -8.4634479 },
  {
    town: "Castletownsend",
    county: "Cork",
    lat: 51.5265757,
    lng: -9.179386899999999,
  },
  {
    town: "Causeway",
    county: "Kerry",
    lat: 52.41442199999999,
    lng: -9.7330771,
  },
  { town: "Cavan", county: "Cavan", lat: 53.9897186, lng: -7.3633319 },
  { town: "Celbridge", county: "Kildare", lat: 53.3383911, lng: -6.5397633 },
  { town: "Charlestown", county: "Mayo", lat: 53.9640248, lng: -8.7926035 },
  { town: "Bellahy", county: "Sligo", lat: 53.9692851, lng: -8.7901711 },
  { town: "Charleville", county: "Cork", lat: 52.3556889, lng: -8.6840314 },
  {
    town: "Cheekpoint",
    county: "Waterford",
    lat: 52.2690955,
    lng: -6.995745899999999,
  },
  {
    town: "Churchtown",
    county: "Cork",
    lat: 52.2713057,
    lng: -8.730869199999999,
  },
  { town: "Kilcar", county: "Donegal", lat: 54.6323944, lng: -8.5894621 },
  {
    town: "Kilronan",
    county: "Galway",
    lat: 53.1232125,
    lng: -9.670372799999999,
  },
  { town: "Clane", county: "Kildare", lat: 53.293785, lng: -6.6870404 },
  { town: "Clara", county: "Offaly", lat: 53.3425103, lng: -7.613480099999999 },
  {
    town: "Claremorris",
    county: "Mayo",
    lat: 53.72387639999999,
    lng: -9.0017356,
  },
  {
    town: "Clarina",
    county: "Limerick",
    lat: 52.6328298,
    lng: -8.728511899999999,
  },
  { town: "Clarinbridge", county: "Galway", lat: 53.2293077, lng: -8.8773824 },
  { town: "Clashmore", county: "Waterford", lat: 52.0093569, lng: -7.8188756 },
  { town: "Clifden", county: "Galway", lat: 53.4891345, lng: -10.0202388 },
  {
    town: "Cliffony",
    county: "Sligo",
    lat: 54.430559,
    lng: -8.453432399999999,
  },
  { town: "Clogh", county: "Kilkenny", lat: 52.8710161, lng: -7.1642467 },
  { town: "Cloghan", county: "Offaly", lat: 53.2252399, lng: -7.8825143 },
  { town: "Clogheen", county: "Tipperary", lat: 52.2770794, lng: -7.9951028 },
  { town: "Clogherhead", county: "Louth", lat: 53.7916777, lng: -6.2388506 },
  {
    town: "Waterford",
    county: "Waterford",
    lat: 52.2593197,
    lng: -7.110070299999999,
  },
  { town: "Drogheda", county: "Louth", lat: 53.717856, lng: -6.3560985 },
  { town: "Dundalk", county: "Louth", lat: 53.9979451, lng: -6.405957 },
  { town: "Navan", county: "Meath", lat: 53.647092, lng: -6.6966605 },
  { town: "Ennis", county: "Clare", lat: 52.8474255, lng: -8.988738399999999 },
  {
    town: "Kilkenny",
    county: "Kilkenny",
    lat: 52.6549027,
    lng: -7.246402900000001,
  },
  { town: "Sligo", county: "Sligo", lat: 54.27661029999999, lng: -8.4760888 },
  { town: "Clonmel", county: "Tipperary", lat: 52.3623523, lng: -7.7007084 },
  { town: "Mullingar", county: "Westmeath", lat: 53.5259334, lng: -7.3381378 },
  { town: "Wexford", county: "Wexford", lat: 52.336916, lng: -6.4633381 },
  { town: "Tralee", county: "Kerry", lat: 52.2713096, lng: -9.6999325 },
  {
    town: "Newbridge",
    county: "Kildare",
    lat: 53.17507579999999,
    lng: -6.8047467,
  },
  { town: "Naas", county: "Kildare", lat: 53.2205654, lng: -6.6593079 },
  {
    town: "Portlaoise",
    county: "Laois",
    lat: 53.0328123,
    lng: -7.298793300000001,
  },
  { town: "Letterkenny", county: "Donegal", lat: 54.9558392, lng: -7.7342787 },
  { town: "Tullamore", county: "Offaly", lat: 53.2752768, lng: -7.4924439 },
  {
    town: "Killarney",
    county: "Kerry",
    lat: 52.0598646,
    lng: -9.504362900000002,
  },
  { town: "Cobh", county: "Cork", lat: 51.8530415, lng: -8.295090499999999 },
  { town: "Midleton", county: "Cork", lat: 51.9143321, lng: -8.1726276 },
  { town: "Mallow", county: "Cork", lat: 52.13897619999999, lng: -8.6539164 },
  { town: "Enniscorthy", county: "Wexford", lat: 52.5031135, lng: -6.5727721 },
  {
    town: "Wicklow",
    county: "Wicklow",
    lat: 52.9808207,
    lng: -6.044588999999999,
  },
  { town: "Ayrfield", county: "Dublin", lat: 53.3942333, lng: -6.1827751 },
  { town: "Gorey", county: "Wexford", lat: 52.67573549999999, lng: -6.2943022 },
  {
    town: "Kildare",
    county: "Kildare",
    lat: 53.15980589999999,
    lng: -6.9089763,
  },
  { town: "Derry", county: "Londonderry", lat: 54.9964705, lng: -7.3101358 },
  { town: "Tuam", county: "Galway", lat: 53.5141156, lng: -8.8565177 },
  {
    town: "New Ross",
    county: "Wexford",
    lat: 52.3958337,
    lng: -6.945456699999999,
  },
  {
    town: "Portmarnock",
    county: "Dublin",
    lat: 53.42560839999999,
    lng: -6.1316039,
  },
  {
    town: "Roscommon",
    county: "Roscommon",
    lat: 53.63401649999999,
    lng: -8.181945899999999,
  },
  { town: "Shannon", county: "Clare", lat: 52.71178219999999, lng: -8.8783365 },
  {
    town: "Finglas",
    county: "Dublin",
    lat: 53.390325,
    lng: -6.298400699999999,
  },
  { town: "Rush", county: "Dublin", lat: 53.5256684, lng: -6.0911586 },
  { town: "Rathmines", county: "Dublin", lat: 53.3218816, lng: -6.2655052 },
  {
    town: "Cloghfin",
    county: "Donegal",
    lat: 54.67310000000001,
    lng: -8.05295,
  },
  {
    town: "Clonakilty",
    county: "Cork",
    lat: 51.6203379,
    lng: -8.905510399999999,
  },
  { town: "Clonard", county: "Meath", lat: 53.4515329, lng: -7.0231073 },
  {
    town: "Clonaslee",
    county: "Laois",
    lat: 53.1486014,
    lng: -7.524369599999999,
  },
  {
    town: "Clonbullogue",
    county: "Offaly",
    lat: 53.26068309999999,
    lng: -7.086431,
  },
  {
    town: "Clondrohid",
    county: "Cork",
    lat: 51.9292332,
    lng: -9.022767499999999,
  },
  { town: "Clondulane", county: "Cork", lat: 52.1421634, lng: -8.2203365 },
  { town: "Clonee", county: "Meath", lat: 53.4105621, lng: -6.4426 },
  { town: "Clonegall", county: "Carlow", lat: 52.6948009, lng: -6.6477303 },
  {
    town: "Clones",
    county: "Monaghan",
    lat: 54.1796829,
    lng: -7.230667100000001,
  },
  { town: "Clongeen", county: "Wexford", lat: 52.2978897, lng: -6.7727531 },
  { town: "Clonlara", county: "Clare", lat: 52.7238329, lng: -8.5528365 },
  {
    town: "Clonmany",
    county: "Donegal",
    lat: 55.2628052,
    lng: -7.411610800000001,
  },
  { town: "Clonmellon", county: "Westmeath", lat: 53.6640717, lng: -7.0183933 },
  { town: "Clonroche", county: "Wexford", lat: 52.4496872, lng: -6.7174157 },
  { town: "Clontibret", county: "Monaghan", lat: 54.2054431, lng: -6.8415402 },
  { town: "Clonygowan", county: "Offaly", lat: 53.1874518, lng: -7.2804446 },
  { town: "Cloonfad", county: "Roscommon", lat: 53.6867556, lng: -8.75182 },
  { town: "Cloughduv", county: "Cork", lat: 51.8473203, lng: -8.7880041 },
  {
    town: "Cloughjordan",
    county: "Tipperary",
    lat: 52.9429442,
    lng: -8.0383372,
  },
  { town: "Cloyne", county: "Cork", lat: 51.8630971, lng: -8.121236999999999 },
  { town: "Cloonboo", county: "Galway", lat: 53.5137007, lng: -8.6601381 },
  {
    town: "Coachford",
    county: "Cork",
    lat: 51.909471,
    lng: -8.788960099999999,
  },
  {
    town: "Blackwood",
    county: "Kildare",
    lat: 53.2931573,
    lng: -6.818584400000001,
  },
  {
    town: "Collinstown",
    county: "Westmeath",
    lat: 53.64747149999999,
    lng: -7.212159999999999,
  },
  { town: "Collon", county: "Louth", lat: 53.7777425, lng: -6.4840991 },
  { town: "Collooney", county: "Sligo", lat: 54.1849522, lng: -8.4865545 },
  { town: "Conna", county: "Cork", lat: 52.0931079, lng: -8.1073545 },
  { town: "Convoy", county: "Donegal", lat: 54.859725, lng: -7.6654466 },
  {
    town: "Coolaney",
    county: "Sligo",
    lat: 54.17380929999999,
    lng: -8.6008023,
  },
  {
    town: "Coolboy",
    county: "Wicklow",
    lat: 52.7620319,
    lng: -6.462780299999999,
  },
  { town: "Coolgreany", county: "Wexford", lat: 52.7647077, lng: -6.2360077 },
  { town: "Cootehall", county: "Roscommon", lat: 53.9841688, lng: -8.1580069 },
  { town: "Cootehill", county: "Cavan", lat: 54.0725034, lng: -7.0823541 },
  { town: "Corofin", county: "Clare", lat: 52.9448216, lng: -9.0640874 },
  {
    town: "Corrandulla",
    county: "Galway",
    lat: 53.3991608,
    lng: -8.995794499999999,
  },
  { town: "Corrofin", county: "Galway", lat: 53.437565, lng: -8.8602715 },
  {
    town: "Courtbrack",
    county: "Cork",
    lat: 51.9775437,
    lng: -8.645808299999999,
  },
  {
    town: "Courtmacsherry",
    county: "Cork",
    lat: 51.63385539999999,
    lng: -8.7059339,
  },
  { town: "Courtown", county: "Wexford", lat: 52.6448624, lng: -6.2286169 },
  {
    town: "Cratloe",
    county: "Clare",
    lat: 52.6985433,
    lng: -8.762853699999999,
  },
  { town: "Craughwell", county: "Galway", lat: 53.2302689, lng: -8.7347736 },
  {
    town: "Creeslough",
    county: "Donegal",
    lat: 55.12334970000001,
    lng: -7.909467599999999,
  },
  {
    town: "Crinkle",
    county: "Offaly",
    lat: 53.07837860000001,
    lng: -7.8970751,
  },
  {
    town: "Cromane",
    county: "Kerry",
    lat: 52.1061221,
    lng: -9.898534699999999,
  },
  {
    town: "Crookstown",
    county: "Cork",
    lat: 51.84242700000001,
    lng: -8.830431899999999,
  },
  { town: "Croom", county: "Limerick", lat: 52.519036, lng: -8.7174345 },
  {
    town: "Crossakiel",
    county: "Meath",
    lat: 53.71411920000001,
    lng: -7.020536799999999,
  },
  { town: "Crossbarry", county: "Cork", lat: 51.8020473, lng: -8.6453657 },
  {
    town: "Crosshaven - Churchbay",
    county: "Cork",
    lat: 51.8022632,
    lng: -8.2904494,
  },
  { town: "Crossmolina", county: "Mayo", lat: 54.100342, lng: -9.3158456 },
  { town: "Crusheen", county: "Clare", lat: 52.9386532, lng: -8.8967688 },
  {
    town: "Culdaff",
    county: "Donegal",
    lat: 55.2859437,
    lng: -7.165135100000001,
  },
  {
    town: "Ballysax",
    county: "Kildare",
    lat: 53.12651,
    lng: -6.812869999999999,
  },
  { town: "Daingean", county: "Offaly", lat: 53.2960818, lng: -7.291967 },
  { town: "Delvin", county: "Westmeath", lat: 53.6125653, lng: -7.0917224 },
  { town: "Derrinturn", county: "Kildare", lat: 53.3395275, lng: -6.9399818 },
  { town: "Dingle", county: "Kerry", lat: 52.1408534, lng: -10.2671142 },
  {
    town: "Derrybeg",
    county: "Donegal",
    lat: 55.0743448,
    lng: -8.294187299999999,
  },
  { town: "Donard", county: "Wicklow", lat: 53.0222351, lng: -6.6130978 },
  { town: "Donaskeigh", county: "Tipperary", lat: 52.5218302, lng: -8.0647868 },
  {
    town: "Doneraile",
    county: "Cork",
    lat: 52.2151735,
    lng: -8.585977399999999,
  },
  { town: "Donore", county: "Meath", lat: 53.6921097, lng: -6.4172506 },
  { town: "Dooagh", county: "Mayo", lat: 53.9749643, lng: -10.1266688 },
  {
    town: "Doohamlet",
    county: "Monaghan",
    lat: 54.1287354,
    lng: -6.823994600000001,
  },
  { town: "Doohooma", county: "Mayo", lat: 54.0645, lng: -9.5357 },
  {
    town: "Doon",
    county: "Limerick",
    lat: 52.6043221,
    lng: -8.248491699999999,
  },
  { town: "Doonbeg", county: "Clare", lat: 52.7308501, lng: -9.5259951 },
  {
    town: "Drangan",
    county: "Tipperary",
    lat: 52.5170482,
    lng: -7.583662700000001,
  },
  {
    town: "Drimoleague",
    county: "Cork",
    lat: 51.6599357,
    lng: -9.261245299999999,
  },
  { town: "Dromahair", county: "Leitrim", lat: 54.2306704, lng: -8.3026235 },
  { town: "Dromina", county: "Cork", lat: 52.3117507, lng: -8.8012075 },
  { town: "Dromineer", county: "Tipperary", lat: 52.9248849, lng: -8.2756534 },
  {
    town: "Dromiskin",
    county: "Louth",
    lat: 53.9217123,
    lng: -6.401009200000001,
  },
  { town: "Drommahane", county: "Cork", lat: 52.1056038, lng: -8.6908349 },
  { town: "Dromore West", county: "Sligo", lat: 54.2495094, lng: -8.8799262 },
  { town: "Drumcollogher", county: "Limerick", lat: 52.33944, lng: -8.90729 },
  { town: "Drumconrath", county: "Meath", lat: 53.8515966, lng: -6.6563415 },
  { town: "Drumkeen", county: "Donegal", lat: 54.8660308, lng: -7.7513945 },
  {
    town: "Drumkeeran",
    county: "Leitrim",
    lat: 54.16940899999999,
    lng: -8.142664,
  },
  {
    town: "Drumlish",
    county: "Longford",
    lat: 53.82059030000001,
    lng: -7.768414,
  },
  {
    town: "Drumshanbo",
    county: "Leitrim",
    lat: 54.04780040000001,
    lng: -8.0400238,
  },
  { town: "Drumsna", county: "Leitrim", lat: 53.9269176, lng: -8.0079271 },
  { town: "Duagh", county: "Kerry", lat: 52.4147893, lng: -9.3901918 },
  { town: "Duleek", county: "Meath", lat: 53.6547501, lng: -6.4221752 },
  { town: "Dunboyne", county: "Meath", lat: 53.4192677, lng: -6.4758857 },
  { town: "Duncannon", county: "Wexford", lat: 52.2206117, lng: -6.9325606 },
  {
    town: "Duncormick",
    county: "Wexford",
    lat: 52.2285412,
    lng: -6.655054199999999,
  },
  { town: "Dunderrow", county: "Cork", lat: 51.726487, lng: -8.5874569 },
  {
    town: "Dundrum",
    county: "Tipperary",
    lat: 52.5595234,
    lng: -8.043225099999999,
  },
  { town: "Dunfanaghy", county: "Donegal", lat: 55.1834711, lng: -7.9694369 },
  {
    town: "Dunhill",
    county: "Waterford",
    lat: 52.1737783,
    lng: -7.263755799999999,
  },
  {
    town: "Dunkineely",
    county: "Donegal",
    lat: 54.6320711,
    lng: -8.357078699999999,
  },
  { town: "Dun Laoighre", county: "Dublin", lat: 53.2934153, lng: -6.1425047 },
  { town: "Dunlavin", county: "Wicklow", lat: 53.0578764, lng: -6.7016395 },
  { town: "Dunleer", county: "Louth", lat: 53.8310533, lng: -6.3944559 },
  { town: "Dunmanway", county: "Cork", lat: 51.7222571, lng: -9.1118088 },
  { town: "Dunmore", county: "Galway", lat: 53.6180163, lng: -8.7441461 },
  {
    town: "Dunmore East",
    county: "Waterford",
    lat: 52.1527666,
    lng: -6.994803999999999,
  },
  {
    town: "Dunshaughlin",
    county: "Meath",
    lat: 53.5135229,
    lng: -6.540252499999999,
  },
  {
    town: "Durrow",
    county: "Laois",
    lat: 52.84622479999999,
    lng: -7.394980200000001,
  },
  { town: "Durrus", county: "Cork", lat: 51.6217112, lng: -9.521993 },
  { town: "Easky", county: "Sligo", lat: 54.286325, lng: -8.9623692 },
  { town: "Edenderry", county: "Offaly", lat: 53.3432235, lng: -7.0517202 },
  {
    town: "Edgeworthstown",
    county: "Longford",
    lat: 53.6956862,
    lng: -7.608492999999999,
  },
  { town: "Elphin", county: "Roscommon", lat: 53.8522697, lng: -8.1978488 },
  { town: "Emly", county: "Tipperary", lat: 52.4633291, lng: -8.3498992 },
  { town: "Emo", county: "Laois", lat: 53.097203, lng: -7.208534999999999 },
  {
    town: "Emyvale",
    county: "Monaghan",
    lat: 54.3409914,
    lng: -6.959261199999999,
  },
  { town: "Enfield", county: "Meath", lat: 53.4142026, lng: -6.8296541 },
  {
    town: "Inniscrone",
    county: "Sligo",
    lat: 54.2136818,
    lng: -9.090835199999999,
  },
  { town: "Enniskerry", county: "Wicklow", lat: 53.1935037, lng: -6.1707299 },
  { town: "Ennistymon", county: "Clare", lat: 52.940372, lng: -9.2934951 },
  { town: "Erril", county: "Laois", lat: 52.8599496, lng: -7.6769934 },
  { town: "Eyrecourt", county: "Galway", lat: 53.1997171, lng: -8.131427 },
  { town: "Fahan", county: "Donegal", lat: 55.0876125, lng: -7.4724123 },
  { town: "Farran", county: "Cork", lat: 51.8733225, lng: -8.720434299999999 },
  { town: "Feakle", county: "Clare", lat: 52.9260682, lng: -8.6486521 },
  {
    town: "Fedamore",
    county: "Limerick",
    lat: 52.54550709999999,
    lng: -8.6016177,
  },
  { town: "Fenit", county: "Kerry", lat: 52.2784045, lng: -9.8612114 },
  { town: "Fennagh", county: "Carlow", lat: 52.71467, lng: -6.84615 },
  { town: "Ferbane", county: "Offaly", lat: 53.27195, lng: -7.8278719 },
  {
    town: "Ferns",
    county: "Wexford",
    lat: 52.5909249,
    lng: -6.498010499999999,
  },
  { town: "Fethard", county: "Tipperary", lat: 52.4659436, lng: -7.6927334 },
  { town: "Fethard", county: "Wexford", lat: 52.1923021, lng: -6.8402183 },
  {
    town: "Fiddown",
    county: "Kilkenny",
    lat: 52.331516,
    lng: -7.313796000000001,
  },
  { town: "Fieries", county: "Kerry", lat: 52.17019, lng: -9.59718 },
  { town: "Foxford", county: "Mayo", lat: 53.98077259999999, lng: -9.113245 },
  {
    town: "Foynes",
    county: "Limerick",
    lat: 52.6102719,
    lng: -9.104861699999999,
  },
  { town: "Frankford", county: "Offaly", lat: 53.2356871, lng: -7.7122229 },
  { town: "Freemount", county: "Cork", lat: 52.2768369, lng: -8.8847534 },
  { town: "Frenchpark", county: "Roscommon", lat: 53.8695107, lng: -8.4074282 },
  {
    town: "Freshford",
    county: "Kilkenny",
    lat: 52.73250669999999,
    lng: -7.3981631,
  },
  { town: "Galbally", county: "Limerick", lat: 52.4000202, lng: -8.2936733 },
  { town: "Garristown", county: "Dublin", lat: 53.5668673, lng: -6.3835624 },
  { town: "Geashill", county: "Offaly", lat: 53.2352224, lng: -7.3209776 },
  { town: "Glanworth", county: "Cork", lat: 52.1866296, lng: -8.3567369 },
  {
    town: "Glassan",
    county: "Westmeath",
    lat: 53.4699107,
    lng: -7.863907299999999,
  },
  {
    town: "Glaslough",
    county: "Monaghan",
    lat: 54.31727840000001,
    lng: -6.8971168,
  },
  {
    town: "Gleann Cholm Cille",
    county: "Donegal",
    lat: 54.7080885,
    lng: -8.7295157,
  },
  { town: "Glenamaddy", county: "Galway", lat: 53.6055461, lng: -8.5604032 },
  { town: "Glenbeigh", county: "Kerry", lat: 52.0561861, lng: -9.9403477 },
  { town: "Glencullen", county: "Dublin", lat: 53.2224638, lng: -6.216138 },
  {
    town: "Glenealy",
    county: "Wicklow",
    lat: 52.9666341,
    lng: -6.144572900000001,
  },
  { town: "Gleneely", county: "Donegal", lat: 55.2387477, lng: -7.1466476 },
  { town: "Glengarriff", county: "Cork", lat: 51.7500277, lng: -9.5522224 },
  { town: "Glenties", county: "Donegal", lat: 54.7971669, lng: -8.2830316 },
  {
    town: "Glenville",
    county: "Cork",
    lat: 52.04565789999999,
    lng: -8.4254293,
  },
  { town: "Gneevgullia", county: "Kerry", lat: 52.1166851, lng: -9.2719471 },
  { town: "Achill Sound", county: "Mayo", lat: 53.9302564, lng: -9.9266332 },
  {
    town: "Golden",
    county: "Tipperary",
    lat: 52.4984411,
    lng: -7.980749500000001,
  },
  { town: "Goresbridge", county: "Kilkenny", lat: 52.6297084, lng: -6.9940878 },
  {
    town: "Gormanston",
    county: "Meath",
    lat: 53.63734059999999,
    lng: -6.2342763,
  },
  { town: "Gort", county: "Galway", lat: 53.0666432, lng: -8.8187486 },
  {
    town: "Gortahork",
    county: "Donegal",
    lat: 55.116926,
    lng: -8.140072500000002,
  },
  {
    town: "Gortnahoe",
    county: "Tipperary",
    lat: 52.6759449,
    lng: -7.602561799999999,
  },
  { town: "Gowran", county: "Kilkenny", lat: 52.6290523, lng: -7.0665112 },
  {
    town: "Graiguenamanagh",
    county: "Kilkenny",
    lat: 52.5409689,
    lng: -6.955470699999999,
  },
  { town: "Granard", county: "Longford", lat: 53.7791755, lng: -7.4923774 },
  { town: "Grange", county: "Sligo", lat: 54.3990932, lng: -8.527353399999999 },
  {
    town: "Brannockstown",
    county: "Kildare",
    lat: 53.1226695,
    lng: -6.693786599999999,
  },
  { town: "Greencastle", county: "Donegal", lat: 55.203361, lng: -6.9875478 },
  { town: "Grenagh", county: "Cork", lat: 52.0104774, lng: -8.610667 },
  {
    town: "Gorteen",
    county: "Sligo",
    lat: 53.9901565,
    lng: -8.501586399999999,
  },
  { town: "Hacketstown", county: "Carlow", lat: 52.8653857, lng: -6.5586053 },
  { town: "Halfway", county: "Cork", lat: 51.80401999999999, lng: -8.57161 },
  {
    town: "Headford",
    county: "Galway",
    lat: 53.4694617,
    lng: -9.106133999999999,
  },
  {
    town: "Holycross",
    county: "Tipperary",
    lat: 52.6397686,
    lng: -7.870179299999998,
  },
  { town: "Hospital", county: "Limerick", lat: 52.4743003, lng: -8.4324543 },
  { town: "Inagh", county: "Clare", lat: 52.8769834, lng: -9.176764499999999 },
  { town: "Inchigeelagh", county: "Cork", lat: 51.8424464, lng: -9.1264323 },
  {
    town: "Inistioge",
    county: "Kilkenny",
    lat: 52.4884908,
    lng: -7.065458899999999,
  },
  { town: "Inishannon", county: "Cork", lat: 51.7649008, lng: -8.6574773 },
  {
    town: "Inniskeen",
    county: "Monaghan",
    lat: 54.00315999999999,
    lng: -6.58406,
  },
  { town: "Irishtown", county: "Mayo", lat: 53.6627806, lng: -8.8900518 },
  {
    town: "Jenkinstown",
    county: "Louth",
    lat: 54.01605559999999,
    lng: -6.3103519,
  },
  { town: "Johnstown", county: "Kildare", lat: 53.2370579, lng: -6.6217975 },
  {
    town: "Johnstown",
    county: "Kilkenny",
    lat: 52.74851779999999,
    lng: -7.556992999999999,
  },
  {
    town: "Johnstownbridge",
    county: "Kildare",
    lat: 53.4019965,
    lng: -6.855203299999999,
  },
  { town: "Julianstown", county: "Meath", lat: 53.6729374, lng: -6.285651 },
  { town: "Kanturk", county: "Cork", lat: 52.17725489999999, lng: -8.9051669 },
  { town: "Keadew", county: "Roscommon", lat: 54.0511423, lng: -8.1377557 },
  { town: "Keel", county: "Mayo", lat: 53.9744322, lng: -10.0852574 },
  { town: "Keenagh", county: "Longford", lat: 53.622007, lng: -7.8147206 },
  {
    town: "Kells",
    county: "Kilkenny",
    lat: 52.5396697,
    lng: -7.273187099999999,
  },
  {
    town: "Kells",
    county: "Meath",
    lat: 53.72718030000001,
    lng: -6.876972299999999,
  },
  {
    town: "Kentstown",
    county: "Meath",
    lat: 53.6275364,
    lng: -6.533341099999999,
  },
  { town: "Kernanstown", county: "Carlow", lat: 52.8344641, lng: -6.8833706 },
  {
    town: "Keshcarrigan",
    county: "Leitrim",
    lat: 54.0188646,
    lng: -7.942526399999999,
  },
  { town: "Kilbeggan", county: "Westmeath", lat: 53.3684574, lng: -7.4994634 },
  {
    town: "Kilberry",
    county: "Kildare",
    lat: 53.0311398,
    lng: -7.016986999999999,
  },
  { town: "Kilbrin", county: "Cork", lat: 52.2111951, lng: -8.8311286 },
  { town: "Kilbrittain", county: "Cork", lat: 51.6735577, lng: -8.6917337 },
  { town: "Kilcloon", county: "Meath", lat: 53.42723, lng: -6.58392 },
  { town: "Kilcock", county: "Kildare", lat: 53.3994266, lng: -6.6681233 },
  {
    town: "Kilcolgan",
    county: "Galway",
    lat: 53.210233,
    lng: -8.869438299999999,
  },
  {
    town: "Kilcoole",
    county: "Wicklow",
    lat: 53.1062723,
    lng: -6.064586100000001,
  },
  { town: "Kilcormac", county: "Offaly", lat: 53.1764113, lng: -7.7274112 },
  {
    town: "Kilcullen",
    county: "Kildare",
    lat: 53.1302865,
    lng: -6.745279099999999,
  },
  {
    town: "Kilcummin",
    county: "Kerry",
    lat: 52.0984108,
    lng: -9.470070399999999,
  },
  { town: "Kildalkey", county: "Meath", lat: 53.5714499, lng: -6.9060291 },
  {
    town: "Kildangan",
    county: "Kildare",
    lat: 53.1052189,
    lng: -7.013760100000001,
  },
  { town: "Kildavin", county: "Carlow", lat: 52.683205, lng: -6.6849832 },
  { town: "Kildimo", county: "Limerick", lat: 52.6237328, lng: -8.8120969 },
  { town: "Kildorrery", county: "Cork", lat: 52.2464986, lng: -8.4273898 },
  {
    town: "Kildrum",
    county: "Donegal",
    lat: 54.9791343,
    lng: -7.419445400000001,
  },
  { town: "Kildysart", county: "Clare", lat: 52.6739738, lng: -9.1075349 },
  { town: "Kilfenora", county: "Clare", lat: 52.9899429, lng: -9.218327 },
  { town: "Kilfinane", county: "Limerick", lat: 52.3591426, lng: -8.4682225 },
  {
    town: "Kilflynn",
    county: "Kerry",
    lat: 52.3505422,
    lng: -9.625316399999999,
  },
  { town: "Kilgarvan", county: "Kerry", lat: 51.9031476, lng: -9.4384061 },
  { town: "Kilkee", county: "Clare", lat: 52.6828228, lng: -9.6444116 },
  { town: "Kilkelly", county: "Mayo", lat: 53.87179219999999, lng: -8.8502415 },
  {
    town: "Kilkishen",
    county: "Clare",
    lat: 52.8071551,
    lng: -8.747034600000001,
  },
  {
    town: "Kill",
    county: "Kildare",
    lat: 53.24870629999999,
    lng: -6.590530600000001,
  },
  {
    town: "Kill",
    county: "Waterford",
    lat: 52.1758844,
    lng: -7.335392100000001,
  },
  { town: "Killala", county: "Mayo", lat: 54.2125146, lng: -9.220999899999999 },
  { town: "Killaloe", county: "Clare", lat: 52.808886, lng: -8.4475152 },
  {
    town: "Killashee",
    county: "Longford",
    lat: 53.6812889,
    lng: -7.879322800000001,
  },
  { town: "Killavullen", county: "Cork", lat: 52.1465049, lng: -8.5166811 },
  { town: "Killeagh", county: "Cork", lat: 51.9424888, lng: -7.9920158 },
  {
    town: "Killeigh",
    county: "Offaly",
    lat: 53.2140733,
    lng: -7.450342699999999,
  },
  { town: "Killenard", county: "Laois", lat: 53.1349458, lng: -7.1478515 },
  { town: "Killenaule", county: "Tipperary", lat: 52.5684251, lng: -7.6734719 },
  { town: "Killeshandra", county: "Cavan", lat: 54.0138727, lng: -7.5282313 },
  { town: "Killimor", county: "Galway", lat: 53.1656231, lng: -8.2910516 },
  { town: "Killinierin", county: "Wexford", lat: 52.7300058, lng: -6.2850306 },
  {
    town: "Killorglin",
    county: "Kerry",
    lat: 52.10635079999999,
    lng: -9.7843403,
  },
  {
    town: "Killucan",
    county: "Westmeath",
    lat: 53.5136852,
    lng: -7.142785699999999,
  },
  { town: "Killumney", county: "Cork", lat: 51.8637285, lng: -8.6539137 },
  { town: "Killurin", county: "Offaly", lat: 53.2173433, lng: -7.5509127 },
  { town: "Killybegs", county: "Donegal", lat: 54.6347625, lng: -8.4547084 },
  {
    town: "Killygordon",
    county: "Donegal",
    lat: 54.7968309,
    lng: -7.683535399999999,
  },
  {
    town: "Kilmacanogue",
    county: "Wicklow",
    lat: 53.16859669999999,
    lng: -6.1361973,
  },
  {
    town: "Kilmacow",
    county: "Kilkenny",
    lat: 52.31208050000001,
    lng: -7.1765298,
  },
  { town: "Kilmacrennan", county: "Donegal", lat: 55.030269, lng: -7.7793541 },
  {
    town: "Kilmacthomas",
    county: "Waterford",
    lat: 52.2054897,
    lng: -7.426765199999999,
  },
  { town: "Kilmaine", county: "Mayo", lat: 53.5811547, lng: -9.1227567 },
  { town: "Kilmainhamwood", county: "Meath", lat: 53.852351, lng: -6.8048926 },
  { town: "Kilmaley", county: "Clare", lat: 52.8188446, lng: -9.1088116 },
  { town: "Kilmallock", county: "Limerick", lat: 52.3986185, lng: -8.5715956 },
  { town: "Kilmeade", county: "Kildare", lat: 53.0347993, lng: -6.9022241 },
  { town: "Kilmeaden", county: "Waterford", lat: 52.23333, lng: -7.24281 },
  { town: "Kilmeage", county: "Kildare", lat: 53.2510535, lng: -6.8399263 },
  {
    town: "Kilmessan",
    county: "Meath",
    lat: 53.55888849999999,
    lng: -6.6589527,
  },
  { town: "Kilmihil", county: "Clare", lat: 52.7208888, lng: -9.3198723 },
  {
    town: "Kilmoganny",
    county: "Kilkenny",
    lat: 52.46147269999999,
    lng: -7.3282056,
  },
  { town: "Kilmore", county: "Wexford", lat: 52.2056319, lng: -6.5473792 },
  {
    town: "Kilmore Quay",
    county: "Wexford",
    lat: 52.17571940000001,
    lng: -6.5863919,
  },
  {
    town: "Kilmuckridge",
    county: "Wexford",
    lat: 52.51308849999999,
    lng: -6.283934599999999,
  },
  {
    town: "Ballymulcashel",
    county: "Clare",
    lat: 52.7729069,
    lng: -8.769755199999999,
  },
  { town: "Kilnaleck", county: "Cavan", lat: 53.8622249, lng: -7.3213784 },
  {
    town: "Kilpedder",
    county: "Wicklow",
    lat: 53.11037349999999,
    lng: -6.1055471,
  },
  {
    town: "Kilsheelan",
    county: "Tipperary",
    lat: 52.3611942,
    lng: -7.580387699999999,
  },
  {
    town: "Kilteel",
    county: "Kildare",
    lat: 53.2359545,
    lng: -6.521514199999999,
  },
  {
    town: "Kilteely",
    county: "Limerick",
    lat: 52.55401999999999,
    lng: -8.391494999999999,
  },
  { town: "Kiltimagh", county: "Mayo", lat: 53.8494839, lng: -8.9994739 },
  {
    town: "Kilworth",
    county: "Cork",
    lat: 52.1760533,
    lng: -8.247312299999999,
  },
  { town: "Kingscourt", county: "Cavan", lat: 53.9068739, lng: -6.8052229 },
  { town: "Kinlough", county: "Leitrim", lat: 54.4487146, lng: -8.2858213 },
  { town: "Kinnegad", county: "Westmeath", lat: 53.4568103, lng: -7.1049959 },
  { town: "Kinnitty", county: "Offaly", lat: 53.097806, lng: -7.7189969 },
  { town: "Kinsale", county: "Cork", lat: 51.7058853, lng: -8.5222327 },
  {
    town: "Kinsaley",
    county: "Dublin",
    lat: 53.4247723,
    lng: -6.173013099999999,
  },
  { town: "Kinvarra", county: "Galway", lat: 53.1389085, lng: -8.9362964 },
  { town: "Knightstown", county: "Kerry", lat: 51.9253777, lng: -10.2918805 },
  { town: "Knock", county: "Mayo", lat: 53.7900638, lng: -8.921465999999999 },
  {
    town: "Knockananna",
    county: "Wicklow",
    lat: 52.87287190000001,
    lng: -6.4970477,
  },
  {
    town: "Knockbridge",
    county: "Louth",
    lat: 53.9718223,
    lng: -6.486281099999999,
  },
  {
    town: "Knockcroghery",
    county: "Roscommon",
    lat: 53.5743895,
    lng: -8.0922325,
  },
  { town: "Knocklong", county: "Limerick", lat: 52.4373844, lng: -8.4117892 },
  { town: "Knocknagree", county: "Cork", lat: 52.1221274, lng: -9.2090996 },
  { town: "Knockglass", county: "Cork", lat: 51.8884298, lng: -8.0267122 },
  { town: "Knockraha", county: "Cork", lat: 51.9579423, lng: -8.3400979 },
  {
    town: "Knocktopher",
    county: "Kilkenny",
    lat: 52.4856956,
    lng: -7.217107599999999,
  },
  { town: "Lackaghbeg", county: "Galway", lat: 53.3659038, lng: -8.8813396 },
  {
    town: "Ladysbridge",
    county: "Cork",
    lat: 51.89760829999999,
    lng: -8.0433337,
  },
  {
    town: "Ladytown",
    county: "Kildare",
    lat: 53.1980824,
    lng: -6.729719999999999,
  },
  { town: "Laghy", county: "Donegal", lat: 54.61819999999999, lng: -8.0876 },
  { town: "Lahardane", county: "Mayo", lat: 54.0295472, lng: -9.3220186 },
  { town: "Lahinch", county: "Clare", lat: 52.9334632, lng: -9.3440769 },
  {
    town: "Lanesborough",
    county: "Longford",
    lat: 53.67245459999999,
    lng: -7.9863962,
  },
  {
    town: "Ballyleague",
    county: "Roscommon",
    lat: 53.6793209,
    lng: -7.995011799999999,
  },
  { town: "Laragh", county: "Wicklow", lat: 53.0089293, lng: -6.3004389 },
  { town: "Laytown", county: "Meath", lat: 53.68063009999999, lng: -6.2374498 },
  {
    town: "Bettystown",
    county: "Meath",
    lat: 53.6983238,
    lng: -6.246067999999999,
  },
  {
    town: "Mornington",
    county: "Meath",
    lat: 53.7175479,
    lng: -6.258288299999999,
  },
  { town: "Donacarney", county: "Meath", lat: 53.71051, lng: -6.2793 },
  { town: "Leap", county: "Cork", lat: 51.5811998, lng: -9.1408671 },
  { town: "Legan", county: "Longford", lat: 53.6212481, lng: -7.6308386 },
  {
    town: "Leighlinbridge",
    county: "Carlow",
    lat: 52.73605190000001,
    lng: -6.9759183,
  },
  { town: "Leitrim", county: "Leitrim", lat: 53.99019, lng: -8.06501 },
  {
    town: "Lemybrien",
    county: "Waterford",
    lat: 52.1683402,
    lng: -7.519583700000001,
  },
  {
    town: "Lifford",
    county: "Donegal",
    lat: 54.8328751,
    lng: -7.485638899999999,
  },
  { town: "Liscannor", county: "Clare", lat: 52.9383667, lng: -9.3922452 },
  { town: "Liscarroll", county: "Cork", lat: 52.2600728, lng: -8.8041711 },
  { town: "Lisdoonvarna", county: "Clare", lat: 53.0301669, lng: -9.2897106 },
  {
    town: "Lismore",
    county: "Waterford",
    lat: 52.13581840000001,
    lng: -7.9376803,
  },
  { town: "Lisronagh", county: "Tipperary", lat: 52.417443, lng: -7.7028889 },
  { town: "Lissycasey", county: "Clare", lat: 52.7446724, lng: -9.158153 },
  {
    town: "Littleton",
    county: "Tipperary",
    lat: 52.6369829,
    lng: -7.735418999999998,
  },
  { town: "Lixnaw", county: "Kerry", lat: 52.4064615, lng: -9.6181275 },
  {
    town: "Loughanure",
    county: "Donegal",
    lat: 54.9981527,
    lng: -8.291505299999999,
  },
  { town: "Longwood", county: "Meath", lat: 53.4550101, lng: -6.9247866 },
  { town: "Rampark", county: "Louth", lat: 53.97515, lng: -6.48617 },
  { town: "Lordship", county: "Louth", lat: 54.00011, lng: -6.26667 },
  {
    town: "Loughglinn",
    county: "Roscommon",
    lat: 53.8225476,
    lng: -8.561582999999999,
  },
  {
    town: "Loch Gowna",
    county: "Cavan",
    lat: 53.87370490000001,
    lng: -7.5336731,
  },
  {
    town: "Loughshinny",
    county: "Dublin",
    lat: 53.54922800000001,
    lng: -6.0829049,
  },
  { town: "Louisburgh", county: "Mayo", lat: 53.7631376, lng: -9.8090849 },
  { town: "Lusk", county: "Dublin", lat: 53.52632639999999, lng: -6.1658841 },
  { town: "Lyre", county: "Cork", lat: 52.0817595, lng: -8.8580581 },
  { town: "Macroom", county: "Cork", lat: 51.9048039, lng: -8.9588478 },
  { town: "Moycullen", county: "Galway", lat: 53.3387481, lng: -9.1814776 },
  {
    town: "Malin",
    county: "Donegal",
    lat: 55.2949279,
    lng: -7.263082499999999,
  },
  {
    town: "Manorcunningham",
    county: "Donegal",
    lat: 54.9552585,
    lng: -7.6233678,
  },
  {
    town: "Manorhamilton",
    county: "Leitrim",
    lat: 54.3048735,
    lng: -8.1766956,
  },
  {
    town: "Manor Kilbride",
    county: "Wicklow",
    lat: 53.1986734,
    lng: -6.4669351,
  },
  { town: "Maynooth", county: "Kildare", lat: 53.3812896, lng: -6.5918499 },
  { town: "Milford", county: "Cork", lat: 52.3407344, lng: -8.8557344 },
  {
    town: "Milford",
    county: "Donegal",
    lat: 55.0883927,
    lng: -7.698962099999999,
  },
  {
    town: "Millstreet",
    county: "Cork",
    lat: 52.06013180000001,
    lng: -9.0647121,
  },
  {
    town: "Milltown",
    county: "Kildare",
    lat: 53.2051083,
    lng: -6.861192699999999,
  },
  { town: "Milltown", county: "Kerry", lat: 52.1451912, lng: -9.7175069 },
  {
    town: "Milltown",
    county: "Galway",
    lat: 53.6137016,
    lng: -8.898962000000001,
  },
  { town: "Miltown Malbay", county: "Clare", lat: 52.8562013, lng: -9.4007763 },
  { town: "Meenlaragh", county: "Donegal", lat: 55.1451784, lng: -8.1850861 },
  { town: "Mitchelstown", county: "Cork", lat: 52.2662127, lng: -8.2698863 },
  {
    town: "Model Village",
    county: "Cork",
    lat: 51.6205307,
    lng: -8.881194899999999,
  },
  { town: "Dripsey", county: "Cork", lat: 51.9143772, lng: -8.7420906 },
  { town: "Mogeely", county: "Cork", lat: 51.9314482, lng: -8.057133499999999 },
  {
    town: "Mohill",
    county: "Leitrim",
    lat: 53.9218288,
    lng: -7.865310099999999,
  },
  { town: "Monaghan", county: "Monaghan", lat: 54.2492434, lng: -6.9683082 },
  { town: "Ballyedmond", county: "Wexford", lat: 52.5432699, lng: -6.3400433 },
  { town: "Monamolin", county: "Wexford", lat: 52.556856, lng: -6.3496134 },
  { town: "Monard", county: "Tipperary", lat: 52.5107814, lng: -8.2232875 },
  { town: "Monasterevin", county: "Kildare", lat: 53.1400651, lng: -7.060377 },
  {
    town: "Moneenroe",
    county: "Kilkenny",
    lat: 52.8397987,
    lng: -7.155310399999999,
  },
  {
    town: "Moneygall",
    county: "Offaly",
    lat: 52.87973760000001,
    lng: -7.9578079,
  },
  { town: "Monivea", county: "Galway", lat: 53.37426199999999, lng: -8.702721 },
  { town: "Mooncoin", county: "Kilkenny", lat: 52.2914094, lng: -7.251814 },
  { town: "Moroe", county: "Limerick", lat: 52.649444, lng: -8.39949 },
  { town: "Mountbellew", county: "Galway", lat: 53.47188, lng: -8.5049 },
  { town: "Mountcharles", county: "Donegal", lat: 54.6474421, lng: -8.1947189 },
  {
    town: "Mountcollins",
    county: "Limerick",
    lat: 52.3220585,
    lng: -9.2386125,
  },
  { town: "Mountmellick", county: "Laois", lat: 53.1181481, lng: -7.3267379 },
  { town: "Mountrath", county: "Laois", lat: 53.0025597, lng: -7.4751396 },
  {
    town: "Mountshannon",
    county: "Clare",
    lat: 52.931724,
    lng: -8.429834399999999,
  },
  {
    town: "Moville",
    county: "Donegal",
    lat: 55.1890182,
    lng: -7.040053299999999,
  },
  {
    town: "Moylough",
    county: "Galway",
    lat: 53.48770529999999,
    lng: -8.565876,
  },
  {
    town: "Mucklagh",
    county: "Offaly",
    lat: 53.250532,
    lng: -7.550175399999999,
  },
  { town: "Muff", county: "Donegal", lat: 55.0680564, lng: -7.2689498 },
  {
    town: "Bagenalstown",
    county: "Carlow",
    lat: 52.7007292,
    lng: -6.957055599999999,
  },
  {
    town: "Mullagh",
    county: "Cavan",
    lat: 53.8115174,
    lng: -6.945981799999999,
  },
  { town: "Mullaghmore", county: "Sligo", lat: 54.4655463, lng: -8.4494545 },
  {
    town: "Mullinahone",
    county: "Tipperary",
    lat: 52.512032,
    lng: -7.505708599999999,
  },
  { town: "Mullinavat", county: "Kilkenny", lat: 52.3679303, lng: -7.1711881 },
  {
    town: "Mulranny",
    county: "Mayo",
    lat: 53.9061783,
    lng: -9.781377299999999,
  },
  {
    town: "Multyfarnham",
    county: "Westmeath",
    lat: 53.6252723,
    lng: -7.3893159,
  },
  {
    town: "Mungret",
    county: "Limerick",
    lat: 52.6356745,
    lng: -8.693501999999999,
  },
  { town: "Murrintown", county: "Wexford", lat: 52.28724, lng: -6.5222437 },
  { town: "Myrtleville", county: "Cork", lat: 51.7829961, lng: -8.3003542 },
  { town: "Myshall", county: "Carlow", lat: 52.6858791, lng: -6.7804051 },
  { town: "Downings", county: "Donegal", lat: 55.1948628, lng: -7.8369654 },
  { town: "Furbogh", county: "Galway", lat: 53.24918, lng: -9.20495 },
  {
    town: "Narraghmore",
    county: "Kildare",
    lat: 53.04868010000001,
    lng: -6.8294142,
  },
  { town: "Naul", county: "Dublin", lat: 53.5850362, lng: -6.288999899999999 },
  { town: "Nenagh", county: "Tipperary", lat: 52.8642968, lng: -8.1975561 },
  { town: "Newbawn", county: "Wexford", lat: 52.3428304, lng: -6.7912642 },
  { town: "Newbliss", county: "Monaghan", lat: 54.1576466, lng: -7.1368133 },
  { town: "Newcastle", county: "Dublin", lat: 53.2998135, lng: -6.5010237 },
  {
    town: "Newcastle",
    county: "Wicklow",
    lat: 53.07335519999999,
    lng: -6.057185,
  },
  { town: "Newcastle", county: "Tipperary", lat: 52.2725965, lng: -7.8112834 },
  {
    town: "Newcastle West",
    county: "Limerick",
    lat: 52.4492389,
    lng: -9.0499054,
  },
  { town: "Newmarket", county: "Cork", lat: 52.2140232, lng: -9.0030815 },
  {
    town: "Newmarket-on-Fergus",
    county: "Clare",
    lat: 52.7605328,
    lng: -8.895750099999999,
  },
  {
    town: "Newport",
    county: "Tipperary",
    lat: 52.71089629999999,
    lng: -8.406208399999999,
  },
  { town: "Newport", county: "Mayo", lat: 53.8852758, lng: -9.5463685 },
  { town: "Newtown", county: "Laois", lat: 52.8630266, lng: -7.1082572 },
  {
    town: "Newtownshandrum",
    county: "Cork",
    lat: 52.34646040000001,
    lng: -8.7672052,
  },
  { town: "Newtown", county: "Tipperary", lat: 52.8778127, lng: -8.292807 },
  {
    town: "Newtowncunningham",
    county: "Donegal",
    lat: 54.99881749999999,
    lng: -7.5127836,
  },
  {
    town: "Newtownforbes",
    county: "Longford",
    lat: 53.7654838,
    lng: -7.835621,
  },
  {
    town: "Newtownmountkennedy",
    county: "Wicklow",
    lat: 53.0927815,
    lng: -6.112023,
  },
  { town: "Moyvane", county: "Kerry", lat: 52.50166, lng: -9.3705609 },
  { town: "Nobber", county: "Meath", lat: 53.8217066, lng: -6.747939799999999 },
  { town: "Nurney", county: "Kildare", lat: 53.0966332, lng: -6.9482121 },
  {
    town: "O'Briensbridge - Montpelier",
    county: "Limerick",
    lat: 52.75060389999999,
    lng: -8.496743,
  },
  { town: "Oylegate", county: "Wexford", lat: 52.42101, lng: -6.52994 },
  {
    town: "Oldcastle",
    county: "Meath",
    lat: 53.7700178,
    lng: -7.162411799999999,
  },
  {
    town: "Oldtown",
    county: "Dublin",
    lat: 53.522678,
    lng: -6.315430699999999,
  },
  { town: "Omeath", county: "Louth", lat: 54.0882604, lng: -6.2593365 },
  { town: "Oola", county: "Limerick", lat: 52.5296598, lng: -8.2596358 },
  { town: "Oram", county: "Monaghan", lat: 54.1508756, lng: -6.7016152 },
  { town: "Oranmore", county: "Galway", lat: 53.2647506, lng: -8.9297335 },
  {
    town: "Oughterard",
    county: "Galway",
    lat: 53.42854810000001,
    lng: -9.3189472,
  },
  {
    town: "Oulart",
    county: "Wexford",
    lat: 52.5039145,
    lng: -6.390861699999999,
  },
  { town: "Palatine", county: "Carlow", lat: 52.8649974, lng: -6.8610348 },
  {
    town: "Pallasgreen",
    county: "Limerick",
    lat: 52.54873,
    lng: -8.355184999999999,
  },
  { town: "Pallaskenry", county: "Limerick", lat: 52.6447411, lng: -8.8674281 },
  { town: "Parteen", county: "Clare", lat: 52.696836, lng: -8.6149881 },
  {
    town: "Passage East",
    county: "Waterford",
    lat: 52.2396938,
    lng: -6.974394999999999,
  },
  {
    town: "Passage West - Monkstown",
    county: "Cork",
    lat: 51.8485442,
    lng: -8.3325695,
  },
  {
    town: "Patrickswell",
    county: "Limerick",
    lat: 52.5980577,
    lng: -8.707284399999999,
  },
  {
    town: "Paulstown",
    county: "Kilkenny",
    lat: 52.6793223,
    lng: -7.021358999999999,
  },
  {
    town: "Pettigo",
    county: "Donegal",
    lat: 54.54943,
    lng: -7.832033900000001,
  },
  {
    town: "Piercetown",
    county: "Wexford",
    lat: 52.28785999999999,
    lng: -6.48747,
  },
  {
    town: "Piltown",
    county: "Kilkenny",
    lat: 52.3511631,
    lng: -7.331338099999999,
  },
  {
    town: "Pollagh",
    county: "Offaly",
    lat: 53.2768407,
    lng: -7.709173000000001,
  },
  { town: "Portarlington", county: "Laois", lat: 53.1616096, lng: -7.1895142 },
  {
    town: "Portlaw",
    county: "Waterford",
    lat: 52.28926569999999,
    lng: -7.3147439,
  },
  { town: "Portmagee", county: "Kerry", lat: 51.8854295, lng: -10.36194 },
  { town: "Portrane", county: "Dublin", lat: 53.49319999999999, lng: -6.11462 },
  { town: "Portroe", county: "Tipperary", lat: 52.8847, lng: -8.344875 },
  {
    town: "Portumna",
    county: "Galway",
    lat: 53.0927811,
    lng: -8.218540599999999,
  },
  { town: "Prosperous", county: "Kildare", lat: 53.2881253, lng: -6.7550083 },
  { town: "Puckaun", county: "Tipperary", lat: 52.931884, lng: -8.2385501 },
  {
    town: "Quigleys Point",
    county: "Donegal",
    lat: 55.1248808,
    lng: -7.194928300000001,
  },
  { town: "Quilty", county: "Clare", lat: 52.81725669999999, lng: -9.4559219 },
  { town: "Quin", county: "Clare", lat: 52.8175778, lng: -8.8637803 },
  { town: "Raharney", county: "Westmeath", lat: 53.5241052, lng: -7.0960895 },
  {
    town: "Ramelton",
    county: "Donegal",
    lat: 55.03590000000001,
    lng: -7.64809,
  },
  { town: "Ranafast", county: "Donegal", lat: 55.034738, lng: -8.3063621 },
  { town: "Raphoe", county: "Donegal", lat: 54.8738705, lng: -7.5994379 },
  { town: "Rathangan", county: "Kildare", lat: 53.2210179, lng: -6.9945422 },
  { town: "Aherla", county: "Cork", lat: 51.8536728, lng: -8.732601899999999 },
  {
    town: "Rathcoffey",
    county: "Kildare",
    lat: 53.3342027,
    lng: -6.678636200000001,
  },
  { town: "Rathard", county: "Cork", lat: 51.8483429, lng: -8.7384071 },
  { town: "Rathcoole", county: "Dublin", lat: 53.2817307, lng: -6.466167 },
  {
    town: "Rathcoole",
    county: "Cork",
    lat: 52.0942583,
    lng: -8.974065099999999,
  },
  { town: "Rathcormac", county: "Cork", lat: 52.07613, lng: -8.28176 },
  {
    town: "Rathdowney",
    county: "Laois",
    lat: 52.8555198,
    lng: -7.584797300000001,
  },
  {
    town: "Rathdrum",
    county: "Wicklow",
    lat: 52.93231230000001,
    lng: -6.231101199999999,
  },
  { town: "Rathkeale", county: "Limerick", lat: 52.5225255, lng: -8.9403655 },
  { town: "Rathmolyon", county: "Meath", lat: 53.488177, lng: -6.8036692 },
  { town: "Rathmore", county: "Kerry", lat: 52.0842484, lng: -9.2184337 },
  { town: "Rathmullan", county: "Donegal", lat: 55.0970707, lng: -7.5363061 },
  { town: "Rathnew", county: "Wicklow", lat: 52.9946004, lng: -6.082812 },
  { town: "Rathowen", county: "Westmeath", lat: 53.656076, lng: -7.5203959 },
  { town: "Rathtoe", county: "Carlow", lat: 52.7872217, lng: -6.8000132 },
  {
    town: "Rathwire",
    county: "Westmeath",
    lat: 53.51240869999999,
    lng: -7.1501475,
  },
  {
    town: "Rathvilly",
    county: "Carlow",
    lat: 52.87983449999999,
    lng: -6.6960481,
  },
  {
    town: "Ratoath",
    county: "Meath",
    lat: 53.5062898,
    lng: -6.465680099999999,
  },
  { town: "Rear Cross", county: "Tipperary", lat: 52.6863722, lng: -8.2370086 },
  {
    town: "Redcross",
    county: "Wicklow",
    lat: 52.88974400000001,
    lng: -6.1452741,
  },
  { town: "Rhode", county: "Offaly", lat: 53.3499888, lng: -7.198962900000001 },
  {
    town: "Ringaskiddy",
    county: "Cork",
    lat: 51.83042829999999,
    lng: -8.3219487,
  },
  { town: "Loughbeg", county: "Cork", lat: 52.02726, lng: -8.198839999999999 },
  { town: "Rivermeade", county: "Dublin", lat: 53.45306, lng: -6.29128 },
  { town: "Riverstick", county: "Cork", lat: 51.773023, lng: -8.4960524 },
  { town: "Riverstown", county: "Sligo", lat: 54.1304398, lng: -8.3952507 },
  {
    town: "Robertstown",
    county: "Kildare",
    lat: 53.269268,
    lng: -6.815316399999999,
  },
  { town: "Robinstown", county: "Meath", lat: 53.5946777, lng: -6.7280973 },
  {
    town: "Balbradagh",
    county: "Meath",
    lat: 53.59842090000001,
    lng: -6.7236603,
  },
  {
    town: "Rochfortbridge",
    county: "Westmeath",
    lat: 53.4146417,
    lng: -7.2996835,
  },
  { town: "Rockcorry", county: "Monaghan", lat: 54.1178982, lng: -7.0148381 },
  {
    town: "Roosky",
    county: "Roscommon",
    lat: 53.8327737,
    lng: -7.921534899999998,
  },
  {
    town: "Roscrea",
    county: "Tipperary",
    lat: 52.9551789,
    lng: -7.798469600000001,
  },
  { town: "Rosegreen", county: "Tipperary", lat: 52.469442, lng: -7.8327776 },
  {
    town: "Rosscahill",
    county: "Galway",
    lat: 53.38712,
    lng: -9.246229999999999,
  },
  { town: "Rosscarbery", county: "Cork", lat: 51.57767, lng: -9.038 },
  { town: "Rosses Point", county: "Sligo", lat: 54.3047546, lng: -8.5640206 },
  { town: "Rosslare", county: "Wexford", lat: 52.2720275, lng: -6.3892254 },
  {
    town: "Rosslare Harbour",
    county: "Wexford",
    lat: 52.2513061,
    lng: -6.3414908,
  },
  {
    town: "Roundstone",
    county: "Galway",
    lat: 53.39642079999999,
    lng: -9.919018399999999,
  },
  { town: "Roundwood", county: "Wicklow", lat: 53.0657875, lng: -6.2241877 },
  {
    town: "Saggart",
    county: "Dublin",
    lat: 53.28083969999999,
    lng: -6.4431841,
  },
  { town: "Saleen", county: "Cork", lat: 51.8637224, lng: -8.161825799999999 },
  { town: "Sallins", county: "Kildare", lat: 53.251067, lng: -6.6652312 },
  { town: "Scarriff", county: "Clare", lat: 52.9088498, lng: -8.533109 },
  { town: "Tuamgraney", county: "Clare", lat: 52.8985172, lng: -8.541013 },
  { town: "Schull", county: "Cork", lat: 51.526867, lng: -9.5479801 },
  { town: "Scotshouse", county: "Monaghan", lat: 54.1226216, lng: -7.2471111 },
  {
    town: "Scotstown",
    county: "Monaghan",
    lat: 54.2778889,
    lng: -7.066951400000001,
  },
  { town: "Shanagarry", county: "Cork", lat: 51.8527122, lng: -8.0299249 },
  { town: "Shanagolden", county: "Limerick", lat: 52.5746155, lng: -9.1004049 },
  { town: "Shanbally", county: "Cork", lat: 51.8334141, lng: -8.3570879 },
  { town: "Shanballymore", county: "Cork", lat: 52.2187977, lng: -8.4759914 },
  {
    town: "Shannonbridge",
    county: "Offaly",
    lat: 53.279632,
    lng: -8.044721299999999,
  },
  { town: "Shercock", county: "Cavan", lat: 53.9950108, lng: -6.8979854 },
  {
    town: "Shillelagh",
    county: "Wicklow",
    lat: 52.7545156,
    lng: -6.533460499999999,
  },
  { town: "Shinrone", county: "Offaly", lat: 52.9844035, lng: -7.9245622 },
  { town: "Shrule", county: "Mayo", lat: 53.5202745, lng: -9.0883693 },
  {
    town: "Silvermines",
    county: "Tipperary",
    lat: 52.79436099999999,
    lng: -8.2366272,
  },
  {
    town: "Sixmilebridge",
    county: "Clare",
    lat: 52.7420054,
    lng: -8.772400500000002,
  },
  {
    town: "Skibbereen",
    county: "Cork",
    lat: 51.55592439999999,
    lng: -9.262130299999999,
  },
  { town: "Slane", county: "Meath", lat: 53.7086474, lng: -6.543651199999999 },
  { town: "Slieverue", county: "Kilkenny", lat: 52.2828142, lng: -7.0689659 },
  {
    town: "Smithborough",
    county: "Monaghan",
    lat: 54.2213027,
    lng: -7.0981545,
  },
  { town: "Sneem", county: "Kerry", lat: 51.8373942, lng: -9.8983245 },
  {
    town: "Spa",
    county: "Kerry",
    lat: 52.27538999999999,
    lng: -9.788499999999999,
  },
  { town: "Spanish Point", county: "Clare", lat: 52.8466428, lng: -9.4397015 },
  { town: "Stamullen", county: "Meath", lat: 53.63023, lng: -6.26834 },
  {
    town: "St. Johnston",
    county: "Donegal",
    lat: 54.93468,
    lng: -7.461359999999999,
  },
  {
    town: "Stoneyford",
    county: "Kilkenny",
    lat: 52.5370387,
    lng: -7.229316900000001,
  },
  {
    town: "Stradbally",
    county: "Laois",
    lat: 53.01579830000001,
    lng: -7.149471699999999,
  },
  { town: "Stradbally", county: "Waterford", lat: 52.1313569, lng: -7.4608978 },
  {
    town: "Straffan",
    county: "Kildare",
    lat: 53.3118851,
    lng: -6.608824800000001,
  },
  {
    town: "Strandhill",
    county: "Sligo",
    lat: 54.26999009999999,
    lng: -8.598940299999999,
  },
  {
    town: "Stratford-on-Slaney",
    county: "Wicklow",
    lat: 52.9878852,
    lng: -6.675076499999999,
  },
  {
    town: "Strokestown",
    county: "Roscommon",
    lat: 53.7758666,
    lng: -8.1035319,
  },
  {
    town: "Summerhill",
    county: "Meath",
    lat: 53.4813655,
    lng: -6.732514699999999,
  },
  { town: "Suncroft", county: "Kildare", lat: 53.1075785, lng: -6.8613827 },
  { town: "Swanlinbar", county: "Cavan", lat: 54.1937636, lng: -7.7047113 },
  { town: "Swinford", county: "Mayo", lat: 53.9437021, lng: -8.950006 },
  { town: "Taghmon", county: "Wexford", lat: 52.3229838, lng: -6.6546699 },
  {
    town: "Tagoat",
    county: "Wexford",
    lat: 52.2454387,
    lng: -6.388300099999999,
  },
  { town: "Tallanstown", county: "Louth", lat: 53.9200662, lng: -6.5471834 },
  {
    town: "Tallow",
    county: "Waterford",
    lat: 52.09214069999999,
    lng: -8.007030199999999,
  },
  {
    town: "Tarbert",
    county: "Kerry",
    lat: 52.5724738,
    lng: -9.374537199999999,
  },
  {
    town: "Templemore",
    county: "Tipperary",
    lat: 52.794802,
    lng: -7.839860900000001,
  },
  {
    town: "Templetuohy",
    county: "Tipperary",
    lat: 52.78847,
    lng: -7.721649999999999,
  },
  {
    town: "Tarmonbarry",
    county: "Roscommon",
    lat: 53.7422919,
    lng: -7.920643500000001,
  },
  {
    town: "Termonfeckin",
    county: "Louth",
    lat: 53.76101389999999,
    lng: -6.271843199999999,
  },
  {
    town: "The Ballagh",
    county: "Wexford",
    lat: 52.480407,
    lng: -6.421085799999999,
  },
  {
    town: "The Commons",
    county: "Tipperary",
    lat: 52.6220558,
    lng: -7.5652776,
  },
  {
    town: "The Swan",
    county: "Laois",
    lat: 52.88580100000001,
    lng: -7.156259599999999,
  },
  { town: "Thomastown", county: "Kilkenny", lat: 52.5264574, lng: -7.1383205 },
  {
    town: "Three Mile House",
    county: "Monaghan",
    lat: 54.21667,
    lng: -7.042671299999999,
  },
  { town: "Thurles", county: "Tipperary", lat: 52.6801064, lng: -7.8044421 },
  { town: "Tooban", county: "Donegal", lat: 55.06532, lng: -7.430820000000001 },
  {
    town: "Timoleague",
    county: "Cork",
    lat: 51.6432296,
    lng: -8.766188699999999,
  },
  {
    town: "Tinahely",
    county: "Wicklow",
    lat: 52.7997634,
    lng: -6.463184399999999,
  },
  {
    town: "Tinriland",
    county: "Carlow",
    lat: 52.794705,
    lng: -6.896094799999999,
  },
  { town: "Tinure", county: "Louth", lat: 53.79188, lng: -6.419 },
  {
    town: "Tipperary",
    county: "Tipperary",
    lat: 52.47363910000001,
    lng: -8.1617622,
  },
  {
    town: "Toomevara",
    county: "Tipperary",
    lat: 52.85071629999999,
    lng: -8.0350506,
  },
  {
    town: "Toornafulla",
    county: "Limerick",
    lat: 52.36463999999999,
    lng: -9.152849999999999,
  },
  { town: "Tower", county: "Cork City", lat: 51.92518, lng: -8.60797 },
  {
    town: "Tramore",
    county: "Waterford",
    lat: 52.16143539999999,
    lng: -7.1492952,
  },
  { town: "Trim", county: "Meath", lat: 53.5523666, lng: -6.7866474 },
  { town: "Tubbercurry", county: "Sligo", lat: 54.0552258, lng: -8.7298236 },
  { town: "Tulla", county: "Clare", lat: 52.8692148, lng: -8.7607816 },
  { town: "Tullaghan", county: "Leitrim", lat: 54.468449, lng: -8.3290498 },
  { town: "Tullow", county: "Carlow", lat: 52.802547, lng: -6.7371517 },
  { town: "Tullyallen", county: "Louth", lat: 53.73661, lng: -6.42244 },
  { town: "Tulsk", county: "Roscommon", lat: 53.7799868, lng: -8.2560526 },
  { town: "Turlough", county: "Mayo", lat: 53.8841074, lng: -9.2124764 },
  { town: "Turloughmore", county: "Galway", lat: 53.3821288, lng: -8.853684 },
  {
    town: "Twomileborris",
    county: "Tipperary",
    lat: 52.6725745,
    lng: -7.711432999999999,
  },
  {
    town: "Tyrrellspass",
    county: "Westmeath",
    lat: 53.3889615,
    lng: -7.3773447,
  },
  { town: "Unionhall", county: "Cork", lat: 51.558803, lng: -9.1434791 },
  { town: "Urlingford", county: "Kilkenny", lat: 52.7205983, lng: -7.5821799 },
  {
    town: "Villierstown",
    county: "Waterford",
    lat: 52.08824449999999,
    lng: -7.850768,
  },
  { town: "Virginia", county: "Cavan", lat: 53.8344096, lng: -7.0813222 },
  { town: "Coolagary", county: "Offaly", lat: 53.234638, lng: -7.2138556 },
  { town: "Watergrasshill", county: "Cork", lat: 52.0113682, lng: -8.3440414 },
  { town: "Waterville", county: "Kerry", lat: 51.829465, lng: -10.1745436 },
  {
    town: "Whitechurch",
    county: "Cork",
    lat: 51.9840023,
    lng: -8.516469899999999,
  },
  { town: "Whitegate", county: "Clare", lat: 52.9505524, lng: -8.3729064 },
  { town: "Whitegate", county: "Cork", lat: 51.8267841, lng: -8.2321121 },
  { town: "Williamstown", county: "Galway", lat: 53.6777196, lng: -8.579964 },
  { town: "Woodford", county: "Galway", lat: 53.0521803, lng: -8.4002368 },
];

const towns2 = [
  { town: "Abbey", county: "Galway", lat: 53.10337, lng: -8.39013 },
  { town: "Abbey", county: "Galway", lat: 53.43701, lng: -8.74945 },
  { town: "Abbeydorney", county: "Kerry", lat: 52.34661, lng: -9.68791 },
  { town: "Abbeyfeale", county: "Limerick", lat: 52.38618, lng: -9.29707 },
  { town: "Abbeylara", county: "Longford", lat: 53.76528, lng: -7.44887 },
  { town: "Abbeyleix", county: "Laois", lat: 52.91528, lng: -7.3479 },
  { town: "Abbeyshrule", county: "Longford", lat: 53.58251, lng: -7.65756 },
  { town: "Achill Sound", county: "Mayo", lat: 53.93026, lng: -9.92664 },
  { town: "Achonry", county: "Sligo", lat: 54.08043, lng: -8.66391 },
  { town: "Aclare", county: "Sligo", lat: 54.03646, lng: -8.89665 },
  { town: "Adamstown", county: "Wexford", lat: 52.39357, lng: -6.71856 },
  { town: "Adare", county: "Limerick", lat: 52.56402, lng: -8.79118 },
  { town: "Adrigole", county: "Cork", lat: 51.6942, lng: -9.72281 },
  { town: "Aghaboe", county: "Laois", lat: 52.92036, lng: -7.51191 },
  { town: "Aghabullogue", county: "Cork", lat: 51.9443, lng: -8.80785 },
  { town: "Aghada", county: "Cork", lat: 51.83699, lng: -8.209 },
  { town: "Aghadown", county: "Cork", lat: 51.56059, lng: -9.39044 },
  { town: "Aghadrumkeen", county: "Monaghan", lat: 54.14813, lng: -7.06007 },
  { town: "Aghagower", county: "Mayo", lat: 53.7634, lng: -9.46509 },
  { town: "Aghamore", county: "Leitrim", lat: 53.899, lng: -7.95399 },
  { town: "Aghamore", county: "Mayo", lat: 53.82769, lng: -8.82447 },
  { town: "Aghatubrid", county: "Kerry", lat: 51.89435, lng: -10.22489 },
  { town: "Aghavas", county: "Leitrim", lat: 53.95627, lng: -7.71793 },
  { town: "Aghleam", county: "Mayo", lat: 54.11822, lng: -10.10154 },
  { town: "Aghnacliff", county: "Longford", lat: 53.84779, lng: -7.60464 },
  { town: "Aghnamullen", county: "Monaghan", lat: 54.09506, lng: -6.95954 },
  { town: "Aglish", county: "Tipperary", lat: 53.04087, lng: -8.08607 },
  { town: "Aglish", county: "Waterford", lat: 52.07151, lng: -7.82226 },
  { town: "Ahakista", county: "Cork", lat: 51.5999, lng: -9.63219 },
  { town: "Ahascragh", county: "Galway", lat: 53.39761, lng: -8.33463 },
  { town: "Ahenny", county: "Tipperary", lat: 52.41416, lng: -7.39256 },
  { town: "Aherla", county: "Cork", lat: 51.85367, lng: -8.7326 },
  { town: "Allenwood", county: "Kildare", lat: 53.28395, lng: -6.86185 },
  { town: "Allihies", county: "Cork", lat: 51.64126, lng: -10.04416 },
  { town: "Altnapaste", county: "Donegal", lat: 54.8136, lng: -7.88936 },
  { town: "Anascaul", county: "Kerry", lat: 52.15108, lng: -10.05675 },
  { town: "Anglesborough", county: "Limerick", lat: 52.3403, lng: -8.28954 },
  { town: "Annacarty", county: "Tipperary", lat: 52.56442, lng: -8.11039 },
  { town: "Annacotty", county: "Limerick", lat: 52.66805, lng: -8.53768 },
  { town: "Annagary", county: "Donegal", lat: 55.02198, lng: -8.32028 },
  { town: "Annagassan", county: "Louth", lat: 53.88262, lng: -6.34349 },
  { town: "Annagh", county: "Sligo", lat: 54.05815, lng: -8.82 },
  { town: "Annaghdown", county: "Galway", lat: 53.38813, lng: -9.07104 },
  { town: "Annaghmore", county: "Leitrim", lat: 53.89459, lng: -7.78921 },
  { town: "Annahaia", county: "Monaghan", lat: 54.0869, lng: -6.86412 },
  { town: "Annamoe", county: "Wicklow", lat: 53.03152, lng: -6.25423 },
  { town: "Annayalla", county: "Monaghan", lat: 54.16608, lng: -6.79678 },
  { town: "Annestown", county: "Waterford", lat: 52.14059, lng: -7.27193 },
  { town: "Araglin", county: "Cork", lat: 52.20716, lng: -8.10662 },
  { town: "Aran Island", county: "Donegal", lat: 54.99918, lng: -8.52998 },
  { town: "Archerstown", county: "Westmeath", lat: 53.65608, lng: -7.10427 },
  { town: "Ardagh", county: "Limerick", lat: 52.49412, lng: -9.06372 },
  { town: "Ardara", county: "Donegal", lat: 54.76714, lng: -8.40929 },
  { town: "Ardattin", county: "Carlow", lat: 52.75503, lng: -6.70616 },
  { town: "Ardcrony", county: "Tipperary", lat: 52.93552, lng: -8.1561 },
  { town: "Ardee", county: "Louth", lat: 53.85762, lng: -6.54001 },
  { town: "Ardfert", county: "Kerry", lat: 52.32695, lng: -9.78073 },
  { town: "Ardfield", county: "Cork", lat: 51.56697, lng: -8.91024 },
  { town: "Ardfinnan", county: "Tipperary", lat: 52.30925, lng: -7.88422 },
  { town: "Ardgroom", county: "Cork", lat: 51.7335, lng: -9.89694 },
  { town: "Ardlougher", county: "Cavan", lat: 54.06328, lng: -7.57597 },
  { town: "Ardmore", county: "Galway", lat: 53.30686, lng: -9.76937 },
  { town: "Ardmore", county: "Waterford", lat: 51.95108, lng: -7.72533 },
  { town: "Ardmulchan", county: "Meath", lat: 53.66928, lng: -6.63219 },
  { town: "Ardnacrusha", county: "Clare", lat: 52.7091, lng: -8.61394 },
  { town: "Ardpatrick", county: "Limerick", lat: 52.34145, lng: -8.52536 },
  { town: "Ardrahan", county: "Galway", lat: 53.15643, lng: -8.80587 },
  { town: "Arigna", county: "Roscommon", lat: 54.07634, lng: -8.11252 },
  { town: "Arklow", county: "Wicklow", lat: 52.79769, lng: -6.15994 },
  { town: "Artane", county: "Dublin", lat: 53.383, lng: -6.20514 },
  { town: "Arthurstown", county: "Wexford", lat: 52.24202, lng: -6.95137 },
  { town: "Arvagh", county: "Cavan", lat: 53.92144, lng: -7.57992 },
  { town: "Ashbourne", county: "Meath", lat: 53.51116, lng: -6.39738 },
  { town: "Ashford", county: "Limerick", lat: 52.35974, lng: -9.05921 },
  { town: "Ashford", county: "Wicklow", lat: 53.01079, lng: -6.10829 },
  { town: "Ashtown", county: "Dublin", lat: 53.37251, lng: -6.33164 },
  { town: "Askamore", county: "Wexford", lat: 52.67241, lng: -6.47976 },
  { town: "Askanagap", county: "Wicklow", lat: 52.87221, lng: -6.41051 },
  { town: "Askeaton", county: "Limerick", lat: 52.60066, lng: -8.97175 },
  { town: "Astee", county: "Kerry", lat: 52.55184, lng: -9.56 },
  { town: "Athboy", county: "Meath", lat: 53.62363, lng: -6.91565 },
  { town: "Athea", county: "Limerick", lat: 52.46099, lng: -9.28962 },
  { town: "Athenry", county: "Galway", lat: 53.30433, lng: -8.75018 },
  { town: "Athgarvan", county: "Kildare", lat: 53.15289, lng: -6.7777 },
  { town: "Athlacca", county: "Limerick", lat: 52.45801, lng: -8.65008 },
  { town: "Athleague", county: "Roscommon", lat: 53.56763, lng: -8.25674 },
  { town: "Athlone", county: "Westmeath", lat: 53.42393, lng: -7.94069 },
  { town: "Athy", county: "Kildare", lat: 52.99183, lng: -6.98573 },
  { town: "Attanagh", county: "Laois", lat: 52.83329, lng: -7.35323 },
  { town: "Attimachugh", county: "Mayo", lat: 54.00955, lng: -9.02572 },
  { town: "Attymass", county: "Mayo", lat: 54.05132, lng: -9.08326 },
  { town: "Attymon", county: "Galway", lat: 53.323, lng: -8.60859 },
  { town: "Aughinish", county: "Clare", lat: 53.16296, lng: -9.07214 },
  { town: "Aughrim", county: "Galway", lat: 53.30237, lng: -8.31652 },
  { town: "Aughrim", county: "Wicklow", lat: 52.85593, lng: -6.32594 },
  { town: "Avoca", county: "Wicklow", lat: 52.85796, lng: -6.21302 },
  { town: "Bagenalstown", county: "Carlow", lat: 52.70311, lng: -6.95587 },
  { town: "Bailieborough", county: "Cavan", lat: 53.91561, lng: -6.9693 },
  { town: "Balbriggan", county: "Dublin", lat: 53.60832, lng: -6.18208 },
  { town: "Baldoyle", county: "Dublin", lat: 53.39867, lng: -6.12725 },
  { town: "Balla", county: "Mayo", lat: 53.80438, lng: -9.1305 },
  { town: "Ballagh", county: "Limerick", lat: 52.37269, lng: -9.07828 },
  { town: "Ballaghaderreen", county: "Roscommon", lat: 53.9017, lng: -8.57922 },
  { town: "Ballaghkeen", county: "Wexford", lat: 52.48041, lng: -6.42109 },
  { town: "Ballaghnatrillick", county: "Sligo", lat: 54.41372, lng: -8.38783 },
  { town: "Ballickmoyler", county: "Laois", lat: 52.87865, lng: -7.01168 },
  { town: "Ballina", county: "Mayo", lat: 54.11701, lng: -9.15697 },
  { town: "Ballina", county: "Tipperary", lat: 52.80701, lng: -8.43092 },
  { town: "Ballina", county: "Westmeath", lat: 53.50692, lng: -7.42129 },
  { town: "Ballinabrackey", county: "Meath", lat: 53.41621, lng: -7.13316 },
  { town: "Ballinaclash", county: "Wicklow", lat: 52.90524, lng: -6.25795 },
  { town: "Ballinadee", county: "Cork", lat: 51.71184, lng: -8.62684 },
  { town: "Ballinafad", county: "Galway", lat: 53.45691, lng: -9.82063 },
  { town: "Ballinafad", county: "Sligo", lat: 54.02756, lng: -8.33333 },
  { town: "Ballinagar", county: "Offaly", lat: 53.26621, lng: -7.33711 },
  { town: "Ballinagleragh", county: "Leitrim", lat: 54.1563, lng: -8.00793 },
  { town: "Ballinagree", county: "Cork", lat: 51.97601, lng: -8.92806 },
  { town: "Ballinahinch", county: "Tipperary", lat: 52.76818, lng: -8.38153 },
  { town: "Ballinakill", county: "Laois", lat: 52.87501, lng: -7.30858 },
  { town: "Ballinalack", county: "Westmeath", lat: 53.63056, lng: -7.47305 },
  { town: "Ballinalee", county: "Longford", lat: 53.77507, lng: -7.6551 },
  { town: "Ballinameen", county: "Roscommon", lat: 53.89723, lng: -8.30122 },
  { town: "Ballinamore", county: "Leitrim", lat: 54.05222, lng: -7.80321 },
  {
    town: "Ballinamore Bridge",
    county: "Galway",
    lat: 53.48355,
    lng: -8.36529,
  },
  { town: "Ballinamuck", county: "Longford", lat: 53.86489, lng: -7.71724 },
  { town: "Ballinard", county: "Tipperary", lat: 52.48981, lng: -8.26822 },
  { town: "Ballinascarty", county: "Cork", lat: 51.67245, lng: -8.85774 },
  { town: "Ballinasloe", county: "Galway", lat: 53.32876, lng: -8.22695 },
  { town: "Ballincollig", county: "Cork", lat: 51.88802, lng: -8.5949 },
  { town: "Ballincurrig", county: "Cork", lat: 51.98877, lng: -8.22764 },
  { town: "Ballindaggan", county: "Wexford", lat: 52.56854, lng: -6.68026 },
  { town: "Ballindangan", county: "Cork", lat: 52.23739, lng: -8.34017 },
  { town: "Ballinderreen", county: "Galway", lat: 53.18567, lng: -8.90964 },
  { town: "Ballinderry", county: "Tipperary", lat: 53.02514, lng: -8.22079 },
  { town: "Ballindine", county: "Mayo", lat: 53.66926, lng: -8.95673 },
  { town: "Ballindoon", county: "Sligo", lat: 54.0872, lng: -8.32716 },
  { town: "Ballindrait", county: "Donegal", lat: 54.84516, lng: -7.52653 },
  { town: "Ballineen", county: "Cork", lat: 51.73564, lng: -8.95079 },
  { town: "Ballinfull", county: "Sligo", lat: 54.36176, lng: -8.58116 },
  { town: "Ballingarry", county: "Limerick", lat: 52.47346, lng: -8.86239 },
  { town: "Ballingarry", county: "Tipperary", lat: 52.58766, lng: -7.54711 },
  { town: "Ballingarry", county: "Tipperary", lat: 53.01765, lng: -8.031 },
  { town: "Ballingeary", county: "Cork", lat: 51.85074, lng: -9.23264 },
  { town: "Ballinglen", county: "Wicklow", lat: 52.82388, lng: -6.41945 },
  { town: "Ballinhassig", county: "Cork", lat: 51.81235, lng: -8.53772 },
  { town: "Ballinlough", county: "Meath", lat: 53.7353, lng: -7.03455 },
  { town: "Ballinlough", county: "Roscommon", lat: 53.74189, lng: -8.63356 },
  { town: "Ballinroad", county: "Waterford", lat: 52.10375, lng: -7.57341 },
  { town: "Ballinrobe", county: "Mayo", lat: 53.62374, lng: -9.22112 },
  { town: "Ballinruan", county: "Clare", lat: 52.954, lng: -8.82897 },
  { town: "Ballinskelligs", county: "Kerry", lat: 51.8244, lng: -10.27301 },
  { town: "Ballinspittle", county: "Cork", lat: 51.66634, lng: -8.59659 },
  { town: "Ballinteer", county: "Dublin", lat: 53.27462, lng: -6.25354 },
  { town: "Ballintober", county: "Mayo", lat: 53.74508, lng: -9.29838 },
  { town: "Ballintober", county: "Roscommon", lat: 53.71808, lng: -8.4101 },
  { town: "Ballintogher", county: "Sligo", lat: 54.20065, lng: -8.36498 },
  { town: "Ballintra", county: "Donegal", lat: 54.57885, lng: -8.12433 },
  { town: "Ballintubbert", county: "Laois", lat: 52.98399, lng: -7.08663 },
  { town: "Ballinunty", county: "Tipperary", lat: 52.59009, lng: -7.66651 },
  { town: "Ballinure", county: "Tipperary", lat: 52.5642, lng: -7.76635 },
  { town: "Ballitore", county: "Kildare", lat: 53.00974, lng: -6.81564 },
  { town: "Ballivor", county: "Meath", lat: 53.53295, lng: -6.96808 },
  { town: "Ballon", county: "Carlow", lat: 52.7391, lng: -6.76979 },
  { town: "Ballsbridge", county: "Dublin", lat: 53.32887, lng: -6.23053 },
  { town: "Ballyagran", county: "Limerick", lat: 52.40264, lng: -8.78329 },
  { town: "Ballybaun", county: "Roscommon", lat: 53.93585, lng: -8.31387 },
  { town: "Ballybay", county: "Monaghan", lat: 54.12946, lng: -6.90279 },
  { town: "Ballyboden", county: "Dublin", lat: 53.28496, lng: -6.29508 },
  { town: "Ballyboe", county: "Donegal", lat: 55.14732, lng: -8.05494 },
  { town: "Ballybofey", county: "Donegal", lat: 54.79682, lng: -7.78755 },
  { town: "Ballyboghil", county: "Dublin", lat: 53.52021, lng: -6.2676 },
  { town: "Ballybrack", county: "Dublin", lat: 53.24878, lng: -6.11938 },
  { town: "Ballybrittas", county: "Laois", lat: 53.10903, lng: -7.13742 },
  { town: "Ballybunnion", county: "Kerry", lat: 52.51241, lng: -9.67068 },
  { town: "Ballycahill", county: "Tipperary", lat: 52.68893, lng: -7.90489 },
  { town: "Ballycanew", county: "Wexford", lat: 52.61068, lng: -6.30498 },
  { town: "Ballycarney", county: "Wexford", lat: 52.58268, lng: -6.57136 },
  { town: "Ballycarra", county: "Mayo", lat: 53.80228, lng: -9.21414 },
  { town: "Ballycastle", county: "Mayo", lat: 54.27967, lng: -9.37246 },
  { town: "Ballyclerahan", county: "Tipperary", lat: 52.4119, lng: -7.75306 },
  { town: "Ballyclogh", county: "Cork", lat: 52.16739, lng: -8.74224 },
  { town: "Ballycogly", county: "Wexford", lat: 52.24369, lng: -6.48958 },
  { town: "Ballycolla", county: "Laois", lat: 52.88268, lng: -7.44653 },
  { town: "Ballycommon", county: "Tipperary", lat: 52.89139, lng: -8.24427 },
  { town: "Ballyconneely", county: "Galway", lat: 53.43249, lng: -10.07604 },
  { town: "Ballyconnell", county: "Cavan", lat: 54.11622, lng: -7.58396 },
  { town: "Ballyconnell", county: "Wicklow", lat: 52.81302, lng: -6.62899 },
  { town: "Ballycoog", county: "Wicklow", lat: 52.83017, lng: -6.2715 },
  { town: "Ballycotton", county: "Cork", lat: 51.82992, lng: -8.00975 },
  { town: "Ballycrossaun", county: "Galway", lat: 53.15174, lng: -8.18839 },
  { town: "Ballycroy", county: "Mayo", lat: 54.02389, lng: -9.82605 },
  { town: "Ballycullane", county: "Wexford", lat: 52.28033, lng: -6.83978 },
  { town: "Ballycumber", county: "Offaly", lat: 53.32642, lng: -7.68612 },
  { town: "Ballydangan", county: "Roscommon", lat: 53.34436, lng: -8.09559 },
  { town: "Ballydehob", county: "Cork", lat: 51.56301, lng: -9.45536 },
  { town: "Ballydesmond", county: "Cork", lat: 52.17965, lng: -9.23414 },
  { town: "Ballyduff", county: "Kerry", lat: 52.45455, lng: -9.6629 },
  { town: "Ballyduff", county: "Waterford", lat: 52.1478, lng: -8.05127 },
  { town: "Ballyduff", county: "Waterford", lat: 52.2344, lng: -7.24414 },
  { town: "Ballyfad", county: "Wexford", lat: 52.76766, lng: -6.26805 },
  { town: "Ballyfarnagh", county: "Mayo", lat: 53.78552, lng: -8.96189 },
  { town: "Ballyfarnon", county: "Roscommon", lat: 54.07237, lng: -8.20472 },
  { town: "Ballyfermot", county: "Dublin", lat: 53.34225, lng: -6.35356 },
  { town: "Ballyferriter", county: "Kerry", lat: 52.16958, lng: -10.4027 },
  { town: "Ballyfin", county: "Laois", lat: 53.05215, lng: -7.41966 },
  { town: "Ballyforan", county: "Roscommon", lat: 53.47008, lng: -8.27006 },
  { town: "Ballyfoyle", county: "Kilkenny", lat: 52.72171, lng: -7.23227 },
  { town: "Ballygar", county: "Galway", lat: 53.52256, lng: -8.32575 },
  { town: "Ballygarrett", county: "Wexford", lat: 52.57299, lng: -6.22998 },
  { town: "Ballygarvan", county: "Cork", lat: 51.82427, lng: -8.46125 },
  { town: "Ballygawley", county: "Sligo", lat: 54.19202, lng: -8.44524 },
  { town: "Ballyglass", county: "Mayo", lat: 53.74017, lng: -9.17239 },
  { town: "Ballyglunin", county: "Galway", lat: 53.43181, lng: -8.79574 },
  { town: "Ballygorman", county: "Donegal", lat: 55.3623, lng: -7.33681 },
  { town: "Ballyhack", county: "Wexford", lat: 52.24847, lng: -6.96419 },
  { town: "Ballyhahill", county: "Limerick", lat: 52.55987, lng: -9.19054 },
  { town: "Ballyhaise", county: "Cavan", lat: 54.04734, lng: -7.31552 },
  { town: "Ballyhale", county: "Kilkenny", lat: 52.46666, lng: -7.20024 },
  { town: "Ballyhar", county: "Kerry", lat: 52.11971, lng: -9.56577 },
  { town: "Ballyhaunis", county: "Mayo", lat: 53.76283, lng: -8.7646 },
  { town: "Ballyhean", county: "Mayo", lat: 53.79495, lng: -9.31475 },
  { town: "Ballyheige", county: "Kerry", lat: 52.39046, lng: -9.82774 },
  { town: "Ballyhoge", county: "Wexford", lat: 52.41475, lng: -6.57575 },
  { town: "Ballyhooly", county: "Cork", lat: 52.14527, lng: -8.3981 },
  { town: "Ballyhoorisky", county: "Donegal", lat: 55.24706, lng: -7.74462 },
  { town: "Ballyhugh", county: "Cavan", lat: 54.08535, lng: -7.53503 },
  { town: "Ballyjamesduff", county: "Cavan", lat: 53.86387, lng: -7.20646 },
  { town: "Ballykeeran", county: "Westmeath", lat: 53.44945, lng: -7.88702 },
  { town: "Ballylanders", county: "Limerick", lat: 52.37107, lng: -8.346 },
  { town: "Ballylaneen", county: "Waterford", lat: 52.17313, lng: -7.39983 },
  { town: "Ballylicky", county: "Cork", lat: 51.72153, lng: -9.43778 },
  { town: "Ballyliffin", county: "Donegal", lat: 55.27844, lng: -7.39421 },
  { town: "Ballylongford", county: "Kerry", lat: 52.548, lng: -9.47881 },
  { town: "Ballylooby", county: "Tipperary", lat: 52.32669, lng: -7.9843 },
  { town: "Ballylynan", county: "Laois", lat: 52.94358, lng: -7.04204 },
  { town: "Ballymacarbry", county: "Waterford", lat: 52.26708, lng: -7.71765 },
  { town: "Ballymacavany", county: "Donegal", lat: 54.60658, lng: -7.85707 },
  { town: "Ballymackey", county: "Tipperary", lat: 52.87688, lng: -8.08482 },
  { town: "Ballymackney", county: "Monaghan", lat: 53.95586, lng: -6.66998 },
  { town: "Ballymacoda", county: "Cork", lat: 51.89211, lng: -7.93956 },
  { town: "Ballymacurly", county: "Roscommon", lat: 53.69668, lng: -8.29581 },
  { town: "Ballymacward", county: "Galway", lat: 53.3805, lng: -8.48756 },
  { town: "Ballymahon", county: "Longford", lat: 53.56436, lng: -7.76461 },
  { town: "Ballymakeery", county: "Cork", lat: 51.93639, lng: -9.14543 },
  { town: "Ballymaleel", county: "Donegal", lat: 54.98169, lng: -7.67476 },
  { town: "Ballymanus", county: "Westmeath", lat: 53.73233, lng: -7.28156 },
  { town: "Ballymartle", county: "Cork", lat: 51.7716, lng: -8.51853 },
  { town: "Ballymoe", county: "Galway", lat: 53.69403, lng: -8.4618 },
  { town: "Ballymore", county: "Cork", lat: 51.8651, lng: -8.24904 },
  { town: "Ballymore", county: "Westmeath", lat: 53.49092, lng: -7.67975 },
  {
    town: "Ballymore Eustace",
    county: "Kildare",
    lat: 53.13301,
    lng: -6.61489,
  },
  { town: "Ballymote", county: "Sligo", lat: 54.08983, lng: -8.51719 },
  { town: "Ballymun", county: "Dublin", lat: 53.39393, lng: -6.2641 },
  { town: "Ballymurn", county: "Wexford", lat: 52.44128, lng: -6.47705 },
  { town: "Ballymurphy", county: "Carlow", lat: 52.57223, lng: -6.86148 },
  { town: "Ballymurray", county: "Roscommon", lat: 53.60051, lng: -8.12911 },
  { town: "Ballynabola", county: "Wexford", lat: 52.36885, lng: -6.83666 },
  { town: "Ballynacally", county: "Clare", lat: 52.71929, lng: -9.0665 },
  { town: "Ballynacarriga", county: "Cork", lat: 51.70606, lng: -9.02978 },
  { town: "Ballynacarrigy", county: "Westmeath", lat: 53.58208, lng: -7.53651 },
  { town: "Ballynacarrow", county: "Sligo", lat: 54.1341, lng: -8.56047 },
  { town: "Ballynacorra", county: "Cork", lat: 51.89957, lng: -8.16673 },
  { town: "Ballynagore", county: "Westmeath", lat: 53.40563, lng: -7.46317 },
  { town: "Ballynahown", county: "Galway", lat: 53.2494, lng: -9.21468 },
  { town: "Ballynahown", county: "Westmeath", lat: 53.35068, lng: -7.84889 },
  { town: "Ballynakill", county: "Galway", lat: 53.09192, lng: -8.45618 },
  { town: "Ballynamona", county: "Cork", lat: 52.08895, lng: -8.63554 },
  { town: "Ballynamult", county: "Waterford", lat: 52.2115, lng: -7.73382 },
  { town: "Ballynanty", county: "Limerick", lat: 52.67769, lng: -8.6374 },
  { town: "Ballyneety", county: "Limerick", lat: 52.59775, lng: -8.54901 },
  { town: "Ballyneill", county: "Tipperary", lat: 52.37424, lng: -7.46802 },
  { town: "Ballynoe", county: "Cork", lat: 52.05968, lng: -8.09753 },
  { town: "Ballyorgan", county: "Limerick", lat: 52.31817, lng: -8.4696 },
  { town: "Ballypatrick", county: "Tipperary", lat: 52.39307, lng: -7.54764 },
  { town: "Ballyporeen", county: "Tipperary", lat: 52.27011, lng: -8.09921 },
  { town: "Ballyragget", county: "Kilkenny", lat: 52.78867, lng: -7.33382 },
  { town: "Ballyroan", county: "Laois", lat: 52.9484, lng: -7.30468 },
  { town: "Ballyroebuck", county: "Wexford", lat: 52.65129, lng: -6.55111 },
  { town: "Ballysadare", county: "Sligo", lat: 54.21212, lng: -8.50905 },
  { town: "Ballyshannon", county: "Donegal", lat: 54.50299, lng: -8.19205 },
  { town: "Ballyshrule", county: "Galway", lat: 53.10186, lng: -8.29176 },
  { town: "Ballysteen", county: "Limerick", lat: 52.64623, lng: -8.95266 },
  { town: "Ballyvaghan", county: "Clare", lat: 53.11553, lng: -9.14943 },
  { town: "Ballyvelaghan", county: "Clare", lat: 53.1558, lng: -9.07697 },
  { town: "Ballyvourney", county: "Cork", lat: 51.94549, lng: -9.16409 },
  { town: "Ballywilliam", county: "Wexford", lat: 52.44845, lng: -6.86415 },
  { town: "Ballywire", county: "Tipperary", lat: 52.43289, lng: -8.30991 },
  { town: "Balrath", county: "Meath", lat: 53.62463, lng: -6.47928 },
  { town: "Baltimore", county: "Cork", lat: 51.48433, lng: -9.36611 },
  { town: "Baltinglass", county: "Wicklow", lat: 52.94078, lng: -6.71098 },
  { town: "Baltray", county: "Louth", lat: 53.73711, lng: -6.2705 },
  { town: "Banagher", county: "Offaly", lat: 53.19011, lng: -7.98611 },
  { town: "Bandon", county: "Cork", lat: 51.74613, lng: -8.73182 },
  { town: "Banemore", county: "Kerry", lat: 52.38502, lng: -9.5463 },
  { town: "Bangor", county: "Mayo", lat: 54.14411, lng: -9.74287 },
  { town: "Banoge", county: "Limerick", lat: 52.48092, lng: -8.71032 },
  { town: "Bansha", county: "Tipperary", lat: 52.44824, lng: -8.0643 },
  { town: "Banteer", county: "Cork", lat: 52.12946, lng: -8.8935 },
  { town: "Bantry", county: "Cork", lat: 51.68089, lng: -9.4486 },
  { town: "Barefield", county: "Clare", lat: 52.88761, lng: -8.94763 },
  { town: "Barna", county: "Galway", lat: 53.25181, lng: -9.14747 },
  { town: "Barnaderg", county: "Galway", lat: 53.47949, lng: -8.72055 },
  { town: "Barnatra", county: "Mayo", lat: 54.22173, lng: -9.84076 },
  { town: "Barnesmore", county: "Donegal", lat: 54.69296, lng: -7.99555 },
  { town: "Barraderry", county: "Tipperary", lat: 52.59112, lng: -8.09057 },
  { town: "Barraduff", county: "Kerry", lat: 52.06002, lng: -9.3363 },
  { town: "Barretstown", county: "Kildare", lat: 53.21238, lng: -6.78251 },
  {
    town: "Barringtonsbridge",
    county: "Limerick",
    lat: 52.64481,
    lng: -8.47356,
  },
  { town: "Barry", county: "Longford", lat: 53.59672, lng: -7.76115 },
  { town: "Bartlemy", county: "Cork", lat: 52.0489, lng: -8.26426 },
  { town: "Batterjohn", county: "Meath", lat: 53.52641, lng: -6.67358 },
  { town: "Batterstown", county: "Meath", lat: 53.46852, lng: -6.53573 },
  { town: "Bawnboy", county: "Cavan", lat: 54.12124, lng: -7.67676 },
  { town: "Bealaclugga", county: "Clare", lat: 53.12075, lng: -9.07113 },
  { town: "Bealad", county: "Cork", lat: 51.6402, lng: -8.98996 },
  { town: "Bealadangan", county: "Galway", lat: 53.31371, lng: -9.61453 },
  { town: "Bealin", county: "Westmeath", lat: 53.43376, lng: -7.842 },
  { town: "Beaufort", county: "Kerry", lat: 52.06938, lng: -9.63334 },
  { town: "Beaumont", county: "Dublin", lat: 53.38569, lng: -6.22867 },
  { town: "Beaumont", county: "Meath", lat: 53.66567, lng: -6.3579 },
  { town: "Bective", county: "Meath", lat: 53.58022, lng: -6.69676 },
  { town: "Bekan", county: "Mayo", lat: 53.76277, lng: -8.85112 },
  { town: "Belclare", county: "Galway", lat: 53.4909, lng: -8.93373 },
  { town: "Belderg", county: "Mayo", lat: 54.29658, lng: -9.54737 },
  { town: "Belgooly", county: "Cork", lat: 51.7369, lng: -8.48159 },
  { town: "Bellacorick", county: "Mayo", lat: 54.11912, lng: -9.57855 },
  { town: "Bellanagare", county: "Roscommon", lat: 53.83699, lng: -8.38151 },
  { town: "Bellanamore", county: "Donegal", lat: 54.86583, lng: -8.04376 },
  { town: "Bellanamullia", county: "Roscommon", lat: 53.42694, lng: -8.01949 },
  { town: "Bellananagh", county: "Cavan", lat: 53.93309, lng: -7.40792 },
  { town: "Bellanode", county: "Monaghan", lat: 54.26692, lng: -7.03644 },
  { town: "Bellavary", county: "Mayo", lat: 53.89593, lng: -9.15139 },
  { town: "Bellewstown", county: "Meath", lat: 53.64387, lng: -6.34879 },
  { town: "Belmont", county: "Offaly", lat: 53.25607, lng: -7.89517 },
  { town: "Belmullet", county: "Mayo", lat: 54.2239, lng: -9.98761 },
  { town: "Beltra", county: "Sligo", lat: 54.22187, lng: -8.62483 },
  { town: "Belturbet", county: "Cavan", lat: 54.10191, lng: -7.44968 },
  { town: "Bennettsbridge", county: "Kilkenny", lat: 52.59166, lng: -7.18427 },
  { town: "Berrings", county: "Cork", lat: 51.93743, lng: -8.69743 },
  { town: "Bettystown", county: "Meath", lat: 53.69833, lng: -6.24607 },
  { town: "Bilboa", county: "Carlow", lat: 52.80054, lng: -7.03573 },
  { town: "Binghamstown", county: "Mayo", lat: 54.20638, lng: -10.03541 },
  { town: "Birdhill", county: "Tipperary", lat: 52.7675, lng: -8.43712 },
  { town: "Birr", county: "Offaly", lat: 53.098, lng: -7.9097 },
  { town: "Blacklion", county: "Cavan", lat: 54.29207, lng: -7.87512 },
  { town: "Blackrock", county: "Dublin", lat: 53.30079, lng: -6.17708 },
  { town: "Blackrock", county: "Louth", lat: 53.96804, lng: -6.36181 },
  { town: "Blacksod", county: "Mayo", lat: 54.10064, lng: -10.06886 },
  { town: "Blackwater", county: "Wexford", lat: 52.44736, lng: -6.34693 },
  { town: "Blackwater Bridge", county: "Kerry", lat: 51.85899, lng: -9.74251 },
  { town: "Blackwood", county: "Kildare", lat: 53.29316, lng: -6.81858 },
  { town: "Blanchardstown", county: "Dublin", lat: 53.38681, lng: -6.37721 },
  { town: "Blarney", county: "Cork", lat: 51.93436, lng: -8.56695 },
  { town: "Blennerville", county: "Kerry", lat: 52.25525, lng: -9.73634 },
  { town: "Blessington", county: "Wicklow", lat: 53.16966, lng: -6.53408 },
  { town: "Blue Ball", county: "Offaly", lat: 53.22995, lng: -7.61123 },
  { town: "Bluebell", county: "Dublin", lat: 53.32934, lng: -6.33486 },
  { town: "Bodyke", county: "Clare", lat: 52.88434, lng: -8.59671 },
  { town: "Bofeenaun", county: "Mayo", lat: 53.98664, lng: -9.32281 },
  { town: "Bohalas", county: "Roscommon", lat: 53.92137, lng: -8.6209 },
  { town: "Boheraphuca", county: "Offaly", lat: 53.01928, lng: -7.7594 },
  { town: "Boherboy", county: "Cork", lat: 52.15824, lng: -9.06848 },
  { town: "Boherlahan", county: "Tipperary", lat: 52.57302, lng: -7.89356 },
  { town: "Bohermeen", county: "Meath", lat: 53.6532, lng: -6.79031 },
  { town: "Bohola", county: "Mayo", lat: 53.90258, lng: -9.055 },
  { town: "Boleyvogue", county: "Wexford", lat: 52.55418, lng: -6.41824 },
  { town: "Booterstown", county: "Dublin", lat: 53.30882, lng: -6.19669 },
  { town: "Borris", county: "Carlow", lat: 52.59981, lng: -6.92308 },
  { town: "Borris In Ossory", county: "Laois", lat: 52.93918, lng: -7.6311 },
  { town: "Borrisokane", county: "Tipperary", lat: 52.99638, lng: -8.12733 },
  { town: "Borrisoleigh", county: "Tipperary", lat: 52.75252, lng: -7.95789 },
  { town: "Bouladuff", county: "Tipperary", lat: 52.71733, lng: -7.91119 },
  { town: "Boyle", county: "Roscommon", lat: 53.97221, lng: -8.29728 },
  { town: "Boyounagh", county: "Galway", lat: 53.62482, lng: -8.62228 },
  { town: "Braade", county: "Donegal", lat: 55.02885, lng: -8.34808 },
  { town: "Brackloon", county: "Mayo", lat: 53.78998, lng: -8.71522 },
  { town: "Bracklyn", county: "Westmeath", lat: 53.56835, lng: -7.10721 },
  { town: "Bracknagh", county: "Offaly", lat: 53.2092, lng: -7.09818 },
  { town: "Brandon", county: "Kerry", lat: 52.26641, lng: -10.16251 },
  { town: "Brandrum", county: "Monaghan", lat: 54.23838, lng: -7.02043 },
  { town: "Brannockstown", county: "Kildare", lat: 53.12267, lng: -6.69379 },
  { town: "Bray", county: "Wicklow", lat: 53.20091, lng: -6.11108 },
  { town: "Breaghwy", county: "Mayo", lat: 53.84711, lng: -9.23933 },
  { town: "Bree", county: "Wexford", lat: 52.43518, lng: -6.60871 },
  { town: "Brickeens", county: "Mayo", lat: 53.72277, lng: -8.87589 },
  { town: "Bridebridge", county: "Cork", lat: 52.07998, lng: -8.2281 },
  { town: "Brideswell", county: "Roscommon", lat: 53.45172, lng: -8.09065 },
  { town: "Bridge End", county: "Donegal", lat: 55.04224, lng: -7.38218 },
  { town: "Bridgetown", county: "Clare", lat: 52.76144, lng: -8.52636 },
  { town: "Bridgetown", county: "Donegal", lat: 54.59441, lng: -8.11231 },
  { town: "Bridgetown", county: "Wexford", lat: 52.23025, lng: -6.54906 },
  { town: "Brinlack", county: "Donegal", lat: 55.12974, lng: -8.2804 },
  { town: "Brittas", county: "Limerick", lat: 52.6108, lng: -8.42001 },
  { town: "Broadford", county: "Clare", lat: 52.80603, lng: -8.63286 },
  { town: "Broadford", county: "Kildare", lat: 53.42447, lng: -6.96068 },
  { town: "Broadford", county: "Limerick", lat: 52.34592, lng: -8.97362 },
  { town: "Broomfield", county: "Monaghan", lat: 54.06803, lng: -6.70208 },
  { town: "Brosna", county: "Kerry", lat: 52.31271, lng: -9.27071 },
  { town: "Brosna", county: "Offaly", lat: 52.99164, lng: -7.88778 },
  { town: "Brownsgrove", county: "Galway", lat: 53.57209, lng: -8.78644 },
  { town: "Bruckless", county: "Donegal", lat: 54.63999, lng: -8.38823 },
  { town: "Bruff", county: "Limerick", lat: 52.47559, lng: -8.54705 },
  { town: "Bruree", county: "Limerick", lat: 52.42428, lng: -8.66202 },
  { town: "Buckode", county: "Leitrim", lat: 54.42577, lng: -8.21326 },
  { town: "Bulgaden", county: "Limerick", lat: 52.42049, lng: -8.52959 },
  { town: "Bullaun", county: "Galway", lat: 53.24789, lng: -8.55435 },
  { town: "Bunacurry", county: "Mayo", lat: 53.97519, lng: -9.98578 },
  { town: "Bunane", county: "Kerry", lat: 51.81551, lng: -9.5384 },
  { town: "Bunbeg", county: "Donegal", lat: 55.06087, lng: -8.30035 },
  { town: "Bunbrosna", county: "Westmeath", lat: 53.60823, lng: -7.43498 },
  { town: "Bunclody", county: "Wexford", lat: 52.65508, lng: -6.65219 },
  { town: "Buncrana", county: "Donegal", lat: 55.13465, lng: -7.45344 },
  { town: "Bundoran", county: "Donegal", lat: 54.47914, lng: -8.27794 },
  { town: "Bunmahon", county: "Waterford", lat: 52.14055, lng: -7.3652 },
  { town: "Bunnahowen", county: "Mayo", lat: 54.19191, lng: -9.90541 },
  { town: "Bunnanaddan", county: "Sligo", lat: 54.053, lng: -8.60345 },
  { town: "Bunnyconnellan", county: "Mayo", lat: 54.10962, lng: -9.0263 },
  { town: "Bunratty", county: "Clare", lat: 52.69887, lng: -8.81113 },
  { town: "Burncourt", county: "Tipperary", lat: 52.31131, lng: -8.07922 },
  { town: "Burnfoot", county: "Donegal", lat: 55.05831, lng: -7.40494 },
  { town: "Burnfort", county: "Cork", lat: 52.06627, lng: -8.59954 },
  { town: "Burtonport", county: "Donegal", lat: 54.98453, lng: -8.43266 },
  { town: "Butlers Bridge", county: "Cavan", lat: 54.04521, lng: -7.37697 },
  { town: "Butlerstown", county: "Cork", lat: 51.59956, lng: -8.72822 },
  { town: "Buttevant", county: "Cork", lat: 52.23213, lng: -8.67013 },
  { town: "Bweeng", county: "Cork", lat: 52.0448, lng: -8.72315 },
  { town: "Cabinteely", county: "Dublin", lat: 53.26495, lng: -6.15956 },
  { town: "Cabra", county: "Dublin", lat: 53.3674, lng: -6.30234 },
  { town: "Cadamstown", county: "Kildare", lat: 53.39998, lng: -6.92023 },
  { town: "Cadamstown", county: "Offaly", lat: 53.12745, lng: -7.66026 },
  { town: "Caher", county: "Clare", lat: 52.96636, lng: -8.64784 },
  { town: "Caher", county: "Tipperary", lat: 52.37456, lng: -7.92517 },
  { town: "Caheragh", county: "Cork", lat: 51.63255, lng: -9.30652 },
  { town: "Caherconlish", county: "Limerick", lat: 52.59515, lng: -8.4723 },
  { town: "Caherdaniel", county: "Kerry", lat: 51.76901, lng: -10.09953 },
  { town: "Caherdavin", county: "Limerick", lat: 52.67258, lng: -8.66778 },
  { town: "Caherelly", county: "Limerick", lat: 52.54808, lng: -8.49782 },
  { town: "Caherlistrane", county: "Galway", lat: 53.49749, lng: -9.02038 },
  { town: "Cahersiveen", county: "Kerry", lat: 51.9478, lng: -10.2209 },
  { town: "Cahore Point", county: "Wexford", lat: 52.56512, lng: -6.20098 },
  { town: "Callan", county: "Kilkenny", lat: 52.54482, lng: -7.39204 },
  { town: "Callow", county: "Mayo", lat: 53.96749, lng: -9.03095 },
  { town: "Callow", county: "Roscommon", lat: 53.90409, lng: -8.43653 },
  { town: "Calry", county: "Sligo", lat: 54.28435, lng: -8.39445 },
  { town: "Caltra", county: "Galway", lat: 53.43529, lng: -8.43556 },
  { town: "Calverstown", county: "Kildare", lat: 53.08261, lng: -6.798 },
  { town: "Camolin", county: "Wexford", lat: 52.61568, lng: -6.42634 },
  { town: "Camp", county: "Kerry", lat: 52.22093, lng: -9.90891 },
  { town: "Campile", county: "Wexford", lat: 52.28766, lng: -6.94128 },
  { town: "Camross", county: "Laois", lat: 53.0034, lng: -7.59767 },
  { town: "Camus Oughter", county: "Galway", lat: 53.37312, lng: -9.55638 },
  { town: "Canningstown", county: "Cavan", lat: 53.98081, lng: -7.04742 },
  { town: "Cappagh White", county: "Tipperary", lat: 52.58222, lng: -8.16466 },
  { town: "Cappamore", county: "Limerick", lat: 52.61525, lng: -8.33689 },
  { town: "Capparoe", county: "Tipperary", lat: 52.82106, lng: -8.25423 },
  { town: "Cappataggle", county: "Galway", lat: 53.27615, lng: -8.40916 },
  { town: "Cappeen", county: "Cork", lat: 51.79492, lng: -8.99155 },
  { town: "Cappoquin", county: "Waterford", lat: 52.14627, lng: -7.84274 },
  { town: "Carbury", county: "Kildare", lat: 53.35605, lng: -6.96405 },
  { town: "Carlanstown", county: "Meath", lat: 53.75971, lng: -6.84136 },
  { town: "Carlingford", county: "Louth", lat: 54.04266, lng: -6.18695 },
  { town: "Carlow", county: "Carlow", lat: 52.83651, lng: -6.93414 },
  { town: "Carna", county: "Galway", lat: 53.32422, lng: -9.83611 },
  { town: "Carnaross", county: "Meath", lat: 53.75066, lng: -6.95509 },
  { town: "Carnboy", county: "Donegal", lat: 55.05715, lng: -8.34044 },
  { town: "Carndonagh", county: "Donegal", lat: 55.25062, lng: -7.26689 },
  { town: "Carnew", county: "Wicklow", lat: 52.70967, lng: -6.49924 },
  { town: "Carney", county: "Sligo", lat: 54.33867, lng: -8.52771 },
  { town: "Carpenterstown", county: "Dublin", lat: 53.37252, lng: -6.38848 },
  { town: "Carracastle", county: "Mayo", lat: 53.95105, lng: -8.6959 },
  { town: "Carragh", county: "Kildare", lat: 53.23737, lng: -6.72908 },
  { town: "Carraholly", county: "Mayo", lat: 53.81431, lng: -9.59257 },
  { town: "Carran", county: "Clare", lat: 53.0376, lng: -9.07605 },
  { town: "Carraroe", county: "Galway", lat: 53.2641, lng: -9.59826 },
  { town: "Carrick", county: "Donegal", lat: 54.65773, lng: -8.63276 },
  { town: "Carrick", county: "Donegal", lat: 55.10454, lng: -8.28606 },
  { town: "Carrick", county: "Wexford", lat: 52.23949, lng: -6.73182 },
  { town: "Carrickaboy", county: "Cavan", lat: 53.9316, lng: -7.32154 },
  { town: "Carrickart", county: "Donegal", lat: 55.17755, lng: -7.79328 },
  { town: "Carrickboy", county: "Longford", lat: 53.63237, lng: -7.68623 },
  { town: "Carrickfin", county: "Donegal", lat: 55.05404, lng: -8.33091 },
  { town: "Carrickmacross", county: "Monaghan", lat: 53.97797, lng: -6.71883 },
  { town: "Carrickmines", county: "Dublin", lat: 53.25008, lng: -6.17956 },
  {
    town: "Carrick-On-Shannon",
    county: "Leitrim",
    lat: 53.94519,
    lng: -8.08553,
  },
  {
    town: "Carrick-On-Suir",
    county: "Tipperary",
    lat: 52.34765,
    lng: -7.41897,
  },
  { town: "Carrickroe", county: "Monaghan", lat: 54.35773, lng: -7.02752 },
  { town: "Carrig", county: "Tipperary", lat: 53.06242, lng: -7.97286 },
  { town: "Carrigadrohid", county: "Cork", lat: 51.90259, lng: -8.86106 },
  { town: "Carrigaholt", county: "Clare", lat: 52.60479, lng: -9.70966 },
  { town: "Carrigahorig", county: "Tipperary", lat: 53.0665, lng: -8.14498 },
  { town: "Carrigaline", county: "Cork", lat: 51.81791, lng: -8.39149 },
  { town: "Carrigallen", county: "Leitrim", lat: 53.97694, lng: -7.64433 },
  { town: "Carrigan", county: "Cavan", lat: 53.8888, lng: -7.40379 },
  { town: "Carriganimmy", county: "Cork", lat: 51.97819, lng: -9.0361 },
  { town: "Carrigans", county: "Donegal", lat: 54.95122, lng: -7.42828 },
  { town: "Carrigatogher", county: "Tipperary", lat: 52.83548, lng: -8.27571 },
  { town: "Carrigkerry", county: "Limerick", lat: 52.4947, lng: -9.14849 },
  { town: "Carrignavar", county: "Cork", lat: 51.98911, lng: -8.47793 },
  { town: "Carrigrohane", county: "Cork", lat: 51.89563, lng: -8.56343 },
  { town: "Carrigtohill", county: "Cork", lat: 51.90949, lng: -8.25894 },
  { town: "Carrowbeg", county: "Mayo", lat: 53.90516, lng: -9.63415 },
  { town: "Carrowbehy", county: "Roscommon", lat: 53.81233, lng: -8.6666 },
  { town: "Carrowcannon", county: "Donegal", lat: 55.14053, lng: -8.07486 },
  { town: "Carrowkeel", county: "Donegal", lat: 55.13771, lng: -7.66387 },
  { town: "Carrowmenagh", county: "Donegal", lat: 55.26026, lng: -7.06855 },
  { town: "Carrowmoreknock", county: "Galway", lat: 53.41304, lng: -9.17934 },
  { town: "Carrowneden", county: "Sligo", lat: 54.1372, lng: -8.69759 },
  { town: "Carrownisky", county: "Mayo", lat: 53.7325, lng: -9.86556 },
  { town: "Carrowteige", county: "Mayo", lat: 54.31311, lng: -9.8127 },
  { town: "Cartron", county: "Galway", lat: 53.23706, lng: -9.49293 },
  { town: "Cashel", county: "Tipperary", lat: 52.51676, lng: -7.88767 },
  { town: "Cashelgarran", county: "Sligo", lat: 54.35989, lng: -8.52249 },
  { town: "Cashel Glebe", county: "Donegal", lat: 55.23334, lng: -7.73283 },
  { town: "Castlebaldwin", county: "Sligo", lat: 54.07925, lng: -8.37589 },
  { town: "Castlebane", county: "Donegal", lat: 55.16755, lng: -8.00413 },
  { town: "Castlebar", county: "Mayo", lat: 53.855, lng: -9.28793 },
  { town: "Castlebellingham", county: "Louth", lat: 53.89786, lng: -6.38944 },
  { town: "Castleblakeney", county: "Galway", lat: 53.42943, lng: -8.47473 },
  { town: "Castleblayney", county: "Monaghan", lat: 54.12066, lng: -6.73973 },
  { town: "Castlebridge", county: "Wexford", lat: 52.38243, lng: -6.4503 },
  { town: "Castlecomer", county: "Kilkenny", lat: 52.80547, lng: -7.20955 },
  { town: "Castleconnell", county: "Limerick", lat: 52.71565, lng: -8.49324 },
  { town: "Castlecoote", county: "Roscommon", lat: 53.61327, lng: -8.29051 },
  { town: "Castlecor", county: "Cork", lat: 52.20518, lng: -8.80267 },
  { town: "Castle Cove", county: "Kerry", lat: 51.77912, lng: -10.03675 },
  { town: "Castledermot", county: "Kildare", lat: 52.91188, lng: -6.83758 },
  { town: "Castlefinn", county: "Donegal", lat: 54.803, lng: -7.58958 },
  { town: "Castlefreke", county: "Cork", lat: 51.57303, lng: -8.96373 },
  { town: "Castlegregory", county: "Kerry", lat: 52.25347, lng: -10.02072 },
  { town: "Castlehill", county: "Mayo", lat: 54.05559, lng: -9.33714 },
  { town: "Castleisland", county: "Kerry", lat: 52.2329, lng: -9.46015 },
  { town: "Castlejordan", county: "Meath", lat: 53.39735, lng: -7.1186 },
  { town: "Castleknock", county: "Dublin", lat: 53.37365, lng: -6.35842 },
  { town: "Castleleiny", county: "Tipperary", lat: 52.78623, lng: -7.78897 },
  { town: "Castlelyons", county: "Cork", lat: 52.08857, lng: -8.23369 },
  { town: "Castlemaine", county: "Kerry", lat: 52.16843, lng: -9.70147 },
  { town: "Castlemartyr", county: "Cork", lat: 51.91195, lng: -8.05355 },
  { town: "Castleplunket", county: "Roscommon", lat: 53.75023, lng: -8.3442 },
  { town: "Castlepollard", county: "Westmeath", lat: 53.68007, lng: -7.29645 },
  { town: "Castlequarter", county: "Galway", lat: 53.37771, lng: -9.01593 },
  { town: "Castlerea", county: "Roscommon", lat: 53.76866, lng: -8.49246 },
  { town: "Castleshane", county: "Monaghan", lat: 54.23662, lng: -6.87756 },
  { town: "Castletown", county: "Cork", lat: 51.76928, lng: -8.95366 },
  { town: "Castletown", county: "Laois", lat: 52.97747, lng: -7.49957 },
  { town: "Castletown", county: "Limerick", lat: 52.42398, lng: -8.81243 },
  { town: "Castletown", county: "Meath", lat: 53.78016, lng: -6.71407 },
  { town: "Castletown", county: "Westmeath", lat: 53.75459, lng: -7.3313 },
  { town: "Castletown", county: "Wexford", lat: 52.71942, lng: -6.18337 },
  {
    town: "Castletown Bearhaven",
    county: "Cork",
    lat: 51.65149,
    lng: -9.91034,
  },
  {
    town: "Castletown Geoghegan",
    county: "Westmeath",
    lat: 53.44628,
    lng: -7.48762,
  },
  { town: "Castletownroche", county: "Cork", lat: 52.17312, lng: -8.46345 },
  { town: "Castletownshend", county: "Cork", lat: 51.52657, lng: -9.17939 },
  { town: "Castletroy", county: "Limerick", lat: 52.67168, lng: -8.55325 },
  { town: "Castlewarren", county: "Kilkenny", lat: 52.69231, lng: -7.10587 },
  { town: "Causeway", county: "Kerry", lat: 52.41442, lng: -9.73307 },
  { town: "Cavan", county: "Cavan", lat: 53.9906, lng: -7.36294 },
  { town: "Cecilstown", county: "Cork", lat: 52.16896, lng: -8.77564 },
  { town: "Celbridge", county: "Kildare", lat: 53.3399, lng: -6.53846 },
  { town: "Chaffpool", county: "Sligo", lat: 54.04631, lng: -8.6813 },
  { town: "Chapelizod", county: "Dublin", lat: 53.34923, lng: -6.34386 },
  { town: "Chapeltown", county: "Kerry", lat: 51.90755, lng: -10.33494 },
  { town: "Charlestown", county: "Mayo", lat: 53.96403, lng: -8.79261 },
  { town: "Charleville", county: "Cork", lat: 52.35491, lng: -8.68318 },
  { town: "Church Cross", county: "Cork", lat: 51.54199, lng: -9.3522 },
  { town: "Church Hill", county: "Donegal", lat: 54.98932, lng: -7.89153 },
  { town: "Churchstreet", county: "Roscommon", lat: 53.8819, lng: -8.45003 },
  { town: "Churchtown", county: "Cork", lat: 51.82658, lng: -8.07928 },
  { town: "Churchtown", county: "Cork", lat: 52.2713, lng: -8.73086 },
  { town: "Churchtown", county: "Dublin", lat: 53.29361, lng: -6.24725 },
  { town: "Churchtown", county: "Wexford", lat: 52.13465, lng: -6.92586 },
  { town: "Church Town", county: "Donegal", lat: 54.95897, lng: -7.47284 },
  { town: "Claddaghduff", county: "Galway", lat: 53.5435, lng: -10.13818 },
  { town: "Clane", county: "Kildare", lat: 53.29379, lng: -6.68704 },
  { town: "Clara", county: "Offaly", lat: 53.34251, lng: -7.61349 },
  { town: "Clarecastle", county: "Clare", lat: 52.81474, lng: -8.96425 },
  { town: "Clareen", county: "Offaly", lat: 53.07343, lng: -7.79509 },
  { town: "Claregalway", county: "Galway", lat: 53.34091, lng: -8.94568 },
  { town: "Clare Island", county: "Mayo", lat: 53.80685, lng: -9.99067 },
  { town: "Claremorris", county: "Mayo", lat: 53.72387, lng: -9.00174 },
  { town: "Clarina", county: "Limerick", lat: 52.63318, lng: -8.72683 },
  { town: "Clashmore", county: "Waterford", lat: 52.00936, lng: -7.81887 },
  { town: "Cleggan", county: "Galway", lat: 53.5566, lng: -10.11219 },
  { town: "Cleristown", county: "Wexford", lat: 52.2762, lng: -6.59441 },
  { town: "Clifden", county: "Galway", lat: 53.48899, lng: -10.01792 },
  { town: "Clifferna", county: "Cavan", lat: 53.95948, lng: -7.17747 },
  { town: "Cliffony", county: "Sligo", lat: 54.43056, lng: -8.45343 },
  { town: "Clogh", county: "Kilkenny", lat: 52.86075, lng: -7.16326 },
  { town: "Clogh", county: "Laois", lat: 52.88682, lng: -7.50984 },
  { town: "Clogh", county: "Wexford", lat: 52.64247, lng: -6.34279 },
  { town: "Cloghan", county: "Donegal", lat: 54.84103, lng: -7.93384 },
  { town: "Cloghan", county: "Offaly", lat: 53.22524, lng: -7.88252 },
  { town: "Cloghane", county: "Kerry", lat: 52.23048, lng: -10.18244 },
  { town: "Cloghans", county: "Mayo", lat: 54.07058, lng: -9.21675 },
  { town: "Cloghans Hill", county: "Mayo", lat: 53.58884, lng: -9.01924 },
  { town: "Clogharinka", county: "Kilkenny", lat: 52.75943, lng: -7.17549 },
  { town: "Cloghboley", county: "Sligo", lat: 54.34759, lng: -8.60666 },
  { town: "Cloghbrack", county: "Mayo", lat: 54.00097, lng: -9.32152 },
  { town: "Clogheen", county: "Tipperary", lat: 52.27708, lng: -7.99511 },
  { town: "Clogher", county: "Mayo", lat: 53.83832, lng: -9.46839 },
  { town: "Clogher", county: "Mayo", lat: 54.1301, lng: -10.09025 },
  { town: "Clogherhead", county: "Louth", lat: 53.79168, lng: -6.23885 },
  { town: "Cloghjordan", county: "Tipperary", lat: 52.94295, lng: -8.03834 },
  { town: "Clohamon", county: "Wexford", lat: 52.63694, lng: -6.62134 },
  { town: "Clonakenny", county: "Tipperary", lat: 52.87474, lng: -7.833 },
  { town: "Clonakilty", county: "Cork", lat: 51.62151, lng: -8.8887 },
  { town: "Clonalvy", county: "Meath", lat: 53.58783, lng: -6.34269 },
  { town: "Clonard", county: "Meath", lat: 53.45153, lng: -7.02311 },
  { town: "Clonaslee", county: "Laois", lat: 53.1486, lng: -7.52437 },
  { town: "Clonbern", county: "Galway", lat: 53.56077, lng: -8.64789 },
  { town: "Clonbulloge", county: "Offaly", lat: 53.26068, lng: -7.08644 },
  { town: "Clonbur", county: "Galway", lat: 53.54517, lng: -9.36437 },
  { town: "Cloncurry", county: "Kildare", lat: 53.41535, lng: -6.79193 },
  { town: "Clondalkin", county: "Dublin", lat: 53.32174, lng: -6.39521 },
  { town: "Clondrohid", county: "Cork", lat: 51.92924, lng: -9.02277 },
  { town: "Clondulane", county: "Cork", lat: 52.14216, lng: -8.22033 },
  { town: "Clonea", county: "Waterford", lat: 52.27948, lng: -7.43852 },
  { town: "Clonee", county: "Meath", lat: 53.41056, lng: -6.44261 },
  { town: "Cloneen", county: "Tipperary", lat: 52.47552, lng: -7.60187 },
  { town: "Clonegall", county: "Carlow", lat: 52.6948, lng: -6.64773 },
  { town: "Clones", county: "Monaghan", lat: 54.17968, lng: -7.23068 },
  { town: "Clonfert", county: "Galway", lat: 53.22588, lng: -8.06185 },
  { town: "Clonfinlough", county: "Offaly", lat: 53.31663, lng: -7.92378 },
  { town: "Clonmacnoise", county: "Offaly", lat: 53.32328, lng: -7.98137 },
  { town: "Clonmany", county: "Donegal", lat: 55.26281, lng: -7.41161 },
  { town: "Clonmel", county: "Tipperary", lat: 52.35582, lng: -7.69025 },
  { town: "Clonmellon", county: "Westmeath", lat: 53.66407, lng: -7.0184 },
  { town: "Clonmore", county: "Carlow", lat: 52.82633, lng: -6.5693 },
  { town: "Clonmult", county: "Cork", lat: 51.9933, lng: -8.10643 },
  { town: "Clonoulty", county: "Tipperary", lat: 52.60428, lng: -7.95855 },
  { town: "Clonroche", county: "Wexford", lat: 52.44967, lng: -6.71776 },
  { town: "Clonsilla", county: "Dublin", lat: 53.38307, lng: -6.41032 },
  { town: "Clonskeagh", county: "Dublin", lat: 53.31603, lng: -6.23747 },
  { town: "Clontarf", county: "Dublin", lat: 53.36602, lng: -6.20451 },
  { town: "Clontibret", county: "Monaghan", lat: 54.20544, lng: -6.84154 },
  { town: "Clontuskert", county: "Galway", lat: 53.27425, lng: -8.24461 },
  { town: "Clonygowan", county: "Offaly", lat: 53.18745, lng: -7.28045 },
  { town: "Cloonacool", county: "Sligo", lat: 54.10075, lng: -8.77786 },
  { town: "Cloonagh", county: "Longford", lat: 53.83462, lng: -7.51973 },
  { town: "Cloondaff", county: "Mayo", lat: 53.93901, lng: -9.44146 },
  { town: "Cloondara", county: "Longford", lat: 53.7311, lng: -7.90439 },
  { town: "Cloone", county: "Leitrim", lat: 53.94588, lng: -7.78534 },
  { town: "Clooney", county: "Donegal", lat: 54.83826, lng: -8.41095 },
  { town: "Cloonfad", county: "Roscommon", lat: 53.68675, lng: -8.75182 },
  { town: "Cloonfallagh", county: "Mayo", lat: 53.84986, lng: -8.92667 },
  { town: "Cloonlara", county: "Clare", lat: 52.72471, lng: -8.55462 },
  { town: "Cloonloogh", county: "Sligo", lat: 53.97411, lng: -8.41185 },
  { town: "Cloonminda", county: "Galway", lat: 53.64177, lng: -8.53545 },
  { town: "Cloonmore", county: "Mayo", lat: 53.97574, lng: -8.71934 },
  { town: "Cloontia", county: "Mayo", lat: 53.97761, lng: -8.61849 },
  { town: "Cloonyquin", county: "Roscommon", lat: 53.8049, lng: -8.23161 },
  { town: "Cloughduv", county: "Cork", lat: 51.84732, lng: -8.788 },
  { town: "Cloverhill", county: "Cavan", lat: 54.08037, lng: -7.37011 },
  { town: "Cloyne", county: "Cork", lat: 51.8631, lng: -8.12123 },
  { town: "Coachford", county: "Cork", lat: 51.90947, lng: -8.78896 },
  { town: "Coalbrook", county: "Tipperary", lat: 52.61127, lng: -7.57907 },
  { town: "Coan", county: "Kilkenny", lat: 52.7863, lng: -7.11927 },
  { town: "Cobh", county: "Cork", lat: 51.85033, lng: -8.29428 },
  { town: "Colehill", county: "Longford", lat: 53.59806, lng: -7.67962 },
  { town: "Colgagh", county: "Sligo", lat: 54.2831, lng: -8.40519 },
  { town: "Collinstown", county: "Westmeath", lat: 53.64747, lng: -7.21216 },
  { town: "Collon", county: "Louth", lat: 53.77774, lng: -6.48411 },
  { town: "Collooney", county: "Sligo", lat: 54.18495, lng: -8.48656 },
  { town: "Colmanstown", county: "Galway", lat: 53.39363, lng: -8.61921 },
  { town: "Commeen", county: "Donegal", lat: 54.81777, lng: -7.97719 },
  { town: "Commons", county: "Tipperary", lat: 52.62595, lng: -7.54556 },
  { town: "Cong", county: "Mayo", lat: 53.54079, lng: -9.28538 },
  { town: "Conna", county: "Cork", lat: 52.09311, lng: -8.10735 },
  { town: "Connolly", county: "Clare", lat: 52.83064, lng: -9.18345 },
  { town: "Convoy", county: "Donegal", lat: 54.85973, lng: -7.66545 },
  { town: "Coolagherty", county: "Longford", lat: 53.74472, lng: -7.56025 },
  { town: "Coolaney", county: "Sligo", lat: 54.17181, lng: -8.60441 },
  { town: "Coolbaun", county: "Kilkenny", lat: 52.81881, lng: -7.1779 },
  { town: "Coolbaun", county: "Tipperary", lat: 52.9817, lng: -8.2484 },
  { town: "Coolboy", county: "Donegal", lat: 55.00439, lng: -7.73837 },
  { town: "Coolboy", county: "Wicklow", lat: 52.76204, lng: -6.46278 },
  { town: "Coolderry", county: "Monaghan", lat: 53.91025, lng: -6.69965 },
  { town: "Coole", county: "Westmeath", lat: 53.69926, lng: -7.36666 },
  { town: "Coolea", county: "Cork", lat: 51.92828, lng: -9.2178 },
  { town: "Coolgreany", county: "Wexford", lat: 52.76471, lng: -6.23602 },
  { town: "Coolkenna", county: "Wicklow", lat: 52.78061, lng: -6.60601 },
  { town: "Coollegreane", county: "Leitrim", lat: 54.21712, lng: -8.04558 },
  { town: "Coolmeen", county: "Clare", lat: 52.65946, lng: -9.21409 },
  { town: "Coolmore", county: "Donegal", lat: 54.54413, lng: -8.21367 },
  { town: "Coolock", county: "Dublin", lat: 53.38823, lng: -6.19922 },
  { town: "Coolquoy", county: "Dublin", lat: 53.45884, lng: -6.34906 },
  { town: "Coolrain", county: "Laois", lat: 52.97741, lng: -7.56584 },
  { town: "Cooraclare", county: "Clare", lat: 52.70272, lng: -9.41841 },
  { town: "Cootehall", county: "Roscommon", lat: 53.98417, lng: -8.15801 },
  { town: "Cootehill", county: "Cavan", lat: 54.07373, lng: -7.07574 },
  { town: "Corbally", county: "Sligo", lat: 54.17008, lng: -9.06218 },
  { town: "Corcullionglish", county: "Monaghan", lat: 54.05194, lng: -6.65482 },
  { town: "Cordal", county: "Kerry", lat: 52.21806, lng: -9.38496 },
  { town: "Corduff", county: "Dublin", lat: 53.4012, lng: -6.37789 },
  { town: "Corduff", county: "Monaghan", lat: 54.01201, lng: -6.82074 },
  { town: "Cork", county: "Cork", lat: 51.89689, lng: -8.4863 },
  { town: "Corlismore", county: "Cavan", lat: 53.92761, lng: -7.46729 },
  { town: "Corlough", county: "Cavan", lat: 54.12602, lng: -7.76812 },
  { town: "Cornafean", county: "Cavan", lat: 53.94743, lng: -7.48625 },
  { town: "Cornafulla", county: "Roscommon", lat: 53.37583, lng: -8.00534 },
  { town: "Cornamona", county: "Galway", lat: 53.51458, lng: -9.4488 },
  { town: "Cornelscourt", county: "Dublin", lat: 53.27038, lng: -6.16699 },
  { town: "Corrandulla", county: "Galway", lat: 53.39916, lng: -8.9958 },
  { town: "Corrawaleen", county: "Leitrim", lat: 54.01073, lng: -7.71009 },
  { town: "Correllstown", county: "Westmeath", lat: 53.48114, lng: -7.17117 },
  { town: "Corries Cross", county: "Carlow", lat: 52.65852, lng: -6.90054 },
  { town: "Corriga", county: "Leitrim", lat: 53.93909, lng: -7.69084 },
  { town: "Corrofin", county: "Clare", lat: 52.94482, lng: -9.06409 },
  { town: "Corrofin", county: "Galway", lat: 53.43756, lng: -8.86028 },
  { town: "Corroy", county: "Mayo", lat: 54.06461, lng: -9.17594 },
  { town: "Corry", county: "Leitrim", lat: 54.17047, lng: -8.0655 },
  { town: "Cortober", county: "Monaghan", lat: 54.03928, lng: -6.92347 },
  { town: "Cortown", county: "Meath", lat: 53.68542, lng: -6.8499 },
  { town: "Costelloe", county: "Galway", lat: 53.28794, lng: -9.54996 },
  { town: "Courtmacsherry", county: "Cork", lat: 51.63386, lng: -8.70593 },
  { town: "Courtown", county: "Wexford", lat: 52.64486, lng: -6.22863 },
  { town: "Craggagh", county: "Clare", lat: 53.1015, lng: -9.29714 },
  { town: "Crannogeboy", county: "Donegal", lat: 54.77423, lng: -8.48913 },
  { town: "Cranny", county: "Clare", lat: 52.69248, lng: -9.22548 },
  { town: "Cratloe", county: "Clare", lat: 52.69968, lng: -8.76655 },
  { town: "Craughwell", county: "Galway", lat: 53.23027, lng: -8.73478 },
  { town: "Creaghanroe", county: "Monaghan", lat: 54.17212, lng: -6.73794 },
  { town: "Crecora", county: "Limerick", lat: 52.57631, lng: -8.66909 },
  { town: "Creegh", county: "Clare", lat: 52.74634, lng: -9.43008 },
  { town: "Creeslough", county: "Donegal", lat: 55.12335, lng: -7.90946 },
  { town: "Creeve", county: "Monaghan", lat: 54.0982, lng: -6.90711 },
  { town: "Cregganbaun", county: "Mayo", lat: 53.70278, lng: -9.80825 },
  { town: "Creggs", county: "Galway", lat: 53.59379, lng: -8.36167 },
  { town: "Crettyard", county: "Laois", lat: 52.84217, lng: -7.12978 },
  { town: "Crinkill", county: "Offaly", lat: 53.07527, lng: -7.90412 },
  { town: "Croagh", county: "Donegal", lat: 54.68483, lng: -8.33474 },
  { town: "Croagh", county: "Limerick", lat: 52.53527, lng: -8.87116 },
  { town: "Croghan", county: "Roscommon", lat: 53.91159, lng: -8.22072 },
  { town: "Crolly", county: "Donegal", lat: 55.02725, lng: -8.26061 },
  { town: "Cromane", county: "Kerry", lat: 52.10612, lng: -9.89854 },
  { town: "Crookedwood", county: "Westmeath", lat: 53.60268, lng: -7.29281 },
  { town: "Crookhaven", county: "Cork", lat: 51.4684, lng: -9.72605 },
  { town: "Crookstown", county: "Cork", lat: 51.84243, lng: -8.83042 },
  { town: "Croom", county: "Limerick", lat: 52.51895, lng: -8.71818 },
  { town: "Cross", county: "Clare", lat: 52.59862, lng: -9.77473 },
  { town: "Cross", county: "Mayo", lat: 53.54107, lng: -9.21752 },
  { town: "Crossakeel", county: "Meath", lat: 53.71387, lng: -7.02136 },
  { town: "Cross Barry", county: "Cork", lat: 51.80205, lng: -8.64536 },
  { town: "Crossconnell", county: "Donegal", lat: 55.27883, lng: -7.44502 },
  { town: "Crossdoney", county: "Cavan", lat: 53.95427, lng: -7.43363 },
  { town: "Crosshaven", county: "Cork", lat: 51.80347, lng: -8.3016 },
  { town: "Cross Keys", county: "Cavan", lat: 53.91896, lng: -7.26483 },
  { town: "Crossmolina", county: "Mayo", lat: 54.10034, lng: -9.31584 },
  { town: "Crosspatrick", county: "Kilkenny", lat: 52.7722, lng: -7.60073 },
  { town: "Crumlin", county: "Dublin", lat: 53.32848, lng: -6.30509 },
  { town: "Crusheen", county: "Clare", lat: 52.93865, lng: -8.89676 },
  { town: "Cuilmore", county: "Mayo", lat: 53.95105, lng: -8.87824 },
  { town: "Culdaff", county: "Donegal", lat: 55.28594, lng: -7.16513 },
  { town: "Culfadda", county: "Sligo", lat: 54.0274, lng: -8.47834 },
  { town: "Culkeeny", county: "Donegal", lat: 55.31574, lng: -7.2026 },
  { town: "Cullahill", county: "Laois", lat: 52.81869, lng: -7.47469 },
  { town: "Culleens", county: "Sligo", lat: 54.21182, lng: -8.9917 },
  { town: "Cullen", county: "Cork", lat: 52.11186, lng: -9.1211 },
  { town: "Cullen", county: "Tipperary", lat: 52.51077, lng: -8.27511 },
  { town: "Currabeha", county: "Cork", lat: 52.12137, lng: -8.12712 },
  { town: "Curracloe", county: "Wexford", lat: 52.39319, lng: -6.39213 },
  { town: "Curraghboy", county: "Roscommon", lat: 53.48469, lng: -8.11198 },
  { town: "Curragh Camp", county: "Kildare", lat: 53.14745, lng: -6.82997 },
  { town: "Curraghroe", county: "Roscommon", lat: 53.72463, lng: -8.02753 },
  { town: "Curragh West", county: "Galway", lat: 53.65222, lng: -8.66286 },
  { town: "Curraglass", county: "Cork", lat: 52.09407, lng: -8.04658 },
  { town: "Curragunneen", county: "Tipperary", lat: 52.88792, lng: -7.78526 },
  { town: "Curreeny", county: "Tipperary", lat: 52.72762, lng: -8.14571 },
  { town: "Currow", county: "Kerry", lat: 52.18493, lng: -9.49631 },
  { town: "Curry", county: "Sligo", lat: 54.00139, lng: -8.77299 },
  { town: "Cut Bush", county: "Kildare", lat: 53.12372, lng: -6.8326 },
  { town: "Daingean", county: "Offaly", lat: 53.29608, lng: -7.29198 },
  { town: "Dalkey", county: "Dublin", lat: 53.27837, lng: -6.10729 },
  { town: "Dalystown", county: "Westmeath", lat: 53.43711, lng: -7.38254 },
  { town: "Darndale", county: "Dublin", lat: 53.39831, lng: -6.19231 },
  { town: "Dartrey", county: "Monaghan", lat: 54.11034, lng: -7.06357 },
  { town: "Davidstown", county: "Wexford", lat: 52.47804, lng: -6.63464 },
  { town: "Deansgrange", county: "Dublin", lat: 53.28612, lng: -6.16737 },
  { town: "Delgany", county: "Wicklow", lat: 53.13261, lng: -6.09234 },
  { town: "Delvin", county: "Westmeath", lat: 53.61256, lng: -7.09172 },
  { town: "Dernagree", county: "Cork", lat: 52.12109, lng: -9.03434 },
  { town: "Derradda", county: "Leitrim", lat: 54.09986, lng: -7.78748 },
  { town: "Derragh", county: "Clare", lat: 52.79444, lng: -9.06133 },
  { town: "Derreen", county: "Galway", lat: 53.45067, lng: -8.70336 },
  { town: "Derreen", county: "Mayo", lat: 53.90175, lng: -9.9405 },
  { town: "Derreenargan", county: "Roscommon", lat: 54.02375, lng: -8.11456 },
  { town: "Derrinturn", county: "Kildare", lat: 53.33972, lng: -6.94043 },
  { town: "Derrybeg", county: "Donegal", lat: 55.07435, lng: -8.29419 },
  { town: "Derryilan", county: "Monaghan", lat: 54.05879, lng: -6.69667 },
  { town: "Derrynacaheragh", county: "Cork", lat: 51.75071, lng: -9.17546 },
  { town: "Derrynacreeve", county: "Cavan", lat: 54.15769, lng: -7.71789 },
  { town: "Derryvohy", county: "Mayo", lat: 53.8185, lng: -9.06707 },
  { town: "Dingle", county: "Kerry", lat: 52.13876, lng: -10.27053 },
  { town: "Doagh", county: "Donegal", lat: 55.22312, lng: -7.85586 },
  { town: "Doagh Beg", county: "Donegal", lat: 55.25114, lng: -7.62744 },
  { town: "Dolla", county: "Tipperary", lat: 52.79794, lng: -8.18868 },
  { town: "Dollymount", county: "Dublin", lat: 53.36598, lng: -6.17425 },
  { town: "Dolphins Barn", county: "Dublin", lat: 53.33354, lng: -6.29147 },
  { town: "Donabate", county: "Dublin", lat: 53.48534, lng: -6.15046 },
  { town: "Donaghmede", county: "Dublin", lat: 53.39864, lng: -6.16165 },
  { town: "Donaghmore", county: "Laois", lat: 52.87251, lng: -7.60215 },
  { town: "Donaghmore", county: "Meath", lat: 53.67201, lng: -6.65902 },
  { town: "Donaghmoyne", county: "Monaghan", lat: 54.00252, lng: -6.69278 },
  { town: "Donaghpatrick", county: "Meath", lat: 53.69634, lng: -6.75903 },
  { town: "Donard", county: "Wicklow", lat: 53.02223, lng: -6.61311 },
  { town: "Donegal", county: "Donegal", lat: 54.65468, lng: -8.10414 },
  { town: "Doneraile", county: "Cork", lat: 52.21518, lng: -8.58598 },
  { town: "Donnybrook", county: "Dublin", lat: 53.31926, lng: -6.23202 },
  { town: "Donnycarney", county: "Dublin", lat: 53.37551, lng: -6.21878 },
  { town: "Donohill", county: "Tipperary", lat: 52.53829, lng: -8.14135 },
  { town: "Donore", county: "Meath", lat: 53.69211, lng: -6.41726 },
  { town: "Donoughmore", county: "Cork", lat: 51.98963, lng: -8.74221 },
  { town: "Dooagh", county: "Mayo", lat: 53.97496, lng: -10.12667 },
  { town: "Doocastle", county: "Mayo", lat: 54.02011, lng: -8.63708 },
  { town: "Doocharry", county: "Donegal", lat: 54.90565, lng: -8.2066 },
  { town: "Dooega", county: "Mayo", lat: 53.92482, lng: -10.02062 },
  { town: "Doogary", county: "Cavan", lat: 54.0377, lng: -7.62871 },
  { town: "Doogort", county: "Mayo", lat: 54.01367, lng: -10.01551 },
  { town: "Doohamlat", county: "Monaghan", lat: 54.12874, lng: -6.82399 },
  { town: "Doohat", county: "Monaghan", lat: 54.12805, lng: -7.13211 },
  { town: "Doohooma", county: "Mayo", lat: 54.07367, lng: -9.93118 },
  { town: "Dooleeg", county: "Mayo", lat: 54.1058, lng: -9.49254 },
  { town: "Doolin", county: "Clare", lat: 53.01744, lng: -9.37304 },
  { town: "Doon", county: "Limerick", lat: 52.60433, lng: -8.24784 },
  { town: "Doonaha", county: "Clare", lat: 52.62343, lng: -9.64643 },
  { town: "Doonbeg", county: "Clare", lat: 52.73085, lng: -9.52599 },
  { town: "Doonreaghan", county: "Galway", lat: 53.41929, lng: -9.82253 },
  { town: "Dooradoyle", county: "Limerick", lat: 52.63859, lng: -8.65308 },
  { town: "Doorus", county: "Galway", lat: 53.17002, lng: -8.97885 },
  { town: "Dore", county: "Donegal", lat: 55.04377, lng: -8.27135 },
  { town: "Dovea", county: "Tipperary", lat: 52.71959, lng: -7.85858 },
  { town: "Downies", county: "Donegal", lat: 55.19486, lng: -7.83696 },
  { town: "Dowra", county: "Cavan", lat: 54.19008, lng: -8.01627 },
  { town: "Dowth", county: "Meath", lat: 53.70242, lng: -6.4632 },
  { town: "Drangan", county: "Tipperary", lat: 52.51691, lng: -7.5841 },
  { town: "Drimnagh", county: "Dublin", lat: 53.32998, lng: -6.32344 },
  { town: "Drimoleague", county: "Cork", lat: 51.65994, lng: -9.26124 },
  { town: "Drinagh", county: "Cork", lat: 51.6514, lng: -9.14715 },
  { town: "Dring", county: "Longford", lat: 53.81827, lng: -7.54303 },
  { town: "Dripsey", county: "Cork", lat: 51.91438, lng: -8.74209 },
  { town: "Drishane", county: "Tipperary", lat: 52.5199, lng: -8.08215 },
  { town: "Drogheda", county: "Louth", lat: 53.71785, lng: -6.35609 },
  { town: "Drom", county: "Tipperary", lat: 52.76724, lng: -7.89648 },
  { town: "Dromacommer", county: "Limerick", lat: 52.44404, lng: -8.65964 },
  { town: "Dromagh", county: "Cork", lat: 52.12517, lng: -8.97225 },
  { town: "Dromahair", county: "Leitrim", lat: 54.23067, lng: -8.30262 },
  { town: "Dromcolliher", county: "Limerick", lat: 52.33967, lng: -8.90794 },
  { town: "Dromcondra", county: "Dublin", lat: 53.37062, lng: -6.25376 },
  { town: "Dromin", county: "Louth", lat: 53.84384, lng: -6.43666 },
  { town: "Dromina", county: "Cork", lat: 52.31401, lng: -8.80213 },
  { town: "Dromineer", county: "Tipperary", lat: 52.92488, lng: -8.27565 },
  { town: "Dromiskin", county: "Louth", lat: 53.92172, lng: -6.401 },
  { town: "Drommahane", county: "Cork", lat: 52.1056, lng: -8.69083 },
  { town: "Drommartin", county: "Kerry", lat: 52.4297, lng: -9.69782 },
  { town: "Dromore", county: "Cork", lat: 51.65687, lng: -9.35921 },
  { town: "Dromore West", county: "Sligo", lat: 54.24951, lng: -8.87992 },
  { town: "Drum", county: "Monaghan", lat: 54.10388, lng: -7.14338 },
  { town: "Drumacrib", county: "Monaghan", lat: 54.14772, lng: -6.6726 },
  { town: "Drumakill", county: "Monaghan", lat: 54.1175, lng: -6.6847 },
  { town: "Drumandoora", county: "Clare", lat: 52.99745, lng: -8.7226 },
  { town: "Drumanespick", county: "Cavan", lat: 53.93348, lng: -7.05003 },
  { town: "Drumard", county: "Sligo", lat: 54.23719, lng: -8.67334 },
  { town: "Drumbane", county: "Tipperary", lat: 52.65501, lng: -7.96799 },
  { town: "Drumcliff", county: "Sligo", lat: 54.32705, lng: -8.4976 },
  { town: "Drumcondra", county: "Meath", lat: 53.85159, lng: -6.65634 },
  { town: "Drumcree", county: "Westmeath", lat: 53.636, lng: -7.1656 },
  { town: "Drumdigus", county: "Clare", lat: 52.65766, lng: -9.30773 },
  { town: "Drumeague", county: "Cavan", lat: 53.96739, lng: -6.98658 },
  { town: "Drumfin", county: "Sligo", lat: 54.13146, lng: -8.44437 },
  { town: "Drumfree", county: "Donegal", lat: 55.1975, lng: -7.39404 },
  { town: "Drumkeen", county: "Donegal", lat: 54.86603, lng: -7.75138 },
  { town: "Drumkeeran", county: "Leitrim", lat: 54.16941, lng: -8.14267 },
  { town: "Drumlish", county: "Longford", lat: 53.82059, lng: -7.76841 },
  { town: "Drummacool", county: "Sligo", lat: 54.13521, lng: -8.31355 },
  { town: "Drummin", county: "Mayo", lat: 53.68537, lng: -9.61299 },
  { town: "Drummullin", county: "Roscommon", lat: 53.85013, lng: -8.12108 },
  { town: "Drumod", county: "Leitrim", lat: 53.85757, lng: -7.92084 },
  { town: "Drumone", county: "Meath", lat: 53.71969, lng: -7.15326 },
  { town: "Drumraney", county: "Westmeath", lat: 53.48168, lng: -7.74994 },
  { town: "Drumree", county: "Meath", lat: 53.50775, lng: -6.57728 },
  { town: "Drumshanbo", county: "Leitrim", lat: 54.0478, lng: -8.04002 },
  { town: "Drumsna", county: "Leitrim", lat: 53.92692, lng: -8.00793 },
  { town: "Drung", county: "Cavan", lat: 54.04106, lng: -7.22258 },
  { town: "Duagh", county: "Kerry", lat: 52.41479, lng: -9.39019 },
  { town: "Dually", county: "Tipperary", lat: 52.54154, lng: -7.81592 },
  { town: "Dublin", county: "Dublin", lat: 53.34944, lng: -6.26008 },
  { town: "Dublin 2", county: "Dublin", lat: 53.34073, lng: -6.25824 },
  { town: "Duleek", county: "Meath", lat: 53.65475, lng: -6.42218 },
  { town: "Dunaff", county: "Donegal", lat: 55.2742, lng: -7.49445 },
  { town: "Dunamon", county: "Roscommon", lat: 53.63077, lng: -8.32138 },
  { town: "Dunboyne", county: "Meath", lat: 53.41927, lng: -6.47589 },
  { town: "Duncannon", county: "Wexford", lat: 52.22061, lng: -6.93255 },
  { town: "Duncormick", county: "Wexford", lat: 52.22855, lng: -6.65506 },
  { town: "Dundalk", county: "Louth", lat: 53.99795, lng: -6.40595 },
  { town: "Dunderrow", county: "Cork", lat: 51.72649, lng: -8.58745 },
  { town: "Dunderry", county: "Meath", lat: 53.608, lng: -6.77291 },
  { town: "Dundrum", county: "Dublin", lat: 53.28914, lng: -6.24327 },
  { town: "Dundrum", county: "Tipperary", lat: 52.55952, lng: -8.04323 },
  { town: "Dunfanaghy", county: "Donegal", lat: 55.18347, lng: -7.96943 },
  { town: "Dungarvan", county: "Kilkenny", lat: 52.58676, lng: -7.09534 },
  { town: "Dungarvan", county: "Waterford", lat: 52.09368, lng: -7.62036 },
  { town: "Dunglow", county: "Donegal", lat: 54.95029, lng: -8.35736 },
  { town: "Dungourney", county: "Cork", lat: 51.96804, lng: -8.09688 },
  { town: "Dunhill", county: "Waterford", lat: 52.17378, lng: -7.26375 },
  { town: "Dunkerrin", county: "Offaly", lat: 52.91353, lng: -7.90827 },
  { town: "Dunkineely", county: "Donegal", lat: 54.63208, lng: -8.35707 },
  { town: "Dunlavin", county: "Wicklow", lat: 53.05788, lng: -6.70164 },
  { town: "Dunleer", county: "Louth", lat: 53.83106, lng: -6.39446 },
  { town: "Dunmanway", county: "Cork", lat: 51.72226, lng: -9.1118 },
  { town: "Dunmore", county: "Galway", lat: 53.61802, lng: -8.74414 },
  { town: "Dunmore East", county: "Waterford", lat: 52.15277, lng: -6.99479 },
  { town: "Dunnamaggan", county: "Kilkenny", lat: 52.50298, lng: -7.29952 },
  { town: "Dunquin", county: "Kerry", lat: 52.13007, lng: -10.45027 },
  { town: "Dunsany", county: "Meath", lat: 53.54108, lng: -6.62226 },
  { town: "Dunshaughlin", county: "Meath", lat: 53.51352, lng: -6.54025 },
  { town: "Durrow", county: "Laois", lat: 52.84527, lng: -7.39652 },
  { town: "Durrus", county: "Cork", lat: 51.62171, lng: -9.52199 },
  { town: "Dysart", county: "Westmeath", lat: 53.47276, lng: -7.45856 },
  { town: "Dysert", county: "Roscommon", lat: 53.46458, lng: -8.21921 },
  { town: "Easky", county: "Sligo", lat: 54.28633, lng: -8.96237 },
  { town: "East Barrs", county: "Leitrim", lat: 54.28703, lng: -8.04658 },
  { town: "Edenderry", county: "Offaly", lat: 53.34329, lng: -7.05059 },
  { town: "Edenmore", county: "Dublin", lat: 53.39069, lng: -6.18393 },
  { town: "Edgeworthstown", county: "Longford", lat: 53.69569, lng: -7.6085 },
  { town: "Edmondstown", county: "Dublin", lat: 53.27775, lng: -6.28835 },
  { town: "Edmondstown", county: "Louth", lat: 53.90863, lng: -6.6183 },
  { town: "Edmondstown", county: "Roscommon", lat: 53.924, lng: -8.53373 },
  { town: "Elphin", county: "Roscommon", lat: 53.84571, lng: -8.19144 },
  { town: "Elton", county: "Limerick", lat: 52.42677, lng: -8.45716 },
  { town: "Emlaghmore", county: "Kerry", lat: 51.86601, lng: -10.23672 },
  { town: "Emly", county: "Tipperary", lat: 52.46391, lng: -8.35128 },
  { town: "Emo", county: "Laois", lat: 53.0972, lng: -7.20854 },
  { town: "Emyvale", county: "Monaghan", lat: 54.34099, lng: -6.95926 },
  { town: "Ennis", county: "Clare", lat: 52.84742, lng: -8.98874 },
  { town: "Enniscorthy", county: "Wexford", lat: 52.5022, lng: -6.56614 },
  { town: "Enniskean", county: "Cork", lat: 51.73936, lng: -8.92691 },
  { town: "Enniskerry", county: "Wicklow", lat: 53.19351, lng: -6.17074 },
  { town: "Ennistimon", county: "Clare", lat: 52.94037, lng: -9.2935 },
  { town: "Errill", county: "Laois", lat: 52.85995, lng: -7.67699 },
  { town: "Esker", county: "Longford", lat: 53.78822, lng: -7.70402 },
  { town: "Eskeragh", county: "Mayo", lat: 54.10898, lng: -9.45322 },
  { town: "Eslinbridge", county: "Leitrim", lat: 53.93943, lng: -7.91768 },
  { town: "Eyeries", county: "Cork", lat: 51.6902, lng: -9.95405 },
  { town: "Eyrecourt", county: "Galway", lat: 53.19972, lng: -8.13142 },
  { town: "Facefield", county: "Mayo", lat: 53.77486, lng: -9.06417 },
  { town: "Fahan", county: "Donegal", lat: 55.08761, lng: -7.47241 },
  { town: "Fairymount", county: "Roscommon", lat: 53.83648, lng: -8.4835 },
  { town: "Falcarragh", county: "Donegal", lat: 55.13641, lng: -8.10448 },
  { town: "Fanavolty", county: "Donegal", lat: 55.23765, lng: -7.69653 },
  { town: "Fardrum", county: "Westmeath", lat: 53.40203, lng: -7.87803 },
  { town: "Farnaght", county: "Leitrim", lat: 53.86287, lng: -7.81611 },
  { town: "Farnanes", county: "Cork", lat: 51.85828, lng: -8.79947 },
  { town: "Farran", county: "Cork", lat: 51.87333, lng: -8.72043 },
  { town: "Farranfore", county: "Kerry", lat: 52.17199, lng: -9.55051 },
  { town: "Farranhavane", county: "Cork", lat: 51.77968, lng: -8.79133 },
  { town: "Faughart Upper", county: "Louth", lat: 54.04856, lng: -6.38141 },
  { town: "Feakle", county: "Clare", lat: 52.92606, lng: -8.64865 },
  { town: "Fedamore", county: "Limerick", lat: 52.54545, lng: -8.6017 },
  { town: "Feenagh", county: "Limerick", lat: 52.39012, lng: -8.88011 },
  { town: "Fenagh", county: "Leitrim", lat: 54.0202, lng: -7.83462 },
  { town: "Fenit", county: "Kerry", lat: 52.2784, lng: -9.86121 },
  { town: "Fennagh", county: "Carlow", lat: 52.71436, lng: -6.84467 },
  { town: "Fennor", county: "Waterford", lat: 52.16148, lng: -7.22713 },
  { town: "Feohanagh", county: "Kerry", lat: 52.21651, lng: -10.35154 },
  { town: "Feohanagh", county: "Limerick", lat: 52.38451, lng: -8.972 },
  { town: "Ferbane", county: "Offaly", lat: 53.27195, lng: -7.82788 },
  { town: "Fermoy", county: "Cork", lat: 52.13936, lng: -8.27556 },
  { town: "Ferns", county: "Wexford", lat: 52.59093, lng: -6.49802 },
  { town: "Fethard", county: "Tipperary", lat: 52.46594, lng: -7.69273 },
  { town: "Fethard", county: "Wexford", lat: 52.19231, lng: -6.84022 },
  { town: "Fiddown", county: "Kilkenny", lat: 52.33231, lng: -7.3148 },
  { town: "Fieries", county: "Kerry", lat: 52.17008, lng: -9.59628 },
  { town: "Figanny", county: "Monaghan", lat: 54.36583, lng: -6.91654 },
  { town: "Finglas", county: "Dublin", lat: 53.39032, lng: -6.29841 },
  { town: "Finnea", county: "Westmeath", lat: 53.77996, lng: -7.38846 },
  { town: "Finny", county: "Mayo", lat: 53.57084, lng: -9.493 },
  { town: "Fintown", county: "Donegal", lat: 54.86957, lng: -8.11547 },
  { town: "Finuge", county: "Kerry", lat: 52.42487, lng: -9.53672 },
  { town: "Finvarra", county: "Clare", lat: 53.15057, lng: -9.10254 },
  { town: "Firhouse", county: "Dublin", lat: 53.28173, lng: -6.34215 },
  { town: "Firmount", county: "Cork", lat: 51.98784, lng: -8.6992 },
  { town: "Fivealley", county: "Offaly", lat: 53.15205, lng: -7.83479 },
  { town: "Fivemilebourne", county: "Leitrim", lat: 54.27442, lng: -8.33365 },
  { town: "Flagmount", county: "Clare", lat: 52.98867, lng: -8.64761 },
  { town: "Fordstown", county: "Meath", lat: 53.66998, lng: -6.90214 },
  { town: "Fore", county: "Westmeath", lat: 53.68048, lng: -7.22692 },
  { town: "Fortel", county: "Offaly", lat: 53.07985, lng: -7.8309 },
  { town: "Foulkesmill", county: "Wexford", lat: 52.3139, lng: -6.75137 },
  {
    town: "Four Mile House",
    county: "Roscommon",
    lat: 53.71055,
    lng: -8.20747,
  },
  { town: "Four Roads", county: "Roscommon", lat: 53.51556, lng: -8.22223 },
  { town: "Foxford", county: "Mayo", lat: 53.98077, lng: -9.11325 },
  { town: "Fox & Geese", county: "Dublin", lat: 53.32756, lng: -6.3698 },
  { town: "Foxrock", county: "Dublin", lat: 53.27378, lng: -6.17322 },
  { town: "Foynes", county: "Limerick", lat: 52.61052, lng: -9.10511 },
  { town: "Freemount", county: "Cork", lat: 52.27656, lng: -8.88513 },
  { town: "Frenchpark", county: "Roscommon", lat: 53.86951, lng: -8.40743 },
  { town: "Freshford", county: "Kilkenny", lat: 52.73251, lng: -7.39685 },
  { town: "Frosses", county: "Donegal", lat: 54.66877, lng: -8.24358 },
  { town: "Furnace", county: "Mayo", lat: 53.9227, lng: -9.57236 },
  { town: "Fybagh", county: "Kerry", lat: 52.16497, lng: -9.82629 },
  { town: "Galbally", county: "Limerick", lat: 52.40123, lng: -8.29612 },
  { town: "Galmoy", county: "Kilkenny", lat: 52.79257, lng: -7.56825 },
  { town: "Galvone", county: "Limerick", lat: 52.64944, lng: -8.59886 },
  { town: "Galway", county: "Galway", lat: 53.29178, lng: -9.02859 },
  { town: "Garrafrauns", county: "Galway", lat: 53.63276, lng: -8.82428 },
  { town: "Garranard", county: "Mayo", lat: 54.17774, lng: -9.35352 },
  { town: "Garranlahan", county: "Roscommon", lat: 53.71287, lng: -8.6675 },
  { town: "Garristown", county: "Dublin", lat: 53.56687, lng: -6.38356 },
  { town: "Garryfine", county: "Limerick", lat: 52.4124, lng: -8.73147 },
  { town: "Garryhill", county: "Carlow", lat: 52.67909, lng: -6.85425 },
  { town: "Garryhinch", county: "Offaly", lat: 53.14534, lng: -7.26941 },
  { town: "Garryowen", county: "Limerick", lat: 52.65968, lng: -8.60216 },
  { town: "Garryspillane", county: "Limerick", lat: 52.41051, lng: -8.37801 },
  { town: "Garvagh", county: "Leitrim", lat: 53.97991, lng: -7.85869 },
  { town: "Gathabawn", county: "Kilkenny", lat: 52.76686, lng: -7.47161 },
  { town: "Gaybrook", county: "Westmeath", lat: 53.4766, lng: -7.32296 },
  { town: "Geashill", county: "Offaly", lat: 53.23523, lng: -7.32098 },
  { town: "Geevagh", county: "Sligo", lat: 54.09893, lng: -8.25008 },
  { town: "Gibstown", county: "Meath", lat: 53.70794, lng: -6.74107 },
  { town: "Glandore", county: "Cork", lat: 51.56651, lng: -9.12097 },
  { town: "Glangevlin", county: "Cavan", lat: 54.19215, lng: -7.89265 },
  { town: "Glanlough", county: "Cork", lat: 51.63375, lng: -9.61564 },
  { town: "Glanmire", county: "Cork", lat: 51.91939, lng: -8.39646 },
  { town: "Glantane", county: "Cork", lat: 52.10372, lng: -8.75578 },
  { town: "Glanworth", county: "Cork", lat: 52.18663, lng: -8.35673 },
  { town: "Glasleck", county: "Cavan", lat: 53.9702, lng: -6.91116 },
  { town: "Glaslough", county: "Monaghan", lat: 54.31727, lng: -6.89712 },
  { town: "Glasnevin", county: "Dublin", lat: 53.38283, lng: -6.27629 },
  { town: "Glasnevin North", county: "Dublin", lat: 53.39065, lng: -6.27935 },
  { town: "Glassan", county: "Westmeath", lat: 53.47087, lng: -7.8639 },
  { town: "Glassillaun", county: "Galway", lat: 53.61096, lng: -9.87569 },
  { town: "Glasthule", county: "Dublin", lat: 53.28792, lng: -6.12603 },
  { town: "Glebe", county: "Donegal", lat: 55.18502, lng: -7.51328 },
  { town: "Glen", county: "Donegal", lat: 55.12641, lng: -7.8144 },
  { town: "Glenade", county: "Leitrim", lat: 54.38072, lng: -8.29681 },
  { town: "Glenamoy", county: "Mayo", lat: 54.24094, lng: -9.67603 },
  { town: "Glenbeigh", county: "Kerry", lat: 52.05618, lng: -9.94034 },
  { town: "Glenboy", county: "Leitrim", lat: 54.28683, lng: -8.13285 },
  { town: "Glencar", county: "Kerry", lat: 51.99447, lng: -9.85803 },
  { town: "Glencar", county: "Leitrim", lat: 54.33396, lng: -8.35206 },
  { town: "Glencolumbkille", county: "Donegal", lat: 54.70809, lng: -8.72951 },
  { town: "Glencree", county: "Wicklow", lat: 53.20059, lng: -6.29239 },
  { town: "Glencullen", county: "Dublin", lat: 53.22246, lng: -6.21614 },
  { town: "Glenderry", county: "Kerry", lat: 52.39934, lng: -9.88844 },
  { town: "Glenealy", county: "Wicklow", lat: 52.96663, lng: -6.14459 },
  { town: "Gleneely", county: "Donegal", lat: 55.23875, lng: -7.14664 },
  { town: "Glenfarne", county: "Leitrim", lat: 54.28441, lng: -7.98566 },
  { town: "Glenflesk", county: "Kerry", lat: 52.01236, lng: -9.36077 },
  { town: "Glengarriff", county: "Cork", lat: 51.75003, lng: -9.55222 },
  { town: "Glenisland", county: "Mayo", lat: 53.89946, lng: -9.40557 },
  { town: "Glenmaquin", county: "Donegal", lat: 54.90529, lng: -7.66646 },
  { town: "Glenmore", county: "Kilkenny", lat: 52.35277, lng: -7.02563 },
  { town: "Glennamaddy", county: "Galway", lat: 53.60754, lng: -8.56 },
  { town: "Glenroe", county: "Limerick", lat: 52.3222, lng: -8.40944 },
  { town: "Glenties", county: "Donegal", lat: 54.79717, lng: -8.28303 },
  { town: "Glentogher", county: "Donegal", lat: 55.21108, lng: -7.26066 },
  { town: "Glenvar", county: "Donegal", lat: 55.15704, lng: -7.59879 },
  { town: "Glenville", county: "Cork", lat: 52.04566, lng: -8.42542 },
  { town: "Glin", county: "Limerick", lat: 52.57191, lng: -9.28498 },
  { town: "Glinsk", county: "Galway", lat: 53.65069, lng: -8.43151 },
  { town: "Glounthaune", county: "Cork", lat: 51.91338, lng: -8.33784 },
  { town: "Glynn", county: "Wexford", lat: 52.37359, lng: -6.58554 },
  { town: "Gneevgullia", county: "Kerry", lat: 52.11669, lng: -9.27195 },
  { town: "Goatstown", county: "Dublin", lat: 53.29058, lng: -6.22757 },
  { town: "Golden", county: "Tipperary", lat: 52.49845, lng: -7.98075 },
  { town: "Goleen", county: "Cork", lat: 51.49387, lng: -9.70957 },
  { town: "Goold's Cross", county: "Tipperary", lat: 52.58987, lng: -7.95921 },
  { town: "Goresbridge", county: "Kilkenny", lat: 52.63003, lng: -6.99265 },
  { town: "Gorey", county: "Wexford", lat: 52.67574, lng: -6.29431 },
  { town: "Gort", county: "Galway", lat: 53.06664, lng: -8.81874 },
  { town: "Gortacloona", county: "Limerick", lat: 52.49278, lng: -8.45794 },
  { town: "Gortahork", county: "Donegal", lat: 55.11693, lng: -8.14007 },
  { town: "Gortaroo", county: "Cork", lat: 51.92951, lng: -7.91959 },
  { town: "Gorteen", county: "Sligo", lat: 53.99582, lng: -8.52236 },
  { town: "Gorteeny", county: "Galway", lat: 53.02062, lng: -8.36138 },
  { town: "Gortnagroagh", county: "Galway", lat: 53.39575, lng: -9.25544 },
  { town: "Gortnahoo", county: "Tipperary", lat: 52.67575, lng: -7.60278 },
  { town: "Gortnalughoge", county: "Donegal", lat: 55.23305, lng: -7.79587 },
  { town: "Gorumna", county: "Galway", lat: 53.26018, lng: -9.67765 },
  { town: "Gowran", county: "Kilkenny", lat: 52.62936, lng: -7.06501 },
  { town: "Graiguenamanagh", county: "Kilkenny", lat: 52.54154, lng: -6.9556 },
  { town: "Granagh", county: "Limerick", lat: 52.45974, lng: -8.76356 },
  { town: "Granard", county: "Longford", lat: 53.77927, lng: -7.49178 },
  { town: "Grange", county: "Limerick", lat: 52.54126, lng: -8.54676 },
  { town: "Grange", county: "Sligo", lat: 54.39328, lng: -8.52209 },
  { town: "Grange", county: "Tipperary", lat: 52.33873, lng: -7.82973 },
  { town: "Grange", county: "Waterford", lat: 51.9868, lng: -7.74788 },
  { town: "Grangebellew", county: "Louth", lat: 53.81618, lng: -6.33468 },
  { town: "Grange Con", county: "Wicklow", lat: 52.99787, lng: -6.74316 },
  { town: "Graniamore", county: "Sligo", lat: 54.08413, lng: -8.44674 },
  { town: "Greenan", county: "Wicklow", lat: 52.92478, lng: -6.2941 },
  { town: "Greencastle", county: "Donegal", lat: 55.20336, lng: -6.98755 },
  { town: "Greenhills", county: "Dublin", lat: 53.31335, lng: -6.34136 },
  { town: "Greenore", county: "Louth", lat: 54.03154, lng: -6.13136 },
  { town: "Grenagh", county: "Cork", lat: 52.01048, lng: -8.61067 },
  { town: "Greystones", county: "Wicklow", lat: 53.14912, lng: -6.07934 },
  { town: "Grousehall", county: "Cavan", lat: 53.9415, lng: -7.12342 },
  { town: "Gubaveeny", county: "Cavan", lat: 54.24165, lng: -7.95908 },
  { town: "Gurteen", county: "Leitrim", lat: 54.32402, lng: -8.29271 },
  { town: "Gusserane", county: "Wexford", lat: 52.3069, lng: -6.85918 },
  { town: "Gweedore", county: "Donegal", lat: 55.04981, lng: -8.22871 },
  { town: "Gweesalia", county: "Mayo", lat: 54.11201, lng: -9.89843 },
  { town: "Hackballs Cross", county: "Louth", lat: 54.03085, lng: -6.52019 },
  { town: "Hacketstown", county: "Carlow", lat: 52.86539, lng: -6.55862 },
  { town: "Harboursmouth", county: "Cork", lat: 51.47584, lng: -9.40221 },
  { town: "Harolds Cross", county: "Dublin", lat: 53.32647, lng: -6.27794 },
  { town: "Harristown", county: "Kildare", lat: 53.09646, lng: -6.97201 },
  { town: "Headford", county: "Galway", lat: 53.46946, lng: -9.10613 },
  { town: "Headfort", county: "Kerry", lat: 52.04124, lng: -9.34578 },
  { town: "Herbertstown", county: "Limerick", lat: 52.51882, lng: -8.46873 },
  { town: "Hill Of Down", county: "Meath", lat: 53.47414, lng: -7.0136 },
  { town: "Hillsborough", county: "Kildare", lat: 53.16492, lng: -6.76371 },
  { town: "Hillstreet", county: "Roscommon", lat: 53.88252, lng: -8.08326 },
  { town: "Hollyford", county: "Tipperary", lat: 52.63366, lng: -8.11117 },
  { town: "Hollymount", county: "Mayo", lat: 53.66072, lng: -9.11726 },
  { town: "Hollywood", county: "Wicklow", lat: 53.09182, lng: -6.59906 },
  { town: "Holycross", county: "Limerick", lat: 52.50774, lng: -8.54085 },
  { town: "Holycross", county: "Tipperary", lat: 52.63977, lng: -7.87017 },
  { town: "Horse & Jockey", county: "Tipperary", lat: 52.6159, lng: -7.77575 },
  { town: "Horseleap", county: "Westmeath", lat: 53.39226, lng: -7.58074 },
  { town: "Hospital", county: "Limerick", lat: 52.47326, lng: -8.43253 },
  { town: "Howth", county: "Dublin", lat: 53.38585, lng: -6.06469 },
  { town: "Hugginstown", county: "Kilkenny", lat: 52.4519, lng: -7.25123 },
  { town: "Illion", county: "Galway", lat: 53.51813, lng: -9.72039 },
  { town: "Inagh", county: "Clare", lat: 52.87699, lng: -9.17676 },
  { town: "Inch", county: "Donegal", lat: 55.07098, lng: -7.48024 },
  { town: "Inch", county: "Kerry", lat: 52.14765, lng: -9.96248 },
  { town: "Inch", county: "Limerick", lat: 52.59151, lng: -8.51052 },
  { town: "Inch", county: "Wexford", lat: 52.74114, lng: -6.236 },
  { town: "Inchabaun", county: "Limerick", lat: 52.39578, lng: -9.17704 },
  { town: "Inchicore", county: "Dublin", lat: 53.33542, lng: -6.32702 },
  { town: "Inchigeelagh", county: "Cork", lat: 51.84245, lng: -9.12643 },
  { town: "Inishannon", county: "Cork", lat: 51.76462, lng: -8.65653 },
  { town: "Inishbiggle", county: "Mayo", lat: 53.99482, lng: -9.91307 },
  { town: "Inishbofin", county: "Galway", lat: 53.61348, lng: -10.20955 },
  { town: "Inishcarra", county: "Cork", lat: 51.90615, lng: -8.65005 },
  { town: "Inishcrone", county: "Sligo", lat: 54.21329, lng: -9.09208 },
  { town: "Inisheer", county: "Galway", lat: 53.05944, lng: -9.52814 },
  { town: "Inishkeen", county: "Monaghan", lat: 54.00313, lng: -6.5796 },
  { town: "Inishmaan", county: "Galway", lat: 53.08943, lng: -9.58793 },
  { town: "Inishmore", county: "Galway", lat: 53.12805, lng: -9.73159 },
  { town: "Inishtrahull", county: "Donegal", lat: 55.4323, lng: -7.23788 },
  { town: "Inishturk", county: "Mayo", lat: 53.70667, lng: -10.09291 },
  { town: "Inistioge", county: "Kilkenny", lat: 52.48755, lng: -7.06512 },
  { town: "Innfield", county: "Meath", lat: 53.4142, lng: -6.82966 },
  { town: "Inver", county: "Donegal", lat: 54.65443, lng: -8.27921 },
  { town: "Inveran", county: "Galway", lat: 53.24031, lng: -9.46555 },
  { town: "Irishtown", county: "Dublin", lat: 53.33751, lng: -6.21941 },
  { town: "Irishtown", county: "Mayo", lat: 53.66278, lng: -8.89005 },
  { town: "Islandbridge", county: "Dublin", lat: 53.34622, lng: -6.30822 },
  { town: "Islandeady", county: "Mayo", lat: 53.82619, lng: -9.40157 },
  { town: "Jenkinstown", county: "Kilkenny", lat: 52.74433, lng: -7.32201 },
  { town: "Jenkinstown", county: "Louth", lat: 54.01605, lng: -6.31036 },
  { town: "Jobstown", county: "Dublin", lat: 53.27606, lng: -6.39478 },
  { town: "Johnstown", county: "Kildare", lat: 53.23706, lng: -6.6218 },
  { town: "Johnstown", county: "Kilkenny", lat: 52.74915, lng: -7.55649 },
  { town: "Johnstown Bridge", county: "Kildare", lat: 53.40199, lng: -6.85521 },
  { town: "Julianstown", county: "Meath", lat: 53.67293, lng: -6.28565 },
  { town: "Kanturk", county: "Cork", lat: 52.17725, lng: -8.90516 },
  { town: "Keadew", county: "Roscommon", lat: 54.05114, lng: -8.13776 },
  { town: "Kealkill", county: "Cork", lat: 51.74971, lng: -9.38133 },
  { town: "Keel", county: "Kerry", lat: 51.85028, lng: -10.33416 },
  { town: "Keel", county: "Mayo", lat: 53.97443, lng: -10.08527 },
  { town: "Keem", county: "Mayo", lat: 53.96822, lng: -10.1943 },
  { town: "Keenagh", county: "Longford", lat: 53.622, lng: -7.81473 },
  { town: "Kells", county: "Kerry", lat: 52.01373, lng: -10.09937 },
  { town: "Kells", county: "Kilkenny", lat: 52.53953, lng: -7.27315 },
  { town: "Kells", county: "Meath", lat: 53.72684, lng: -6.87483 },
  { town: "Kenmare", county: "Kerry", lat: 51.87796, lng: -9.58357 },
  { town: "Kennastown", county: "Meath", lat: 53.62141, lng: -6.66808 },
  { town: "Kentstown", county: "Meath", lat: 53.62754, lng: -6.53334 },
  { town: "Kesh", county: "Sligo", lat: 54.05045, lng: -8.44522 },
  { town: "Keshcarrigan", county: "Leitrim", lat: 54.01886, lng: -7.94253 },
  { town: "Kilbaha", county: "Clare", lat: 52.57055, lng: -9.86166 },
  { town: "Kilbane", county: "Clare", lat: 52.80328, lng: -8.56352 },
  { town: "Kilbarrick", county: "Dublin", lat: 53.38374, lng: -6.15255 },
  { town: "Kilbarry", county: "Cork", lat: 51.86197, lng: -9.06007 },
  { town: "Kilbeggan", county: "Westmeath", lat: 53.36845, lng: -7.49947 },
  { town: "Kilbeheny", county: "Limerick", lat: 52.29421, lng: -8.20541 },
  { town: "Kilberry", county: "Kildare", lat: 53.03114, lng: -7.01699 },
  { town: "Kilbrackan", county: "Leitrim", lat: 53.98782, lng: -7.60258 },
  { town: "Kilbride", county: "Carlow", lat: 52.71892, lng: -6.72191 },
  { town: "Kilbride", county: "Wicklow", lat: 52.92227, lng: -6.11245 },
  { town: "Kilbride", county: "Wicklow", lat: 53.19867, lng: -6.46695 },
  { town: "Kilbrin", county: "Cork", lat: 52.2112, lng: -8.83112 },
  { town: "Kilbrittain", county: "Cork", lat: 51.67356, lng: -8.69174 },
  { town: "Kilcar", county: "Donegal", lat: 54.6324, lng: -8.58946 },
  { town: "Kilchreest", county: "Galway", lat: 53.17832, lng: -8.65296 },
  { town: "Kilclare", county: "Leitrim", lat: 54.00817, lng: -8.01521 },
  { town: "Kilclone", county: "Meath", lat: 53.43418, lng: -6.59335 },
  { town: "Kilcock", county: "Kildare", lat: 53.39942, lng: -6.66812 },
  { town: "Kilcogy", county: "Cavan", lat: 53.8291, lng: -7.42551 },
  { town: "Kilcolgan", county: "Galway", lat: 53.21024, lng: -8.86944 },
  { town: "Kilcolman", county: "Limerick", lat: 52.53298, lng: -9.08488 },
  { town: "Kilcomin", county: "Offaly", lat: 52.96814, lng: -7.96159 },
  { town: "Kilcommon", county: "Mayo", lat: 54.26148, lng: -9.80055 },
  { town: "Kilcommon", county: "Tipperary", lat: 52.69226, lng: -8.14677 },
  { town: "Kilconly", county: "Galway", lat: 53.57387, lng: -8.97636 },
  { town: "Kilconnell", county: "Galway", lat: 53.33147, lng: -8.40143 },
  { town: "Kilcoole", county: "Wicklow", lat: 53.10627, lng: -6.0646 },
  { town: "Kilcormac", county: "Offaly", lat: 53.17641, lng: -7.72741 },
  { town: "Kilcorney", county: "Cork", lat: 52.06244, lng: -8.96179 },
  { town: "Kilcotty", county: "Wexford", lat: 52.48859, lng: -6.47692 },
  { town: "Kilcrohane", county: "Cork", lat: 51.58061, lng: -9.70438 },
  { town: "Kilcullen", county: "Kildare", lat: 53.13029, lng: -6.74529 },
  { town: "Kilcummin", county: "Kerry", lat: 52.09841, lng: -9.47007 },
  { town: "Kilcurly", county: "Louth", lat: 54.00796, lng: -6.46812 },
  { town: "Kildalkey", county: "Meath", lat: 53.57145, lng: -6.90604 },
  { town: "Kildangan", county: "Kildare", lat: 53.10522, lng: -7.01377 },
  { town: "Kildare", county: "Kildare", lat: 53.15893, lng: -6.90957 },
  { town: "Kildavin", county: "Carlow", lat: 52.68321, lng: -6.68499 },
  { town: "Kildimo New", county: "Limerick", lat: 52.62378, lng: -8.81307 },
  { town: "Kildimo Old", county: "Limerick", lat: 52.61329, lng: -8.80757 },
  { town: "Kildorrery", county: "Cork", lat: 52.2465, lng: -8.42738 },
  { town: "Kilfeakle", county: "Tipperary", lat: 52.48721, lng: -8.06321 },
  { town: "Kilfenora", county: "Clare", lat: 52.98994, lng: -9.21833 },
  { town: "Kilfinnane", county: "Limerick", lat: 52.35869, lng: -8.46728 },
  { town: "Kilfinny", county: "Limerick", lat: 52.51373, lng: -8.80605 },
  { town: "Kilflyn", county: "Kerry", lat: 52.35054, lng: -9.62532 },
  { town: "Kilfree", county: "Sligo", lat: 53.97261, lng: -8.53763 },
  { town: "Kilgarvan", county: "Kerry", lat: 51.90315, lng: -9.4384 },
  { town: "Kilglass", county: "Roscommon", lat: 53.81748, lng: -8.01593 },
  { town: "Kilglass", county: "Sligo", lat: 54.24141, lng: -9.05561 },
  { town: "Kilgobnet", county: "Kerry", lat: 52.06312, lng: -9.72399 },
  { town: "Kilgoly", county: "Donegal", lat: 54.71395, lng: -8.70072 },
  { town: "Kilinkere", county: "Cavan", lat: 53.88557, lng: -7.06641 },
  { town: "Kilkea", county: "Kildare", lat: 52.94756, lng: -6.89272 },
  { town: "Kilkee", county: "Clare", lat: 52.68282, lng: -9.6444 },
  { town: "Kilkelly", county: "Mayo", lat: 53.87179, lng: -8.85025 },
  { town: "Kilkenny", county: "Kilkenny", lat: 52.65415, lng: -7.24479 },
  { town: "Kilkerrin", county: "Galway", lat: 53.55474, lng: -8.56863 },
  { town: "Kilkieran", county: "Galway", lat: 53.32271, lng: -9.73697 },
  { town: "Kilkishen", county: "Clare", lat: 52.80715, lng: -8.74704 },
  { town: "Kill", county: "Cavan", lat: 54.03432, lng: -7.11222 },
  { town: "Kill", county: "Kildare", lat: 53.24871, lng: -6.59054 },
  { town: "Kill", county: "Waterford", lat: 52.17588, lng: -7.33538 },
  { town: "Killadoon", county: "Mayo", lat: 53.70759, lng: -9.89168 },
  { town: "Killadysert", county: "Clare", lat: 52.67333, lng: -9.10767 },
  { town: "Killala", county: "Mayo", lat: 54.21252, lng: -9.22101 },
  { town: "Killaloe", county: "Clare", lat: 52.80888, lng: -8.44752 },
  { town: "Killann", county: "Wexford", lat: 52.52187, lng: -6.74509 },
  { town: "Killarga", county: "Leitrim", lat: 54.23009, lng: -8.2099 },
  { town: "Killarney", county: "Kerry", lat: 52.06912, lng: -9.51647 },
  { town: "Killashandra", county: "Cavan", lat: 54.01387, lng: -7.52824 },
  { town: "Killashee", county: "Longford", lat: 53.68129, lng: -7.87933 },
  { town: "Killasser", county: "Mayo", lat: 53.98279, lng: -8.95981 },
  { town: "Killateeaun", county: "Mayo", lat: 53.61028, lng: -9.43922 },
  { town: "Killavally", county: "Mayo", lat: 53.75903, lng: -9.36694 },
  { town: "Killavullen", county: "Cork", lat: 52.1465, lng: -8.51668 },
  { town: "Killea", county: "Tipperary", lat: 52.82481, lng: -7.87274 },
  { town: "Killeagh", county: "Cork", lat: 51.94249, lng: -7.99201 },
  { town: "Killeenadeema", county: "Galway", lat: 53.16372, lng: -8.5752 },
  { town: "Killeenleagh", county: "Kerry", lat: 51.90447, lng: -10.0638 },
  { town: "Killeevan", county: "Monaghan", lat: 54.17334, lng: -7.15165 },
  { town: "Killegar", county: "Leitrim", lat: 54.01395, lng: -7.61196 },
  { town: "Killeigh", county: "Offaly", lat: 53.21407, lng: -7.45034 },
  { town: "Killenagh", county: "Wexford", lat: 52.59049, lng: -6.27662 },
  { town: "Killenard", county: "Laois", lat: 53.13495, lng: -7.14786 },
  { town: "Killenaule", county: "Tipperary", lat: 52.56802, lng: -7.67331 },
  { town: "Killerrig", county: "Carlow", lat: 52.84306, lng: -6.78906 },
  { town: "Killeshin", county: "Laois", lat: 52.84808, lng: -6.99805 },
  { town: "Killester", county: "Dublin", lat: 53.37156, lng: -6.20263 },
  { town: "Killimor", county: "Galway", lat: 53.16563, lng: -8.29105 },
  { town: "Killinaboy", county: "Clare", lat: 52.97108, lng: -9.0873 },
  { town: "Killiney", county: "Dublin", lat: 53.26501, lng: -6.11496 },
  { town: "Killinick", county: "Wexford", lat: 52.2561, lng: -6.45637 },
  { town: "Killinierin", county: "Wexford", lat: 52.73058, lng: -6.28674 },
  { town: "Killinny", county: "Galway", lat: 53.09693, lng: -8.91253 },
  { town: "Killinure", county: "Limerick", lat: 52.61344, lng: -8.43628 },
  { town: "Killnamartery", county: "Cork", lat: 51.90074, lng: -9.08135 },
  { town: "Killoe", county: "Longford", lat: 53.75699, lng: -7.69619 },
  {
    town: "Kill Of The Grange",
    county: "Dublin",
    lat: 53.27966,
    lng: -6.16208,
  },
  { town: "Killoran", county: "Galway", lat: 53.24485, lng: -8.35528 },
  { town: "Killorglin", county: "Kerry", lat: 52.10635, lng: -9.78433 },
  { town: "Killoscully", county: "Tipperary", lat: 52.7696, lng: -8.32723 },
  { town: "Killour", county: "Mayo", lat: 53.58859, lng: -9.27716 },
  { town: "Killucan", county: "Westmeath", lat: 53.51369, lng: -7.1428 },
  { town: "Killurin", county: "Offaly", lat: 53.21734, lng: -7.55092 },
  { town: "Killybegs", county: "Donegal", lat: 54.63477, lng: -8.45471 },
  { town: "Killybrone", county: "Monaghan", lat: 54.39585, lng: -7.00556 },
  { town: "Killygordon", county: "Donegal", lat: 54.79683, lng: -7.68354 },
  { town: "Killyneill", county: "Monaghan", lat: 54.26687, lng: -6.89065 },
  { town: "Kilmacanoge", county: "Wicklow", lat: 53.16859, lng: -6.1362 },
  { town: "Kilmacow", county: "Kilkenny", lat: 52.31236, lng: -7.17691 },
  { town: "Kilmacrenan", county: "Donegal", lat: 55.03027, lng: -7.77935 },
  { town: "Kilmacteige", county: "Sligo", lat: 54.03943, lng: -8.92258 },
  { town: "Kilmacthomas", county: "Waterford", lat: 52.20861, lng: -7.42506 },
  { town: "Kilmactranny", county: "Sligo", lat: 54.05999, lng: -8.25427 },
  { town: "Kilmacud", county: "Dublin", lat: 53.28457, lng: -6.20935 },
  { town: "Kilmaganny", county: "Kilkenny", lat: 52.46133, lng: -7.32893 },
  { town: "Kilmaine", county: "Mayo", lat: 53.58116, lng: -9.12276 },
  { town: "Kilmainham", county: "Dublin", lat: 53.3406, lng: -6.30769 },
  { town: "Kilmainhamwood", county: "Meath", lat: 53.85235, lng: -6.8049 },
  { town: "Kilmaley", county: "Clare", lat: 52.81883, lng: -9.1088 },
  { town: "Kilmallock", county: "Limerick", lat: 52.39866, lng: -8.57093 },
  { town: "Kilmanahan", county: "Waterford", lat: 52.31634, lng: -7.77758 },
  { town: "Kilmangah", county: "Kilkenny", lat: 52.62086, lng: -7.42092 },
  { town: "Kilmead", county: "Kildare", lat: 53.0348, lng: -6.90222 },
  { town: "Kilmeage", county: "Kildare", lat: 53.25105, lng: -6.83993 },
  { town: "Kilmeedy", county: "Limerick", lat: 52.41604, lng: -8.91567 },
  { town: "Kilmessan", county: "Meath", lat: 53.55888, lng: -6.65895 },
  { town: "Kilmichael", county: "Cork", lat: 51.83407, lng: -9.04168 },
  { town: "Kilmihil", county: "Clare", lat: 52.72099, lng: -9.32115 },
  { town: "Kilmore", county: "Clare", lat: 52.75439, lng: -8.59273 },
  { town: "Kilmore", county: "Dublin", lat: 53.39298, lng: -6.22056 },
  { town: "Kilmore", county: "Leitrim", lat: 54.26476, lng: -8.32743 },
  { town: "Kilmore", county: "Roscommon", lat: 53.88827, lng: -8.01063 },
  { town: "Kilmore", county: "Wexford", lat: 52.20563, lng: -6.54738 },
  { town: "Kilmore Quay", county: "Wexford", lat: 52.17572, lng: -6.5864 },
  { town: "Kilmorna", county: "Kerry", lat: 52.42934, lng: -9.37523 },
  { town: "Kilmovee", county: "Mayo", lat: 53.88834, lng: -8.68821 },
  { town: "Kilmuckridge", county: "Wexford", lat: 52.5131, lng: -6.28394 },
  { town: "Kilmurry", county: "Clare", lat: 52.77817, lng: -8.78264 },
  { town: "Kilmurry", county: "Cork", lat: 51.84163, lng: -8.8819 },
  { town: "Kilnaleck", county: "Cavan", lat: 53.86223, lng: -7.32139 },
  { town: "Kilnamanagh", county: "Dublin", lat: 53.29801, lng: -6.36068 },
  { town: "Kilnamona", county: "Clare", lat: 52.86813, lng: -9.07813 },
  { town: "Kilpedder", county: "Wicklow", lat: 53.11038, lng: -6.10555 },
  { town: "Kilrane", county: "Wexford", lat: 52.24194, lng: -6.35491 },
  { town: "Kilrean", county: "Donegal", lat: 54.78163, lng: -8.31637 },
  { town: "Kilreekill", county: "Galway", lat: 53.23069, lng: -8.453 },
  { town: "Kilronan", county: "Galway", lat: 53.12321, lng: -9.67037 },
  { town: "Kilroosky", county: "Roscommon", lat: 53.67736, lng: -8.10766 },
  { town: "Kilross", county: "Tipperary", lat: 52.43697, lng: -8.28443 },
  { town: "Kilrush", county: "Clare", lat: 52.6407, lng: -9.48594 },
  { town: "Kilsallagh", county: "Galway", lat: 53.6484, lng: -8.52103 },
  { town: "Kilsallagh", county: "Mayo", lat: 53.7725, lng: -9.73907 },
  { town: "Kilsallaghan", county: "Dublin", lat: 53.47588, lng: -6.31906 },
  { town: "Kilshanny", county: "Clare", lat: 52.98247, lng: -9.29839 },
  { town: "Kilsheelan", county: "Tipperary", lat: 52.36158, lng: -7.58346 },
  { town: "Kilskeer", county: "Meath", lat: 53.69227, lng: -6.99601 },
  { town: "Kiltamagh", county: "Mayo", lat: 53.84948, lng: -8.99948 },
  { town: "Kiltealy", county: "Wexford", lat: 52.56543, lng: -6.74559 },
  { town: "Kilteel", county: "Kildare", lat: 53.23595, lng: -6.52152 },
  { town: "Kilteely", county: "Limerick", lat: 52.52254, lng: -8.39958 },
  { town: "Kiltegan", county: "Wicklow", lat: 52.90404, lng: -6.6056 },
  { town: "Kiltiernan", county: "Dublin", lat: 53.23596, lng: -6.1938 },
  { town: "Kiltoom", county: "Roscommon", lat: 53.47261, lng: -8.01943 },
  { town: "Kiltormer", county: "Galway", lat: 53.23551, lng: -8.27339 },
  { town: "Kiltullagh", county: "Galway", lat: 53.27507, lng: -8.63692 },
  { town: "Kiltyclogher", county: "Leitrim", lat: 54.35624, lng: -8.03688 },
  { town: "Kilworth", county: "Cork", lat: 52.17605, lng: -8.24731 },
  { town: "Kimmage", county: "Dublin", lat: 53.31235, lng: -6.29944 },
  { town: "Kincaslough", county: "Donegal", lat: 55.01807, lng: -8.39985 },
  { town: "Kincon", county: "Mayo", lat: 54.20775, lng: -9.31748 },
  { town: "Kingscourt", county: "Cavan", lat: 53.90687, lng: -6.80523 },
  { town: "Kinlough", county: "Leitrim", lat: 54.44872, lng: -8.28583 },
  { town: "Kinnegad", county: "Westmeath", lat: 53.45657, lng: -7.10456 },
  { town: "Kinnitty", county: "Offaly", lat: 53.09781, lng: -7.71899 },
  { town: "Kinsale", county: "Cork", lat: 51.70605, lng: -8.5225 },
  { town: "Kinsalebeg", county: "Waterford", lat: 51.96929, lng: -7.80921 },
  { town: "Kinsaley", county: "Dublin", lat: 53.42451, lng: -6.17636 },
  { town: "Kinvarra", county: "Galway", lat: 53.13884, lng: -8.93792 },
  { town: "Kishkeam", county: "Cork", lat: 52.17618, lng: -9.15875 },
  { town: "Knightsbrook", county: "Meath", lat: 53.5328, lng: -6.78699 },
  { town: "Knight's Town", county: "Kerry", lat: 51.92537, lng: -10.29187 },
  { town: "Knock", county: "Clare", lat: 52.62851, lng: -9.33352 },
  { town: "Knock", county: "Mayo", lat: 53.79007, lng: -8.92147 },
  { town: "Knockabritta", county: "Tipperary", lat: 52.57896, lng: -7.63626 },
  { town: "Knockaderry", county: "Limerick", lat: 52.46558, lng: -8.96674 },
  { town: "Knockaderry", county: "Waterford", lat: 52.14017, lng: -7.64589 },
  { town: "Knockainy", county: "Limerick", lat: 52.47384, lng: -8.46612 },
  { town: "Knockananna", county: "Wicklow", lat: 52.87287, lng: -6.49706 },
  { town: "Knockanarrigan", county: "Wicklow", lat: 52.98125, lng: -6.58254 },
  { town: "Knockanevin", county: "Cork", lat: 52.2956, lng: -8.38387 },
  { town: "Knockanillaun", county: "Mayo", lat: 54.12057, lng: -9.23108 },
  { town: "Knockanore", county: "Waterford", lat: 52.05245, lng: -7.88977 },
  { town: "Knockatallan", county: "Monaghan", lat: 54.29347, lng: -7.15355 },
  { town: "Knockavilla", county: "Tipperary", lat: 52.54696, lng: -8.01249 },
  { town: "Knockboy", county: "Waterford", lat: 52.19858, lng: -7.67945 },
  { town: "Knockbridge", county: "Louth", lat: 53.97182, lng: -6.48628 },
  { town: "Knockcroghery", county: "Roscommon", lat: 53.57439, lng: -8.09224 },
  { town: "Knockdoemore", county: "Galway", lat: 53.36767, lng: -8.89866 },
  { town: "Knockdrin", county: "Westmeath", lat: 53.56701, lng: -7.32309 },
  { town: "Knocklofty", county: "Tipperary", lat: 52.33664, lng: -7.7942 },
  { town: "Knocklong", county: "Limerick", lat: 52.43676, lng: -8.41035 },
  { town: "Knocklyon", county: "Dublin", lat: 53.28219, lng: -6.31621 },
  { town: "Knockmore", county: "Mayo", lat: 54.02376, lng: -9.17472 },
  { town: "Knocknagashel", county: "Kerry", lat: 52.33135, lng: -9.38177 },
  { town: "Knocknagree", county: "Cork", lat: 52.12213, lng: -9.2091 },
  { town: "Knocknahur", county: "Sligo", lat: 54.24361, lng: -8.54055 },
  { town: "Knocknalina", county: "Mayo", lat: 54.25103, lng: -9.89997 },
  { town: "Knockraha", county: "Cork", lat: 51.95795, lng: -8.3401 },
  { town: "Knocktopher", county: "Kilkenny", lat: 52.48319, lng: -7.21472 },
  { town: "Knockvicar", county: "Roscommon", lat: 54.00073, lng: -8.19348 },
  { town: "Labasheeda", county: "Clare", lat: 52.6237, lng: -9.24589 },
  { town: "Lackamore", county: "Tipperary", lat: 52.6981, lng: -8.29258 },
  { town: "Lackan", county: "Roscommon", lat: 53.72481, lng: -8.09566 },
  { town: "Lacken", county: "Kilkenny", lat: 52.66818, lng: -7.34059 },
  { town: "Ladysbridge", county: "Cork", lat: 51.89761, lng: -8.04333 },
  { town: "Lady's Island", county: "Wexford", lat: 52.21032, lng: -6.38144 },
  { town: "Lag", county: "Donegal", lat: 55.32124, lng: -7.321 },
  { town: "Laghy", county: "Donegal", lat: 54.61744, lng: -8.08652 },
  { town: "Lahardaun", county: "Mayo", lat: 54.02955, lng: -9.32202 },
  { town: "Lanesborough", county: "Longford", lat: 53.67245, lng: -7.98639 },
  { town: "Laragh", county: "Monaghan", lat: 54.05141, lng: -6.78456 },
  { town: "Laragh", county: "Wicklow", lat: 53.00893, lng: -6.30044 },
  { town: "Latnamard", county: "Monaghan", lat: 54.17135, lng: -7.05632 },
  { town: "Lattin", county: "Tipperary", lat: 52.46629, lng: -8.27667 },
  { town: "Latton", county: "Monaghan", lat: 54.07055, lng: -6.94659 },
  { town: "Lauragh", county: "Kerry", lat: 51.76289, lng: -9.77904 },
  { town: "Laurencetown", county: "Galway", lat: 53.23649, lng: -8.1776 },
  { town: "Lavagh", county: "Sligo", lat: 54.10524, lng: -8.68579 },
  { town: "Laytown", county: "Meath", lat: 53.68063, lng: -6.23745 },
  { town: "Leabgarrow", county: "Donegal", lat: 54.98446, lng: -8.4956 },
  { town: "Leamlara", county: "Cork", lat: 51.97138, lng: -8.26458 },
  { town: "Leap", county: "Cork", lat: 51.5812, lng: -9.14087 },
  { town: "Lecarrow", county: "Roscommon", lat: 53.54352, lng: -8.04772 },
  { town: "Leckanvy", county: "Mayo", lat: 53.77883, lng: -9.6892 },
  { town: "Leckaun", county: "Leitrim", lat: 54.27465, lng: -8.28668 },
  { town: "Leckemy", county: "Donegal", lat: 55.22244, lng: -7.07659 },
  { town: "Leenaun", county: "Galway", lat: 53.5956, lng: -9.69314 },
  { town: "Legan", county: "Longford", lat: 53.62124, lng: -7.63084 },
  { town: "Lehinch", county: "Clare", lat: 52.93217, lng: -9.34599 },
  { town: "Leighlinbridge", county: "Carlow", lat: 52.73577, lng: -6.97482 },
  { town: "Leitrim", county: "Leitrim", lat: 53.9926, lng: -8.06559 },
  { town: "Leixlip", county: "Kildare", lat: 53.36384, lng: -6.49001 },
  { town: "Lemybrien", county: "Waterford", lat: 52.16834, lng: -7.51959 },
  { town: "Letterbarra", county: "Donegal", lat: 54.69136, lng: -8.18698 },
  { town: "Letterfrack", county: "Galway", lat: 53.55361, lng: -9.94823 },
  { town: "Letterkenny", county: "Donegal", lat: 54.95584, lng: -7.73428 },
  { town: "Lettermacaward", county: "Donegal", lat: 54.8476, lng: -8.3153 },
  { town: "Lettermore", county: "Galway", lat: 53.29318, lng: -9.6606 },
  { town: "Lettermullan", county: "Galway", lat: 53.24181, lng: -9.73263 },
  { town: "Levally", county: "Galway", lat: 53.52213, lng: -8.68773 },
  { town: "Lifford", county: "Donegal", lat: 54.83287, lng: -7.48563 },
  { town: "Limerick", county: "Limerick", lat: 52.67054, lng: -8.63083 },
  {
    town: "Limerick Junction",
    county: "Tipperary",
    lat: 52.4978,
    lng: -8.20471,
  },
  { town: "Lisacul", county: "Roscommon", lat: 53.85921, lng: -8.63674 },
  { town: "Lisboduff", county: "Cavan", lat: 54.07235, lng: -7.22701 },
  { town: "Liscannor", county: "Clare", lat: 52.93836, lng: -9.39224 },
  { town: "Liscarney", county: "Mayo", lat: 53.73825, lng: -9.55419 },
  { town: "Liscarroll", county: "Cork", lat: 52.26007, lng: -8.80417 },
  { town: "Liscasey", county: "Clare", lat: 52.74467, lng: -9.15816 },
  { town: "Liscooly", county: "Donegal", lat: 54.80169, lng: -7.64286 },
  { town: "Lisdeen", county: "Clare", lat: 52.67357, lng: -9.59277 },
  { town: "Lisdoonvarna", county: "Clare", lat: 53.03017, lng: -9.28972 },
  { town: "Lisduff", county: "Cavan", lat: 53.79725, lng: -7.03071 },
  { town: "Lisgoold", county: "Cork", lat: 51.97355, lng: -8.21679 },
  { town: "Lisgrea", county: "Cavan", lat: 53.8645, lng: -7.1136 },
  { town: "Lisheen", county: "Tipperary", lat: 52.76269, lng: -7.73219 },
  { town: "Lislevane", county: "Cork", lat: 51.60822, lng: -8.75511 },
  { town: "Lismacaffry", county: "Westmeath", lat: 53.71369, lng: -7.4615 },
  { town: "Lismore", county: "Waterford", lat: 52.13582, lng: -7.93767 },
  { town: "Lisnagry", county: "Limerick", lat: 52.68046, lng: -8.51195 },
  { town: "Lisnalong", county: "Monaghan", lat: 54.08961, lng: -7.01195 },
  { town: "Lispole", county: "Kerry", lat: 52.141, lng: -10.16513 },
  { town: "Lisronagh", county: "Tipperary", lat: 52.41744, lng: -7.70289 },
  { town: "Lissacresig", county: "Cork", lat: 51.91836, lng: -9.07019 },
  { town: "Lissalway", county: "Roscommon", lat: 53.76457, lng: -8.3974 },
  { town: "Lissatava", county: "Mayo", lat: 53.65814, lng: -9.07515 },
  { town: "Lissavard", county: "Cork", lat: 51.60072, lng: -8.95929 },
  { town: "Lisselton", county: "Kerry", lat: 52.48848, lng: -9.57698 },
  { town: "Lissinagroagh", county: "Leitrim", lat: 54.30627, lng: -8.10737 },
  { town: "Listowel", county: "Kerry", lat: 52.44605, lng: -9.48537 },
  { town: "Lisvarrinane", county: "Tipperary", lat: 52.41239, lng: -8.22369 },
  { town: "Littleton", county: "Tipperary", lat: 52.63698, lng: -7.73541 },
  { town: "Lixnaw", county: "Kerry", lat: 52.40646, lng: -9.61813 },
  { town: "Lobinstown", county: "Meath", lat: 53.7851, lng: -6.64636 },
  { town: "Loch Gowna", county: "Cavan", lat: 53.8737, lng: -7.53368 },
  { town: "Loghill", county: "Limerick", lat: 52.59546, lng: -9.19257 },
  { town: "Logleagh", county: "Waterford", lat: 52.18266, lng: -7.98728 },
  { town: "Lombardstown", county: "Cork", lat: 52.11991, lng: -8.78277 },
  { town: "Longford", county: "Longford", lat: 53.72763, lng: -7.79326 },
  { town: "Longwood", county: "Meath", lat: 53.45501, lng: -6.9248 },
  { town: "Lorrha", county: "Tipperary", lat: 53.0931, lng: -8.12281 },
  { town: "Loughanavally", county: "Westmeath", lat: 53.48673, lng: -7.52661 },
  { town: "Loughanure", county: "Donegal", lat: 54.99816, lng: -8.2915 },
  { town: "Loughaunbeg", county: "Galway", lat: 53.2433, lng: -9.40573 },
  { town: "Loughduff", county: "Cavan", lat: 53.87832, lng: -7.46407 },
  { town: "Loughglinn", county: "Roscommon", lat: 53.82255, lng: -8.56159 },
  { town: "Loughlinstown", county: "Dublin", lat: 53.24457, lng: -6.12133 },
  { town: "Loughmoe", county: "Tipperary", lat: 52.75641, lng: -7.82964 },
  { town: "Loughmorne", county: "Monaghan", lat: 54.07697, lng: -6.84227 },
  { town: "Loughrea", county: "Galway", lat: 53.19891, lng: -8.56609 },
  { town: "Louisburgh", county: "Mayo", lat: 53.76314, lng: -9.80908 },
  { town: "Louth", county: "Louth", lat: 53.9512, lng: -6.53964 },
  { town: "Lucan", county: "Dublin", lat: 53.35721, lng: -6.44986 },
  { town: "Luggacurren", county: "Laois", lat: 52.9472, lng: -7.12679 },
  { town: "Lurga", county: "Mayo", lat: 53.92198, lng: -8.78542 },
  { town: "Lusk", county: "Dublin", lat: 53.52633, lng: -6.16588 },
  { town: "Lyracrumpane", county: "Kerry", lat: 52.35201, lng: -9.49196 },
  { town: "Lyre", county: "Cork", lat: 52.08176, lng: -8.85806 },
  { town: "Maas", county: "Donegal", lat: 54.82933, lng: -8.36787 },
  { town: "Macroom", county: "Cork", lat: 51.9048, lng: -8.95885 },
  { town: "Madabawn", county: "Cavan", lat: 54.03063, lng: -7.02224 },
  { town: "Maddockstown", county: "Kilkenny", lat: 52.63182, lng: -7.19424 },
  { town: "Maganey", county: "Kildare", lat: 52.90881, lng: -6.93091 },
  { town: "Magheraboy", county: "Sligo", lat: 54.26813, lng: -8.4907 },
  { town: "Magheracloone", county: "Monaghan", lat: 53.94248, lng: -6.78022 },
  { town: "Magheradrumman", county: "Donegal", lat: 55.25251, lng: -7.67249 },
  { town: "Maghery", county: "Donegal", lat: 54.92928, lng: -8.44177 },
  { town: "Mahonburgh", county: "Clare", lat: 52.82291, lng: -9.05428 },
  { town: "Mahoonagh", county: "Limerick", lat: 52.43069, lng: -9.01008 },
  { town: "Malahide", county: "Dublin", lat: 53.45093, lng: -6.15015 },
  { town: "Malin", county: "Donegal", lat: 55.29493, lng: -7.26308 },
  { town: "Malin Beg", county: "Donegal", lat: 54.66801, lng: -8.78325 },
  { town: "Mallaranny", county: "Mayo", lat: 53.9068, lng: -9.7751 },
  { town: "Mallow", county: "Cork", lat: 52.13897, lng: -8.65391 },
  { town: "Manorcunningham", county: "Donegal", lat: 54.95526, lng: -7.62336 },
  { town: "Manorhamilton", county: "Leitrim", lat: 54.30487, lng: -8.17669 },
  { town: "Mantua", county: "Roscommon", lat: 53.83642, lng: -8.28579 },
  { town: "Manulla", county: "Mayo", lat: 53.83332, lng: -9.18523 },
  { town: "Marino", county: "Dublin", lat: 53.36952, lng: -6.23044 },
  { town: "Marshalstown", county: "Wexford", lat: 52.54976, lng: -6.59731 },
  { town: "Martinstown", county: "Limerick", lat: 52.39689, lng: -8.47912 },
  { town: "Mastergeehy", county: "Kerry", lat: 51.88305, lng: -10.10317 },
  { town: "Matehy", county: "Cork", lat: 51.95349, lng: -8.65523 },
  { town: "Maum", county: "Galway", lat: 53.51909, lng: -9.56582 },
  { town: "Mauricesmills", county: "Clare", lat: 52.89719, lng: -9.14648 },
  { town: "Maynooth", county: "Kildare", lat: 53.38129, lng: -6.59186 },
  { town: "Mayo", county: "Mayo", lat: 53.76217, lng: -9.11986 },
  { town: "Meanus", county: "Limerick", lat: 52.51089, lng: -8.60861 },
  { town: "Meelin", county: "Cork", lat: 52.26504, lng: -9.03233 },
  { town: "Meenaneary", county: "Donegal", lat: 54.7012, lng: -8.60977 },
  { town: "Meenlaragh", county: "Donegal", lat: 55.14518, lng: -8.18508 },
  { town: "Melmore", county: "Donegal", lat: 55.24185, lng: -7.79323 },
  { town: "Menlough", county: "Galway", lat: 53.42124, lng: -8.57173 },
  { town: "Merrion", county: "Dublin", lat: 53.3184, lng: -6.21154 },
  { town: "Midfield", county: "Mayo", lat: 53.89711, lng: -8.9272 },
  { town: "Midleton", county: "Cork", lat: 51.91372, lng: -8.17223 },
  { town: "Milehouse", county: "Wexford", lat: 52.51674, lng: -6.59877 },
  { town: "Milestone", county: "Tipperary", lat: 52.67566, lng: -8.07779 },
  { town: "Milford", county: "Cork", lat: 52.34074, lng: -8.85654 },
  { town: "Millford", county: "Donegal", lat: 55.08839, lng: -7.69896 },
  { town: "Millstreet", county: "Cork", lat: 52.06013, lng: -9.06471 },
  { town: "Milltown", county: "Cavan", lat: 54.06558, lng: -7.4775 },
  { town: "Milltown", county: "Donegal", lat: 55.19642, lng: -7.73023 },
  { town: "Milltown", county: "Dublin", lat: 53.3106, lng: -6.24805 },
  { town: "Milltown", county: "Galway", lat: 53.6137, lng: -8.89896 },
  { town: "Milltown", county: "Kerry", lat: 52.14519, lng: -9.7175 },
  { town: "Milltown", county: "Kildare", lat: 53.20511, lng: -6.8612 },
  { town: "Milltown Malbay", county: "Clare", lat: 52.8562, lng: -9.40078 },
  { town: "Milltownpass", county: "Westmeath", lat: 53.44223, lng: -7.24278 },
  { town: "Minane Bridge", county: "Cork", lat: 51.76223, lng: -8.37131 },
  { town: "Mitchelstown", county: "Cork", lat: 52.26621, lng: -8.26989 },
  { town: "Moate", county: "Westmeath", lat: 53.395, lng: -7.71862 },
  { town: "Moat Farrell", county: "Longford", lat: 53.73802, lng: -7.6427 },
  { town: "Model Village", county: "Cork", lat: 51.92564, lng: -8.74359 },
  { town: "Model Village", county: "Cork", lat: 51.92605, lng: -8.6048 },
  { town: "Mogeely", county: "Cork", lat: 51.93145, lng: -8.05713 },
  { town: "Mohill", county: "Leitrim", lat: 53.92183, lng: -7.86532 },
  { town: "Monaghan", county: "Monaghan", lat: 54.24924, lng: -6.96831 },
  { town: "Monamolin", county: "Wexford", lat: 52.55686, lng: -6.34962 },
  { town: "Monaster", county: "Limerick", lat: 52.52204, lng: -8.66936 },
  { town: "Monasteraden", county: "Sligo", lat: 53.94373, lng: -8.49557 },
  { town: "Monasterboice", county: "Louth", lat: 53.78752, lng: -6.4163 },
  { town: "Monasterevin", county: "Kildare", lat: 53.14007, lng: -7.06038 },
  { town: "Moneenroe", county: "Kilkenny", lat: 52.84006, lng: -7.15486 },
  { town: "Money Beg", county: "Donegal", lat: 55.0316, lng: -8.14484 },
  { town: "Moneygall", county: "Offaly", lat: 52.87973, lng: -7.95781 },
  { town: "Moneygashel", county: "Cavan", lat: 54.25325, lng: -7.89807 },
  { town: "Monilea", county: "Westmeath", lat: 53.58392, lng: -7.30719 },
  { town: "Monivea", county: "Galway", lat: 53.37426, lng: -8.70272 },
  { town: "Monkstown", county: "Cork", lat: 51.84854, lng: -8.33257 },
  { town: "Monkstown", county: "Dublin", lat: 53.2937, lng: -6.15396 },
  { town: "Mooncoin", county: "Kilkenny", lat: 52.29124, lng: -7.25094 },
  { town: "Moone", county: "Kildare", lat: 52.97554, lng: -6.81571 },
  { town: "Moroe", county: "Limerick", lat: 52.65054, lng: -8.39914 },
  { town: "Mothel", county: "Waterford", lat: 52.2999, lng: -7.41918 },
  {
    town: "Mountbellew Bridge",
    county: "Galway",
    lat: 53.47028,
    lng: -8.50223,
  },
  { town: "Mountbolus", county: "Offaly", lat: 53.19602, lng: -7.62869 },
  { town: "Mountcharles", county: "Donegal", lat: 54.64744, lng: -8.19472 },
  { town: "Mountcollins", county: "Limerick", lat: 52.31927, lng: -9.23474 },
  { town: "Mountheaton", county: "Offaly", lat: 52.96012, lng: -7.85795 },
  { town: "Mountmellick", county: "Laois", lat: 53.11814, lng: -7.32674 },
  { town: "Mount Merrion", county: "Dublin", lat: 53.29559, lng: -6.2111 },
  { town: "Mount Nugent", county: "Cavan", lat: 53.81794, lng: -7.25632 },
  { town: "Mountrath", county: "Laois", lat: 53.00256, lng: -7.47514 },
  { town: "Mountshannon", county: "Clare", lat: 52.93172, lng: -8.42983 },
  { town: "Mount Talbot", county: "Roscommon", lat: 53.53018, lng: -8.28262 },
  { town: "Mount Temple", county: "Westmeath", lat: 53.428, lng: -7.77669 },
  { town: "Mount Uniacke", county: "Cork", lat: 51.97953, lng: -8.00396 },
  { town: "Moville", county: "Donegal", lat: 55.18902, lng: -7.04005 },
  { town: "Moyard", county: "Galway", lat: 53.54382, lng: -10.00165 },
  { town: "Moyasta", county: "Clare", lat: 52.66781, lng: -9.53812 },
  { town: "Moycullen", county: "Galway", lat: 53.33875, lng: -9.18148 },
  { town: "Moydow", county: "Longford", lat: 53.6645, lng: -7.78591 },
  { town: "Moyglass", county: "Tipperary", lat: 52.52419, lng: -7.71749 },
  { town: "Moylough", county: "Galway", lat: 53.48771, lng: -8.56588 },
  { town: "Moynalty", county: "Meath", lat: 53.78901, lng: -6.88551 },
  { town: "Moyne", county: "Longford", lat: 53.91522, lng: -7.62851 },
  { town: "Moyne", county: "Roscommon", lat: 53.84869, lng: -8.54131 },
  { town: "Moyne", county: "Tipperary", lat: 52.73586, lng: -7.71471 },
  { town: "Moyne", county: "Wicklow", lat: 52.86358, lng: -6.46426 },
  { town: "Moyvore", county: "Westmeath", lat: 53.53542, lng: -7.63173 },
  { town: "Moyvoughly", county: "Westmeath", lat: 53.44191, lng: -7.69931 },
  { town: "Mucklagh", county: "Offaly", lat: 53.25053, lng: -7.55018 },
  { town: "Muckross", county: "Kerry", lat: 52.02513, lng: -9.48999 },
  { town: "Muff", county: "Donegal", lat: 55.06806, lng: -7.26895 },
  { town: "Mulhuddart", county: "Dublin", lat: 53.40168, lng: -6.39982 },
  { town: "Mullagh", county: "Cavan", lat: 53.81152, lng: -6.94599 },
  { town: "Mullagh", county: "Clare", lat: 52.79984, lng: -9.41446 },
  { town: "Mullaghmore", county: "Sligo", lat: 54.46555, lng: -8.44945 },
  { town: "Mullennaglogh", county: "Tipperary", lat: 52.44646, lng: -7.46933 },
  { town: "Mullies", county: "Leitrim", lat: 54.34121, lng: -8.21248 },
  { town: "Mullinahone", county: "Tipperary", lat: 52.51272, lng: -7.50368 },
  { town: "Mullinavat", county: "Kilkenny", lat: 52.36899, lng: -7.17094 },
  { town: "Mullincross", county: "Louth", lat: 53.85876, lng: -6.37587 },
  { town: "Mullingar", county: "Westmeath", lat: 53.52594, lng: -7.33815 },
  { town: "Multyfarnham", county: "Westmeath", lat: 53.62527, lng: -7.38932 },
  { town: "Mungret", county: "Limerick", lat: 52.63641, lng: -8.69298 },
  { town: "Murntown", county: "Wexford", lat: 52.28724, lng: -6.52225 },
  { town: "Murreagh", county: "Kerry", lat: 52.18592, lng: -10.36077 },
  { town: "Murrisk", county: "Mayo", lat: 53.77935, lng: -9.63701 },
  { town: "Myrtleville", county: "Cork", lat: 51.783, lng: -8.30035 },
  { town: "Myshall", county: "Carlow", lat: 52.68588, lng: -6.78042 },
  { town: "Naas", county: "Kildare", lat: 53.22057, lng: -6.65932 },
  { town: "Naran", county: "Donegal", lat: 54.83865, lng: -8.44677 },
  { town: "Narraghmore", county: "Kildare", lat: 53.04868, lng: -6.82943 },
  { town: "Naul", county: "Dublin", lat: 53.58563, lng: -6.28942 },
  { town: "Navan", county: "Meath", lat: 53.65543, lng: -6.68671 },
  { town: "Neale", county: "Mayo", lat: 53.57291, lng: -9.22964 },
  { town: "Nenagh", county: "Tipperary", lat: 52.8643, lng: -8.19756 },
  { town: "Newbawn", county: "Wexford", lat: 52.34283, lng: -6.79126 },
  { town: "New Birmingham", county: "Tipperary", lat: 52.61658, lng: -7.63917 },
  { town: "Newbliss", county: "Monaghan", lat: 54.15764, lng: -7.13682 },
  { town: "Newbridge", county: "Kildare", lat: 53.18114, lng: -6.79656 },
  { town: "Newcastle", county: "Dublin", lat: 53.29982, lng: -6.50103 },
  { town: "Newcastle", county: "Limerick", lat: 52.66702, lng: -8.56798 },
  { town: "Newcastle", county: "Tipperary", lat: 52.2726, lng: -7.81128 },
  { town: "Newcastle", county: "Wicklow", lat: 53.07112, lng: -6.06385 },
  { town: "Newcastle West", county: "Limerick", lat: 52.44875, lng: -9.05458 },
  { town: "Newcestown", county: "Cork", lat: 51.78142, lng: -8.86923 },
  { town: "Newchapel", county: "Tipperary", lat: 52.98725, lng: -8.22486 },
  { town: "Newgrove", county: "Galway", lat: 53.50876, lng: -8.43063 },
  { town: "Newinn", county: "Tipperary", lat: 52.44059, lng: -7.88161 },
  { town: "New Inn", county: "Cavan", lat: 53.90257, lng: -7.17903 },
  { town: "New Inn", county: "Galway", lat: 53.29969, lng: -8.48968 },
  { town: "Newmarket", county: "Cork", lat: 52.21576, lng: -8.99959 },
  { town: "Newmarket On Fergus", county: "Clare", lat: 52.76066, lng: -8.899 },
  { town: "Newport", county: "Mayo", lat: 53.88528, lng: -9.54637 },
  { town: "New Ross", county: "Wexford", lat: 52.39621, lng: -6.94719 },
  { town: "Newtown", county: "Clare", lat: 52.63406, lng: -9.73328 },
  { town: "Newtown", county: "Kildare", lat: 53.3942, lng: -6.75903 },
  { town: "Newtown", county: "Tipperary", lat: 52.87781, lng: -8.29281 },
  { town: "Newtown", county: "Waterford", lat: 52.2217, lng: -7.38797 },
  { town: "Newtown", county: "Waterford", lat: 51.98682, lng: -7.9057 },
  { town: "Newtown Cashel", county: "Longford", lat: 53.58751, lng: -7.93796 },
  {
    town: "Newtown Cunningham",
    county: "Donegal",
    lat: 54.99881,
    lng: -7.51279,
  },
  { town: "Newtown Forbes", county: "Longford", lat: 53.76548, lng: -7.83562 },
  { town: "Newtown Gore", county: "Leitrim", lat: 54.04268, lng: -7.67435 },
  {
    town: "Newtown Monasterboice",
    county: "Louth",
    lat: 53.77012,
    lng: -6.40219,
  },
  {
    town: "Newtown Mount Kennedy",
    county: "Wicklow",
    lat: 53.09278,
    lng: -6.11203,
  },
  { town: "Newtown Sandes", county: "Kerry", lat: 52.50168, lng: -9.37054 },
  { town: "Newtownshandrum", county: "Cork", lat: 52.3471, lng: -8.76966 },
  {
    town: "New Twopothouse Village",
    county: "Cork",
    lat: 52.18598,
    lng: -8.67027,
  },
  { town: "Ninemilehouse", county: "Tipperary", lat: 52.46275, lng: -7.46332 },
  { town: "Nobber", county: "Meath", lat: 53.8217, lng: -6.74795 },
  { town: "Nohaval", county: "Cork", lat: 51.72225, lng: -8.40025 },
  { town: "Northlands", county: "Cavan", lat: 53.96894, lng: -6.8659 },
  { town: "Nurney", county: "Carlow", lat: 52.75207, lng: -6.91102 },
  { town: "Nurney", county: "Kildare", lat: 53.09664, lng: -6.94822 },
  { town: "Oatfield", county: "Clare", lat: 52.75512, lng: -8.67858 },
  { town: "O'Briensbridge", county: "Clare", lat: 52.7527, lng: -8.49859 },
  { town: "O'Callaghansmills", county: "Clare", lat: 52.84217, lng: -8.686 },
  { town: "Ogonnelloe", county: "Clare", lat: 52.88693, lng: -8.45899 },
  { town: "Oilgate", county: "Wexford", lat: 52.42399, lng: -6.53089 },
  { town: "Oldbawn", county: "Dublin", lat: 53.2749, lng: -6.36761 },
  { town: "Oldcastle", county: "Meath", lat: 53.77002, lng: -7.16242 },
  { town: "Oldleighlin", county: "Carlow", lat: 52.73645, lng: -7.02345 },
  { town: "Oldtown", county: "Dublin", lat: 53.52268, lng: -6.31543 },
  { town: "Old Town", county: "Laois", lat: 52.90809, lng: -7.3742 },
  { town: "Omeath", county: "Louth", lat: 54.08826, lng: -6.25933 },
  { town: "Oola", county: "Limerick", lat: 52.53064, lng: -8.26085 },
  { town: "Oranmore", county: "Galway", lat: 53.27034, lng: -8.92195 },
  { town: "Oughterard", county: "Galway", lat: 53.42855, lng: -9.31895 },
  { town: "Oulart", county: "Wexford", lat: 52.50392, lng: -6.39086 },
  { town: "Owenbeg", county: "Sligo", lat: 54.23262, lng: -8.94168 },
  { town: "Owning", county: "Kilkenny", lat: 52.38715, lng: -7.34395 },
  { town: "Palace", county: "Wexford", lat: 52.42453, lng: -6.79985 },
  { town: "Palatine", county: "Carlow", lat: 52.865, lng: -6.86104 },
  { town: "Pallas Grean", county: "Limerick", lat: 52.54884, lng: -8.35472 },
  {
    town: "Pallas Grean New",
    county: "Limerick",
    lat: 52.56908,
    lng: -8.33793,
  },
  { town: "Pallaskenry", county: "Limerick", lat: 52.64403, lng: -8.86732 },
  { town: "Palmerston", county: "Dublin", lat: 53.35661, lng: -6.37149 },
  { town: "Park", county: "Mayo", lat: 53.90691, lng: -9.19881 },
  { town: "Parteen", county: "Clare", lat: 52.69684, lng: -8.61498 },
  { town: "Partry", county: "Mayo", lat: 53.69639, lng: -9.27806 },
  { town: "Passage East", county: "Waterford", lat: 52.2397, lng: -6.9744 },
  { town: "Passage West", county: "Cork", lat: 51.87207, lng: -8.33621 },
  { town: "Patrickswell", county: "Limerick", lat: 52.59822, lng: -8.70686 },
  { town: "Paulstown", county: "Kilkenny", lat: 52.68132, lng: -7.02113 },
  { town: "Peterswell", county: "Galway", lat: 53.11146, lng: -8.73688 },
  { town: "Pettigoe", county: "Donegal", lat: 54.55024, lng: -7.83259 },
  { town: "Pilltown", county: "Kilkenny", lat: 52.35066, lng: -7.325 },
  { town: "Poles", county: "Cavan", lat: 53.991, lng: -7.29008 },
  { town: "Pollagh", county: "Offaly", lat: 53.27684, lng: -7.70918 },
  { town: "Poppintree", county: "Dublin", lat: 53.40017, lng: -6.28526 },
  { town: "Portarlington", county: "Laois", lat: 53.16121, lng: -7.18776 },
  { town: "Portlaoise", county: "Laois", lat: 53.03281, lng: -7.2988 },
  { town: "Portlaw", county: "Waterford", lat: 52.2882, lng: -7.31444 },
  { town: "Portmagee", county: "Kerry", lat: 51.88543, lng: -10.36193 },
  { town: "Portmarnock", county: "Dublin", lat: 53.42561, lng: -6.13161 },
  { town: "Portnablahy", county: "Donegal", lat: 55.17847, lng: -7.93425 },
  { town: "Portnoo", county: "Donegal", lat: 54.84005, lng: -8.46313 },
  { town: "Portroe", county: "Tipperary", lat: 52.8847, lng: -8.34487 },
  { town: "Portsalon", county: "Donegal", lat: 55.20563, lng: -7.63508 },
  { town: "Portumna", county: "Galway", lat: 53.09278, lng: -8.21854 },
  { town: "Porturlin", county: "Mayo", lat: 54.31608, lng: -9.71495 },
  { town: "Poulanargid", county: "Cork", lat: 51.83705, lng: -8.92768 },
  { town: "Prosperous", county: "Kildare", lat: 53.28813, lng: -6.75501 },
  { town: "Puckaun", county: "Tipperary", lat: 52.93188, lng: -8.23855 },
  { town: "Querrin", county: "Clare", lat: 52.63547, lng: -9.596 },
  { town: "Quigley's Point", county: "Donegal", lat: 55.12488, lng: -7.19493 },
  { town: "Quilty", county: "Clare", lat: 52.81726, lng: -9.45592 },
  { town: "Quin", county: "Clare", lat: 52.81758, lng: -8.86378 },
  { town: "Rahan", county: "Offaly", lat: 53.27979, lng: -7.6159 },
  { town: "Rahara", county: "Roscommon", lat: 53.52398, lng: -8.14283 },
  { town: "Raharney", county: "Westmeath", lat: 53.52411, lng: -7.09609 },
  { town: "Raheen", county: "Wexford", lat: 52.39205, lng: -6.79481 },
  { town: "Raheny", county: "Dublin", lat: 53.3804, lng: -6.17511 },
  { town: "Ramsgrange", county: "Wexford", lat: 52.24505, lng: -6.92012 },
  { town: "Ranelagh", county: "Dublin", lat: 53.32685, lng: -6.25708 },
  { town: "Raphoe", county: "Donegal", lat: 54.87388, lng: -7.59944 },
  { town: "Rathangan", county: "Kildare", lat: 53.22102, lng: -6.99455 },
  { town: "Rathcabban", county: "Tipperary", lat: 53.11729, lng: -8.02421 },
  { town: "Rathcarran", county: "Meath", lat: 53.61031, lng: -6.86365 },
  { town: "Rathconrath", county: "Westmeath", lat: 53.52995, lng: -7.52196 },
  { town: "Rathcool", county: "Cork", lat: 52.09425, lng: -8.97406 },
  { town: "Rathcoole", county: "Dublin", lat: 53.28173, lng: -6.46618 },
  { town: "Rathcormack", county: "Cork", lat: 52.07827, lng: -8.28124 },
  { town: "Rathdangan", county: "Wicklow", lat: 52.91979, lng: -6.54591 },
  { town: "Rathdowney", county: "Laois", lat: 52.85572, lng: -7.58673 },
  { town: "Rathdrum", county: "Wicklow", lat: 52.93231, lng: -6.2311 },
  { town: "Rathfarnham", county: "Dublin", lat: 53.30049, lng: -6.28386 },
  { town: "Rathfeigh", county: "Meath", lat: 53.59337, lng: -6.48619 },
  { town: "Rathgar", county: "Dublin", lat: 53.31889, lng: -6.26916 },
  { town: "Rathgormuck", county: "Waterford", lat: 52.3049, lng: -7.49449 },
  { town: "Rathkea", county: "Tipperary", lat: 52.44193, lng: -8.23696 },
  { town: "Rathkeale", county: "Limerick", lat: 52.52381, lng: -8.9371 },
  { town: "Rathkenny", county: "Meath", lat: 53.75164, lng: -6.64543 },
  { town: "Rathlackan", county: "Mayo", lat: 54.29386, lng: -9.25838 },
  { town: "Rathlee", county: "Sligo", lat: 54.27531, lng: -9.02456 },
  { town: "Rathmelton", county: "Donegal", lat: 55.03685, lng: -7.65037 },
  { town: "Rathmines", county: "Dublin", lat: 53.32131, lng: -6.26569 },
  { town: "Rathmolyon", county: "Meath", lat: 53.488, lng: -6.80466 },
  { town: "Rathmore", county: "Kerry", lat: 52.08425, lng: -9.21844 },
  { town: "Rathmore", county: "Kildare", lat: 53.21744, lng: -6.56388 },
  { town: "Rathmore", county: "Meath", lat: 53.64645, lng: -6.86258 },
  { town: "Rathmullan", county: "Donegal", lat: 55.09707, lng: -7.5363 },
  { town: "Rathnamagh", county: "Mayo", lat: 54.13712, lng: -9.30887 },
  { town: "Rathnew", county: "Wicklow", lat: 52.9946, lng: -6.08283 },
  { town: "Rathnure", county: "Wexford", lat: 52.49919, lng: -6.76862 },
  { town: "Rathowen", county: "Westmeath", lat: 53.65608, lng: -7.5204 },
  { town: "Rathtoe", county: "Carlow", lat: 52.78722, lng: -6.80002 },
  { town: "Rathvilly", county: "Carlow", lat: 52.87984, lng: -6.69606 },
  { town: "Ratoath", county: "Meath", lat: 53.50629, lng: -6.46568 },
  { town: "Ravensdale", county: "Louth", lat: 54.05636, lng: -6.33839 },
  { town: "Ray", county: "Donegal", lat: 55.07433, lng: -7.59647 },
  { town: "Readypenny", county: "Louth", lat: 53.93197, lng: -6.46972 },
  { town: "Reananerree", county: "Cork", lat: 51.89974, lng: -9.16579 },
  { town: "Reanascreena", county: "Cork", lat: 51.62607, lng: -9.05505 },
  { town: "Rear Cross", county: "Tipperary", lat: 52.68639, lng: -8.23752 },
  { town: "Recess", county: "Galway", lat: 53.46553, lng: -9.72371 },
  { town: "Redcastle", county: "Donegal", lat: 55.1594, lng: -7.13433 },
  { town: "Redcross", county: "Wicklow", lat: 52.88974, lng: -6.14529 },
  { town: "Redhills", county: "Cavan", lat: 54.09909, lng: -7.31933 },
  { town: "Rerrin", county: "Cork", lat: 51.6324, lng: -9.81745 },
  { town: "Rhode", county: "Offaly", lat: 53.34999, lng: -7.19897 },
  { town: "Ringaskiddy", county: "Cork", lat: 51.83043, lng: -8.32195 },
  { town: "Ringsend", county: "Dublin", lat: 53.34186, lng: -6.22672 },
  { town: "Ringville", county: "Waterford", lat: 52.04689, lng: -7.57189 },
  { town: "Rinnafarset", county: "Donegal", lat: 55.03846, lng: -8.31712 },
  { town: "Riverstick", county: "Cork", lat: 51.77302, lng: -8.49604 },
  { town: "Riverstown", county: "Sligo", lat: 54.13044, lng: -8.39525 },
  { town: "Robertstown", county: "Kildare", lat: 53.26926, lng: -6.81533 },
  { town: "Robinstown", county: "Meath", lat: 53.59468, lng: -6.7281 },
  { town: "Rochfortbridge", county: "Westmeath", lat: 53.41464, lng: -7.29968 },
  { town: "Rockbrook", county: "Dublin", lat: 53.26208, lng: -6.30739 },
  { town: "Rockchapel", county: "Cork", lat: 52.2891, lng: -9.14419 },
  { town: "Rockcorry", county: "Monaghan", lat: 54.1179, lng: -7.01485 },
  { town: "Ronanstown", county: "Dublin", lat: 53.33777, lng: -6.40633 },
  { town: "Roosky", county: "Roscommon", lat: 53.83277, lng: -7.92154 },
  { town: "Roscommon", county: "Roscommon", lat: 53.62759, lng: -8.18909 },
  { town: "Roscrea", county: "Tipperary", lat: 52.95517, lng: -7.79847 },
  { town: "Rosegreen", county: "Tipperary", lat: 52.46944, lng: -7.83278 },
  { town: "Rosemount", county: "Westmeath", lat: 53.43077, lng: -7.63655 },
  { town: "Rosenallis", county: "Laois", lat: 53.13569, lng: -7.40705 },
  { town: "Rosmore", county: "Galway", lat: 53.03457, lng: -8.3175 },
  { town: "Rosnakill", county: "Donegal", lat: 55.18967, lng: -7.68665 },
  { town: "Ross", county: "Meath", lat: 53.78821, lng: -7.2939 },
  { town: "Rossadrehid", county: "Tipperary", lat: 52.41478, lng: -8.10784 },
  { town: "Rossaveel", county: "Galway", lat: 53.27104, lng: -9.5477 },
  { town: "Rossbeg", county: "Donegal", lat: 54.82108, lng: -8.52374 },
  { town: "Ross Carbery", county: "Cork", lat: 51.5765, lng: -9.03611 },
  { town: "Rosses Point", county: "Sligo", lat: 54.30476, lng: -8.56403 },
  { town: "Rossinver", county: "Leitrim", lat: 54.38518, lng: -8.11172 },
  { town: "Rosslare", county: "Wexford", lat: 52.27204, lng: -6.38923 },
  { town: "Rosslare Harbour", county: "Wexford", lat: 52.25131, lng: -6.34149 },
  { town: "Rossmackowen", county: "Cork", lat: 51.66288, lng: -9.82328 },
  { town: "Rossmore", county: "Cork", lat: 51.67053, lng: -8.99074 },
  { town: "Rossmore", county: "Tipperary", lat: 52.61859, lng: -8.00304 },
  { town: "Ross Port", county: "Mayo", lat: 54.28533, lng: -9.79681 },
  { town: "Ross West", county: "Mayo", lat: 53.92028, lng: -9.2422 },
  { town: "Rostellan", county: "Cork", lat: 51.84466, lng: -8.18763 },
  { town: "Roundstone", county: "Galway", lat: 53.39642, lng: -9.91902 },
  { town: "Roundwood", county: "Wicklow", lat: 53.06579, lng: -6.22419 },
  { town: "Ruan", county: "Clare", lat: 52.92908, lng: -8.98987 },
  { town: "Runnaroddan", county: "Roscommon", lat: 53.91642, lng: -8.3765 },
  { town: "Rush", county: "Dublin", lat: 53.52567, lng: -6.09115 },
  { town: "Rush Hall", county: "Laois", lat: 52.95608, lng: -7.54649 },
  { town: "Rylane", county: "Cork", lat: 51.9839, lng: -8.84085 },
  { town: "Saggart", county: "Dublin", lat: 53.28084, lng: -6.44319 },
  { town: "Saint Margaret's", county: "Dublin", lat: 53.43109, lng: -6.29827 },
  { town: "Saint Mullin's", county: "Carlow", lat: 52.49114, lng: -6.92977 },
  { town: "Saleen", county: "Cork", lat: 51.86372, lng: -8.16182 },
  { town: "Sallins", county: "Kildare", lat: 53.25107, lng: -6.66524 },
  { town: "Sallynoggin", county: "Dublin", lat: 53.28003, lng: -6.13571 },
  { town: "Salthill", county: "Galway", lat: 53.26256, lng: -9.07043 },
  { town: "Saltmills", county: "Wexford", lat: 52.22535, lng: -6.83413 },
  { town: "Sandyford", county: "Dublin", lat: 53.26997, lng: -6.22493 },
  { town: "Sandymount", county: "Dublin", lat: 53.33056, lng: -6.21575 },
  { town: "Santry", county: "Dublin", lat: 53.39959, lng: -6.24402 },
  { town: "Scardaun", county: "Mayo", lat: 53.65855, lng: -9.00847 },
  { town: "Scarriff", county: "Clare", lat: 52.91124, lng: -8.53067 },
  { town: "Scartaglin", county: "Kerry", lat: 52.17977, lng: -9.4076 },
  { town: "Scotshouse", county: "Monaghan", lat: 54.12262, lng: -7.24711 },
  { town: "Scotstown", county: "Monaghan", lat: 54.27789, lng: -7.06696 },
  { town: "Scramoge", county: "Roscommon", lat: 53.76753, lng: -8.05125 },
  { town: "Selloo", county: "Monaghan", lat: 54.25337, lng: -7.12564 },
  { town: "Shanagarry", county: "Cork", lat: 51.85271, lng: -8.02992 },
  { town: "Shanagolden", county: "Limerick", lat: 52.57464, lng: -9.10035 },
  { town: "Shanballymore", county: "Cork", lat: 52.2188, lng: -8.476 },
  { town: "Shankill", county: "Dublin", lat: 53.23327, lng: -6.12377 },
  { town: "Shanlaragh", county: "Cork", lat: 51.78319, lng: -9.07315 },
  { town: "Shannon", county: "Clare", lat: 52.71247, lng: -8.87307 },
  { town: "Shannonbridge", county: "Offaly", lat: 53.27963, lng: -8.04473 },
  { town: "Shannon Harbour", county: "Offaly", lat: 53.22194, lng: -7.95106 },
  { town: "Shantonagh", county: "Monaghan", lat: 54.04533, lng: -6.86417 },
  { town: "Sharavogue", county: "Offaly", lat: 53.03391, lng: -7.90057 },
  { town: "Sheean", county: "Mayo", lat: 53.81033, lng: -9.46422 },
  { town: "Shercock", county: "Cavan", lat: 53.99501, lng: -6.89798 },
  { town: "Shillelagh", county: "Wicklow", lat: 52.75452, lng: -6.53346 },
  { town: "Shinrone", county: "Offaly", lat: 52.98441, lng: -7.92457 },
  { town: "Shrule", county: "Mayo", lat: 53.52027, lng: -9.08838 },
  { town: "Silvermines", county: "Tipperary", lat: 52.79436, lng: -8.23663 },
  { town: "Sixmilebridge", county: "Clare", lat: 52.74363, lng: -8.77765 },
  { town: "Skeaghvasteen", county: "Kilkenny", lat: 52.5909, lng: -6.99235 },
  { town: "Skerries", county: "Dublin", lat: 53.58055, lng: -6.10788 },
  { town: "Skibbereen", county: "Cork", lat: 51.55593, lng: -9.26213 },
  { town: "Skreen", county: "Meath", lat: 53.58579, lng: -6.5616 },
  { town: "Skreen", county: "Sligo", lat: 54.24088, lng: -8.73163 },
  { town: "Skull", county: "Cork", lat: 51.52687, lng: -9.54798 },
  { town: "Slade", county: "Wexford", lat: 52.1358, lng: -6.9129 },
  { town: "Slane", county: "Meath", lat: 53.70865, lng: -6.54366 },
  { town: "Slanemore", county: "Westmeath", lat: 53.54565, lng: -7.42836 },
  { town: "Slieveroe", county: "Kilkenny", lat: 52.28334, lng: -7.06787 },
  { town: "Sligo", county: "Sligo", lat: 54.27661, lng: -8.47609 },
  { town: "Smarmore", county: "Louth", lat: 53.82369, lng: -6.55747 },
  { town: "Smithborough", county: "Monaghan", lat: 54.2213, lng: -7.09815 },
  { town: "Sneem", county: "Kerry", lat: 51.8374, lng: -9.89833 },
  { town: "Sooey", county: "Sligo", lat: 54.16574, lng: -8.39317 },
  { town: "Spanish Point", county: "Clare", lat: 52.84664, lng: -9.43969 },
  { town: "Speenoge", county: "Donegal", lat: 55.03621, lng: -7.44309 },
  { town: "Spiddle", county: "Galway", lat: 53.24418, lng: -9.30536 },
  { town: "Srah", county: "Mayo", lat: 53.69568, lng: -9.3342 },
  { town: "Sraheens", county: "Mayo", lat: 53.85874, lng: -9.0945 },
  { town: "Srahmore", county: "Mayo", lat: 53.96415, lng: -9.57067 },
  { town: "Srahnamanragh", county: "Mayo", lat: 54.07025, lng: -9.83324 },
  { town: "Stabannan", county: "Louth", lat: 53.86684, lng: -6.43959 },
  { town: "Stackallan", county: "Meath", lat: 53.69758, lng: -6.61988 },
  { town: "Stamullin", county: "Meath", lat: 53.62768, lng: -6.26355 },
  { town: "Staplestown", county: "Kildare", lat: 53.32993, lng: -6.76844 },
  { town: "Stepaside", county: "Dublin", lat: 53.25306, lng: -6.2143 },
  { town: "Stifyans", county: "Louth", lat: 53.82308, lng: -6.46985 },
  { town: "Stillorgan", county: "Dublin", lat: 53.2849, lng: -6.20195 },
  { town: "Stoneyford", county: "Kilkenny", lat: 52.53639, lng: -7.22813 },
  { town: "Stradbally", county: "Kerry", lat: 52.24422, lng: -10.06238 },
  { town: "Stradbally", county: "Laois", lat: 53.0158, lng: -7.14948 },
  { town: "Stradbally", county: "Waterford", lat: 52.13136, lng: -7.46089 },
  { town: "Strade", county: "Mayo", lat: 53.92107, lng: -9.13078 },
  { town: "Stradone", county: "Cavan", lat: 53.98207, lng: -7.23664 },
  { town: "Straffan", county: "Kildare", lat: 53.31188, lng: -6.60882 },
  { town: "Straleel", county: "Donegal", lat: 54.67691, lng: -8.61868 },
  { town: "Strand", county: "Limerick", lat: 52.39799, lng: -9.10836 },
  { town: "Strandhill", county: "Sligo", lat: 54.26999, lng: -8.59894 },
  { town: "Stranoodan", county: "Monaghan", lat: 54.17598, lng: -6.99645 },
  { town: "Stranorlar", county: "Donegal", lat: 54.80489, lng: -7.76868 },
  { town: "Stratford", county: "Wicklow", lat: 52.98845, lng: -6.67372 },
  { town: "Streamstown", county: "Westmeath", lat: 53.43927, lng: -7.57545 },
  { town: "Street", county: "Westmeath", lat: 53.68068, lng: -7.4915 },
  { town: "Strokestown", county: "Roscommon", lat: 53.77587, lng: -8.10354 },
  { town: "Summerhill", county: "Meath", lat: 53.48136, lng: -6.73251 },
  { town: "Suncroft", county: "Kildare", lat: 53.10758, lng: -6.86139 },
  { town: "Sutton", county: "Dublin", lat: 53.38952, lng: -6.1103 },
  { town: "Swanlinbar", county: "Cavan", lat: 54.19377, lng: -7.70472 },
  { town: "Swinford", county: "Mayo", lat: 53.9437, lng: -8.95 },
  { town: "Swords", county: "Dublin", lat: 53.45575, lng: -6.21975 },
  { town: "Taghmaconnell", county: "Roscommon", lat: 53.394, lng: -8.17207 },
  { town: "Taghmon", county: "Wexford", lat: 52.32298, lng: -6.65468 },
  { town: "Tagoat", county: "Wexford", lat: 52.24544, lng: -6.38831 },
  { town: "Tahilla", county: "Kerry", lat: 51.83044, lng: -9.82242 },
  { town: "Tallaght", county: "Dublin", lat: 53.28659, lng: -6.36659 },
  { town: "Tallanstown", county: "Louth", lat: 53.92007, lng: -6.54718 },
  { town: "Tallow", county: "Waterford", lat: 52.09214, lng: -8.00702 },
  { town: "Tang", county: "Westmeath", lat: 53.53518, lng: -7.79277 },
  { town: "Tara", county: "Meath", lat: 53.56786, lng: -6.57999 },
  { town: "Tarbert", county: "Kerry", lat: 52.5726, lng: -9.37512 },
  { town: "Tawny", county: "Donegal", lat: 55.19895, lng: -7.69202 },
  { town: "Tawnylea", county: "Leitrim", lat: 54.20466, lng: -8.15734 },
  { town: "Tedavnet", county: "Monaghan", lat: 54.29627, lng: -7.01543 },
  { town: "Teerelton", county: "Cork", lat: 51.83849, lng: -8.99069 },
  { town: "Teevurcher", county: "Meath", lat: 53.87933, lng: -6.92644 },
  { town: "Templeboy", county: "Sligo", lat: 54.24407, lng: -8.81508 },
  { town: "Templederry", county: "Tipperary", lat: 52.77428, lng: -8.07312 },
  { town: "Templemartin", county: "Cork", lat: 51.80664, lng: -8.76143 },
  { town: "Templemore", county: "Tipperary", lat: 52.7948, lng: -7.83986 },
  { town: "Templeogue", county: "Dublin", lat: 53.2963, lng: -6.3091 },
  { town: "Templeorum", county: "Kilkenny", lat: 52.38389, lng: -7.29801 },
  { town: "Templeshanbo", county: "Wexford", lat: 52.57921, lng: -6.67828 },
  { town: "Templetouhy", county: "Tipperary", lat: 52.78874, lng: -7.72031 },
  { town: "Terenure", county: "Dublin", lat: 53.30981, lng: -6.28351 },
  { town: "Termon", county: "Donegal", lat: 55.0488, lng: -7.81726 },
  { town: "Termonbarry", county: "Roscommon", lat: 53.74229, lng: -7.92064 },
  { town: "Termonfeckin", county: "Louth", lat: 53.76101, lng: -6.27185 },
  { town: "Terryglass", county: "Tipperary", lat: 53.05645, lng: -8.19875 },
  { town: "The Butts", county: "Carlow", lat: 52.76949, lng: -7.07752 },
  { town: "The Downs", county: "Westmeath", lat: 53.50844, lng: -7.2418 },
  { town: "The Heath", county: "Laois", lat: 53.05597, lng: -7.22453 },
  { town: "The Pigeons", county: "Westmeath", lat: 53.50906, lng: -7.82803 },
  { town: "The Rower", county: "Kilkenny", lat: 52.4545, lng: -6.95864 },
  { town: "Thomastown", county: "Kilkenny", lat: 52.52709, lng: -7.13882 },
  { town: "Thomastown", county: "Tipperary", lat: 52.49321, lng: -8.02331 },
  { town: "Threecastles", county: "Kilkenny", lat: 52.71178, lng: -7.32685 },
  {
    town: "Three Mile House",
    county: "Monaghan",
    lat: 54.21667,
    lng: -7.04267,
  },
  { town: "Thurles", county: "Tipperary", lat: 52.68011, lng: -7.80444 },
  { town: "Tibohine", county: "Roscommon", lat: 53.88702, lng: -8.50125 },
  { town: "Tiknock", county: "Carlow", lat: 52.89196, lng: -6.61911 },
  { town: "Timahoe", county: "Laois", lat: 52.96066, lng: -7.20147 },
  { town: "Timoleague", county: "Cork", lat: 51.64323, lng: -8.76618 },
  { town: "Timolin", county: "Kildare", lat: 52.98494, lng: -6.81008 },
  { town: "Tinahely", county: "Wicklow", lat: 52.79977, lng: -6.46319 },
  { town: "Tinryland", county: "Carlow", lat: 52.79697, lng: -6.89575 },
  { town: "Tipperary", county: "Tipperary", lat: 52.47473, lng: -8.15444 },
  { town: "Tobercurry", county: "Sligo", lat: 54.05522, lng: -8.72983 },
  { town: "Toem", county: "Tipperary", lat: 52.57943, lng: -8.19748 },
  { town: "Togher", county: "Louth", lat: 53.83923, lng: -6.30209 },
  { town: "Tomhaggard", county: "Wexford", lat: 52.21619, lng: -6.4941 },
  { town: "Tonregee", county: "Mayo", lat: 53.95265, lng: -9.85565 },
  { town: "Tonyduff", county: "Cavan", lat: 53.95854, lng: -7.06124 },
  { town: "Toomard", county: "Galway", lat: 53.52447, lng: -8.45048 },
  { town: "Tooms", county: "Cork", lat: 51.86584, lng: -8.98271 },
  { town: "Toomyvara", county: "Tipperary", lat: 52.85072, lng: -8.03505 },
  { town: "Toor", county: "Tipperary", lat: 52.73017, lng: -8.29337 },
  { town: "Tooraneena", county: "Waterford", lat: 52.20252, lng: -7.71585 },
  { town: "Tooreen", county: "Mayo", lat: 53.829, lng: -8.76471 },
  { town: "Toorlestraun", county: "Sligo", lat: 54.03823, lng: -8.84729 },
  { town: "Toormakeady", county: "Mayo", lat: 53.65483, lng: -9.36635 },
  { town: "Toormore", county: "Cork", lat: 51.5194, lng: -9.65379 },
  { town: "Toornafulla", county: "Limerick", lat: 52.36394, lng: -9.15704 },
  { town: "Tory Island", county: "Donegal", lat: 55.26638, lng: -8.22885 },
  { town: "Trafrask", county: "Cork", lat: 51.68598, lng: -9.67002 },
  { town: "Tralee", county: "Kerry", lat: 52.27131, lng: -9.69992 },
  { town: "Tramore", county: "Waterford", lat: 52.16527, lng: -7.14617 },
  { town: "Trien", county: "Roscommon", lat: 53.73209, lng: -8.55321 },
  { town: "Trim", county: "Meath", lat: 53.55483, lng: -6.79113 },
  { town: "Tuam", county: "Galway", lat: 53.51799, lng: -8.85563 },
  { town: "Tuamgraney", county: "Clare", lat: 52.89852, lng: -8.54102 },
  { town: "Tubber", county: "Galway", lat: 52.99954, lng: -8.88316 },
  { town: "Tulla", county: "Clare", lat: 52.86922, lng: -8.76078 },
  { town: "Tullaghan", county: "Leitrim", lat: 54.46845, lng: -8.32905 },
  { town: "Tullagher", county: "Kilkenny", lat: 52.43025, lng: -7.05405 },
  { town: "Tullamore", county: "Offaly", lat: 53.27553, lng: -7.49339 },
  { town: "Tullaroan", county: "Kilkenny", lat: 52.66257, lng: -7.43635 },
  { town: "Tullcoe", county: "Cavan", lat: 54.0111, lng: -7.20228 },
  { town: "Tullig", county: "Clare", lat: 52.61486, lng: -9.78208 },
  { town: "Tullow", county: "Carlow", lat: 52.80255, lng: -6.73716 },
  { town: "Tully", county: "Galway", lat: 53.59695, lng: -9.97571 },
  { town: "Tullyallen", county: "Louth", lat: 53.73679, lng: -6.41864 },
  { town: "Tullycoly", county: "Leitrim", lat: 54.19172, lng: -8.26412 },
  { town: "Tullylease", county: "Cork", lat: 52.3145, lng: -8.93942 },
  { town: "Tullynacross", county: "Monaghan", lat: 54.01323, lng: -6.68843 },
  { town: "Tullyvin", county: "Cavan", lat: 54.04996, lng: -7.14485 },
  { town: "Tulrohaun", county: "Mayo", lat: 53.70196, lng: -8.82445 },
  { town: "Tulsk", county: "Roscommon", lat: 53.77999, lng: -8.25605 },
  { town: "Tuosist", county: "Kerry", lat: 51.80358, lng: -9.75653 },
  { town: "Turlough", county: "Mayo", lat: 53.88411, lng: -9.21248 },
  { town: "Turloughmore", county: "Galway", lat: 53.38213, lng: -8.85369 },
  { town: "Twomileborris", county: "Tipperary", lat: 52.67257, lng: -7.71144 },
  { town: "Two Mile House", county: "Kildare", lat: 53.17032, lng: -6.70003 },
  { town: "Tymon North", county: "Dublin", lat: 53.29605, lng: -6.34062 },
  { town: "Tynagh", county: "Galway", lat: 53.15002, lng: -8.37365 },
  { town: "Tyrrellspass", county: "Westmeath", lat: 53.38896, lng: -7.37734 },
  { town: "Ummoon", county: "Mayo", lat: 53.94536, lng: -9.10673 },
  { town: "Unionhall", county: "Cork", lat: 51.55881, lng: -9.14348 },
  { town: "Upperchurch", county: "Tipperary", lat: 52.70266, lng: -8.01922 },
  { town: "Urhin", county: "Cork", lat: 51.6712, lng: -10.00349 },
  { town: "Urlaur", county: "Mayo", lat: 53.85633, lng: -8.73491 },
  { town: "Urlingford", county: "Kilkenny", lat: 52.71975, lng: -7.58387 },
  { town: "Valencia Island", county: "Kerry", lat: 51.90454, lng: -10.36387 },
  { town: "Valleymount", county: "Wicklow", lat: 53.10608, lng: -6.52506 },
  { town: "Ventry", county: "Kerry", lat: 52.13331, lng: -10.3617 },
  { town: "Vicarstown", county: "Laois", lat: 53.05176, lng: -7.08342 },
  { town: "Villierstown", county: "Waterford", lat: 52.08824, lng: -7.85077 },
  { town: "Virginia", county: "Cavan", lat: 53.83441, lng: -7.08132 },
  { town: "Walderstown", county: "Westmeath", lat: 53.46704, lng: -7.79406 },
  { town: "Walkinstown", county: "Dublin", lat: 53.3174, lng: -6.33243 },
  { town: "Walshisland", county: "Offaly", lat: 53.23282, lng: -7.22205 },
  { town: "Waterford", county: "Waterford", lat: 52.25273, lng: -7.1256 },
  { town: "Watergrasshill", county: "Cork", lat: 52.01137, lng: -8.34404 },
  { town: "Waterville", county: "Kerry", lat: 51.82947, lng: -10.17453 },
  { town: "Wellingtonbridge", county: "Wexford", lat: 52.26623, lng: -6.74819 },
  { town: "Westport", county: "Mayo", lat: 53.80213, lng: -9.51436 },
  { town: "Wexford", county: "Wexford", lat: 52.33692, lng: -6.46333 },
  { town: "Whiddy Island", county: "Cork", lat: 51.68948, lng: -9.50195 },
  { town: "Whitechurch", county: "Cork", lat: 51.984, lng: -8.51647 },
  { town: "Whitegate", county: "Clare", lat: 52.95055, lng: -8.37291 },
  { town: "Whitegate", county: "Cork", lat: 51.82679, lng: -8.2321 },
  { town: "Whitehall", county: "Dublin", lat: 53.38248, lng: -6.24451 },
  { town: "Whitehall", county: "Limerick", lat: 52.63742, lng: -8.53305 },
  { town: "Whitehall", county: "Roscommon", lat: 53.76247, lng: -7.92086 },
  { town: "Wicklow", county: "Wicklow", lat: 52.98082, lng: -6.04459 },
  { town: "Wilkinstown", county: "Meath", lat: 53.73518, lng: -6.71187 },
  { town: "Willbrook", county: "Dublin", lat: 53.29507, lng: -6.28696 },
  { town: "Williamstown", county: "Galway", lat: 53.67772, lng: -8.57997 },
  { town: "Windgap", county: "Kilkenny", lat: 52.46129, lng: -7.39619 },
  { town: "Windy Arbour", county: "Dublin", lat: 53.29986, lng: -6.24628 },
  { town: "Wolfhill", county: "Laois", lat: 52.89824, lng: -7.13157 },
  { town: "Woodford", county: "Galway", lat: 53.05218, lng: -8.40024 },
  { town: "Woodlawn", county: "Galway", lat: 53.3431, lng: -8.47378 },
  { town: "Woodsgift", county: "Kilkenny", lat: 52.71936, lng: -7.51114 },
  { town: "Yellow Furze", county: "Meath", lat: 53.67338, lng: -6.5721 },
  { town: "Youghal", county: "Cork", lat: 51.95427, lng: -7.84717 },
];

const TermsAndCondtitions = () => {
  return (
    <MapContainer
      center={[53.4129, -8.2439]}
      zoom={7}
      style={{ height: "800px", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution="© OpenStreetMap contributors"
      />

      {towns2.map((town, index) => (
        <Marker key={index} position={[town.lat, town.lng]} icon={icon}>
          <Popup>
            {town.town}, {town.county}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
};

export default TermsAndCondtitions;
